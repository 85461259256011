import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  align-items: left;
  justify-content: end;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
`;
