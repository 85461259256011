/* eslint-disable no-unused-vars */
import styled, {css, keyframes } from "styled-components";

export const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const fadeOut = keyframes`
 from { 
     opacity: 1;
        }
 to { 
     opacity: 0;
    }
`;

const spinnerKeyframes = keyframes`to { transform: rotate(1turn); }`;
const fadeInKeyframes = (from = 0, to = 1) => keyframes`
  from { opacity: ${from}; }
  to { opacity: ${to}; }
`;

const spin = css`animation: ${spinnerKeyframes} 0.86s cubic-bezier(0.4, 0.15, 0.6, 0.85) infinite;`;

const extend = css`
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const overlay = (p = {}) => css`
  position: relative;

  * {
    pointer-events: none;
  }

  &::before {
    ${fadeIn}
    ${extend}
    content: '';
    position: absolute;
    background: ${p.background || "hsla(0, 0%, 100%, 0.8)"};
    z-index: 1;
  }
`;

export const spinner = (p = {}) => css`
  ${overlay(p)}

  &::after {
    ${fadeIn};
    ${spin};
    content: '';
    position: absolute;
    width: ${p.diameter || "30px"};
    height: ${p.diameter || "30px"};
    border-radius: 50%;
    border: ${p.border || "2px"} solid ${p.foreground || "hsla(0, 0%, 0%, 0.25)"};
    border-top-color: transparent;
    z-index: 2;
  }
`;
