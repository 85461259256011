import React from "react";
import { Wrapper } from "./styled";
import Sections from "./components/Sections";
import Options from "./components/Options";

const Bar = ({ toogle }) => {
  return (
    <Wrapper>
      <Sections toogle={toogle} />
      <Options toogle={toogle} />
    </Wrapper>
  );
};

export default Bar;
