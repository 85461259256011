import React from "react";
import Cell from "components/commons/Table/components/Structure/components/Cell";
import { Column } from "components/commons/Table/styled";
import StateCell from "components/commons/Table/components/Structure/components/StateCell";
import DeleteCell from "components/commons/Table/components/Structure/components/DeleteCell";
import ActiveCell from "components/commons/Table/components/Structure/components/ActiveCell";
import SendEmailCell from "components/commons/Table/components/Structure/components/SendEmailCell";
import EditCell from "components/commons/Table/components/Structure/components/EditCell";
import { USER_PROFILE } from "store/Login/initialState";
import { Delete } from "components/commons/Table/components/Structure/components/DeleteCell/styled";

const columns = [
  {
    Header: () => <Column>Nombre</Column>,
    width: 217,
    accessor: "name",
    Cell: ({ value, row }) => <Cell value={value} row={row} />,
  },
  {
    Header: () => <Column>Tipo de perfil</Column>,
    width: 126,
    accessor: "profileId",
    Cell: ({ value }) => <Cell value={USER_PROFILE[value]} />,
  },
  {
    Header: () => <Column>Mail</Column>,
    width: 231,
    accessor: "email",
    Cell: ({ value, row }) => <Cell value={value} row={row} />,
  },
  {
    Header: () => <Column>Estado</Column>,
    width: 136,
    id: "state",
    Cell: ({ row }) => <StateCell row={row} />,
  },
  {
    Header: () => <Column>Eliminar</Column>,
    width: 73,
    id: "delete",
    Cell: ({ row }) => <DeleteCell row={row} icon={<Delete />} />,
  },
  {
    Header: () => <Column>Activar</Column>,
    width: 71,
    id: "active",
    Cell: ({ row }) => <ActiveCell row={row} />,
  },
  {
    Header: () => <Column>Enviar mail</Column>,
    width: 72,
    id: "sendMail",
    Cell: ({ row }) => <SendEmailCell row={row} />,
  },
  {
    Header: () => <Column>Editar</Column>,
    width: 73,
    id: "seeMore",
    Cell: ({ row }) => <EditCell row={row} />,
  },
];

export const getColumns = () => {
  return columns;
};
