import React from "react";
import { Wrapper } from "./styled";
import { get } from "lodash";
import { useSelector } from "react-redux";
import { checkDependecies } from "../../functions";
import { selectPaperworkMetadata } from "store/PaperWork/selectors";

const SectionText = ({ item }) => {
  const { dependencies } = item;
  const label = get(item, "label", null);
  const paperwork = useSelector(selectPaperworkMetadata);
  const stage = get(paperwork, "stageProgress", {});
  const values = checkDependecies(stage, dependencies, false);

  if (values.hidden) return null;

  return <Wrapper>{label}</Wrapper>;
};

export default SectionText;
