import { createGlobalStyle } from "styled-components";
import OpenSansLight from "fonts/OpenSans/OpenSans-Light.ttf";
import OpenSansRegular from "fonts/OpenSans/OpenSans-Regular.ttf";
import OpenSansExtraBold from "fonts/OpenSans/OpenSans-ExtraBold.ttf";
import OpenSansSemiBold from "fonts/OpenSans/OpenSans-Semibold.ttf";
import Domine from "fonts/Domine/Domine-VariableFont_wght.ttf";
import DomineRegular from "fonts/Domine/static/Domine-Regular.ttf";
import DomineBold from "fonts/Domine/static/Domine-Bold.ttf";

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'OpenSansLight';
    font-style: normal;
    font-weight: 100;
    src: url(${OpenSansLight}) format("opentype");
  }
  @font-face {
    font-family: 'OpenSansRegular';
    font-style: normal;
    font-weight: 100;
    src: url(${OpenSansRegular}) format("opentype");
  }
  @font-face {
    font-family: 'OpenSansSemiBold';
    font-style: normal;
    font-weight: 100;
    src: url(${OpenSansSemiBold}) format("opentype");
  }
  @font-face {
    font-family: 'OpenSansExtraBold';
    font-style: normal;
    font-weight: 100;
    src: url(${OpenSansExtraBold}) format("opentype");
  }
  
  @font-face {
    font-family: 'Domine';
    font-style: normal;
    font-weight: 100;
    src: url(${Domine}) format("opentype");
  }
  @font-face {
    font-family: 'DomineBold';
    font-style: normal;
    font-weight: 100;
    src: url(${DomineBold}) format("opentype");
  }
  
  @font-face {
    font-family: 'DomineRegular';
    font-style: normal;
    font-weight: 100;
    src: url(${DomineRegular}) format("opentype");
  }
  
`;
