/* eslint-disable no-unused-vars */
/* eslint-disable react/no-children-prop */
import React, { useState } from "react";
import { Wrapper, Label } from "./styled";
import CommonInput from "components/commons/Inputs/CommonInput";
import colors from "styles/colors";
import useResize from "hooks/useResize";

const PasswordInput = ({ val, disabled, placeholder, name, setUpdateUser, updateUser, required }) => {
  const { screenWidth, screenHeight } = useResize();
  const height = screenWidth <= 905 ? "40px" : "48px";

  const [error, setError] = useState(false);
  const errorMessage = error ? "La contraseña ingresa es inválida" : "";

  const onChange = (e) => {
    if (required && !/^.{6,}$/.test(e.target.value)) setError(true);
    else setError(false);
    setUpdateUser({ ...updateUser, [e.target.name]: e.target.value });
  };

  return (
    <Wrapper>
      <Label children="Contraseña" />
      <CommonInput
        type="password"
        name={name}
        errorMessage={errorMessage}
        error={error}
        height={height}
        onChange={onChange}
        value={val}
        disabled={disabled}
        placeholder={placeholder}
        color={colors.gray10}
        border={`1px solid ${colors.gray50}`}
        borderRadius={"4px"}
        autoComplete={"new-password"}
      />
    </Wrapper>
  );
};

export default PasswordInput;
