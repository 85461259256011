import React from "react";
import { Wrapper } from "./styled";
import { get, entries, flatMap } from "lodash";
import ImageCard from "../Cards/ImageCard";
import DataCard from "../Cards/DataCard";

const Content = ({ stage, index }) => {
  const { jsonSchemaData, otherInputsData, formFieldsSchema } = stage;
  const inputsData = entries(otherInputsData).map((e) => e.pop());
  const schemaData = entries(jsonSchemaData);
  const attachments = get(flatMap(inputsData)[0], "file", []);

  const getAttachmentsCard = (data, i) => <ImageCard key={`${data}_${i}`} value={data} image={true} />;

  const getCard = (data, i) => <DataCard key={`${data[0]}_${i}`} index={index} schemaFields={formFieldsSchema} data={data} />;

  return (
    <Wrapper>
      {schemaData.map(getCard)}
      {inputsData.map((e) => entries(e).map(getCard))}
      {attachments.map(getAttachmentsCard)}
    </Wrapper>
  );
};

export default Content;
