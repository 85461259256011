import styled from "styled-components";
import colors from "styles/colors";
import { ReactComponent as Prov } from "images/logos/MARCA COMPLETA-03.svg";
import { ReactComponent as DGC } from "images/logos/logo-dgc.svg";
import ImageDesk from "images/ilustrations/reset_password.svg";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  width: 100%;
  height: 100%;
  padding-bottom: 44px;
  box-sizing: border-box;
  @media (max-width: 830px) or (min-height: 1180px) {
    width: 100%;
    overflow-y: overlay;
  }
  @media (max-width: 580px) {
    overflow-y: overlay;
  }
  @media (max-height: 700px) {
    padding: 0px;
  }
`;

export const Logos = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 16px 200px 0px 200px;
  margin-bottom: 184px;
  box-sizing: border-box;
  @media (max-width: 1366px) and (max-height: 700px) {
    margin-bottom: 0px;
  }
  @media (max-width: 905px) {
    margin-bottom: 0px;
    padding: 0px 30px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  min-width: 512px;
  height: 376px;
  padding: 48px 40px;
  box-sizing: border-box;
  border: 1px solid ${colors.gray90};
  background: ${colors.white};
  z-index: 1;
  margin-top: 40px;
  @media (max-width: 830px) or (min-height: 1180px) {
    border: none;
  }
  @media (max-width: 580px) {
    padding: 20px;
  }
  @media (max-width: 480px) {
    min-width: 80%;
  }
`;

export const IconProv = styled(Prov)`
  @media (max-width: 905px) {
    width: 128px;
    height: 72px;
  }
`;

export const IconDGC = styled(DGC)`
  @media (max-width: 905px) {
    width: 161px;
    height: 72px;
  }
`;

export const Image = styled.div`
  width: 544px;
  height: 400px;
  margin: auto auto 0px 14px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(${ImageDesk});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  @media (max-width: 830px) or (max-width: 1366px) {
    width: 272px;
    height: 200px;
  }
`;
