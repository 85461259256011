import React from "react";
import { Wrapper } from "./styled";
import { useSelector } from "react-redux";
import { selectModal } from "../../../../../store/Modals/selectors";

const Modal = ({ component, id }) => {
  const show = useSelector((state) => selectModal(state, id));

  if (!show) return null;
  return <Wrapper>{component}</Wrapper>;
};

export default Modal;
