/* eslint-disable no-unused-vars */
import React from "react";
import colors from "styles/colors";
import { Wrapper, Text, TextWrapper } from "./styled";
import { useDispatch, useSelector } from "react-redux";
import { selectPaperworkMetadata, selectUserCanEditPaperworkProgress } from "store/PaperWork/selectors";
import { PAPERWORK_STATE } from "store/PaperWork/constants";
import LabelIconButton from "components/pages/PaperworkDetail/components/LabelIconButton";
import { openModal } from "store/Modals/actions";
import { MODAL_EDIT_MEND } from "store/Modals/constants";
import Header from "./components/Header";
import { USER_PROFILE_ID } from "store/Login/initialState";
import { selectIsAllowed } from "store/Login/selectors";
import get from "lodash/get";

const Rectification = () => {
  const dispatch = useDispatch();
  const metadata = useSelector(selectPaperworkMetadata);
  const state = get(metadata, "state", null);
  const isPending = state === PAPERWORK_STATE.PENDING;
  const isMendRequested = state === PAPERWORK_STATE.MENDING_REQUESTED;
  const canEdit = useSelector(selectUserCanEditPaperworkProgress);
  const allowedRoles = [USER_PROFILE_ID.OPERATOR, USER_PROFILE_ID.ADMINISTRATOR, USER_PROFILE_ID.CERTIFIER];
  const allowed = useSelector((state) => selectIsAllowed(state, allowedRoles));

  const requestMend = () => dispatch(openModal(MODAL_EDIT_MEND));

  const text =
    "Si el trámite no cuenta con todos los valores necesarios para ser procesado, deberá solicitar una subsanación al departamento que corresponda.";

  if (!allowed) return null;
  if (isMendRequested) return null;
  return (
    <Wrapper>
      <Header title={"Subsanación"} />
      <TextWrapper>
        <Text>{text}</Text>
      </TextWrapper>
      <LabelIconButton
        type={"primary"}
        // disabled={!isPending || !canEdit}
        disabled
        color={colors.blue50}
        background={colors.white}
        label={"Solicitar subsanación"}
        border={`1px solid ${colors.blue70}`}
        onClick={requestMend}
        width={"216px"}
        height={"40px"}
      />
    </Wrapper>
  );
};

export default Rectification;
