/* eslint-disable react/jsx-key */
import React from "react";
import { THead, THHead, TRHead } from "../../../../styled";

const Header = ({ data }) => {
  return (
    <THead>
      {data.map((headerGroup) => (
        <TRHead {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column) => (
            <THHead {...column.getHeaderProps()}>{column.render("Header")}</THHead>
          ))}
        </TRHead>
      ))}
    </THead>
  );
};

export default Header;
