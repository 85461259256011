import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  background: white;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
`;

export const Switch = styled.div`
  box-sizing: border-box;
  position: relative;
  min-width: 52px;
  min-height: 32px;
  background: ${colors.gray100};
  border-radius: 35px;
  padding: 4px;
  transition: 300ms all;
  background: ${colors.gray95};
  border: ${(props) => (props.checked ? `2px solid ${colors.lightBlue50}` : `2px solid ${colors.gray70}`)};
  &:before {
    transition: 300ms all;
    content: "";
    position: absolute;
    min-width: 16px;
    min-height: 16px;
    border-radius: 100px;
    top: 50%;
    background: ${colors.gray70};
    transform: translate(0, -50%);
  }
`;

export const Input = styled.input`
  display: none;
  &:checked + ${Switch} {
    background: ${colors.lightBlue95};

    &:before {
      transform: translate(24px, -50%);
      background: ${colors.lightBlue50};
    }
  }
`;

export const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;
