import BackOfficeHeader from "components/commons/BackofficeHeader";
import { PATH_HOME } from "components/pages/App/constants";
import React from "react";

const Header = () => {
  return (
    <BackOfficeHeader
      title={"Trámite generado"}
      description={"Los siguientes ítems son los especificados en el formulario"}
      showBack={"Volver al menu de tramites"}
      route={PATH_HOME}
    />
  );
};

export default Header;
