import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  width: 100%;
  margin-top: 10px;
  overflow: hidden;
  text-align: center;
  font-family: "OpenSansRelative";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: ${colors.gray70};
  &::before,
  &::after {
    background-color: ${colors.gray70};
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
  }
  &::before {
    right: 1.5em;
    margin-left: -50%;
  }
  &::after {
    left: 1.5em;
    margin-right: -50%;
  }
`;
