import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  width: 100%;
  padding: 40px 30px 0px 30px;
  box-sizing: border-box;
  min-height: 96px;
  @media (max-width: 680px) {
    padding: 32px 20px 0px 20px;
  }
`;

export const Title = styled.div`
  font-family: "Domine";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: ${colors.gray20};
  @media (max-width: 580px) {
    font-size: 18px;
  }
`;

export const Line = styled.div`
  display: flex;
  width: 100%;
  margin-top: auto;
  border: 1px solid ${colors.gray95};
`;
