import styled from "styled-components";
import colors from "styles/colors";
import { fadeIn } from "styles/effects";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 5000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 30px 30px 30px 30px;
  overflow: hidden;
  animation: 1s ${fadeIn} forwards;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.75);
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  width: 100%;
  max-width: 512px;
  min-height: 656px;
  padding: 48px 40px;
  box-sizing: border-box;
  background: ${colors.gray100};
  border-radius: 8px;
  overflow: auto;
  @media (max-width: 480px) {
    width: 100%;
    min-height: auto;
  }
`;
