/* eslint-disable react/no-children-prop */
import React from "react";
import { Wrapper, Title, Content, Column, Text, SubTitle } from "../styled";
import { useSelector } from "react-redux";
import { selectPaperworkData } from "store/PaperWork/selectors";
import { get } from "lodash";

const Value = ({ selectedPlot }) => {
  const data = useSelector(selectPaperworkData);
  const economic = get(data, `[${selectedPlot}].valueData.economic`, {});
  const fiscal = get(data, `[${selectedPlot}].valueData.fiscal`, {});

  const landValue = get(economic, "land", "-");
  const E1Value = get(economic, "E1", "-");
  const E2Value = get(economic, "E2", "-");
  const plantationValue = get(economic, "plantation", "-");
  const totalValue = get(economic, "total", "-");

  const fiscalLandValue = get(fiscal, "land", "-");
  const fiscalE1Value = get(fiscal, "E1", "-");
  const fiscalE2Value = get(fiscal, "E2", "-");
  const fiscalPlantationValue = get(fiscal, "plantation", "-");
  const fiscalTotalValue = get(fiscal, "total", "-");

  return (
    <Wrapper>
      <Title children="Valor económico y Fiscal según Ley VII - N°73 Partida 91863" />
      <Content>
        <Column>
          <SubTitle children="Económico" />
          <Text children={`Valor tierra: ${landValue}`} />
          <Text children={`Valor E-1: ${E1Value}`} />
          <Text children={`Valor E-2: ${E2Value}`} />
          <Text children={`Valor plantación: ${plantationValue}`} />
          <Text children={`Valor total: ${totalValue}`} />
        </Column>
        <Column>
          <SubTitle children="Fiscal" />
          <Text children={`Valor tierra: ${fiscalLandValue}`} />
          <Text children={`Valor E-1: ${fiscalE1Value}`} />
          <Text children={`Valor E-2: ${fiscalE2Value}`} />
          <Text children={`Valor plantación: ${fiscalPlantationValue}`} />
          <Text children={`Valor total: ${fiscalTotalValue}`} />
        </Column>
      </Content>
    </Wrapper>
  );
};

export default Value;
