import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectPaperworkStructure, selectPaperworkMetadata, selectStageIndex } from "store/PaperWork/selectors";
import Card from "./components/Cards";
import { Wrapper } from "./styled";
import { get } from "lodash";
import { selectionIsComplete, valueToId } from "store/PaperWork/actions/functions";
import { setStageData } from "store/PaperWork/actions/actions";
import { OTHER_INPUTS } from "store/PaperWork/constants";
import { convertValues } from "./functions";

const MultipleCardSelector = ({ item }) => {
  const { key, options } = item;
  const dispatch = useDispatch();
  const index = useSelector(selectStageIndex);
  const paperw = useSelector(selectPaperworkStructure);
  const stageProg = useSelector(selectPaperworkMetadata);
  const data = get(stageProg, `stageProgress[${index}].otherInputsData[${key}]`, []);
  const stage = get(stageProg, `stageProgress[${index}]`, {});
  const otherInputs = get(paperw, `stages[${index}].form.otherInputs`, []);
  const otherInputsData = get(stageProg, `stageProgress[${index}].otherInputsData[${key}]`, []);
  const values = valueToId(otherInputsData, otherInputs);

  const getCards = (i) => <Card option={i} key={`${key}_${i.id}_${i.name}` } />;

  useEffect(() => dispatch(selectionIsComplete(data)), [paperw, stageProg, index]);

  useEffect(() => dispatch(setStageData(OTHER_INPUTS, convertValues(key, stage, values))), [index]);

  // the condition srcExpanded !== "" is necessary because we only want set src as "" if the src is different from ""
  return <Wrapper> {options.map(getCards)} </Wrapper>;
};

export default MultipleCardSelector;
