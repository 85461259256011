import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  height: 58px;
  width: 292px;
  box-sizing: border-box;
  overflow: visible;
  transition: all 1s;
  cursor: pointer;
`;