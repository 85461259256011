import styled from "styled-components";
import colors from "styles/colors";
import { ReactComponent as Prov } from "images/logos/MARCA COMPLETA-03.svg";
import { ReactComponent as DGC } from "images/logos/logo-dgc.svg";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: right;
  justify-content: top;
  width: 50%;
  height: auto;
  padding: 32px 16px 0px 190px;
  box-sizing: border-box;
  background: ${colors.white};
  margin-top: auto;
  @media (min-width: 1366px) {
    min-height: 1024px;
  }
  @media (max-width: 905px) {
    width: 60%;
    align-items: center;
    padding: 16px 24px 0px 24px;
  }
  @media (max-width: 830px) or (orientation: portraite) {
    width: 100%;
    height: 58%;
    margin-top: auto;
    overflow-y: overlay;
  }
  @media (max-width: 580px) {
    height: 63%;
    overflow-y: overlay;
  }
`;

export const Logos = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 142px;
  box-sizing: border-box;
  @media (max-width: 905px) {
    margin-bottom: 36px;
  }
  @media (max-width: 830px) or (orientation: portraite) {
    display: none;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 424px;
  @media (max-width: 830px) or (orientation: portraite) {
    max-width: 100%;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  width: 424px;
  height: 624px;
  padding: 48px 40px;
  box-sizing: border-box;
  border: 1px solid ${colors.gray90};
  @media (max-width: 1366px) {
    width: 344px;
    height: auto;
    padding: 32px;
  }
  @media (max-width: 830px) or (orientation: portraite) {
    width: 100%;
    border: none;
    padding: 20px 112px;
  }
  @media (max-height: 700px) {
    padding: 32px;
  }
  @media (max-width: 580px) {
    padding: 0px 16px;
  }
`;

export const IconProv = styled(Prov)`
  @media (max-width: 905px) {
    width: 128px;
    height: 72px;
  }
`;

export const IconDGC = styled(DGC)`
  @media (max-width: 905px) {
    width: 161px;
    height: 72px;
  }
`;

export const NoAccount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  margin-bottom: 25px;
  gap: 5px;
`;

export const Text = styled.div`
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.black};
`;

export const Highlighted = styled.div`
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.lightBlue50};
  cursor: pointer;
`;
