import React from "react";
import { Wrapper, ButtonWrapper } from "./style";
import LabelOnlyButton from "components/commons/Buttons/LabelOnlyButton";
import colors from "styles/colors";

const Buttons = ({ onClose, onApply }) => {

    return (
        <Wrapper>
            <ButtonWrapper> 
                <LabelOnlyButton color={colors.blue50} onClick={onClose} label="Cancelar" fontSize={"16px"}/>
            </ButtonWrapper>
            <ButtonWrapper>
                <LabelOnlyButton color={colors.blue50} onClick={onApply} label="Aplicar" fontSize={"16px"}/>
            </ButtonWrapper>
        </Wrapper>
    );
};

export default Buttons;