import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  width: 100%;
  height: 100%;
  margin-top: 40px;
  padding: 0px 47px 20px 47px;
  gap: 24px;
  box-sizing: border-box;
  overflow: overlay;
  @media (max-width: 680px) {
    padding: 20px;
    margin-top: 4px;
  }
`;
