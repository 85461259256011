import React from "react";
import { Wrapper, Title, IconContainer } from "./style";
import { MODAL_SEE_MORE } from "store/Modals/constants";
import { useDispatch } from "react-redux";
import { closeModal } from "store/Modals/actions";
import CloseIcon from "components/commons/Icons/CloseIcon";
import { GRAY } from "styles/constants";


const Header = ({title}) => {
  const dispatch = useDispatch();

  const toggle = () => {
    dispatch(closeModal(MODAL_SEE_MORE));
  };

  return (
    <Wrapper>
      <Title>{title}</Title>
      <IconContainer>
        <CloseIcon onClick={toggle} color={GRAY}/>
      </IconContainer>
    </Wrapper>
  );
};

export default Header;
