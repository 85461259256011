import React from "react";
import colors from "styles/colors";
import Header from "./components/Header";
import Menu from "./components/Menu";
import { Content, MenuContainer, Section, Wrapper } from "./styled";

const CommonLayout = ({ children, header, background = colors.white, paddingLeft, paddingRight }) => {
  return (
    <Wrapper background={background}>
      <MenuContainer>
        <Menu />
      </MenuContainer>
      <Content paddingLeft={paddingLeft} paddingRight={paddingRight}>
        <Header content={header} />
        <Section>{children}</Section>
      </Content>
    </Wrapper>
  );
};

export default CommonLayout;
