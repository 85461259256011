import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  width: 80px;
  height: 100%;
  gap: 8px;
  box-sizing: border-box;
`;

export const Text = styled.div`
  display: flex;
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: ${colors.black};

  @media (max-width: 1366px) {
    font-size: 12px;
  }
`;
