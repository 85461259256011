import React from "react";
import { Wrapper, Image } from "../styled";
import Icon from "images/icons/general/telegramIcon.svg";

const TelegramIcon = ({ onClick }) => {
  return (
    <Wrapper onClick={onClick}>
      <Image src={Icon} alt="icon" />
    </Wrapper>
  );
};

export default TelegramIcon;
