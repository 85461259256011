import React, { useEffect } from "react";
import MainLayout from "components/commons/MainLayout";
import { useDispatch, useSelector } from "react-redux";
import { selectPaperworkData } from "store/PaperWork/selectors";
import { getDocuments, getPaperworkByType } from "store/PaperWork/actions/requests";
import colors from "styles/colors";
import Header from "../components/Header";
import { get } from "lodash";
import { PAPERWORK_TYPE } from "store/PaperWork/constants";
import Detail from "../components/Detail";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const ContributorDetail = () => {
  const dispatch = useDispatch();
  const data = useSelector(selectPaperworkData);
  const pwId = get(data, "[0].paperworkProgressId", null);
  const { id } = useParams();

  useEffect(() => {
    if (!pwId) dispatch(getPaperworkByType(PAPERWORK_TYPE.D2));
    dispatch(getDocuments(id));
  }, [dispatch]);

  return (
    <MainLayout background={colors.gray100} header={<Header />}>
      <Detail />
    </MainLayout>
  );
};

export default ContributorDetail;
