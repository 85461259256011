import React, { useEffect, useRef } from "react";
import FileUpload from "components/commons/FileUpload";
import { Description, Title, Wrapper } from "./styled";
import { useDispatch, useSelector } from "react-redux";
import { saveFiles, setForm } from "store/PaperWork/actions/functions";
import { selectPaperworkMetadata, selectStageIndex } from "store/PaperWork/selectors";
import { get } from "lodash";
import { checkDependecies, getInitialValue } from "../../functions";
import { getFiles, getNames } from "./functions";

const Upload = ({ item, stageKey }) => {
  const ref = useRef(null);
  const dispatch = useDispatch();
  const { key, dependencies, maximum, allowedExtensions, sizeLimit, label, description } = item;
  const index = useSelector(selectStageIndex);
  const paperwProg = useSelector(selectPaperworkMetadata);
  const stage = get(paperwProg, "stageProgress", {});
  const attachedFiles = get(paperwProg, `stageProgress[${index}].attachedFiles`, []);
  const filenameList = getInitialValue(stage, index, stageKey, key, []);
  const values = checkDependecies(stage, dependencies, null);
  const inDisuse = values.hidden || values.disabled;

  const selectFiles = (files) => {
    dispatch(saveFiles(false, getFiles(attachedFiles, files)));
    dispatch(setForm(stageKey, "file", getNames(filenameList, files)));
  };

  const removeFile = (fileName) => {
    if (filenameList.some((e) => e === fileName)) {
      ref.current.value = null;
      const auxFiles = filenameList.filter((e) => e !== fileName);
      const auxAttachedFiles = attachedFiles.filter((e) => e.name !== fileName);
      dispatch(setForm(stageKey, "file", auxFiles));
      dispatch(saveFiles(false, auxAttachedFiles));
    }
  };

  useEffect(() => {
    dispatch(setForm(stageKey, "file", filenameList, inDisuse));
    dispatch(saveFiles(inDisuse));
  }, [inDisuse]);
  
  if(values.hidden) return null;
  return (
    <Wrapper>
      <Title content={label}>{label}</Title>
      <Description content={description}>{description}</Description>
      <FileUpload
        inputRef={ref}
        disabled={values.disabled || filenameList.length === maximum}
        filenameList={filenameList}
        onChange={selectFiles}
        removeFile={removeFile}
        accept={allowedExtensions}
        sizeLimit={sizeLimit}
      />
    </Wrapper>
  );
};

export default Upload;
