import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: top;
  width: 100%;
  padding-top: 40px;
  gap: 8px;
  box-sizing: border-box;
  border-top: 1px solid ${colors.gray99};
`;

export const Text = styled.div`
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.black};
  user-select: none;
  @media (max-width: 1366px) {
    font-size: 12px;
  }
`;