import React from "react";
import { PAPERWORK_STATE } from "store/PaperWork/constants";
import { isEmpty } from "lodash";
import Card from "../Card";
import { Grid, Text, Wrapper } from "./styled";

const Category = ({ data, name }) => {
  const getCategoty = (i) => {
    return <Card key={i.id} type={i.id} text={`${i.id}. ${i.name}`} isDraft={i.state === PAPERWORK_STATE.STARTED} />;
  };

  if (isEmpty(data)) return null;

  return (
    <Wrapper>
      <Text text={name}>{name}</Text>
      <Grid>{data.map(getCategoty)}</Grid>
    </Wrapper>
  );
};

export default Category;
