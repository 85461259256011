import React from "react";
import colors from "styles/colors";
import { Wrapper } from "./styled";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "store/Modals/actions";
import { MODAL_SHARE_FILE } from "store/Modals/constants";
import { downloadReport, printReport } from "store/PaperWork/actions/requests";
import { selectPaperworkMetadata } from "store/PaperWork/selectors";
import LabelIconButton from "components/pages/PaperworkDetail/components/LabelIconButton";
import { PrinterIcon, ShareIcon, DownloadIcon } from "components/pages/PaperworkDetail/components/Detail/components/types/styled";
import get from "lodash/get";

const ShareActions = () => {
  const dispatch = useDispatch();
  const metadata = useSelector(selectPaperworkMetadata);
  const id = get(metadata, "id", null);
  const download = () => dispatch(downloadReport(id));
  const print = () => dispatch(printReport(id));

  return (
    <Wrapper>
      <LabelIconButton
        color={colors.blue50}
        background={colors.white}
        label={"Imprimir"}
        border={`1px solid ${colors.blue70}`}
        icon={<PrinterIcon />}
        onClick={print}
      />
      <LabelIconButton
        color={colors.blue50}
        background={colors.white}
        label={"Compartir"}
        border={`1px solid ${colors.blue70}`}
        icon={<ShareIcon />}
        onClick={() => dispatch(openModal(MODAL_SHARE_FILE))}
      />
      <LabelIconButton color={colors.white} background={colors.blue50} label={"Descargar"} icon={<DownloadIcon />} onClick={download} />
    </Wrapper>
  );
};

export default ShareActions;
