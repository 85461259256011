import { PATH_HOME, PATH_PAPERWORK } from "components/pages/App/constants";
import { getDefVal, getZeros, updateFieldObject } from "components/pages/Paperwork/components/Form/components/Fields/functions";
import { ALREADY_FILLED, ATTACHED_FILES, OTHER_INPUTS, PAPERWORK_WITHOUT_PREVIEW, SCHEMA_DATA } from "../constants";
import {
  selectFormErrors,
  selectModifyMode,
  selectPaperworkStructure,
  selectPaperworkType,
  selectPaperworkMetadata,
  selectPaperworkProgressId,
  selectStageIndex,
  selectPaperworkStageProgress,
} from "../selectors";
import { setFormErrors, setStage, setStageCompleted, setStageData, setStageIndex } from "./actions";
import unset from "lodash/unset";
import { get, isUndefined, isEmpty, isBoolean, isNull, values } from "lodash";
import { error, openModal, setMessageCode, setParams } from "store/Modals/actions";
import { MODAL_MULTIPLE_DEPARTURES, MODAL_SHOW_OBSERVATION } from "store/Modals/constants";
import { loadingFailed } from "store/General/actions";
import head from "lodash/head";
import cloneDeep from "lodash/cloneDeep";
import isInteger from "lodash/isInteger";
import { push } from "connected-react-router";
import { emptyStage, saveStage } from "./requests";
import { everyAlreadyFilled, generatePayload } from "components/pages/Paperwork/components/Buttons/functions";
import { selectProfileDefaultRoute, selectUserId } from "store/Login/selectors";
import { CONTROLS_FAILED_ERROR_CODE } from "store/Modals/messageDescriptions";
import { PAPERWORK_STATE } from "../constants";

export const redirectByPaperWStatus = (data, history) => history.push(PATH_PAPERWORK);

const checkIndex = (index) => {
  if (index < 0) return 0;
  return index;
};

export const getValueRef = (dependency, stage) => {
  const otherInputValue = get(stage, `[${dependency.stageIndex}]otherInputsData.[${dependency.stageKey}][${dependency.field}]`, null);
  const jsonSchemaValue = get(stage, `[${dependency.stageIndex}]jsonSchemaData[${dependency.field}];`, null);
  if (dependency.element === OTHER_INPUTS) return otherInputValue;
  if (dependency.element === SCHEMA_DATA) return jsonSchemaValue;
  return jsonSchemaValue;
};

const generateData = (data, otherInputsData, jsonSchemaData) => {
  const dataCopy = cloneDeep(data);
  dataCopy[ALREADY_FILLED] = !isEmpty(otherInputsData) || !isEmpty(jsonSchemaData);
  dataCopy[OTHER_INPUTS] = otherInputsData;
  if (isEmpty(jsonSchemaData)) {
    const jSDataCopy = { ...dataCopy[SCHEMA_DATA] };
    values(jSDataCopy).map((e) => e === null);
    dataCopy[SCHEMA_DATA] = jSDataCopy;
  } else dataCopy[SCHEMA_DATA] = jsonSchemaData;
  return dataCopy;
};

const processDefaultStages = (stages, stageProg, paperwType, progId) => {
  return (dispatch) => {
    if (!stages) return;
    stages.map((d) => {
      const data = stageProg[d.stageIndex];
      const stage = generatePayload(d.stage.jsonSchemaData, d.stage.otherInputsData, []);
      if (isEmpty(d.stage.otherInputsData) && isEmpty(d.stage.jsonSchemaData)) dispatch(emptyStage(paperwType, d.stageId, progId));
      else dispatch(saveStage(d.stageId, stage, true));
      dispatch(setStage(d.stageIndex, generateData(data, d.stage.otherInputsData, d.stage.jsonSchemaData)));
    });
  };
};

export const goToPreview = () => {
  return (dispatch, getState) => {
    const stageP = selectPaperworkStageProgress(getState());
    const progId = selectPaperworkProgressId(getState());
    const modify = everyAlreadyFilled(stageP);
    const editMode = selectModifyMode(getState());
    let index = stageP.map((e) => e.alreadyFilled).indexOf(false);
    const type = selectPaperworkType(getState());

    if (modify && !PAPERWORK_WITHOUT_PREVIEW.includes(type)) {
      dispatch(push(`/tramites/previsualizacion/${progId}`));
      return;
    }
    if (editMode) dispatch(setStageIndex(index));
  };
};

export const nextStage = () => {
  return (dispatch, getState) => {
    const index = selectStageIndex(getState());
    const paperwork = selectPaperworkStructure(getState());
    const stageP = selectPaperworkStageProgress(getState());
    const dependencies = get(paperwork, `stages.[${index}].form.dependencies`, []);
    const dataCopy = cloneDeep(stageP[index]);
    const dependency = dependencies.find((e) => e.posibilities.every((p) => p.value === getValueRef(p, stageP)));
    const progId = selectPaperworkProgressId(getState());
    const paperworkType = selectPaperworkType(getState());
    dataCopy[ALREADY_FILLED] = true;
    const nextIndex = dependency && dependency.nextStage ? dependency.nextStage : index + 1;
    const isLastStage = stageP.length === nextIndex;

    if (dependency) dispatch(processDefaultStages(dependency.defaultStages, stageP, paperworkType, progId));
    dispatch(setStage(index, dataCopy));
    if (!isLastStage) dispatch(setStageIndex(nextIndex));
    dispatch(goToPreview());
  };
};

export const back = () => {
  return (dispatch, getState) => {
    const paperwork = selectPaperworkStructure(getState());
    const index = selectStageIndex(getState());
    const stageP = selectPaperworkStageProgress(getState());
    const dependencies = get(paperwork, `stages.[${index}].form.dependencies`, []);
    const dependency = dependencies.find((e) => e.posibilities.every((p) => p.value === getValueRef(p, stageP)));
    const backIndex = dependency && dependency.backStage ? dependency.backStage : index - 1;

    dispatch(setStageIndex(checkIndex(backIndex)));
    if (!index) dispatch(push(PATH_HOME));
  };
};

export const addMultipleOption = (key, id) => {
  return (dispatch, getState) => {
    const index = selectStageIndex(getState());
    const { stageProgress } = selectPaperworkMetadata(getState());
    const data = stageProgress[index]?.otherInputsData;
    const dataCopy = cloneDeep(data);
    if (!dataCopy[key]) dataCopy[key] = [];
    if (dataCopy[key].some((s) => s === id)) {
      const keyFilter = dataCopy[key].filter((d) => d !== id);
      dataCopy[key] = keyFilter;
    } else {
      dataCopy[key].push(id);
    }
    dispatch(setStageData(OTHER_INPUTS, dataCopy));
  };
};

export const setValueInJsonSchema = (key, value) => {
  return (dispatch, getState) => {
    const index = selectStageIndex(getState());
    const metadata = selectPaperworkMetadata(getState());
    const stageProgress = get(metadata, "stageProgress", {});
    const data = stageProgress[index]?.jsonSchemaData;
    const dataCopy = cloneDeep(data);
    dataCopy[key] = value;
    dispatch(setStageData(SCHEMA_DATA, dataCopy));
  };
};

export const autocomplete = (schema, field, formData) => {
  return (dispatch) => {
    const preValue = get(formData, field, null);
    const extension = get(schema, `properties.${field}.maxLength`, 0);
    const zeroPadding = get(schema, `properties.${field}.zeroPadding`, false);
    const valDefault = getDefVal(extension);
    const value = getZeros(schema, field, preValue, extension);
    const newValue = preValue ? value : valDefault;
    let data;

    if (zeroPadding) {
      data = updateFieldObject(formData, field, newValue);
      dispatch(setStageData(SCHEMA_DATA, data));
      dispatch(checkErrors(data[field], schema, field));
    } else dispatch(checkErrors(formData[field], schema, field));
  };
};

export const checkErrors = (value, schema, focusedField) => {
  return (dispatch, getState) => {
    const errors = selectFormErrors(getState());
    const maxLength = get(schema, `properties.${focusedField}.maxLength`, 0);
    const minLength = get(schema, `properties.${focusedField}.minLength`, 0);
    const pattern = get(schema, `properties.${focusedField}.pattern`, null);
    const valLength = value?.length || 0;
    const val = value?.toString() || "";

    const newErrorList = errors.filter((e) => e !== focusedField);

    // If the field is empty, error validation goes through "required fields" validation.
    if (valLength === 0) dispatch(setFormErrors([...newErrorList]));
    else {
      const validLength = valLength <= maxLength && valLength >= minLength;
      const validPattern = !!val.match(pattern);

      if (maxLength && minLength && !validLength) dispatch(setFormErrors([...newErrorList, focusedField]));
      else if (pattern && !validPattern) dispatch(setFormErrors([...newErrorList, focusedField]));
      else dispatch(setFormErrors([...newErrorList]));
    }
  };
};

export const firstUnfilledStage = (data) => {
  return (dispatch, getState) => {
    const paperworkProgress = get(data, "metadata", {});
    const paperwUId = get(paperworkProgress, "user.id", null);
    const id = get(paperworkProgress, "id", null);
    const state = get(paperworkProgress, "state", null);
    const stageP = get(paperworkProgress, "stageProgress", {});
    const defaultRoute = selectProfileDefaultRoute(getState());
    const uId = selectUserId(getState());
    const paperworkType = selectPaperworkType(getState());
    const type = selectPaperworkType(getState());

    if (isEmpty(paperworkProgress)) {
      dispatch(push(defaultRoute));
      return;
    }
    if (state === PAPERWORK_STATE.STARTED && paperwUId === uId) {
      if (stageP.every((e) => e.alreadyFilled) && !PAPERWORK_WITHOUT_PREVIEW.includes(type)) {
        dispatch(push(`/tramites/previsualizacion/${id}`));
        return;
      }
      let index = stageP.map((e) => e.alreadyFilled).indexOf(false);
      if (index < 0) index = stageP.length - 1;
      dispatch(push(`/tramites/${paperworkType}`));
      dispatch(setStageIndex(index));
      return;
    }
    if (data.metadata.state === PAPERWORK_STATE.PENDING && paperwUId === uId) {
      dispatch(push(`/tramites/reporte/${id}`));
      return;
    }
    if (data.metadata.state === PAPERWORK_STATE.OBSERVED && paperwUId === uId) {
      dispatch(push(`/tramites/previsualizacion/${id}`));
      dispatch(openModal(MODAL_SHOW_OBSERVATION));
      return;
    }
    dispatch(push(defaultRoute));
  };
};

export const failed = (e) => {
  return (dispatch) => {
    const messageCode = get(e, "response.data.code", null);
    if (messageCode == CONTROLS_FAILED_ERROR_CODE) {
      const controls = get(e, "response.data.result", []);
      const failedControls = controls.filter((c) => c.code != 0);
      const firstFailedControlCode = get(head(failedControls), "code", null);
      dispatch(loadingFailed());

      // 36 is the code for MULTIPLE DEPARTURES
      if (firstFailedControlCode === 36) {
        const departureOptions = get(head(failedControls), "departureOptions", []);
        dispatch(setParams({ departureOptions: departureOptions }));
        dispatch(openModal(MODAL_MULTIPLE_DEPARTURES));
        dispatch(setMessageCode(firstFailedControlCode));
      } else {
        dispatch(error(firstFailedControlCode));
      }
    } else {
      dispatch(loadingFailed());
      dispatch(error(messageCode));
    }
  };
};

export const setForm = (key, field, value, inDisuse) => {
  return (dispatch, getState) => {
    const index = selectStageIndex(getState());
    const paperwProg = selectPaperworkMetadata(getState());
    const data = get(paperwProg, `stageProgress[${index}].otherInputsData`, {});
    const dataCopy = cloneDeep(data);
    if (!dataCopy[key]) dataCopy[key] = {};
    if (inDisuse) unset(dataCopy[key], field);
    else dataCopy[key][field] = value;
    dispatch(setStageData(OTHER_INPUTS, dataCopy));
  };
};

export const saveFiles = (inDisuse, files) => {
  return (dispatch) => {
    if (inDisuse) dispatch(setStageData(ATTACHED_FILES, []));
    else dispatch(setStageData(ATTACHED_FILES, files));
  };
};

export const formIsComplete = (data, required, errors = []) => {
  return (dispatch) => {
    if (errors.length > 0) return dispatch(setStageCompleted(false));

    if (isEmpty(required)) return dispatch(setStageCompleted(true));

    if (isEmpty(data)) return dispatch(setStageCompleted(false));

    if (required.some((e) => isUndefined(data[e]) || isNull(data[e]) || data[e] === "" || (e === "file" && isEmpty(data["file"]))))
      return dispatch(setStageCompleted(false));
    else return dispatch(setStageCompleted(true));
  };
};

export const selectionIsComplete = (data) => {
  return (dispatch) => {
    if (isEmpty(data)) return dispatch(setStageCompleted(false));
    else return dispatch(setStageCompleted(true));
  };
};

export const getvalues = (values, keysEsp) => {
  let list = [];
  values?.map((e, index) => list.push(`${keysEsp[index]}: ${e}`));
  return list;
};

export const getFields = (inputType, key, schemaFields, inputsFields) => {
  let field = key;
  if (inputType === 1) return;
  if (inputType === 2) field = inputsFields[key];
  if (!isEmpty(schemaFields)) field = schemaFields[key];
  return field;
};

export const getValues = (inputType, otherInputs, val) => {
  let value = val;
  if (!val) value = "-";
  if (isBoolean(val)) value = val ? "Sí" : "No";
  if (inputType === 1 && isInteger(val)) value = otherInputs[0].options.filter((option) => option.id === val)[0]?.name;
  return value;
};

export const valueToId = (otherInputsData, otherInputs) => {
  let values = Object.values(otherInputsData).flat();
  values.map((value) => {
    if (otherInputs.some((i) => i.id === 1) && !isInteger(value)) {
      const input = otherInputs.filter((input) => input.id === 1)[0];
      const elem = input.options.filter((option) => option.name === value)[0].id;
      values = [...values, elem];
      values = values.filter((g) => g !== value);
      return null;
    }
  });
  return values;
};
