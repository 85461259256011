import styled from "styled-components";
import colors from "styles/colors";
import { ReactComponent as Download } from "images/icons/gray/download.svg";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: top;
  width: 100%;
  padding: 8px 16px;
  gap: 8px;
  overflow: overlay;
  box-sizing: border-box;
`;

export const Item = styled.div`
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${colors.gray10};
`;

export const DownloadIcon = styled(Download)`
  min-width: 16px;
  min-height: 16px;
`;
