import styled from "styled-components";
import colors from "styles/colors";

export const Text = styled.div`
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.gray70};
  @media (max-width: 580px) {
    font-size: 12px;
  }
`;
