import { io } from "socket.io-client";
import { wsDisconnected } from "store/websocket/actions";
import dispatchWSAction from "./dispatchWSActions";
import get from "lodash/get";

// TODO: Make it scalable to multiple sockets instances.
const socketMiddleware = () => {
  let socket = null;

  return (store) => (next) => (action) => {
    const token = get(store.getState(), "login.token", null);
    switch (action.type) {
      case "WS_CONNECT":
        if (socket !== null) {
          socket.close();
          socket = null;
        }
        socket = io(action.host, {
          auth: { token },
          cors: {
            methods: ["GET", "POST"],
            transports: ["websocket"],
            credentials: true
        },
        });
        socket.onAny((event, ...args) => dispatchWSAction(store, event, ...args));
        break;

      case "WS_DISCONNECT":
        socket.on("disconnect", () => store.dispatch(wsDisconnected()));
        if (socket !== null) {
          socket.close();
          socket = null;
        }
        break;

      case "WS_EMIT":
        if (action.data) socket.emit(action.event, { token, ...action.data } );
        else socket.emit(action.event, { token });
        break;

      default:
        return next(action);
    }
  };
};

export default socketMiddleware();
