import styled from "styled-components";
import colors from "styles/colors";
import { ReactComponent as Icon } from "images/icons/lightBlue/close.svg";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 32px;
  padding: 0px 16px;
  border: 1px solid ${colors.lightBlue80};
  border-radius: 4px;
  box-sizing: border-box;
  background: ${colors.lightBlue95};
`;

export const Text = styled.div`
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${colors.lightBlue50};
  @media (max-width: 390px) {
    font-size: 10px;
  }
`;

export const CloseIcon = styled(Icon)`
  width: 16px;
  height: 16px;
  cursor: pointer;
`;
