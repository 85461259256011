import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  width: 100%;
  min-height: 168px;
  flex: 1;
  padding-bottom: 10px;
  box-sizing: border-box;
  border: 1px solid ${colors.gray95};
  border-radius: 17px;
  overflow: hidden;
  background: ${colors.white};
`;
