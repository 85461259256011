import Img from "components/commons/Img";
import styled from "styled-components";
import colors from "styles/colors";
import { activeBorder } from "styles/cssAtributtes";


export const Wrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 248px;
  height: 184px;
  padding: 12px 16px;
  box-sizing: border-box;
  background: ${colors.white};
  border-radius: 4px;
  border: 1px solid ${colors.gray95};
  border: ${(props) => activeBorder(props)};
  @media (max-width: 980px) {
    width: 336px;
  }
  @media (max-width: 728px) {
    height: 96px;
    width: 100%;
    padding: 8px 12px;
    flex-direction: row;
  }
  flex-direction: column;
  position: relative;
  justify-content: space-between;
  gap: 8px;
`;

export const Name = styled.div`
  flex: 0 0 auto;
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1px;
  color: ${colors.gray20};
  margin-right: auto;
  @media (max-width: 728px) {
    font-size: 14px;
    min-width: 40%;
    max-width: min-content;
  }
`;


export const ImgContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  @media (max-width: 728px) {
    padding: 8px 16px;
    max-width: 50%;
    min-width: 50%;
    height: 80px;
  }
  cursor: pointer;
  position: relative;
`;
  
export const Image = styled(Img)`
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  display: flex;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  pointer-events: none;
`;

export const ExpanderContainer = styled.div`
  position: absolute;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 4px;
  top: 4px;
  right: 4px;
  cursor: pointer;
  padding: 2px;
  width: 16px;
  height: 16px;
  display: ${({show}) => (show ? "flex" : "none")};
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  @media (max-width: 728px) {
    top: 12px;
    right: 20px;
  }
`;