import styled from "styled-components";
import colors from "styles/colors";
import { ReactComponent as Image } from "images/icons/gray/image-outline.svg";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  background: ${colors.gray100};
  border: 1px solid ${colors.gray95};
  border-radius: 8px;
  box-sizing: border-box;
`;

export const Icon = styled(Image)`
  width: 24px;
  height: 24px;
`;
