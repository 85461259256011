import styled from "styled-components";
import colors from "styles/colors";
import { ReactComponent as Google } from "images/icons/general/google.svg";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 36px;
  box-sizing: border-box;
  @media (max-width: 905px) or (max-height: 700px) {
    padding-top: 20px;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 32px;
  gap: 8px;
  box-sizing: border-box;
  @media (max-width: 905px) {
    gap: 20px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  height: 56px;
  @media (max-width: 905px) {
    height: 48px;
  }
`;

export const Line = styled.div`
  width: 100%;
  margin: 30px 0px 16px 0px;
  overflow: hidden;
  text-align: center;
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.black};
  &::before,
  &::after {
    background-color: ${colors.gray50};
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
  }
  &::before {
    right: 1.5em;
    margin-left: -50%;
  }
  &::after {
    left: 1.5em;
    margin-right: -50%;
  }
  @media (max-width: 905px) or (max-height: 700px) {
    margin: 16px 0px 16px 0px;
  }
`;

export const Icon = styled(Google)``;

export const Error = styled.div`
  margin-top: 15px;
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${colors.red50};
  @media (max-width: 1366px) {
    font-size: 12px;
  }
`;
