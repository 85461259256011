import React from "react";
import { Section, Text, Wrapper, Icon, Container, Clock, User } from "./styled";

export const USERS = "Usuarios";
export const PENDING_USERS = "Pendientes";

export const SECTIONS = [
  { name: USERS, key: null, icon: <User /> },
  { name: PENDING_USERS, key: null, icon: <Clock /> },
];

const Selector = ({ setId, id }) => {
  const getSection = (sect) => {
    return (
      <Section key={sect.name} active={sect.name === id} onClick={() => setId(sect.name)}>
        <Icon active={sect.name === id}>{sect.icon}</Icon>
        <Text active={sect.name === id}>{sect.name}</Text>
      </Section>
    );
  };

  return (
    <Wrapper>
      <Container>{SECTIONS.map(getSection)}</Container>
    </Wrapper>
  );
};

export default Selector;
