import React, { useEffect } from "react";
import LeftBlock from "./components/LeftBlock";
import RightBlock from "./components/RightBlock";
import { useDispatch, useSelector } from "react-redux";
import { selectPaperworkData, selectPaperworkMetadata } from "store/PaperWork/selectors";
import { PAPERWORK_STATE } from "store/PaperWork/constants";
import { openModal, closeModal } from "store/Modals/actions";
import { MODAL_SHOW_MEND, MODAL_SHOW_REJECTION, LOCKED_PAPERWORK_MODAL, MULTIPLE_PLOTS_MODAL } from "store/Modals/constants";
import { setCurrentRejection, setCurrentMendingRequest } from "store/PaperWork/actions/actions";
import { get } from "lodash";

const D1 = () => {
  const dispatch = useDispatch();
  const paperworkData = useSelector(selectPaperworkData);
  const metadata = useSelector(selectPaperworkMetadata);
  const state = get(metadata, "state", null);
  const isRejectRequested = state === PAPERWORK_STATE.REJECT_REQUESTED;
  const isMendingRequested = state === PAPERWORK_STATE.MENDING_REQUESTED;

  const hasMultiplePlots = paperworkData.length > 1;

  useEffect(() => {
    if (hasMultiplePlots) dispatch(openModal(MULTIPLE_PLOTS_MODAL));
  }, [hasMultiplePlots]);


  useEffect(() => {
    if (isRejectRequested) {
      const rejectionRequest = get(metadata, "currentRejectionRequest", []);
      dispatch(setCurrentRejection(rejectionRequest?.reason));
      dispatch(openModal(MODAL_SHOW_REJECTION));
    }
    if (isMendingRequested) {
      const mendingRequest = get(metadata, "currentMendingRequest", []);
      dispatch(setCurrentMendingRequest(mendingRequest?.reason, mendingRequest?.mendingAreaName));
      dispatch(openModal(MODAL_SHOW_MEND));
    }
  }, 
  []);

  useEffect(() => {
    return () => {
      dispatch(closeModal(MODAL_SHOW_MEND));
      dispatch(closeModal(LOCKED_PAPERWORK_MODAL));
      dispatch(closeModal(MULTIPLE_PLOTS_MODAL));
    };
  }, []);

  return (
    <>
      <LeftBlock />
      <RightBlock />
    </>
  );
};

export default D1;
