import * as modal from "./constants";

export const initialState = {
  [modal.MODAL_MESSAGE]: false,
  [modal.MODAL_SHOW_OBSERVATION]: false,
  [modal.MODAL_EDIT_OBSERVATION]: false,
  [modal.MODAL_SHOW_REJECTION]: false,
  [modal.MODAL_EDIT_REJECTION]: false,
  [modal.MODAL_FILTERS]: false,
  [modal.MODAL_SHARE_FILE]: false,
  [modal.MODAL_NOMENCLARURE]: false,
  [modal.MODAL_SENT_PAPERWORK]: false,
  [modal.LOCKED_PAPERWORK_MODAL]: false,
  [modal.MODAL_SEE_MORE]: false,
  [modal.MODAL_EXPAND_IMAGE]: false,
  [modal.MODAL_MULTIPLE_DEPARTURES]: false,
  [modal.MULTIPLE_PLOTS_MODAL]: false,
  [modal.MODAL_WARN_MULTIPLE_PLOTS]: false,
  [modal.MODAL_ADD_USER]: false,
  [modal.MODAL_EDIT_USER]: false,
  [modal.MODAL_EXPAND_IMAGE]: false,
  messageCode: null,
  params: {},
};
