/* eslint-disable no-unused-vars */
import React from "react";
import Icon from "images/icons/gray/seeMore.svg";
import { Wrapper, Image } from "./styled";

const SeeMoreIcon = ({ onClick, color }) => {
  return (
    <Wrapper onClick={onClick}>
      <Image src={Icon} alt="icon" />
    </Wrapper>
  );
};

export default SeeMoreIcon;
