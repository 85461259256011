/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import {
  PATH_HOME,
  PATH_PAPERWORK,
  PATH_FORM_PREVIEW,
  PATH_PAPERWORK_GENERATED,
  PATH_DASHBOARD,
  PATH_PAPERWORK_DETAIL,
  KEYCLOAK_BASE_URL,
  KEYCLOAK_SECRET,
  PATH_PROFILE,
  BYPASS_LOGIN,
  PATH_CERTIFICATE_REQUESTS,
  PATH_SUBSANATION_REQUESTS,
  PATH_CONTRIBUTOR_DETAIL,
  PATH_FISCAL_KEY_LOGIN,
  PATH_SIGN_UP,
  PATH_SIGN_IN,
  PATH_BACK_OFFICE,
  PATH_VALIDATION,
  PATH_RESET_PASSWORD,
  PATH_FISCAL_KEY_FIRST_LOGIN,
  PATH_FISCAL_KEY_LOGIN_VALIDATION,
} from "components/pages/App/constants";
import { GlobalStyle } from "styles/globalStyle";
import DefaultRoute from "components/commons/DefaultRoute";
import Paperwork from "components/pages/Paperwork";
import Home from "components/pages/Home";
import FormPreview from "../FormPreview";
import { Wrapper } from "./styled";
import PrivateRoute from "components/commons/PrivateRoute";
import ModalController from "components/commons/ModalController";
import Spinner from "components/commons/Spinners/PuffLoader";
import Dashboard from "../DashBoard";
import CertificateRequests from "../CertificateRequests";
import SubsanationRequests from "../SubsanationRequests";
import PaperworkGenerated from "../PaperworkGenerated";
import FiscalKeyLogin from "../Login/FiscalKeyLogin";
import Profile from "../Profile";
import "./App.css";
import { getIdToken, getUserData, internalSignOut, logout, updateToken } from "store/Login/actions/actions";
import { useDispatch, useSelector } from "react-redux";
import { selectTokenExpires } from "store/Login/selectors";
import ContributorDetail from "../PaperworkDetail/ContributorDetail";
import OperatorDetail from "../PaperworkDetail/OperatorDetail";
import { selectUserUid, selectValidSince } from "store/Login/selectors";
import SignIn from "../Login/InternalLogin/SingIn";
import SignUp from "../Login/InternalLogin/SignUp";
import BackOffice from "../BackOffice";
import ValidationFlow from "../Login/InternalLogin/ValidationFlow";
import ResetPassword from "../Login/InternalLogin/ResetPassword";
import { selectIsLoading } from "store/General/selectors";
import FirstSignUp from "../Login/FiscalKeyLogin/FirstSignUp";
import ProfilePending from "../Login/FiscalKeyLogin/ValidationFlow";

const App = () => {
  const dispatch = useDispatch();
  const tokenExpires = useSelector(selectTokenExpires);
  const validSince = useSelector(selectValidSince);
  const uid = useSelector(selectUserUid); /* firebase unique user identifier  */
  const loading = useSelector(selectIsLoading);

  useEffect(() => {
    if (BYPASS_LOGIN || uid) return;

    const AUTENTICAR_SCRIPT_URL = `${KEYCLOAK_BASE_URL}/auth/js/keycloak.js`;

    const s = document.createElement("script");
    s.src = AUTENTICAR_SCRIPT_URL;
    s.async = true;
    s.onload = () => {
      window.keycloakSession = window.Keycloak({
        realm: "stuc-afip",
        "auth-server-url": `${KEYCLOAK_BASE_URL}/auth`,
        "ssl-required": "external",
        resource: "stuc",
        clientId: "stuc",
        credentials: {
          secret: KEYCLOAK_SECRET,
        },
      });

      window.keycloakSession
        .init({ onLoad: "check-sso", checkLoginIframe: false })
        .success(() => window.keycloakSession?.authenticated && dispatch(getUserData()))
        .error(() => dispatch(logout()));
    };
    document.body.appendChild(s);
  }, []);

  useEffect(() => {
    if (BYPASS_LOGIN && !uid) return;

    if (uid) {
      const sessionLimit = 28800000; // 8 hours
      // const sessionLimit = 300000; // demo 5 minutes
      const isExpiredSesion = Date.now() - validSince >= sessionLimit;
      const updateTime = tokenExpires - (Date.now() + 3540000); // 2 minutes before token expires
      if (isExpiredSesion) dispatch(internalSignOut());
      const timeout = !isExpiredSesion && setTimeout(() => dispatch(getIdToken()), updateTime);
      return () => clearTimeout(timeout);
    }

    const updateTime = tokenExpires - Date.now() / 1000 - 60; // 60 seconds before token expires
    const parsedTimeleft = Math.max(updateTime, 0) * 1000;

    const timeout = parsedTimeleft >= 0 && setTimeout(() => dispatch(updateToken()), updateTime * 1000);
    return () => parsedTimeleft >= 0 && clearTimeout(timeout);
  }, [tokenExpires]);

  return (
    <Wrapper>
      {loading && <Spinner />}
      <ModalController />
      <Switch>
        <Route exact path={PATH_FISCAL_KEY_LOGIN} component={FiscalKeyLogin} />
        <Route exact path={PATH_FISCAL_KEY_FIRST_LOGIN} component={FirstSignUp} />
        <Route exact path={PATH_FISCAL_KEY_LOGIN_VALIDATION} component={ProfilePending} />
        <Route exact path={PATH_SIGN_IN} component={SignIn} />
        <Route exact path={PATH_SIGN_UP} component={SignUp} />
        <Route exact path={PATH_VALIDATION} component={ValidationFlow} />
        <Route exact path={PATH_RESET_PASSWORD} component={ResetPassword} />
        <PrivateRoute exact path={PATH_PAPERWORK} component={Paperwork} />
        <PrivateRoute exact path={PATH_HOME} component={Home} />
        <PrivateRoute exact path={PATH_PAPERWORK_GENERATED} component={PaperworkGenerated} />
        <PrivateRoute exact path={PATH_FORM_PREVIEW} component={FormPreview} />
        <PrivateRoute exact path={PATH_DASHBOARD} component={Dashboard} />
        <PrivateRoute exact path={PATH_BACK_OFFICE} component={BackOffice} />
        <PrivateRoute exact path={PATH_CERTIFICATE_REQUESTS} component={CertificateRequests} />
        <PrivateRoute exact path={PATH_SUBSANATION_REQUESTS} component={SubsanationRequests} />
        {/* <PrivateRoute exact path={PATH_CERTIFICATE_DETAIL} component={CertificateRequestDetail} /> */}
        <PrivateRoute exact path={PATH_PAPERWORK_DETAIL} component={OperatorDetail} />
        <PrivateRoute exact path={PATH_CONTRIBUTOR_DETAIL} component={ContributorDetail} />
        <PrivateRoute exact path={PATH_PROFILE} component={Profile} />
        <DefaultRoute />
      </Switch>
      <GlobalStyle />
    </Wrapper>
  );
};

export default App;
