import React from "react";
import { Wrapper, Title } from "./style";
import BackButton from "./components/Button";
import { selectParams } from "store/Modals/selectors";
import { useSelector } from "react-redux";

const Header = () => {
    const { titleImage } = useSelector(selectParams);
    return <Wrapper>
        <BackButton />
        <Title> {titleImage} </Title>
    </Wrapper>;
};

export default Header;