import { push } from "connected-react-router";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { selectProfileDefaultRoute } from "store/Login/selectors";

const DefaultRoute = () => {
  const defaultRoute = useSelector(selectProfileDefaultRoute);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(push(defaultRoute));
  }, []);

  return (
    <Route
      exact
      path="/"
      render={() => <Redirect to={{ pathname: defaultRoute }} />}
    />
  );
};

export default DefaultRoute;
