import React from "react";
import Header from "./components/Header";
import Menu from "./components/Menu";
import { Content, Section, Wrapper } from "./styled";

const MobileLayout = ({ children, header }) => {
  return (
    <Wrapper>
      <Header content={header} />
      <Content>
        <Section>{children}</Section>
        <Menu />
      </Content>
    </Wrapper>
  );
};

export default MobileLayout;
