import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0px 16px;
  justify-content: center;
  height: 100%;
  background: transparent;
  overflow: hidden;
  @media (max-width: 1366px) {
    font-size: 12px;
  }
`;

export const Text = styled.div`
  display: flex;
  align-items: center;
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: ${colors.gray0};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: 1366px) {
    font-size: 12px;
  }
`;
