import React from "react";
import { useDispatch } from "react-redux";
import { openModal } from "store/Modals/actions";
import { MODAL_NOMENCLARURE } from "store/Modals/constants";
import { Wrapper, Text, Icon } from "./styled";

const Label = ({ hidden }) => {
  const dispatch = useDispatch();
  const onClick = () => dispatch(openModal(MODAL_NOMENCLARURE));

  if (hidden) return null;

  return (
    <Wrapper onClick={onClick}>
      <Text>¿Dónde encuentros esto datos?</Text>
      <Icon />
    </Wrapper>
  );
};

export default Label;
