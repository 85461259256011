import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: left;
  width: 100%;
  height: 100%;
  background: ${colors.gray100};
  box-sizing: border-box;
  overflow: hidden;
  @media (max-width: 980px) {
    padding: 0px 16px;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
  box-sizing: border-box;
  @media (max-width: 720px) {
    flex-direction: column;
    margin-top: 0px;
  }
  @media (max-width: 720px) {
    flex-direction: column;
    margin-bottom: 10px;
  }
`;
