/* eslint-disable react/no-children-prop */
import React from "react";
import { useSelector } from "react-redux";
import { selectPaperworkData } from "store/PaperWork/selectors";
import { Column, Content, Text, Title, Wrapper } from "../styled";
import { get } from "lodash";

const PlotDepartureObservations = ({selectedPlot}) => {
  const data = useSelector(selectPaperworkData);
  const plotObservations = get(data, `[${selectedPlot}].domainData.departureObservations`, "-");
  const departureObservations = get(data, `[${selectedPlot}].domainData.plotObservations`, "-");


  return (
    <Wrapper>
      <Title children="Observaciones de la parcela y partida" />
      <Content>
        <Column>
          <Text children={plotObservations || "No hay observaciones"} />
        </Column>
        <Column>
          <Text children={departureObservations || "No hay observaciones"} />
        </Column>
      </Content>
    </Wrapper>
  );
};

export default PlotDepartureObservations;
