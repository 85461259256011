import React from "react";
import useDelayedMount from "hooks/useDelayedMount";
import Spinner from "react-spinners/PuffLoader";
import colors from "styles/colors";
import { Wrapper } from "./styled";

const PuffLoader = () => {
  const mounted = useDelayedMount(350);

  if (!mounted) return null;
  return (
    <Wrapper>
      <Spinner color={colors.orange50} size={150} />
    </Wrapper>
  );
};

export default PuffLoader;
