import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFilters } from "store/Filters/actions";
import { FILTER_STATUS } from "store/Filters/constants";
import { selectFilterByKey } from "store/Filters/selectors";
import { checkValue, isActive } from "./functions";
import { Wrapper, Text, Container } from "./styled";

const Item = ({ text, states, getPaperworkHistory }) => {
  const dispatch = useDispatch();
  const actualFilterStates = useSelector((state) => selectFilterByKey(state, FILTER_STATUS));
  const active = isActive(states, actualFilterStates);

  const toggleFilter = () => {
    const filters = checkValue(actualFilterStates, states);
    dispatch(setFilters(FILTER_STATUS, filters));
    dispatch(getPaperworkHistory());
  };

  return (
    <Wrapper onClick={toggleFilter} active={active}>
      <Container>
        <Text active={active}>{text}</Text>
      </Container>
    </Wrapper>
  );
};

export default Item;
