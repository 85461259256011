import React from "react";
import { Wrapper, Container } from "./style";
import Header from "./components/Header";
import Content from "./components/Content";
import Buttons from "./components/Buttons";

const FiltersModal = ({ show, filtersConstants, apply, goBack }) => {
  if (!show) return null;
  return (
    <Wrapper>
      <Container onClick={(e) => e.stopPropagation()}>
        <Header goBack={goBack} />
        <Content filtersConstants={filtersConstants} />
        <Buttons apply={apply} goBack={goBack} />
      </Container>
    </Wrapper>
  );
};

export default FiltersModal;
