import React, { useState } from "react";
import CommonInput from "components/commons/Inputs/CommonInput";
import colors from "styles/colors";
import Label from "./components/Label";
import { Wrapper } from "./styled";
import { emailPattern } from "utils/functions";

const Input = ({ setNewUser, newUser, label, value, placeholder, disabled, name }) => {
  const [error, setError] = useState(false);
  const errorMessage = error ? "Email inválido" : null;

  const onChange = (e) => {
    if (emailPattern(e.target.value) || !e.target.value) setError(false);
    else setError(true);
    setNewUser({ ...newUser, [name]: e.target.value });
  };

  return (
    <Wrapper>
      <Label text={label} />
      <CommonInput
        errorMessage={errorMessage}
        error={error}
        height={"48px"}
        onChange={onChange}
        value={value}
        disabled={disabled}
        placeholder={placeholder}
        color={colors.gray10}
        border={`1px solid ${colors.gray90}`}
        borderRadius={"4px"}
      />
    </Wrapper>
  );
};

export default Input;
