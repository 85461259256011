import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: left;
  width: 100%;
  height: 42px;
  margin-top: 78px;
  @media (max-width: 580px) {
    margin-top: 80px;
  }
  @media (max-width: 468px) {
    margin-right: 7px;
  }
  @media (max-width: 430px) {
    margin-right: 12px;
  }
  @media (max-width: 386px) {
    margin-top: 68px;
    margin-right: 16px;
  }
  @media (max-width: 360px) {
    margin-top: 36px;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  height: 42px;
`;

export const Line = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  height: 25px;
  box-sizing: border-box;
  border-top: 1px solid;
  border-right: 1px solid;
  border-color: ${colors.gray60};
  ${({ left }) =>
    left &&
    `
    border-left: 1px solid;
    border-right: none;
    border-color: ${colors.gray60};
  `}
  @media (max-width: 386px) {
    border-left: none;
    border-right: 1px solid;
    border-color: ${colors.gray60};
  }
  @media (max-width: 360px) {
    height: 12px;
  }
`;

export const Item = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  height: 20px;
  margin-top: auto;
  padding: 2px 4px;
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  box-sizing: border-box;
  z-index: 3;
  transition: all 0.4s;
  color: ${colors.gray60};
  background: ${colors.gray99};
  @media (max-width: 580px) {
    font-size: 8px;
  }
`;
