export const check = (value, name) => value.some((e) => e === name);

export const checkValue = (copyValue, name) => {
  if (copyValue.some((e) => e === name)) {
    copyValue = copyValue.filter((e) => e !== name);
  } else {
    copyValue.push(name);
  }
  return copyValue;
};
