import React from "react";
import { useSelector } from "react-redux";
import { selectPaperworkMetadata, selectStageIndex } from "store/PaperWork/selectors";
import { get } from "lodash";
import BackOfficeHeader from "components/commons/BackofficeHeader";
import { PATH_HOME } from "components/pages/App/constants";
import StageSearch from "./components/StageSeach";

const Header = ({ search, setSearch }) => {
  const index = useSelector(selectStageIndex);
  const paperworkProg = useSelector(selectPaperworkMetadata);
  const stageProg = get(paperworkProg, `stageProgress[${index}]`, {});
  const stageTitle = get(stageProg, "stageTitle", "");
  const stageDescription = get(stageProg, "stageDescription", "");

  return (
    <BackOfficeHeader
      title={stageTitle}
      description={stageDescription}
      showBack={"Volver al menu de tramites"}
      route={PATH_HOME}
      element={<StageSearch value={search} onChange={setSearch} />}
    />
  );
};

export default Header;
