import { NUMBERS_PATTERN, LETTERS_PATTERN, EMAIL_PATTERN } from "./constants";

export const isNull = (value) => value === null;
export const isUndefined = (value) => undefined === value;
export const isEmpty = (value) => value.length < 1;

export const objIsEmpty = (obj) => {
  if (!obj) return false;
  return Object.keys(obj).length === 0;
};

export const paperWorkIsComplete = (stageP) => {
  return stageP?.every((e) => e.alreadyFilled);
};

export const objToArray = (object) => {
  if (!object) return [];
  return Object.values(object);
};

export const onlyLetters = (value) => LETTERS_PATTERN.test(value);

export const onlyNumbers = (value) => NUMBERS_PATTERN.test(value);

export const emailPattern = (value) => EMAIL_PATTERN.test(value);

export function validCuit(cuit) {
  const serie = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2];
  let sum = 0;
  const checker = parseInt(cuit.charAt(10), 10);

  for (let i = 0; i < 10; i += 1) sum += parseInt(cuit.charAt(i), 10) * serie[i];

  const mod11 = sum % 11;
  const final = 11 - mod11;

  return (final === 11 && checker === 0) || final === checker;
}

export const getFileName = (type, name) => {
  var extension = type.split("/")[1];
  if (!name) return `Documento.${extension}`;
  return `${name}.${extension}`;
};
