import React from "react";
import { Wrapper, CheckBoxContainer } from "./styled";
import Header from "./components/Header";
import CheckBox from "components/commons/Checkboxs/CheckBox/components/Button";

const SignedPdf = ({signed, onChange}) => {
    return <Wrapper>
        <Header title={"PDF Firmado"} /> 
        <CheckBoxContainer>
            <CheckBox checked={signed} onClick={onChange}/>
        </CheckBoxContainer>
    </Wrapper>;
};

export default SignedPdf;