import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  ${({showBorder}) => showBorder && `
    border: 1px solid ${colors.gray90};
    border-radius: 4px;
  `}
`;

export const Icon = styled.div`
  position: absolute;
  left: ${(props) => (props.left ? "5px" : "none")};
  right: ${(props) => (props.left ? "none" : "10px")};
  top: 50%;
  transform: translateY(-50%);
  svg {
    fill: black;
    transition: 0.3s;
  }
`;

export const Input = styled.input`
  min-height: ${(props) => props.height};
  font-size: 25px;
  width: 100%;
  border: 2px solid ${colors.gray80};
  border-radius: 4px;
  outline: none;
  padding: 8px;
  padding-left: ${(props) => (props.left ? "50px" : "16px")};
  padding-right: ${(props) => (props.left ? "0px" : "40px")};
  box-sizing: border-box;
  transition: 0.3s;
  cursor: pointer;
  border-radius: 8px;
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  border: orange;
  text-overflow: ellipsis;
  background: ${(props) => props.background};
  &:focus {
    border-color: ${colors.blue40};
  }

  :focus + .left-icon {
    svg {
      fill: dodgerBlue;
    }
  }

  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${colors.gray70};
  }
`;
