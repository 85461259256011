import Search from "components/pages/Home/components/Header/component/Search";
import React from "react";
import { useSelector } from "react-redux";
import { selectPaperworkStructure, selectStageIndex } from "store/PaperWork/selectors";
import { get } from "lodash";

const StageSearch = ({ value, onChange }) => {
  const index = useSelector(selectStageIndex);
  const structure = useSelector(selectPaperworkStructure);
  const name = get(structure, `stages.[${index}].name`, "");

  if (name != "Revalidación de CC.") return null;
  return <Search value={value} onChange={onChange} />;
};

export default StageSearch;
