import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  width: 100%;
  min-height: 184px;
  box-sizing: border-box;
  border: 1px solid ${colors.gray95};
  border-radius: 17px;
  background: ${colors.white};
  padding-bottom: 24px;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 12px 0px 12px;
`;

export const Text = styled.div`
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: ${colors.black};
  margin-bottom: 16px;
`;
