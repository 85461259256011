/* eslint-disable no-unused-vars */
import React from "react";
import Icon from "images/icons/blue/close.svg";
import { Wrapper, Image } from "./styled";
import { getIcon } from "./functions";

const CloseIcon = ({ onClick, color }) => {
  return (
    <Wrapper onClick={onClick}>
      <Image src={getIcon(color)} alt="icon" />
    </Wrapper>
  );
};

export default CloseIcon;
