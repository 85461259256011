import React, { useState } from "react";
import { Wrapper, Container, InputContainer, Input, InputText, ErrorText, DescriptionText } from "./styled";
import FileUploadIcon from "components/commons/Icons/FileUploadIcon";
import { isEmpty } from "lodash";

const InputFile = ({ inputRef, disabled, onChange, accept, files, sizeLimit }) => {
  const [errorMessage, setErrorMessage] = useState("");

  const validation = (files) => {
    const invalidFiles = Object.values(files).filter((e) => e.size > sizeLimit || !accept.includes(e.type.split("/")[1]));
    if (isEmpty(invalidFiles)) return setErrorMessage("");
    if (invalidFiles.length > 1) return setErrorMessage("Algunos archivos son inválidos");
    if (invalidFiles[0].size > sizeLimit) return setErrorMessage(`El archivo ${invalidFiles[0].name} supera el límite permitido`);
    else return setErrorMessage(`El formato del archivo ${invalidFiles[0].name} es inválido`);
  };

  const handleChange = (e) => {
    validation(e.target.files);
    const selectFiles = Object.values(e.target.files).filter(
      (e) => !files.includes(e.name) && e.size < sizeLimit && accept.includes(e.type.split("/")[1])
    );
    onChange(selectFiles);
  };

  const handleClick = (event) => {
    if (event.target.id === "input_file") return;
    event.preventDefault();
    inputRef.current.click();
  };

  return (
    <Wrapper disabled={disabled} onClick={handleClick}>
      <Container>
        <FileUploadIcon size={"56px"} />
        <InputContainer>
          <InputText>
            Seleccionar archivo
            <Input type="file" onChange={handleChange} ref={inputRef} accept={accept} multiple id="input_file" />
          </InputText>
          <DescriptionText>Los archivos no deberán pesar más de 10MB (jpg, jpeg, png)</DescriptionText>
          <ErrorText>{errorMessage}</ErrorText>
        </InputContainer>
      </Container>
    </Wrapper>
  );
};

export default InputFile;
