import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  /* @media (max-width: 720px) {
  } */
`;
