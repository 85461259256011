import React from "react";
import { Wrapper, TextArea } from "./style";
import { useSelector } from "react-redux";
import { selectRejectionReason } from "store/PaperWork/selectors";

const Content = ({ expanded }) => {
  const rejectionReason = useSelector(selectRejectionReason);

  return (
    <Wrapper expanded={expanded}>
      <TextArea value={rejectionReason} />
    </Wrapper>
  );
};

export default Content;
