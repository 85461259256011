import React from "react";
import Content from "./components/Content";
import Header from "./components/Header";
import { Wrapper } from "./styled";

const Container = ({ item }) => {
  const { title, form, key } = item;

  return (
    <Wrapper>
      <Header title={title} />
      <Content form={form} stageKey={key} />
    </Wrapper>
  );
};

export default Container;
