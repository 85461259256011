/* eslint-disable react/no-children-prop */
import React from "react";
import { useSelector } from "react-redux";
import Form from "@rjsf/material-ui";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { Wrapper, FormContainer } from "./styled";
import { useStyles, muiTheme } from "../../muiStyled";
import useResize from "hooks/useResize";
import { selectPaperworkStructure, selectStageIndex } from "store/PaperWork/selectors";
import WrapTemplate from "../WrapTemplate";
import { errorHandling } from "../../functions";
import { DESK } from "components/commons/MainLayout/constants";
import { get } from "lodash";
import Label from "../Label";

const DeskFields = ({ formData, onChange, onFocus, onBlur }) => {
  const classes = useStyles();
  const index = useSelector(selectStageIndex);
  const { stages } = useSelector(selectPaperworkStructure);
  const { checkSize } = useResize();
  const schema = get(stages, `[${index}].form.jsonSchema.jsonSchema`, {});
  const uiSchema = get(stages, `[${index}].form.jsonSchema.uiSchema`, {});
  const formName = get(stages, `[${index}].name`, "");
  const NAME = "Ingreso de datos";

  const transformErrors = (errors) => errorHandling(errors, schema);

  if (checkSize() !== DESK) return null;

  return (
    <Wrapper>
      <FormContainer>
        <MuiThemeProvider theme={muiTheme}>
          <Label hidden={formName !== NAME} />
          <Form
            formData={formData}
            className={classes.root}
            schema={schema}
            uiSchema={uiSchema}
            ObjectFieldTemplate={WrapTemplate}
            onChange={({ formData }) => onChange(formData)}
            onBlur={onBlur}
            onFocus={onFocus}
            children={true}
            liveValidate={true}
            showErrorList={false}
            transformErrors={transformErrors}
          />
        </MuiThemeProvider>
      </FormContainer>
    </Wrapper>
  );
};

export default DeskFields;
