/* eslint-disable no-unused-vars */
import React from "react";
import { Wrapper, FilledIcon, OutlinedIcon } from "./styled";

const FilterIcon = ({ onClick, filled }) => {
  return (
    <Wrapper onClick={onClick}>
      { filled ? <FilledIcon /> : <OutlinedIcon /> }
    </Wrapper>
  );
};

export default FilterIcon;
