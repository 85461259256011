import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${({ toogle }) => (toogle ? "center" : "left")};
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  margin-left: ${({ toogle }) => (toogle ? "0px" : "8px")};
`;

export const Icons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${({ toogle }) => (toogle ? "center" : "left")};
  justify-content: top;
  min-width: 32px;
  height: 100%;
  gap: ${(props) => (props.toogle ? "16px" : "12px")};
  box-sizing: border-box;
  transition: all 0.5s;
`;
