import React, { useEffect, useState } from "react";
import { Wrapper } from "./styled";
import { useDispatch, useSelector } from "react-redux";
import { selectFormErrors, selectPaperworkStructure, selectPaperworkMetadata, selectStageIndex } from "store/PaperWork/selectors";
import { autocomplete, checkErrors, formIsComplete } from "store/PaperWork/actions/functions";
import { setStageData } from "store/PaperWork/actions/actions";
import MobileFields from "./components/MobileFields";
import DeskFields from "./components/DeskFields";
import { SCHEMA_DATA } from "store/PaperWork/constants";
import { onFocusField } from "./functions";
import { objToArray } from "utils/functions";
import { get } from "lodash";

const Fields = () => {
  const dispatch = useDispatch();
  const index = useSelector(selectStageIndex);
  const paperwProg = useSelector(selectPaperworkMetadata);
  const stageForm = get(paperwProg, `stageProgress[${index}].jsonSchemaData`, {});
  const stageOtherInputs = get(paperwProg, `stageProgress[${index}].otherInputsData`, {});
  const paperwork = useSelector(selectPaperworkStructure);
  const required = get(paperwork, `stages[${index}].form.jsonSchema.jsonSchema.required`, []);
  const schema = get(paperwork, `stages[${index}].form.jsonSchema.jsonSchema`, {});
  const [formData, setFormData] = useState(stageForm);
  const schemaValues = objToArray(stageForm);
  const otherValues = objToArray(stageOtherInputs).flat();
  const [focusedField, setFocusedField] = useState(null);
  const errors = useSelector(selectFormErrors);

  const onFocus = (value) => {
    setFocusedField(value.split("_")[1]);
    onFocusField(value, schema, formData, setFormData);
  };

  const onChange = (formData) => {
    setFormData(formData);
    dispatch(setStageData(SCHEMA_DATA, formData));
    dispatch(checkErrors(formData[focusedField], schema, focusedField));
  };

  const onBlur = () => dispatch(autocomplete(schema, focusedField, formData, setFormData));

  useEffect(() => {
    dispatch(formIsComplete(stageForm, required, errors));
    setFormData(stageForm);
  }, [otherValues, index, schemaValues, formData]);

  useEffect(() => setFormData(stageForm), [index]);

  return (
    <Wrapper>
      <MobileFields onFocus={onFocus} onChange={onChange} formData={formData} onBlur={onBlur} />
      <DeskFields onFocus={onFocus} onChange={onChange} formData={formData} onBlur={onBlur} />
    </Wrapper>
  );
};

export default Fields;
