import React from "react";
import { Detail, Number, Wrapper } from "./styled";

const Data = ({ color, detail, inCome }) => {
  return (
    <Wrapper>
      <Number color={color}>{inCome}</Number>
      <Detail color={color}>{detail}</Detail>
    </Wrapper>
  );
};

export default Data;
