import React from "react";
import { Text } from "./styled";

const Label = ({ text }) => {
  if (!text) return null;

  return <Text>{text}</Text>;
};

export default Label;
