/* eslint-disable react/react-in-jsx-scope */
import Email from "./components/Media/components/Email";
import Whatsapp from "./components/Media/components/Whatsapp";
import Telegram from "./components/Media/components/Telegram";

export const MEDIA = [
  { 
    title:"Whatsapp",
    component: <Whatsapp /> 
  }, 
  { 
    title:"Email",
    component: <Email /> 
  }, 
  { 
    title:"Telegram",
    component: <Telegram /> 
  }, 
];

export const TITLE = "Archivo compartido de catastro";
