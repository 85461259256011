/* eslint-disable react/no-children-prop */
import React from "react";
import { useSelector } from "react-redux";
import { selectPaperworkData } from "store/PaperWork/selectors";
import { Column, Content, Text, Title, Wrapper } from "../styled";
import { get } from "lodash";

const PlotObservations = ({selectedPlot}) => {
  const data = useSelector(selectPaperworkData);
  const observations = get(data, `[${selectedPlot}].domainData.plotObservations`, "-");

  return (
    <Wrapper>
      <Title children="Observaciones de parcela" />
      <Content>
        <Column>
          <Text children={observations || "No hay observaciones"} />
        </Column>
      </Content>
    </Wrapper>
  );
};

export default PlotObservations;
