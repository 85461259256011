import React from "react";
import Icon from "images/icons/white/users.svg";
import { Wrapper, Image } from "./styled";

const UsersIcon = ({ onClick }) => {
  return (
    <Wrapper onClick={onClick}>
      <Image src={Icon} alt="icon" />
    </Wrapper>
  );
};

export default UsersIcon;
