import styled from "styled-components";
import { ReactComponent as ApproveIcon } from "images/icons/gray/approve.svg";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 16px;
  justify-content: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  cursor: pointer;
`;

export const Approve = styled(ApproveIcon)``;
