/* eslint-disable react/no-children-prop */
import React from "react";
import { Wrapper, Text } from "./styled";

const LabelIconButton = ({ type, background, onClick, disabled, color, label, hidden, border, icon, width = null, height = null }) => {
  if (hidden) return null;

  return (
    <Wrapper type={type} background={background} onClick={onClick} disabled={disabled} border={border} width={width} height={height}>
      {icon}
      <Text color={color} type={type} disabled={disabled} children={label} />
    </Wrapper>
  );
};

export default LabelIconButton;
