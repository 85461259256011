import React from "react";
import { Wrapper } from "./styled";
import RadioButton from "components/commons/Buttons/RadioButton";
import { useDispatch } from "react-redux";
import { addMultipleOption } from "store/PaperWork/actions/functions";

const Checkbox = ({ checked, stageKey, id }) => {
  const dispatch = useDispatch();
  const onClick = () => dispatch(addMultipleOption(stageKey, id));

  return (
    <Wrapper>
      <RadioButton onClick={onClick} checked={checked} name={id} />
    </Wrapper>
  );
};

export default Checkbox;
