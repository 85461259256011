import React from "react";
import { Wrapper, Text } from "./styled";
import Tooltip from "components/commons/Tooltip";

const CommonCard = ({ plot }) => {
  const text = `Parcela: ${plot}`;

  return (
    <Wrapper>
      <Tooltip title={plot}>
        <Text>{text}</Text>
      </Tooltip>
    </Wrapper>
  );
};

export default CommonCard;
