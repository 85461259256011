import { selectModal } from "./selectors";
import * as types from "./types";

export function hideModal(modalName) {
  return {
    type: types.CLOSE_MODAL,
    modalName,
  };
}

export function showModal(modalName) {
  return {
    type: types.OPEN_MODAL,
    modalName,
  };
}

export function openModal(modalName) {
  return async (dispatch, getState) => {
    const isVisible = selectModal(getState(), modalName);
    if (!isVisible) dispatch(showModal(modalName));
  };
}

export function closeModal(modalName) {
  return async (dispatch, getState) => {
    const isVisible = selectModal(getState(), modalName);
    if (isVisible) dispatch(hideModal(modalName));
  };
}

export function success(messageCode) {
  return {
    type: types.SET_SUCCESS,
    messageCode,
  };
}

export function error(messageCode) {
  return {
    type: types.SET_ERROR,
    messageCode,
  };
}

export function clearMessage() {
  return {
      type: types.CLEAR_MESSAGE,
    };
}

export function setMessageCode(messageCode) {
  return {
    type: types.SET_MESSAGE_CODE,
    messageCode: messageCode,
  };
}

export function setParams(params) {
  return {
    type: types.SET_PARAMS,
    params,
  };
}
