import produce from "immer";
import { initialState } from "./initialState";
import * as types from "./types";

const backOfficeReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.SET_USERS:
        draft.users = action.users;
        break;
    }
  });
export default backOfficeReducer;
