/* eslint-disable react/no-children-prop */
import React from "react";
import { Wrapper, Text } from "./styled";
import ArrowBackIcon from "components/commons/Icons/ArrowBackIcon";
import useResize from "hooks/useResize";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { MOBILE } from "components/commons/MainLayout/constants";

const ShowBack = ({ showBack, route }) => {
  const dispatch = useDispatch();
  const { checkSize } = useResize();

  if (!showBack) return null;
  return (
    <Wrapper>
      <ArrowBackIcon onClick={() => dispatch(push(route))} />
      {checkSize() !== MOBILE && <Text children={showBack} />}
    </Wrapper>
  );
};

export default ShowBack;
