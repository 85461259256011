import produce from "immer";
import { initialState } from "./initialState";
import { SET_FILTERS, RESET_FILTERS, SET_ALL_FILTERS } from "./types";

const FiltersReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_FILTERS:
        draft[action.key] = action.value;
        break;
      case SET_ALL_FILTERS:
        draft.cuit = action.filters.cuit;
        draft.dateFrom = action.filters.dateFrom;
        draft.dateTo = action.filters.dateTo;
        draft.department = action.filters.department;
        draft.departure = action.filters.departure;
        draft.nomenclature = action.filters.nomenclature;
        draft.paperworksStates = action.filters.paperworksStates;
        draft.paperworksTypes = action.filters.paperworksTypes;
        draft.paperworkProgressId = action.filters.paperworkProgressId;
        break;
      case RESET_FILTERS:
        draft.cuit = initialState.cuit;
        draft.dateFrom = initialState.dateFrom;
        draft.dateTo = initialState.dateTo;
        draft.department = initialState.department;
        draft.departure = initialState.departure;
        draft.nomenclature = initialState.nomenclature;
        draft.paperworksStates = initialState.paperworksStates;
        draft.paperworksTypes = initialState.paperworksTypes;
        draft.paperworkProgressId = initialState.paperworkProgressId;
        break;
    }
  });

export default FiltersReducer;
