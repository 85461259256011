import styled from "styled-components";
import { fadeIn } from "styles/effects";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: end;
  width: 100%;
  //height: 100%;
  margin-bottom: 50px;
  overflow: visible;
  animation: 0.9s ${fadeIn} forwards;
  box-sizing: border-box;
  @media (max-width: 580px) {
    margin-bottom: 30px;
  }
`;

export const Title = styled.div`
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: ${colors.blue60};
`;
