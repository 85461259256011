import LabelOnlyButton from "components/commons/Buttons/LabelOnlyButton";
import React from "react";
import { useDispatch } from "react-redux";
import { closeModal } from "store/Modals/actions";
import { MODAL_NOMENCLARURE } from "store/Modals/constants";
import colors from "styles/colors";
import { Wrapper, ButtonContainer } from "./style";

const Buttons = () => {
  const dispatch = useDispatch();
  const onClick = () => dispatch(closeModal(MODAL_NOMENCLARURE));

  return (
    <Wrapper>
      <ButtonContainer>
        <LabelOnlyButton label={"Volver"} background={colors.blue50} color={colors.white} onClick={onClick} />
      </ButtonContainer>
    </Wrapper>
  );
};

export default Buttons;
