import styled from "styled-components";
import colors from "styles/colors";
import { ReactComponent as Reject } from "images/icons/blue/reject.svg";
import { ReactComponent as Eye } from "images/icons/blue/eye.svg";
import { ReactComponent as Check } from "images/icons/white/check.svg";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 24px 16px;
  gap: 16px;
  box-sizing: border-box;
  border: 1px solid ${colors.gray95};
  border-radius: 17px;
  background: ${colors.white};
  min-height: 80px;
`;

export const RejectIcon = styled(Reject)`
  & > path {
    fill: ${({ disabled }) => (disabled ? colors.gray80 : "auto")};
  }
`;

export const ObservedIcon = styled(Eye)`
  & > path {
    fill: ${({ disabled }) => (disabled ? colors.gray80 : "auto")};
  }
`;

export const ApprovedIcon = styled(Check)`
  & > path {
    fill: ${({ disabled }) => (disabled ? colors.gray80 : "auto")};
  }
`;
