import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  width: 100%;
  gap: 5px;
  box-sizing: border-box;
  @media (max-width: 1366px) {
    height: 80px;
  }
`;

export const Title = styled.div`
  font-family: "Domine";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  color: ${colors.orange50};
  @media (max-width: 1366px) or (max-height: 700px) {
    font-size: 24px;
  }
  @media (max-width: 680px) {
    font-size: 28px;
  }
  @media (max-width: 360px) {
    font-size: 24px;
  }
`;

export const Description = styled.div`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: ${colors.black};
  @media (max-width: 1366px) or (max-height: 700px) {
    font-size: 16px;
  }
  @media (max-width: 680px) {
    font-size: 18px;
  }
  @media (max-width: 360px) {
    font-size: 16px;
  }
`;
