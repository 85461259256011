/* eslint-disable react/no-children-prop */
import React from "react";
import { Wrapper, Description } from "./styled";
import Title from "./components/Title";

const SectionData = ({ showBack, description, route, title, fontSize, color }) => {
  return (
    <Wrapper>
      <Title showBack={showBack} route={route} color={color} fontSize={fontSize} text={title} />
      <Description children={description} />
    </Wrapper>
  );
};

export default SectionData;
