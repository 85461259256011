import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
  min-height: 88px;
  padding: 16px;
  gap: 8px;
  box-sizing: border-box;
  border: 1px solid ${colors.gray95};
  background: ${colors.white};
  border-radius: 4px;
`;

export const Text = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
  gap: 8px;
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: ${colors.gray10};
`;
