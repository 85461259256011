import React from "react";
import Charts from "./components/Charts";
import NumericalIndicators from "./components/NumericalIndicators";
import { Wrapper } from "./styled";

const DataPanel = () => {
  return (
    <Wrapper>
      <NumericalIndicators />
      <Charts />
    </Wrapper>
  );
};

export default DataPanel;
