import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 5px 0;
    height: 40px;
    border-bottom: solid 1px ${colors.gray95};
`;

export const Label = styled.label`
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: ${colors.black};
  ${({ disabled }) =>
    disabled &&
    `
      color: ${colors.orange50} !important; 
      cursor: not-allowed;
   `}
  margin-left: 16px;
  padding: 8px 0;
`;

export const Input = styled.input`
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  width: 1.5em;
  height: 1.5em;
  border: 2px solid ${colors.gray50};
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  ::after {
    content: "";
    border-radius: 50%;
    width: 75%;
    height: 75%;
    margin: auto;
  }
  :checked {
    ::after {
      background: ${colors.blue50};
    }
  }
  margin-right: 16px;
`;