import React from "react";
import { useSelector } from "react-redux";
import { getFields, getValues } from "store/PaperWork/actions/functions";
import { selectPaperworkStructure } from "store/PaperWork/selectors";
import CommonCard from "../CommonCard";
import { get } from "lodash";

const DataCard = ({ data, index, schemaFields }) => {
  const paperW = useSelector(selectPaperworkStructure);
  const otherInputs = get(paperW, `stages[${index}].form.otherInputs`, []);
  const inputType = get(paperW, `stages[${index}].form.otherInputs[0].id`, 0);
  const inputsFields = get(paperW, `stages[${index}].form.otherInputs[0].formFields`, {});

  const field = getFields(inputType, data[0], schemaFields, inputsFields);
  const value = getValues(inputType, otherInputs, data[1]);

  if (data[0] === "storage" || data[0] === "file") return null;

  return <CommonCard field={field} value={value} image={inputType === 1} />;
};

export default DataCard;
