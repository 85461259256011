/* eslint-disable react/no-children-prop */
import React from "react";
import { Wrapper, Title, Column, Text, Content } from "../styled";
import { useSelector } from "react-redux";
import { selectPaperworkData } from "store/PaperWork/selectors";
import { get } from "lodash";

const NomenclatureData = ({selectedPlot}) => {
  const data = useSelector(selectPaperworkData);
  const nomenclature = get(data, `[${selectedPlot}].nomenclature`, {});

  return (
    <Wrapper>
      <Title children="Nomenclatura catastral" />
      <Content>
        <Column>
          <Text children={`Departamento: ${get(nomenclature, "department", "-")}`} />
          <Text children={`Municipio: ${get(nomenclature, "municipality", "-")}`} />
          <Text children={`Sección: ${get(nomenclature, "section", "-")}`} />
          <Text children={`Chacra: ${get(nomenclature, "ranch", "-")}`} />
        </Column>
        <Column>
          <Text children={`Manzana: ${get(nomenclature, "square", "-")}`} />
          <Text children={`Parcela: ${get(nomenclature, "plot", "-")}`} />
          <Text children={`Subparcela: ${get(nomenclature, "subPlot", "-")}`} />
          <Text children={`Partida: ${get(nomenclature, "departure", "-")}`} />
        </Column>
      </Content>
    </Wrapper>
  );
};

export default NomenclatureData;
