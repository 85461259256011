import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div``;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: ${({ toogle }) => (toogle ? "32px" : "40px")};
  transition: all 0.5s;
  &:hover {
    ${({ toogle }) =>
      toogle &&
      `
    background: ${colors.gray99};
    border-radius: 100px;
  `}
  }
  ${({ selected }) =>
    selected &&
    `
    & > * {
     & path {
      fill: ${colors.blue50};
     }
    }
  `}
`;
