import MainLayout from "components/commons/MainLayout";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSubsanations } from "store/PaperWork/actions/requests";
import colors from "styles/colors";
import Header from "./Header";
import { TableContainer, Wrapper } from "./styled";
import Table from "../../commons/Table";
import { getColumns } from "./resources.jsx";
import { resetFilters } from "store/Filters/actions";
import Filters from "components/commons/Table/components/Filters";
import { selectPaperworkList } from "store/PaperWork/selectors";

const SubsanationRequests = () => {
  const dispatch = useDispatch();
  const data = useSelector(selectPaperworkList);

  useEffect(() => {
    dispatch(resetFilters());
    dispatch(getSubsanations());
  }, []);

  const columns = useMemo(() => getColumns(), []);

  return (
    <MainLayout background={colors.gray100} header={<Header />} paddingLeft={30} paddingRight={110}>
      <Wrapper>
        <TableContainer>
          <Table columns={columns} data={data}>
            <Filters items={[]} getPaperworkHistory={getSubsanations} />
          </Table>
        </TableContainer>
      </Wrapper>
    </MainLayout>
  );
};

export default SubsanationRequests;
