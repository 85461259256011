/* eslint-disable no-irregular-whitespace */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectPaperworkStructure, selectStageIndex, selectPaperworkStageProgress } from "store/PaperWork/selectors";
import Fields from "./components/Fields";
import { Wrapper } from "./styled";
import { get, isEmpty } from "lodash";
import { getValueRef } from "store/PaperWork/actions/functions";
import { setStageStructure } from "store/PaperWork/actions/actions";
import { JSON_SCHEMA_STRUCTURE } from "store/PaperWork/constants";
import { updateData } from "./functions";

const Form = () => {
  const dispatch = useDispatch();
  const index = useSelector(selectStageIndex);
  const paperwork = useSelector(selectPaperworkStructure);
  const stageP = useSelector(selectPaperworkStageProgress);
  const jsonSchema = get(paperwork, `stages[${index}].form.jsonSchema`, []);
  const dependencies = get(paperwork, `stages.[${index}].form.dependencies`, []);
  const dependency = dependencies.find((e) => e.posibilities.every((p) => p.value === getValueRef(p, stageP)));
  const required = get(dependency, "required", []);
  const updateJsonSchema = updateData(jsonSchema, required);

  useEffect(() => {
    //If there is a dependency, the values ​​indicated by it are set
    if (!isEmpty(jsonSchema)) dispatch(setStageStructure(index, JSON_SCHEMA_STRUCTURE, updateJsonSchema));
  }, [index]);

  if (isEmpty(jsonSchema)) return null;

  return (
    <Wrapper>
      <Fields />
    </Wrapper>
  );
};

export default Form;
