/* eslint-disable react/no-children-prop */
import React from "react";
import { Wrapper, Title, Text } from "../styled";
import { useSelector } from "react-redux";
import { selectPaperworkData, selectPaperworkMetadata, selectPaperworkStageProgress } from "store/PaperWork/selectors";
import { get } from "lodash";
import { isEmpty } from "utils/functions";
import List from "./styled";
import { PAPERWORK_STATE } from "store/PaperWork/constants";

const Owners = () => {
  const data = useSelector(selectPaperworkData);
  const owners = get(data, "[0].domainData.owners", []);

  const metadata = useSelector(selectPaperworkMetadata);
  const stageProgress = useSelector(selectPaperworkStageProgress);
  const otherInputsData = get(stageProgress, "[0].otherInputsData", {});
  const isUnconstitutedLand = get(Object.values(otherInputsData)[0], "unconstitutedLand", false);
  const state = get(metadata, "state", null);
  const isRejected = state === PAPERWORK_STATE.REJECTED;

  if (isRejected) return null;

  if (isUnconstitutedLand)
    return (
      <Wrapper>
        <Title children="Propietario/s" />
        {isEmpty(owners) && <Text children="Sin información" />}
        <List>
          {owners.map((owner, index) => (
            <div key={index}>
              <Text children={`Razón social: ${get(owner, "businessName", "-")}`} />
              <Text children={`Dirección: ${get(owner, "address", "-")}`} />
              <Text children={`CUIT: ${get(owner, "cuit", "-")}`} />
            </div>
          ))}
        </List>
      </Wrapper>
    );

  return (
    <Wrapper>
      <Title children="Propietario/s" />
      {isEmpty(owners) && <Text children="Sin información" />}
      <List>
        {owners.map((owner, index) => (
          <div key={index}>
            <Text children={`Razón social: ${get(owner, "businessName", "-")}`} />
            <Text children={`Fecha de titularización: ${get(owner, "securitizationDate", "-")}`} />
            <Text children={`Porcentaje: ${get(owner, "percentage", "-")} %`} />
          </div>
        ))}
      </List>
    </Wrapper>
  );
};

export default Owners;
