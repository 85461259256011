/* eslint-disable react/no-children-prop */
import React from "react";
import Wrapper from "../RealStateTax/styled";
import { Title, Text } from "../styled";
import { useSelector } from "react-redux";
import { selectPaperworkData } from "store/PaperWork/selectors";
import { get } from "lodash";

const Registration = ({selectedPlot}) => {
  const data = useSelector(selectPaperworkData);
  const registry = get(data, `[${selectedPlot}].domainData.registry`, {});

  const department = get(registry, "department", "-");
  const volumeFolio = get(registry, "volumeFolio", "-");
  const number = get(registry, "number", "-");
  const PHDigit = get(registry, "PHDigit", "-");

  return (
    <Wrapper>
      <Title children="Inscripción registro propiedad del inmueble" />
      <Text children={`Departamento: ${department}`} />
      <Text children={`Tomo - Folio - Finca: ${volumeFolio}`} />
      <Text children={`Matrícula: ${number}`} />
      <Text children={`Dígito PH: ${PHDigit}`} />
    </Wrapper>
  );
};

export default Registration;
