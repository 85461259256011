/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import OptionButtons from "components/commons/OptionButtons";
import { BACK, FINALIZE } from "components/commons/OptionButtons/constants";
import { PATH_PAPERWORK } from "components/pages/App/constants";
import { useDispatch, useSelector } from "react-redux";
import { sendPaperworkToReview } from "store/PaperWork/actions/requests";
import { selectPaperworkStructure, selectPaperworkMetadata } from "store/PaperWork/selectors";
import { push } from "connected-react-router";

const Buttons = () => {
  const dispatch = useDispatch();
  const { id: idPap } = useSelector(selectPaperworkStructure);
  const { id: idprog } = useSelector(selectPaperworkMetadata);

  const back = () => dispatch(push(PATH_PAPERWORK));
  const sendForm = () => dispatch(sendPaperworkToReview(idPap, idprog));

  return <OptionButtons leftDisb={true} leftText={BACK} rightText={FINALIZE} leftAction={back} rightAction={sendForm} />;
};

export default Buttons;
