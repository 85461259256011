/* eslint-disable react/react-in-jsx-scope */
import Checkbox from "./components/Checkbox";
import DoubleLine from "./components/DividingLines/DoubleLine";
import CommonLine from "./components/DividingLines/CommonLine";
import Input from "./components/Input";
import RadioOptions from "./components/RadioOptions";
import SectionText from "./components/SectionText";
import Select from "./components/Select";
import SelectionCard from "./components/SelectionCard";
import TextArea from "./components/TextArea";
import Upload from "./components/Upload";
import Description from "./components/Description";

export const COMPONENTS = {
  selectionCard: (e, stageKey, index) => <SelectionCard item={e} stageKey={stageKey} key={`${stageKey}_${index}`} />,
  input: (e, stageKey, index) => <Input item={e} stageKey={stageKey} key={`${stageKey}_${index}`} />,
  select: (e, stageKey, index) => <Select item={e} stageKey={stageKey} key={`${stageKey}_${index}`} />,
  checkbox: (e, stageKey, index) => <Checkbox item={e} stageKey={stageKey} key={`${stageKey}_${index}`} />,
  fileUpload: (e, stageKey, index) => <Upload item={e} stageKey={stageKey} key={`${stageKey}_${index}`} />,
  commonLine: (e, stageKey, index) => <CommonLine key={`${stageKey}_${index}`} />,
  doubleLine: (e, stageKey, index) => <DoubleLine key={`${stageKey}_${index}`} />,
  sectionText: (e, stageKey, index) => <SectionText item={e} key={`${stageKey}_${index}`} />,
  description: (e, stageKey, index) => <Description item={e} key={`${stageKey}_${index}`} />,
  textArea: (e, stageKey, index) => <TextArea item={e} stageKey={stageKey} key={`${stageKey}_${index}`} />,
  radioOptions: (e, stageKey, index) => <RadioOptions item={e} stageKey={stageKey} key={`${stageKey}_${index}`} />,
};
