import styled from "styled-components";

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 100%;
  padding-top: 20px;
  gap: 16px;
  box-sizing: border-box;
  overflow: hidden;
  @media (max-width: 1440px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 580px) {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: end;
    width: 100%;
    height: 100%;
    padding: 20px 0px 20px 0px;
    gap: 8px;
    overflow: hidden;
    box-sizing: border-box;
  }
`;
