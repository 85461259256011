import styled from "styled-components";
import colors from "styles/colors";
import { fadeIn } from "styles/effects";
import { ReactComponent as LockIcon } from "images/icons/blue/lock.svg";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 5;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 30px 30px 30px 30px;
  overflow: hidden;
  animation: 1s ${fadeIn} forwards;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.75);
`;
Wrapper.displayName = "Wrapper";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: right;
  width: 310px;
  height: 280px;
  padding: 34px 24px;
  box-sizing: border-box;
  background: ${colors.white};
  overflow: hidden;
  border: 1px solid #F3F4F7;
  box-shadow: 0px 24px 38px rgba(131, 146, 175, 0.14), 0px 9px 46px rgba(131, 146, 175, 0.12), 0px 11px 15px rgba(131, 146, 175, 0.2);
  border-radius: 16px;
`;
Container.displayName = "Container";

export const Lock = styled(LockIcon)`
  margin-bottom: 16px;
`;
Lock.displayName = "Lock";

export const Title = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  font-family: 'Domine';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: ${colors.blue50};
  margin-bottom: 16px;
`;
Title.displayName = "Title";

export const Description = styled.div`
  display: flex;
  align-items: center;
  letter-spacing: 0.1px;
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.gray20};
`;
Description.displayName = "Description";

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  margin-top: auto;
`;
Description.displayName = "Buttons";

export const AcceptButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 87px;
  height: 32px;
  margin-left: 8px;
`;

export const CancelButtonContainer = styled(AcceptButtonContainer)`
  width: 63px;
`;

