import { WEBSOCKET_BASE_URL } from "components/pages/App/constants";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { wsConnect, wsDisconnect } from "store/websocket/actions";

const useWebsocket = (onConnect, onDisconnect) => {
  const dispatch = useDispatch();
  const host = WEBSOCKET_BASE_URL;
  
  useEffect(() => {
    dispatch(wsConnect(host));
    onConnect && onConnect();

    return () => {
      onDisconnect && onDisconnect();
      dispatch(wsDisconnect(host));
    };
  }, [dispatch, host]);
};

export default useWebsocket;
