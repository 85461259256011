/* eslint-disable react/no-children-prop */
import React from "react";
import { useSelector } from "react-redux";
import { selectPaperworkData, selectPaperworkStageProgress } from "store/PaperWork/selectors";
import { Column, Content, Text, Title, Wrapper } from "../styled";
import { get } from "lodash";

const FractionDenomination = () => {
  const data = useSelector(selectPaperworkData);
  const observations = get(data, "[0].domainData.fractionDenomination", "-");
  const stageProgress = useSelector(selectPaperworkStageProgress);
  const unconstitutedLand = get(stageProgress, "[0].otherInputsData.InformCatastral.unconstitutedLand", "");

  if (!unconstitutedLand) return null;
  return (
    <Wrapper>
      <Title children="Denominación de fracción" />
      <Content>
        <Column>
          <Text children={observations || "No hay observaciones"} />
        </Column>
      </Content>
    </Wrapper>
  );
};

export default FractionDenomination;
