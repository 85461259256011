export const GRAY = "gray";
export const YELLOW = "yellow";
export const ORANGE = "orange";
export const BLUE = "blue";

export const size = {
  size1: "18px",
  size2: "24px",
  size3: "28px",
};
