import styled from "styled-components";

export const Wrapper = styled.div``;

export const Container = styled.div`
  display: ${(props) => (props.toogle ? "none" : "flex")};
  align-items: center;
  justify-content: left;
  height: ${({ toogle }) => (toogle ? "24px" : "40px")};
  transition: all 0.5s;
`;