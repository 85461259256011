import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex: 0 0 326px;
  height: 80px;
  padding: 18px 16px;
  box-sizing: border-box;
  border: 1px solid ${colors.gray90};
  background: ${colors.white};
  cursor: pointer;
  ${({ checked }) => checked && `border-color: ${colors.lightBlue70}; background: ${colors.lightBlue95}`};
  ${({ disabled }) => disabled && "pointer-events: none; opacity: 0.5"};
  @media (max-width: 1366px) {
    flex: 0 0 300px;
  }
`;
