import React, { useState } from "react";
import { Wrapper, Container } from "./style";
import { useSelector } from "react-redux";
import Header from "../components/Header";
import Content from "./components/Content";
import { MODAL_SHOW_REJECTION } from "store/Modals/constants";
import { selectPaperworkMetadata } from "store/PaperWork/selectors";
import { PAPERWORK_STATE } from "store/PaperWork/constants";


const ShowRejectionModal = () => {
  const [expanded, setExpanded] = useState(false);
  const paperworkProgress = useSelector(selectPaperworkMetadata);
  let title;
  if (paperworkProgress && (paperworkProgress.state == PAPERWORK_STATE.REJECT_REQUESTED)) {
    title = "Solicitud de Rechazo";
  } else {
    title = "Motivo del Rechazo";
  }

  return (
    <Wrapper>
      <Container expanded={expanded}>
        <Header expanded={expanded} setExpanded={setExpanded} modal={MODAL_SHOW_REJECTION} title={title} />
        <Content expanded={expanded} />
      </Container>
    </Wrapper>
  );
};

export default ShowRejectionModal;
