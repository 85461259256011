/* eslint-disable react/no-children-prop */
import React from "react";
import { Wrapper, Title } from "../styled";
import { useSelector } from "react-redux";
import { selectPaperworkData } from "store/PaperWork/selectors";
import { get } from "lodash";
import Image from "./components/Image";

const CadastralBoundary = ({ selectedPlot }) => {
  const data = useSelector(selectPaperworkData);
  const resource = get(data, `[${selectedPlot}].planData.cadastralBoundary`, "");

  return (
    <Wrapper>
      <Title children="Linderos catastral" />
      <Image resource={resource} />
    </Wrapper>
  );
};

export default CadastralBoundary;
