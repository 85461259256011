import { OTHER_INPUTS } from "store/PaperWork/constants";
import { get, isEmpty, cloneDeep, isUndefined } from "lodash";

export const checkDependecies = (stage, dependencies, defaultValue, disabled = false) => {
  let values = { value: defaultValue, disabled: disabled, hidden: false };
  if (isEmpty(dependencies)) return values;
  dependencies.map((e) => {
    const data = get(stage, `[${e.stageIndex}].otherInputsData[${e.stageKey}]`, {});
    if (!data) return null;
    if (e.posibilities.some((f) => f.dependencyValue === data[e.field])) {
      values = e.posibilities.filter((f) => f.dependencyValue === data[e.field])[0].values;
      return null;
    }
  });
  return values;
};

export const getInitialValue = (stage, index, stageKey, key, defaultValue) => {
  if (!stage[index][OTHER_INPUTS][stageKey]) return defaultValue;
  if (!stage[index][OTHER_INPUTS][stageKey][key]) return defaultValue;
  return stage[index][OTHER_INPUTS][stageKey][key];
};

export const getValues = (value, key, dependencies, stage, values, defaultValue) => {
  let val = cloneDeep(value);
  if (isUndefined(val)) return defaultValue;
  if (isEmpty(dependencies) || isUndefined(values[key])) return val;
  dependencies.map((e) => {
    const valueRef = get(stage, `[${e.stageIndex}][${OTHER_INPUTS}][${e.stageKey}][${e.field}]`);
    if (e.posibilities.some((f) => f.dependencyValue === valueRef)) val = values[key];
  });
  return val;
};
