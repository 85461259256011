import styled from "styled-components";
import colors from "styles/colors";
import { ReactComponent as NextIcon } from "images/icons/white/arrowRight.svg";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
Wrapper.displayName = "Wrapper";

export const Summary = styled.div`
  user-select: none;
  margin-left: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'OpenSansRegular';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  white-space: nowrap;
  font-style: normal;
  color: ${colors.gray50};
`;
Summary.displayName = "Summary";

export const WrapperButton = styled.button`
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
  padding: 0px;
  border: 0;
  background: transparent;
  background-color: transparent;
  margin: 0 0 0 25px;
  & > svg {
    & > path {
      fill: ${colors.gray40};
    }
  }
  &:disabled {
    & > svg {
      cursor: default;
    }
    & > svg {
      & > path {
        fill: ${colors.gray90};
      }
    }
  }
`;
WrapperButton.displayName = "WrapperButton";

export const Prev = styled(NextIcon)`
  transform: rotate(180deg)
`;
Prev.displayName = "Prev";

export const Next = styled(NextIcon)`
`;
Next.displayName = "Next";
