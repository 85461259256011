import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  align-items: top;
  justify-content: left;
  width: 100%;
  gap: 8px;
  box-sizing: border-box;
`;

export const Text = styled.div`
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: ${colors.blue60};
`;
