import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.tfoot`
  display: flex;
  flex-direction: row-reverse;
  padding: 15px 56px 15px 30px;
  padding-left: 30px;
  padding-right: 56px;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  border-top: 1px solid ${colors.blue95};
  background: ${colors.white};
  align-items: right;
`;
export default Wrapper;