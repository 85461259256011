import styled from "styled-components";
import { ReactComponent as Icon } from "images/icons/gray/send_email.svg";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  cursor: ${({ isDbUser }) => (isDbUser ? "cursor" : "pointer")};
  opacity: ${({ isDbUser }) => (isDbUser ? "0.5" : "1")};
`;

export const Email = styled(Icon)``;
