import React from "react";
import { PENDING_USERS, USERS } from "../Selector";
import PendingUsers from "./components/ PendingUsers";
import Users from "./components/Users";

const Sections = ({ id }) => {
  if (id === USERS) return <Users />;
  if (id === PENDING_USERS) return <PendingUsers />;
  return null;
};

export default Sections;
