import React from "react";
import { Wrapper } from "./styled";
import SeeMoreIcon from "../../../../../Icons/SeeMoreIcon";
import { MODAL_SEE_MORE } from "store/Modals/constants";
import { useDispatch } from "react-redux";
import { openModal } from "store/Modals/actions";
import { setSeeMorePaperwork } from "store/PaperWork/actions/actions";

const SeeMoreCell = ({ row }) => {
  const dispatch = useDispatch();

  const onClick = (event) => {
    dispatch(setSeeMorePaperwork(row.original));
    dispatch(openModal(MODAL_SEE_MORE));
    event.stopPropagation();
  };

  return (
    <Wrapper>
      <SeeMoreIcon onClick={onClick} />
    </Wrapper>
  );
};

export default SeeMoreCell;
