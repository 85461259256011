const P = "PAPERWORK";

export const SET_NEW_PAPERWORKS_LIST = `${P}/SET_NEW_PAPERWORKS_LIST`;
export const SET_STAGE_INDEX = `${P}/SET_STAGE_INDEX`;
export const SET_STAGE_DATA = `${P}/SET_STAGE_DATA`;
export const SET_STAGE = `${P}/SET_STAGE`;
export const SET_STAGE_COMPLETED = `${P}/SET_STAGE_COMPLETED`;
export const SET_STAGE_STRUCTURE = `${P}/SET_STAGE_STRUCTURE`;
export const SET_PAPERWORK_PAGE = `${P}/SET_PAPERWORK_PAGE`;
export const SET_FORM_ERRORS = `${P}/SET_FORM_ERRORS`;
export const SET_PAPERWORK_LIST = `${P}/SET_PAPERWORK_LIST`;
export const SET_PAPERWORK_DETAIL = `${P}/SET_PAPERWORK_DETAIL`;
export const RESET_PAPERWORK_STORE = `${P}/RESET_PAPERWORK_STORE`;
export const SET_REPORT_SHARE_LINK = `${P}/SET_REPORT_SHARE_LINK`;
export const SET_PAPERWORK_COUNT_STATISTICS = `${P}/SET_PAPERWORK_COUNT`;
export const SET_DOCUMENTS = `${P}/SET_DOCUMENTS`;
export const UPDATE_PAPERWORK_METADATA = `${P}/UPDATE_PAPERWORK_METADATA`;
export const SET_CURRENT_REJECTION = `${P}/SET_CURRENT_REJECTION`;
export const SET_CURRENT_MENDING_REQUEST = `${P}/SET_CURRENT_MENDING_REQUEST`;
export const SET_MODIFY_MODE = `${P}/SET_MODIFY_MODE`;
export const SET_SEE_MORE_PAPERWORK = `${P}/SET_SEE_MORE_PAPERWORK`;
export const SET_SUBSANATION_AREAS = `${P}/SET_SUBSANATION_AREAS`;
export const SET_CURRENT_PAPERWORK_TYPES = `${P}/SET_CURRENT_PAPERWORK_TYPES`;
export const SET_PAPERWORK_DATA = `${P}/SET_PAPERWORK_DATA`;
