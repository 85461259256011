import React, { useEffect } from "react";
import { Wrapper } from "./styled";
import colors from "styles/colors";
import { useDispatch, useSelector } from "react-redux";
import { selectpaperworksCountStatistics } from "store/PaperWork/selectors";
import { getPaperworksStatisticsIncome, getPaperworksStatisticsApproved } from "store/PaperWork/actions/requests";
import RightCard from "./components/RightCard";
import LeftCard from "./components/LeftCard";
import { getReformatApprovedData, getReformatIncomeData } from "./functions";
import moment from "moment";
import { FORMAT_DATE } from "store/Filters/constants";

const BottomPanel = () => {
  const dispatch = useDispatch();
  const today = moment();
  const dateTo = today.format(FORMAT_DATE);
  const dateFrom = today.subtract(1, "months").format(FORMAT_DATE);
  const { paperworksIncome, approvedPaperworks } = useSelector(selectpaperworksCountStatistics);

  useEffect(() => {
    dispatch(getPaperworksStatisticsIncome(dateFrom, dateTo, "paperworksIncome"));
    dispatch(getPaperworksStatisticsApproved(dateFrom, dateTo, "approvedPaperworks"));
  }, []);

  return (
    <Wrapper>
      <LeftCard
        leftChartColor={colors.green50}
        rightChartColor={colors.green60}
        data={getReformatApprovedData(approvedPaperworks)}
        detail={approvedPaperworks.approvedObserved + approvedPaperworks.approved}
        approvedObserved={approvedPaperworks.approvedObserved}
        approved={approvedPaperworks.approved}
      />
      <RightCard
        leftChartColor={colors.gray99}
        rightChartColor={colors.orange50}
        data={getReformatIncomeData(paperworksIncome)}
        detail={paperworksIncome.totalPaperworks}
        observedCount={paperworksIncome.observed}
      />
    </Wrapper>
  );
};

export default BottomPanel;
