import React from "react";
import { MODAL_EDIT_USER } from "store/Modals/constants";
import { Wrapper, Container } from "../styled";
import Header from "../components/Header";
import Content from "../components/Content";
import { useDispatch, useSelector } from "react-redux";
import { acceptUser, updateUser } from "store/BackOffice/actions/requests";
import { selectParams } from "store/Modals/selectors";
import { get } from "lodash";
import { closeModal } from "store/Modals/actions";

const EditModal = () => {
  const dispatch = useDispatch();
  const payload = useSelector(selectParams);
  const id = get(payload, "id", "");
  const profileId = get(payload, "profileId", null);
  const confirm = (user) => (profileId ? dispatch(updateUser(id, user)) : dispatch(acceptUser(id, user)));
  const close = () => dispatch(closeModal(MODAL_EDIT_USER));

  return (
    <Wrapper onClick={(e) => close(e)}>
      <Container onClick={(e) => e.stopPropagation()}>
        <Header title="Editar usuario" description="Ingrese los nuevos datos" />
        <Content modal={MODAL_EDIT_USER} confirm={confirm} edit={true} />
      </Container>
    </Wrapper>
  );
};

export default EditModal;
