import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 10px;
  box-sizing: border-box;
  gap: 8px;
  @media (max-width: 680px) {
    width: auto;
  }
`;

export const Text = styled.div`
  display: flex;
  align-items: center;
  margin-right: auto;
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  font-size: ${(props) => props.size};
  color: ${colors.gray50};
  flex: 1;
  user-select: none;
`;
