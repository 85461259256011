import React from "react";
import { Wrapper, Container } from "./style";
import Header from "./components/Header";
import Content from "./components/Content";

const NomenclatureModal = () => {
  return (
    <Wrapper>
      <Container>
        <Header />
        <Content />
      </Container>
    </Wrapper>
  );
};

export default NomenclatureModal;
