import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-bottom: 40px;
  gap: 48px;
  overflow: visible;
  box-sizing: border-box;
  @media (max-width: 980px) {
    padding: 16px;
  }
`;
