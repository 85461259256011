/* eslint-disable no-unused-vars */
/* eslint-disable react/no-children-prop */
import React from "react";
import { Wrapper, Label, Input } from "./styled";

const RadioButton = ({ onClick, label, checked, value, name }) => {
  return (
    <Wrapper>
      <Input type="radio" onClick={onClick} checked={checked} value={value} name={name} />
      <Label htmlFor="radio-button" children={label} />
    </Wrapper>
  );
};

export default RadioButton;
