import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFilters } from "store/Filters/actions";
import { selectFilterByKey } from "store/Filters/selectors";
import { Wrapper } from "./style";
import CommonInput from "components/commons/Inputs/CommonInput";

const Form = ({ section }) => {
  const dispatch = useDispatch();
  const { elements, key } = section;
  const filterValue = useSelector((state) => selectFilterByKey(state, key));
  const [value, setValue] = useState(filterValue);

  const onChange = (e) => {
    setValue(e.target.value);
    dispatch(setFilters(key, e.target.value));
  };

  return (
    <Wrapper>
      {elements.map((element) => (
          <CommonInput 
            key={key} 
            placeholder={element.placeholder} 
            value={value}
            onChange={onChange} 
          />
        ))
      }
  </Wrapper>);
};

export default Form;
