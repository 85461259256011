/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import OptionButtons from "components/commons/OptionButtons";
import { BACK } from "components/commons/OptionButtons/constants";
import { saveStage } from "store/PaperWork/actions/requests";
import { back, goToPreview } from "store/PaperWork/actions/functions";
import { selectStageCompleted, selectStageIndex, selectPaperworkStageProgress, selectPaperworkType } from "store/PaperWork/selectors";
import { everyAlreadyFilled, generatePayload, getText } from "./functions";
import { get, isEqual } from "lodash";
import { PAPERWORK_TYPE, PAPERWORK_WITHOUT_PREVIEW } from "store/PaperWork/constants";
import { openModal } from "store/Modals/actions";
import { MODAL_SHOW_REJECTION } from "store/Modals/constants";

const Buttons = ({ toggle }) => {
  const dispatch = useDispatch();
  const index = useSelector(selectStageIndex);
  const enabled = useSelector(selectStageCompleted);
  const stageProgress = useSelector(selectPaperworkStageProgress);
  const type = useSelector(selectPaperworkType);
  const modify = !PAPERWORK_WITHOUT_PREVIEW.includes(type) && everyAlreadyFilled(stageProgress);
  const jsonSchemaData = get(stageProgress, `[${index}].jsonSchemaData`, {});
  const otherInputsData = get(stageProgress, `[${index}].otherInputsData`, {});
  const stageId = get(stageProgress, `[${index}].stageId`, null);
  const files = get(stageProgress, `[${index}].attachedFiles`, []);
  const data = generatePayload(jsonSchemaData, otherInputsData, files);
  const [savedStage, setSavedStage] = useState(stageProgress[index]);
  const isLastStage = stageProgress.length === index + 1;

  const finishFlow = () => {
    dispatch(saveStage(stageId, data));
    if (isLastStage && type === PAPERWORK_TYPE.D2) toggle();
  };

  const goBack = () => dispatch(back());
  const following = () => (isEqual(savedStage, stageProgress[index]) ? dispatch(goToPreview()) : finishFlow());

  return (
    <OptionButtons
      leftDisb={modify}
      rigthDisb={!enabled}
      leftText={BACK}
      rightText={getText(modify, isLastStage, type)}
      leftAction={goBack}
      rightAction={following}
    />
  );
};

export default Buttons;
