import React from "react";
import LeftBlock from "./components/LeftBlock";
import RightBlock from "./components/RightBlock";
import { Wrapper } from "./styled";

const SmallCard = ({ background, color, data, detail, inCome }) => {
  return (
    <Wrapper>
      <LeftBlock background={background} color={color} detail={detail} inCome={inCome} />
      <RightBlock background={background} color={color} data={data} />
    </Wrapper>
  );
};

export default SmallCard;
