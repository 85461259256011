import styled from "styled-components";
import { fadeIn } from "styles/effects";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: end;
  width: 100%;
  //height: 100%;
  margin-bottom: 50px;
  overflow: visible;
  animation: 0.9s ${fadeIn} forwards;
  box-sizing: border-box;
  @media (max-width: 580px) {
    margin-bottom: 30px;
  }
`;
