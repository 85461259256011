/* eslint-disable no-unused-vars */
/* eslint-disable react/react-in-jsx-scope */
import intersection from "lodash/intersection";
import union from "lodash/union";

export const checkValue = (actualFilterStates, filterStates) => {
  const isActive = intersection(filterStates, actualFilterStates).length === filterStates.length;
  if (isActive) return actualFilterStates.filter((e) => !filterStates.includes(e));
  return union(actualFilterStates, filterStates);
};

export const isActive = (filterStates, actualFilterStates) => {
  return intersection(filterStates, actualFilterStates).length === filterStates.length;
};
