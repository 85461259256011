import React from "react";
import { useDispatch } from "react-redux";
import { Wrapper, Title, IconsContainer } from "./style";
import { closeModal } from "store/Modals/actions";
import ArrowExpandIcon from "components/commons/Icons/ArrowExpandIcon";
import CloseIcon from "components/commons/Icons/CloseIcon";
import { GRAY } from "styles/constants";

const Header = ({ expanded, setExpanded, modal }) => {
  const dispatch = useDispatch();

  const toggle = () => {
    dispatch(closeModal(modal));
  };

  return (
    <Wrapper expanded={expanded}>
      <Title expanded={expanded}>Observaciones</Title>
      <IconsContainer>
        <ArrowExpandIcon
          onClick={() => {
            setExpanded(!expanded);
          }}
        />
        <CloseIcon
          color={GRAY}
          onClick={() => {
            toggle();
          }}
        />
      </IconsContainer>
    </Wrapper>
  );
};

export default Header;
