/* eslint-disable react/jsx-key */
import React from "react";
import Modal from "./components/Modal";
import { MODALS } from "./constants";

const ModalsController = () => {
  return MODALS.map((modal) => <Modal key={modal.id} component={modal.component} id={modal.id} />);
};

export default ModalsController;
