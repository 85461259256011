import React, { useState } from "react";
import { Wrapper, Container, Title, Description, Content } from "./style";
import { useSelector } from "react-redux";
import { selectModalTitle, selectModalDescription } from "store/Modals/selectors";
import RadioOptions from "./components/RadioOptions";
import Buttons from "./components/Buttons";
import { useDispatch } from "react-redux";
import { closeModal } from "store/Modals/actions";
import { MODAL_MULTIPLE_DEPARTURES } from "store/Modals/constants";
import { setValueInJsonSchema } from "store/PaperWork/actions/functions";

const MultipleDeparturesModal = () => {
  const dispatch = useDispatch();
  const title = useSelector(selectModalTitle);
  const description = useSelector(selectModalDescription);
  const [currentValue, setCurrentValue] = useState("");

  const onClick = (e) => {
    setCurrentValue(e.target.value);
  };

  const onClose = () => {
    dispatch(closeModal(MODAL_MULTIPLE_DEPARTURES));
  };

  const onApply = () => {
    dispatch(closeModal(MODAL_MULTIPLE_DEPARTURES));
    dispatch(setValueInJsonSchema("departure", currentValue));
  };

  return (
    <Wrapper>
      <Container>
        <Content>
          <Title>{title}</Title>
          <Description>{description}</Description>
          <RadioOptions currentValue={currentValue} onClick={onClick}/>
        </Content>
        <Buttons onClose={onClose} onApply={onApply}/>
      </Container>
    </Wrapper>
  );
};

export default MultipleDeparturesModal;
