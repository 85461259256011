import styled from "styled-components";
import { ReactComponent as Home } from "images/icons/gray/home-outline.svg";
import { ReactComponent as paperwork } from "images/icons/gray/paperwork.svg";
import { ReactComponent as Dashboard } from "images/icons/gray/dashboard.svg";
import { ReactComponent as Exclamation } from "images/icons/gray/exclamation.svg";
import { ReactComponent as Setting } from "images/icons/gray/setting.svg";
import { ReactComponent as Logout } from "images/icons/gray/logout.svg";
import { ReactComponent as User } from "images/icons/gray/user.svg";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-top: 20px;
  cursor: pointer;
  transition: all 0.5s;
  overflow: hidden;
  box-sizing: border-box;
`;

export const HomeIcon = styled(Home)``;

export const PaperworkIcon = styled(paperwork)``;

export const UserIcon = styled(User)``;

export const DashboardIcon = styled(Dashboard)``;

export const HelpIcon = styled(Exclamation)``;

export const SettingIcon = styled(Setting)``;

export const LogoutIcon = styled(Logout)``;
