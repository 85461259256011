/* eslint-disable react/no-children-prop */
import React from "react";
import { Wrapper } from "./styled";

const TopElements = ({ element }) => {
  if (!element) return null;
  return <Wrapper children={element} />;
};

export default TopElements;
