import React from "react";
import Cell from "../../commons/Table/components/Structure/components/Cell";
import SeeMoreCell from "../../commons/Table/components/Structure/components/SeeMoreCell";
import StatusCell from "../../commons/Table/components/Structure/components/StatusCell";
import { Column } from "../../commons/Table/styled";

const columns = [
  {
    Header: () => <Column>N° de trámite</Column>,
    width: 88,
    accessor: "paperworkProgressId",
    Cell: ({ value, row }) => <Cell value={value} row={row} />,
  },
  {
    Header: () => <Column>CUIT/CUIL</Column>,
    width: 120,
    accessor: "cuit",
    Cell: ({ value, row }) => <Cell value={value} row={row} />,
  },
  {
    Header: () => <Column>Departamento</Column>,
    width: 88,
    accessor: "department",
    Cell: ({ value, row }) => <Cell value={value} row={row} />,
  },
  {
    Header: () => <Column>Partida</Column>,
    width: 88,
    accessor: "departure",
    Cell: ({ value, row }) => <Cell value={value} row={row} />,
  },
  {
    Header: () => <Column>Nomenclatura</Column>,
    width: 186,
    accessor: "cadastralNomenclature",
    Cell: ({ value, row }) => <Cell value={value} row={row} />,
  },
  {
    Header: () => <Column>Fecha inicio</Column>,
    width: 110,
    accessor: "startDate",
    Cell: ({ row, value }) => <Cell value={value} row={row} />,
  },
  {
    Header: () => <Column>Estado</Column>,
    width: 110,
    accessor: "state",
    Cell: ({ row, value }) => <StatusCell value={value} row={row} />,
  },
  {
    Header: () => <Column>Ver más</Column>,
    width: 56,
    id: "seeMore",
    Cell: ({ row }) => <SeeMoreCell row={row} />,
  },
];

export const getColumns = () => {
  return columns;
};
