import React from "react";
import { Wrapper, Row } from "./styled";
import NomenclatureData from "./components/NomenclatureData";
import PlanData from "./components/PlanData";
import RealStateDenomination from "./components/RealStateDenomination";
import CadastralBoundary from "./components/CadastralBoundary";
import Registration from "./components/Registration";
import IssuedCertificates from "./components/IssuedCertificates";
import Value from "./components/Value";
import RealStateTax from "./components/RealStateTax";
import Location from "./components/Location";
import DepartureObservations from "./components/DepartureObservations";
import PlotObservations from "./components/PlotObservations";
import { useSelector } from "react-redux";
import { selectPaperworkMetadata } from "store/PaperWork/selectors";
import { PAPERWORK_STATE } from "store/PaperWork/constants";
import { get } from "lodash";
import FractionDenomination from "./components/FractionDenomination";

const RecoveredData = ({ selectedPlot }) => {
  const metadata = useSelector(selectPaperworkMetadata);
  const state = get(metadata, "state", null);
  const isRejected = state === PAPERWORK_STATE.REJECTED;

  if (isRejected) {
    return (
      <Wrapper>
        <NomenclatureData selectedPlot={selectedPlot} />
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      <NomenclatureData selectedPlot={selectedPlot} />
      <PlanData selectedPlot={selectedPlot} />
      <RealStateDenomination selectedPlot={selectedPlot} />
      <CadastralBoundary selectedPlot={selectedPlot} />
      <Value selectedPlot={selectedPlot} />
      <Row>
        <Registration selectedPlot={selectedPlot} />
        <RealStateTax selectedPlot={selectedPlot} />
      </Row>
      <Row>
        <IssuedCertificates selectedPlot={selectedPlot} />
        <Location selectedPlot={selectedPlot} />
      </Row>
      <PlotObservations selectedPlot={selectedPlot} />
      <DepartureObservations selectedPlot={selectedPlot} />
      <FractionDenomination />
    </Wrapper>
  );
};

export default RecoveredData;
