/* eslint-disable react/no-children-prop */
import React from "react";
import { Wrapper, Title, Column, Text, Content } from "../styled";
import { useSelector } from "react-redux";
import { selectPaperworkData } from "store/PaperWork/selectors";
import { get, isNull } from "lodash";

const NomenclatureData = ({ selectedPlot }) => {
  const data = useSelector(selectPaperworkData);
  const nomenclature = get(data, `[${selectedPlot}].nomenclature`, {});

  const check = (key) => {
    const checkValue = get(nomenclature, key, "-");
    if (isNull(checkValue)) return "-";
    return checkValue;
  };

  return (
    <Wrapper>
      <Title children="Datos de nomenclatura y partida" />
      <Content>
        <Column>
          <Text children={`Departamento: ${check("department")}`} />
          <Text children={`Municipio: ${check("municipality")}`} />
          <Text children={`Sección: ${check("section")}`} />
          <Text children={`Chacra: ${check("ranch")}`} />
        </Column>
        <Column>
          <Text children={`Manzana: ${check("square")}`} />
          <Text children={`Parcela: ${check("plot")}`} />
          <Text children={`Subparcela: ${check("subPlot")}`} />
          <Text children={`Partida: ${check("departure")}`} />
        </Column>
      </Content>
    </Wrapper>
  );
};

export default NomenclatureData;
