import styled from "styled-components";
import colors from "styles/colors";
import { fadeIn } from "styles/effects";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 5;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 30px 16px;
  overflow: hidden;
  animation: 1s ${fadeIn} forwards;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.75);
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  max-width: 520px;
  max-height: 520px;
  box-sizing: border-box;
  background: ${colors.white};
  overflow: hidden;
  border: 1px solid #f3f4f7;
  box-shadow: 0px 24px 38px rgba(131, 146, 175, 0.14), 0px 9px 46px rgba(131, 146, 175, 0.12), 0px 11px 15px rgba(131, 146, 175, 0.2);
  border-radius: 16px;
  @media (max-width: 980px) {
    width: 100%;
  }
  @media (max-width: 360px) {
    max-height: 345px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  width: 100%;
  height: 100%;
  padding: 0px 32px 72px 0px;
  gap: 40px;
  box-sizing: border-box;
  @media (max-width: 1366px) {
    overflow: auto;
    gap: 20px;
  }
`;

export const MediaContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 48px;
  box-sizing: border-box;
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  width: 80px;
  height: 100%;
  gap: 8px;
  box-sizing: border-box;
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background: ${colors.gray100};
`;

export const Text = styled.div`
  display: flex;
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${(props) => props.color};
  @media (max-width: 1366px) {
    font-size: 12px;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: top;
  width: 100%;
  padding-top: 40px;
  gap: 8px;
  box-sizing: border-box;
  border-top: 1px solid ${colors.gray100};
`;
