import styled from "styled-components";
import colors from "styles/colors";

const List = styled.div`
  display: flex;
  flex-direction: column;
  & > :not(:last-child) {
    padding-bottom: 10px;
    border-bottom: 1px solid ${colors.gray99};
  }
  & > :not(:first-child) {
    padding-top: 10px;
  }
`;
export default List;