/* eslint-disable no-unused-vars */
import React from "react";
import { Wrapper, Container, Title, Description, Buttons, AcceptButtonContainer, Icon } from "./style";
import { useDispatch } from "react-redux";
import LabelOnlyButton from "components/commons/Buttons/LabelOnlyButton";
import colors from "styles/colors";
import { closeModal } from "store/Modals/actions";
import { MULTIPLE_PLOTS_MODAL} from "store/Modals/constants";
import { push } from "connected-react-router";
import { PATH_DASHBOARD } from "components/pages/App/constants";

const MultiplePlotsModal = () => {
  const dispatch = useDispatch();

  const goBack = () => {
    dispatch(push(PATH_DASHBOARD));
  };

  const onClose = () => {
    dispatch(closeModal(MULTIPLE_PLOTS_MODAL));
  };


  return (
    <Wrapper>
      <Container>
        <Icon />
        <Title>
          Múltiples parcelas
        </Title>
        <Description>
          Este trámite contiene múltiples parcelas, a continuación visualizarás tu trámite y sus parcela a través de pestañas.
        </Description>
        <Buttons>
          <AcceptButtonContainer>
            <LabelOnlyButton 
              border={` 1px solid ${colors.blue50}`} 
              background={colors.blue50} 
              color={colors.white} 
              label="Aceptar"
              onClick={onClose}
            />
          </AcceptButtonContainer>
        </Buttons>
      </Container>
    </Wrapper>
  );
};

export default MultiplePlotsModal;
