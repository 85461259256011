/* eslint-disable react/no-children-prop */
import React from "react";
import { Wrapper, Text } from "./styled";

const LabelOnlyButton = ({ background, onClick, disabled, color, label, hidden, border, isLoading = false, fontSize }) => {
  if (hidden) return null;

  return (
    <Wrapper background={background} onClick={onClick} disabled={disabled} border={border} isLoading={isLoading}>
      <Text color={color} fontSize={fontSize} children={label} />
    </Wrapper>
  );
};

export default LabelOnlyButton;
