import React from "react";
import { Wrapper } from "./style";
import Description from "./components/Description";
import Buttons from "./components/Buttons";
import Nomenclature from "./components/Nomenclature";

const Content = () => {
  return (
    <Wrapper>
      <Description />
      <Nomenclature />
      <Buttons />
    </Wrapper>
  );
};

export default Content;
