import { CONFIRM, FINALIZE, NEXT } from "components/commons/OptionButtons/constants";
import { SCHEMA_DATA, OTHER_INPUTS, ATTACHED_FILES, PAPERWORK_TYPE } from "store/PaperWork/constants";

export const getText = (modify, isLastStage, type) => {
  if (modify) return CONFIRM;
  if (isLastStage && type == PAPERWORK_TYPE.D2) return FINALIZE;
  return NEXT;
};

export const generatePayload = (jsonSchemaData, otherInputsData, file) => {
  let formData = new FormData();
  formData.append(SCHEMA_DATA, JSON.stringify(jsonSchemaData));
  formData.append(OTHER_INPUTS, JSON.stringify(otherInputsData));
  file.map((element, index) => formData.append(`${ATTACHED_FILES}${index}`, element));
  return formData;
};

export const everyAlreadyFilled = (data) => {
  if (data.length === 0) return false;
  return data?.every((e) => e.alreadyFilled);
};
