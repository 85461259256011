import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100%;
  height: 800px;
  gap: 16px;
  box-sizing: border-box;
  overflow: hidden;
`;

export default Wrapper;