/* eslint-disable no-unused-vars */
import React from "react";
import { Items, OptionContainer, Wrapper } from "./styled";
import UserInfo from "./components/UserInfo";
import GenericItem from "../GenericItem";
import { OPTIONS } from "../../../constants";
import Logout from "./components/Logout";

const Options = ({ toogle }) => {
  const getIcons = (elem) => <GenericItem toogle={toogle} elem={elem} />;

  return (
    <Wrapper>
      <OptionContainer toogle={toogle}>
        <Items toogle={toogle}>
          {OPTIONS.map(getIcons)}
          <Logout toogle={toogle} />
        </Items>
      </OptionContainer>
      <UserInfo toogle={toogle} />
    </Wrapper>
  );
};

export default Options;
