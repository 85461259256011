/* eslint-disable react/no-children-prop */
import React, { useState } from "react";
import { Wrapper, Label } from "./styled";
import CommonInput from "components/commons/Inputs/CommonInput";
import colors from "styles/colors";
import useResize from "hooks/useResize";

const PasswordInput = ({ onClick, val, disabled, placeholder, setUser, user }) => {
  const { screenWidth } = useResize();
  const height = screenWidth <= 905 ? "40px" : "48px";

  const [error, setError] = useState(false);
  const message = error ? "El nombre ingresado es inválido" : "";

  const validation = (value) => {
    return !/^.{6,}$/.test(value);
  };

  const handleChange = (e) => {
    if (validation(e.target.value)) setError(true);
    else setError(false);
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Wrapper>
      <Label children="Contraseña" />
      <CommonInput
        name="password"
        type="password"
        errorMessage={message}
        error={error}
        height={height}
        onClick={(event) => onClick(event)}
        onChange={(event) => handleChange(event)}
        value={val}
        disabled={disabled}
        placeholder={placeholder}
        color={colors.gray10}
        border={`1px solid ${colors.gray50}`}
        borderRadius={"4px"}
      />
    </Wrapper>
  );
};

export default PasswordInput;
