import produce from "immer";
import { initialState } from "./initialState";
import * as types from "./types";

const paperWorkReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.SET_NEW_PAPERWORKS_LIST:
        draft.newPaperworksList = action.value;
        break;
      case types.SET_STAGE_INDEX:
        draft.stageIndex = action.value;
        break;
      case types.SET_STAGE_DATA:
        draft.detail.metadata.stageProgress[draft.stageIndex][action.field] = action.data;
        break;
      case types.SET_STAGE_STRUCTURE:
        draft.detail.structure.stages[action.index].form[action.field] = action.data;
        break;
      case types.SET_STAGE:
        draft.detail.metadata.stageProgress[action.index] = action.stage;
        break;
      case types.SET_PAPERWORK_DATA:
        draft.detail.data = action.data;
        break;
      case types.SET_STAGE_COMPLETED:
        draft.stageCompleted = action.state;
        break;
      case types.SET_FORM_ERRORS:
        draft.formErrors = action.data;
        break;
      case types.SET_PAPERWORK_LIST:
        draft.list = action.response.paperworks;
        draft.totalCount = action.response.totalPaperworks;
        break;
      case types.SET_PAPERWORK_PAGE:
        draft.actualPage = action.actualPage;
        break;
      case types.SET_PAPERWORK_DETAIL:
        draft.detail = { ...draft.detail, ...action.response };
        break;
      case types.UPDATE_PAPERWORK_METADATA:
        draft.detail = { ...draft.detail, metadata: action.updatedPaperworkMetadata };
        break;
      case types.RESET_PAPERWORK_STORE:
        draft.modifyMode = initialState.modifyMode;
        draft.detail = initialState.detail;
        break;
      case types.SET_PAPERWORK_COUNT_STATISTICS:
        draft.charts[action.chart] = action.result;
        break;
      case types.SET_REPORT_SHARE_LINK:
        draft.reportShareLink = action.link;
        break;
      case types.SET_DOCUMENTS:
        draft.apiDocuments = action.apiDocs;
        draft.storageDocuments = action.storageDocs;
        break;

      case types.SET_CURRENT_REJECTION:
        draft.currentRejectionReason = action.rejection;
        break;
      case types.SET_CURRENT_MENDING_REQUEST:
        draft.currentMendingRequestReason = action.mendingRequestReason;
        draft.currentMendingRequestArea = action.mendingRequestArea;
        break;
      case types.SET_MODIFY_MODE:
        draft.modifyMode = true;
        break;
      case types.SET_SEE_MORE_PAPERWORK:
        draft.seeMorePaperwork = action.data;
        break;
      case types.SET_SUBSANATION_AREAS:
        draft.subsanationAreas = action.subsanationAreas;
        break;
    }
  });
export default paperWorkReducer;
