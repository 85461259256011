import styled from "styled-components";
import colors from "styles/colors";

export const SubsanationText = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  margin-right: 10px;
  padding-left: 2px;
  overflow-wrap: normal;
  color: ${colors.gray70};
`;

export const SubsanationArea = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
`;