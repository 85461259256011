import React, { useEffect, useState } from "react";
import { Wrapper } from "./styled";
import Card from "./components/Card";
import { loadingStart, loadingStop } from "store/General/actions";
import { failed, selectionIsComplete } from "store/PaperWork/actions/functions";
import axios from "axios";
import { selectToken } from "store/Login/selectors";
import * as paths from "components/pages/App/constants";
import { useDispatch, useSelector } from "react-redux";
import { setStageData } from "store/PaperWork/actions/actions";
import { OTHER_INPUTS } from "store/PaperWork/constants";
import { selectPaperworkMetadata, selectPaperworkStageProgress, selectPaperworkStructure, selectStageIndex } from "store/PaperWork/selectors";
import { get } from "lodash";
import { isEmpty } from "utils/functions";
import { openModal, setMessageCode } from "store/Modals/actions";
import { MODAL_MESSAGE } from "store/Modals/constants";
import { NO_D1_FOR_REVALIDATE_CODE } from "store/Modals/messageDescriptions";
import { push } from "connected-react-router";
import { filterByDeparture } from "components/pages/Home/components/Sections/components/Section/functions";

const ListOfCertificates = ({ search }) => {
  const dispatch = useDispatch();
  const [paperworks, setPaperworks] = useState([]);
  const token = useSelector(selectToken);
  const index = useSelector(selectStageIndex);
  const structure = useSelector(selectPaperworkStructure);
  const stageProg = useSelector(selectPaperworkMetadata);
  const stageProgress = useSelector(selectPaperworkStageProgress);
  const stageKey = get(structure, `stages[${index}].form.otherInputs[0].key`, "");
  const otherInputsData = get(stageProgress, `[${index}].otherInputsData`, {});
  const selection = get(stageProg, `stageProgress[${index}].otherInputsData[${stageKey}]`, []);
  const getCard = (item) => <Card paperwork={item} pProgId={item.paperworkProgressId} date={item.approvalDate} departure={item.departure} />;
  const filterPaperworks = filterByDeparture(paperworks, search);

  useEffect(() => {
    (async () => {
      dispatch(loadingStart);
      try {
        const response = await axios.get(`${paths.PATH_BASE_URL}/v1/paperwork/D1/revalidation?token=${token}`);
        setPaperworks(response.data.result);
        if (isEmpty(response.data.result)) {
          dispatch(setMessageCode(NO_D1_FOR_REVALIDATE_CODE));
          dispatch(openModal(MODAL_MESSAGE));
          dispatch(push(paths.PATH_HOME));
        }
        dispatch(loadingStop);
      } catch (e) {
        dispatch(failed(e));
      }
    })();
  }, []);

  useEffect(() => dispatch(selectionIsComplete(selection)), [structure, stageProg, index]);

  useEffect(() => dispatch(setStageData(OTHER_INPUTS, otherInputsData)), [index]);

  return <Wrapper>{filterPaperworks.map(getCard)}</Wrapper>;
};

export default ListOfCertificates;
