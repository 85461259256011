import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75px;
  height: 75px;
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 62px;
  height: 51px;
  box-sizing: border-box;
  ${({ selected }) =>
    selected &&
    `
    & > * {
     & path {
      fill: ${colors.orange50};
     }
    }
    border-bottom: 4px solid ${colors.orange50};
  `}
`;
