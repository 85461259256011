import React from "react";
import { Wrapper, Text } from "./styled";

const Cell = ({ className, value }) => {
  return (
    <Wrapper className={className}>
      <Text>{value}</Text>
    </Wrapper>
  );
};

export default Cell;
