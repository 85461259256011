import React, { useEffect, useState } from "react";
import CommonInput from "components/commons/Inputs/CommonInput";
import colors from "styles/colors";
import Label from "./components/Label";
import { Wrapper } from "./styled";
import { useDispatch, useSelector } from "react-redux";
import { selectPaperworkMetadata, selectStageIndex } from "store/PaperWork/selectors";
import { setForm } from "store/PaperWork/actions/functions";
import { getInitialValue, isInvalid } from "./functions";
import { checkDependecies, getValues } from "../../functions";
import { get } from "lodash";
import useResize from "hooks/useResize";
import { DESK } from "components/commons/MainLayout/constants";

const Input = ({ item, onClick = () => {}, stageKey }) => {
  const dispatch = useDispatch();
  const { placeholder, label, dependencies, key, minLength, maxLength, type } = item;
  const index = useSelector(selectStageIndex);
  const paperwP = useSelector(selectPaperworkMetadata);
  const stage = get(paperwP, "stageProgress", {});
  const values = checkDependecies(stage, dependencies, null);
  const inDisuse = values.hidden || values.disabled;
  const [val, setVal] = useState(getInitialValue(stage, index, stageKey, key, values.value));
  const [error, setError] = useState(false);
  const errorMessage = error ? "El valor ingresado es inválido" : null;

  const onChange = (e) => {
    setVal(e.target.value);
    if (isInvalid(e.target.value, minLength, maxLength, type)) {
      setError(true);
      dispatch(setForm(stageKey, key, "", inDisuse));
    } else {
      setError(false);
      dispatch(setForm(stageKey, key, e.target.value, inDisuse));
    }
  };

  useEffect(() => {
    if (inDisuse) setError(false);
    dispatch(setForm(stageKey, key, val, inDisuse));
  }, [inDisuse]);

  useEffect(() => setVal(getValues(val, "value", dependencies, stage, values)), [values]);

  if (values.hidden) return null;

  const { checkSize } = useResize();
  const height = checkSize() === DESK ? "48px" : "28px";

  return (
    <Wrapper>
      <Label text={label} />
      <CommonInput
        errorMessage={errorMessage}
        error={error}
        height={height}
        onClick={(event) => onClick(event)}
        onChange={onChange}
        value={val}
        disabled={values.disabled}
        placeholder={placeholder}
        color={colors.gray10}
        border={`1px solid ${colors.gray50}`}
        borderRadius={"4px"}
      />
    </Wrapper>
  );
};

export default Input;
