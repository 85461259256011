import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: left;
  width: 100%;
  box-sizing: border-box;
`;

export const Title = styled.div`
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: ${colors.blue60};
`;

export const MendingAreaText = styled.div`
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  background-color: ${colors.white};
  color: ${colors.gray10};
  padding-top: 20px;
`;

export const MendingReasonText = styled.div`
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  background-color: ${colors.white};
  color: ${colors.gray10};
`;