import React from "react";
import Button from "./components/Button";
import { ButtonContainer, Description, Wrapper } from "./style";

const Switch = ({ section }) => {
  const { elements, columns } = section;

  return (
    <Wrapper>
      <Description columns={columns}>{elements[0].name}</Description>
      <ButtonContainer>
        <Button section={section} />
      </ButtonContainer>
    </Wrapper>
  );
};

export default Switch;
