/* eslint-disable no-unused-vars */
import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  background: white;
`;

export const Switch = styled.div`
  box-sizing: border-box;
  position: relative;
  min-width: 52px;
  min-height: 32px;
  background: ${colors.gray80};
  border-radius: 35px;
  padding: 4px;
  transition: 300ms all;

  &:before {
    transition: 300ms all;
    content: "";
    position: absolute;
    min-width: 16px;
    min-height: 16px;
    border-radius: 100px;
    top: 50%;
    left: 4px;
    background: white;
    transform: translate(0, -50%);
  }
`;

export const Input = styled.input`
  display: none;

  &:checked + ${Switch} {
    background: ${colors.blue40};

    &:before {
      transform: translate(27px, -50%);
    }
  }
`;

export const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;
