import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  margin-right: auto;
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: ${colors.gray20};
`;