/* eslint-disable no-unused-vars */
import API from "services/api";
import { loadingStart, loadingStop } from "store/General/actions";
import { selectPaperworkActualPage, selectPaperworkType, selectPaperworkPageSize, selectPaperworkProgressId } from "../selectors";
import { failed, firstUnfilledStage, nextStage } from "./functions";
import { selectFilters } from "store/Filters/selectors";
import {
  handlingSendPapwToReview,
  handlingPaperworkHistory,
  handlingObservePaperwork,
  handlingUpdateMetadata,
  handlingDownload,
  handlingReportPrint,
  handlingGetPaperworksStatistics,
} from "./handlings";
import { selectToken, selectUserCuit } from "store/Login/selectors";
import { push } from "connected-react-router";
import { FILTER_IDENTIFICATION } from "store/Filters/constants";
import { setDocuments, setNewPaperworksList, setPaperwDetail, setReportLink, setSubsanationAreas } from "./actions";
import { getCustomFilters } from "store/Filters/functions";

export const getNewPaperworkList = () => {
  return async (dispatch, getState) => {
    dispatch(loadingStart());
    try {
      const token = selectToken(getState());
      const response = await API.paperW.getNewPaperworksList(token);
      dispatch(loadingStop());
      dispatch(setNewPaperworksList(response.data.result));
    } catch (e) {
      dispatch(failed(e));
    }
  };
};

export const getUserPaperworks = (customFilters) => {
  return async (dispatch, getState) => {
    dispatch(loadingStart());
    try {
      const state = getState();
      const token = selectToken(state);
      const cuit = selectUserCuit(state);
      const filters = { ...customFilters, [FILTER_IDENTIFICATION]: cuit };
      const pageNumber = 0;
      const pageSize = 100;
      const response = await API.paperW.getPaperworkHistory(pageSize, pageNumber, filters, token);
      dispatch(handlingPaperworkHistory(response.data.result));
    } catch (e) {
      dispatch(failed(e));
    }
  };
};

export const getPaperworkByType = (paperwType) => {
  return async (dispatch, getState) => {
    dispatch(loadingStart());
    try {
      const token = selectToken(getState());
      const response = await API.paperW.getPaperworkByType(paperwType, token);
      dispatch(setPaperwDetail(response.data.result));
      dispatch(firstUnfilledStage(response.data.result));
      dispatch(loadingStop());
    } catch (e) {
      dispatch(failed(e));
    }
  };
};

export const getPaperworkDetail = (paperworkProgressId) => {
  return async (dispatch, getState) => {
    dispatch(loadingStart());
    try {
      const token = selectToken(getState());
      const response = await API.paperW.getPaperworkDetail(paperworkProgressId, token);
      dispatch(setPaperwDetail(response.data.result));
      dispatch(push(`/administracion/detalle/${paperworkProgressId}`));
      dispatch(loadingStop());
    } catch (e) {
      dispatch(failed(e));
    }
  };
};

export const getPaperworkProgress = (paperworkProgressId) => {
  return async (dispatch, getState) => {
    dispatch(loadingStart());
    try {
      const token = selectToken(getState());
      const response = await API.paperW.getPaperworkDetail(paperworkProgressId, token);
      dispatch(setPaperwDetail(response.data.result));
      dispatch(firstUnfilledStage(response.data.result));
      dispatch(loadingStop());
    } catch (e) {
      dispatch(failed(e));
    }
  };
};

export const unlockPaperwork = (paperworkProgressId) => {
  return async (dispatch, getState) => {
    dispatch(loadingStart());
    try {
      const token = selectToken(getState());
      await API.paperW.unlockPaperwork(paperworkProgressId, token);
    } catch (e) {
      dispatch(failed(e));
    }
  };
};

export const getPaperworkHistoryFromFilters = () => {
  return async (dispatch, getState) => {
    dispatch(loadingStart());
    try {
      const state = getState();
      const token = selectToken(state);
      const pageSize = selectPaperworkPageSize(state);
      const pageNumber = selectPaperworkActualPage(state);
      const filters = selectFilters(state);
      const response = await API.paperW.getPaperworkHistory(pageSize, pageNumber, filters, token);
      dispatch(handlingPaperworkHistory(response.data.result));
    } catch (e) {
      dispatch(failed(e));
    }
  };
};

export const getPaperworkHistoryCustom = (baseFilters) => {
  return async (dispatch, getState) => {
    dispatch(loadingStart());
    try {
      const state = getState();
      const token = selectToken(state);
      const storeFilters = selectFilters(state);
      const filters = getCustomFilters(storeFilters, baseFilters);
      const pageSize = selectPaperworkPageSize(state);
      const pageNumber = selectPaperworkActualPage(state);
      const response = await API.paperW.getPaperworkHistory(pageSize, pageNumber, filters, token);
      dispatch(handlingPaperworkHistory(response.data.result));
    } catch (e) {
      dispatch(failed(e));
    }
  };
};

export const getSubsanations = () => {
  return async (dispatch, getState) => {
    dispatch(loadingStart());
    try {
      const state = getState();
      const token = selectToken(getState());
      const pageSize = selectPaperworkPageSize(state);
      const pageNumber = selectPaperworkActualPage(state);
      const filters = selectFilters(state);
      const response = await API.paperW.getSubsanations(pageSize, pageNumber, filters, token);
      dispatch(handlingPaperworkHistory(response.data.result));
    } catch (e) {
      dispatch(failed(e));
    }
  };
};

export const saveStage = (stageId, data, isDefault) => {
  return async (dispatch, getState) => {
    dispatch(loadingStart());
    try {
      const token = selectToken(getState());
      const paperworkType = selectPaperworkType(getState());
      const progId = selectPaperworkProgressId(getState());
      await API.paperW.saveStage(paperworkType, stageId, progId, data, token);
      dispatch(loadingStop());
      if (isDefault) return;
      dispatch(nextStage());
    } catch (e) {
      dispatch(failed(e));
    }
  };
};

export const emptyStage = (paperwType, stageId, pProgId) => {
  return async (dispatch, getState) => {
    dispatch(loadingStart());
    try {
      const token = selectToken(getState());
      await API.paperW.emptyStage(paperwType, stageId, pProgId, token);
      dispatch(loadingStop());
    } catch (e) {
      dispatch(failed(e));
    }
  };
};

export const sendPaperworkToReview = (paperwType, pProgId) => {
  return async (dispatch, getState) => {
    dispatch(loadingStart());
    try {
      const token = selectToken(getState());
      await API.paperW.sendPaperworkToReview(paperwType, pProgId, token);
      dispatch(handlingSendPapwToReview(paperwType, pProgId));
    } catch (e) {
      dispatch(failed(e));
    }
  };
};

export const observePaperwork = (pProgId, data) => {
  return async (dispatch, getState) => {
    dispatch(loadingStart());
    try {
      const token = selectToken(getState());
      const response = await API.paperW.observePaperwork(pProgId, data, token);
      dispatch(handlingObservePaperwork(response));
    } catch (e) {
      dispatch(failed(e));
    }
  };
};

export const approvePaperwork = (pProgId) => {
  return async (dispatch, getState) => {
    dispatch(loadingStart());
    try {
      const token = selectToken(getState());
      const response = await API.paperW.approvePaperwork(pProgId, token);
      dispatch(handlingUpdateMetadata(response, pProgId));
    } catch (e) {
      dispatch(failed(e));
    }
  };
};

export const mendPaperwork = (pProgId, data) => {
  return async (dispatch, getState) => {
    dispatch(loadingStart());
    try {
      const token = selectToken(getState());
      const response = await API.paperW.mendPaperwork(pProgId, data, token);
      dispatch(handlingUpdateMetadata(response, pProgId));
    } catch (e) {
      dispatch(failed(e));
    }
  };
};

export const requestApprovePaperwork = (pProgId) => {
  return async (dispatch, getState) => {
    dispatch(loadingStart());
    try {
      const token = selectToken(getState());
      const response = await API.paperW.requestApprovePaperwork(pProgId, token);
      dispatch(handlingUpdateMetadata(response, pProgId));
    } catch (e) {
      dispatch(failed(e));
    }
  };
};

export const requestMendingPaperwork = (pProgId, data) => {
  return async (dispatch, getState) => {
    dispatch(loadingStart());
    try {
      const token = selectToken(getState());
      const response = await API.paperW.requestMendingPaperwork(pProgId, data, token);
      dispatch(handlingUpdateMetadata(response, pProgId));
    } catch (e) {
      dispatch(failed(e));
    }
  };
};

export const rejectPaperwork = (pProgId, data) => {
  return async (dispatch, getState) => {
    dispatch(loadingStart());
    try {
      const token = selectToken(getState());
      const response = await API.paperW.rejectPaperwork(pProgId, data, token);
      dispatch(handlingUpdateMetadata(response, pProgId));
    } catch (e) {
      dispatch(failed(e));
    }
  };
};

export const requestRejectPaperwork = (pProgId, data) => {
  return async (dispatch, getState) => {
    dispatch(loadingStart());
    try {
      const token = selectToken(getState());
      const response = await API.paperW.requestRejectPaperwork(pProgId, data, token);
      dispatch(handlingUpdateMetadata(response, pProgId));
    } catch (e) {
      dispatch(failed(e));
    }
  };
};

export const printReport = (pProgId, signed = false) => {
  return async (dispatch, getState) => {
    dispatch(loadingStart());
    try {
      const token = selectToken(getState());
      const response = await API.paperW.downloadReport(pProgId, token, signed);
      dispatch(handlingReportPrint(response));
    } catch (e) {
      dispatch(failed(e));
    }
  };
};

export const shareReport = (pProgId, paperworkId) => {
  return async (dispatch, getState) => {
    dispatch(loadingStart());
    try {
      const token = selectToken(getState());
      const response = await API.paperW.shareReport(pProgId, token);
      dispatch(loadingStop());
      dispatch(setReportLink(response.data.result.link));
    } catch (e) {
      dispatch(failed(e));
    }
  };
};

export const downloadReport = (pProgId, signed = false) => {
  return async (dispatch, getState) => {
    dispatch(loadingStart());
    try {
      const token = selectToken(getState());
      const response = await API.paperW.downloadReport(pProgId, token, signed);
      dispatch(handlingDownload(response));
    } catch (e) {
      dispatch(failed(e));
    }
  };
};

export const getPaperworksStatistics = (dateFrom, dateTo, chart) => {
  return async (dispatch, getState) => {
    dispatch(loadingStart());
    try {
      const token = selectToken(getState());
      const response = await API.paperW.getPaperworksStatistics(dateFrom, dateTo, token);
      dispatch(handlingGetPaperworksStatistics(response.data.result, chart));
    } catch (e) {
      dispatch(failed(e));
    }
  };
};

export const getPaperworksStatisticsByState = (dateFrom, dateTo, chart, state) => {
  return async (dispatch, getState) => {
    dispatch(loadingStart());
    try {
      const token = selectToken(getState());
      const response = await API.paperW.getPaperworksStatisticsByState(state, dateFrom, dateTo, token);
      dispatch(handlingGetPaperworksStatistics(response.data.result, chart));
    } catch (e) {
      dispatch(failed(e));
    }
  };
};

export const getPaperworksStatisticsIncome = (dateFrom, dateTo, chart) => {
  return async (dispatch, getState) => {
    dispatch(loadingStart());
    try {
      const token = selectToken(getState());
      const response = await API.paperW.getPaperworksStatisticsIncome(dateFrom, dateTo, token);
      dispatch(handlingGetPaperworksStatistics(response.data.result, chart));
    } catch (e) {
      dispatch(failed(e));
    }
  };
};

export const getPaperworksStatisticsApproved = (dateFrom, dateTo, chart) => {
  return async (dispatch, getState) => {
    dispatch(loadingStart());
    try {
      const token = selectToken(getState());
      const response = await API.paperW.getPaperworksStatisticsApproved(dateFrom, dateTo, token);
      dispatch(handlingGetPaperworksStatistics(response.data.result, chart));
    } catch (e) {
      dispatch(failed(e));
    }
  };
};

export const getDocuments = (progId) => {
  return async (dispatch, getState) => {
    dispatch(loadingStart());
    try {
      const token = selectToken(getState());
      const response = await API.paperW.getDocuments(progId, token);
      const { apiDocuments, storageDocuments } = response.data.result;
      dispatch(loadingStop());
      dispatch(setDocuments(apiDocuments, storageDocuments));
    } catch (e) {
      dispatch(failed(e));
    }
  };
};

export const downloadDocument = (url, name) => {
  return async (dispatch, getState) => {
    dispatch(loadingStart());
    try {
      const token = selectToken(getState());
      const response = await API.paperW.downloadDocument(url, token);
      dispatch(handlingDownload(response, name));
    } catch (e) {
      dispatch(failed(e));
    }
  };
};

export const getSubsanationAreas = () => {
  return async (dispatch, getState) => {
    dispatch(loadingStart());
    try {
      const token = selectToken(getState());
      const response = await API.paperW.getSubsanationAreas(token);
      dispatch(loadingStop());
      dispatch(setSubsanationAreas(response.data.result));
    } catch (e) {
      dispatch(failed(e));
    }
  };
};
