/* eslint-disable react/no-children-prop */
import React from "react";
import { Wrapper, Title, Content, Column, Text } from "../styled";
import { useSelector } from "react-redux";
import { selectPaperworkData } from "store/PaperWork/selectors";
import { get } from "lodash";

const RealStateDenomination = ({ selectedPlot }) => {
  const data = useSelector(selectPaperworkData);
  const accordingToPlan = get(data, `[${selectedPlot}].planData.realStateDenomination.accordingToPlan`, "-");
  const accordingToTitle = get(data, `[${selectedPlot}].planData.realStateDenomination.accordingToTitle`, "-");

  return (
    <Wrapper>
      <Content>
        <Column>
          <Title children="Descripción según plano" />
          <Text children={accordingToPlan} />
        </Column>
        <Column>
          <Title children="Descripción según título" />
          <Text children={accordingToTitle} />
        </Column>
      </Content>
    </Wrapper>
  );
};

export default RealStateDenomination;
