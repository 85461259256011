import React from "react";
import Content from "./components/Content";
import { Wrapper } from "./styled";
import Header from "./components/Header";

const AttachesFilesInfo = () => {
  return (
    <Wrapper>
      <Header title={"Archivos adjuntos"} />
      <Content />
    </Wrapper>
  );
};

export default AttachesFilesInfo;
