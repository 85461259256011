import * as types from "./types";

export function setFilters(key, value) {
  return {
    type: types.SET_FILTERS,
    key,
    value,
  };
}

export function setAllFilters(filters) {
  return {
    type: types.SET_ALL_FILTERS,
    filters,
  };
}

export function resetFilters() {
  return {
    type: types.RESET_FILTERS
  };
}
