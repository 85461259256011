/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-children-prop */
import React from "react";
import { Wrapper, Button } from "./styled";
import { MODAL_SEE_MORE } from "store/Modals/constants";
import { useDispatch } from "react-redux";
import { openModal } from "store/Modals/actions";
import { setSeeMorePaperwork } from "store/PaperWork/actions/actions";

const StateCell = ({ row }) => {
  const dispatch = useDispatch();

  // const onClick = (event) => {
  //   dispatch(setSeeMorePaperwork(row.original));
  //   dispatch(openModal(MODAL_SEE_MORE));
  //   event.stopPropagation();
  // };

  const getText = () => {
    if (row.original.disabled) return "Inactivo";
    return "Activo";
  };

  return (
    <Wrapper>
      <Button children={getText()} disabled={row.original.disabled} />
    </Wrapper>
  );
};

export default StateCell;
