import React, { useEffect, useState } from "react";
import MainLayout from "components/commons/MainLayout";
import { ButtonContainer, Content, Wrapper } from "./styled";
import Form from "./components/Form";
import ProgressBar from "./components/ProgressBar";
import Header from "./components/Header";
import Buttons from "./components/Buttons";
import { useDispatch, useSelector } from "react-redux";
import { selectPaperworkStructure } from "store/PaperWork/selectors";
import { isEmpty } from "lodash";
import colors from "styles/colors";
import SpecialItems from "./components/SpecialItems";
import { useParams } from "react-router-dom";
import { getPaperworkByType } from "store/PaperWork/actions/requests";
import D1Confirm from "./components/D1Confirm";
import { PAPERWORK_TYPE } from "store/PaperWork/constants";

const Paperwork = () => {
  const dispatch = useDispatch();
  const { type } = useParams();
  const info = useSelector(selectPaperworkStructure);
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState([]);
  const toggle = () => setOpen(!open);
  const PAPERWORK_DISABLED = [PAPERWORK_TYPE.D2, PAPERWORK_TYPE.D5];

  useEffect(() => {
    if (!PAPERWORK_DISABLED.includes(type) && type && isEmpty(info)) dispatch(getPaperworkByType(type));
  }, []);

  return (
    <MainLayout header={<Header setSearch={setSearch} search={search} />} background={colors.gray100}>
      <Wrapper>
        <D1Confirm action={null} title={null} description={null} icon={null} toggle={toggle} open={open} />
        <Content>
          <ProgressBar />
          <Form />
          <SpecialItems search={search} />
        </Content>
        <ButtonContainer>
          <Buttons toggle={toggle} />
        </ButtonContainer>
      </Wrapper>
    </MainLayout>
  );
};

export default Paperwork;
