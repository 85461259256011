import React from "react";
import { Wrapper, Logo } from "./styled";
import ToogleIcon from "images/logos/catastro.svg";
import LogoIcon from "images/logos/logo.svg";
import ItemList from "./components/ItemList";

const Sections = ({ toogle }) => {
  const getLogo = () => {
    if (!toogle) return LogoIcon;
    return ToogleIcon;
  };

  return (
    <Wrapper toogle={toogle}>
      <Logo src={getLogo()} alt="img" toogle={toogle} />
      <ItemList toogle={toogle} />
    </Wrapper>
  );
};

export default Sections;
