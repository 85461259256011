import React from "react";
import { MEDIA } from "../../constants";
import { Wrapper } from "./style";

const Media = () => (
  <Wrapper>
    {MEDIA.map((el => el.component))}
  </Wrapper>
);

export default Media;
