import React from "react";
import Actions from "./components/Actions";
import Wrapper from "./styled";
import UserInfo from "components/pages/PaperworkDetail/components/UserInfo";
import AttachesFilesInfo from "components/pages/PaperworkDetail/components/AttachesFilesInfo";

const RightBlock = () => {
  return (
    <Wrapper>
      <UserInfo />
      <AttachesFilesInfo />
      <Actions />
    </Wrapper>
  );
};

export default RightBlock;
