import styled from "styled-components";

import { fadeIn } from "styles/effects";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  box-sizing: border-box;
  background: ${(props) => props.background};
  position: fixed;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  padding-left: ${({ paddingLeft }) => paddingLeft || 120}px;
  padding-right: ${({ paddingRight }) => paddingRight || 200}px;
  overflow: hidden;
  overflow-y: overlay;
  animation: 500ms ${fadeIn};
`;

export const Section = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
`;

export const MenuContainer = styled.div`
  display: flex;
  min-width: 80px;
  max-width: 150px;
  height: 100%;
  overflow: visible;
`;
