import React from "react";
import Filters from "components/commons/Table/components/Filters";
import { ADMINISTRATION_ITEMS } from "components/commons/Table/components/Filters/constants";
import { getPaperworkHistoryFromFilters } from "store/PaperWork/actions/requests";

const AdministrationFilters = () => {
  return <Filters items={ADMINISTRATION_ITEMS} getPaperworkHistory={getPaperworkHistoryFromFilters} />;
};

export default AdministrationFilters;
