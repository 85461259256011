import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  width: 100%;
  height: 100%;
  padding-bottom: 136px;
  box-sizing: border-box;
  overflow: visible;
  @media (max-width: 1366px) {
    padding-bottom: 50px;
  }
  @media (max-width: 980px) {
    padding: 20px 0px;
  }
  @media (max-width: 580px) {
    padding-inline: 16px;
  }
`;
