import fetch from "../connector";
import * as paths from "components/pages/App/constants";

export const getEnabledUser = (uid, currentState, token) =>
  fetch(paths.PATH_BASE_URL, paths.getEnabledUser(uid, currentState, token), {
    method: "PUT",
  });

export const getUsers = (token) =>
  fetch(paths.PATH_BASE_URL, paths.getUsers(token), {
    method: "GET",
  });

export const getDeleteUser = (uid, token) =>
  fetch(paths.PATH_BASE_URL, paths.getDeleteUser(uid, token), {
    method: "DELETE",
  });

export const getVerificationEmail = (uid, token) =>
  fetch(paths.PATH_BASE_URL, paths.getVerificationEmail(uid, token), {
    method: "POST",
  });

export const getAddUser = (user, token) =>
  fetch(paths.PATH_BASE_URL, paths.getAddUser(token), {
    method: "POST",
    data: user,
  });

export const getupdateUser = (uid, user, token) =>
  fetch(paths.PATH_BASE_URL, paths.getUpdateUser(uid, token), {
    method: "PUT",
    data: user,
  });

export const getPendingUsers = (token) =>
  fetch(paths.PATH_BASE_URL, paths.getPendingUsers(token), {
    method: "GET",
  });
