/* eslint-disable react/no-children-prop */
import React from "react";
import { TabContainer, Wrapper } from "./styled";
import Filters from "./components/Filters";
import TabSelector from "components/commons/TabSelector";

const BottomElements = ({ filters, id, setId, sections }) => {
  return (
    <Wrapper>
      <TabContainer>
        <TabSelector id={id} setId={setId} sections={sections} />
      </TabContainer>
      <Filters children={filters} />
    </Wrapper>
  );
};

export default BottomElements;
