import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
  width: 100%;
  height: ${(props) => (props.expanded ? "96px" : "80px")};
  padding: 24px 24px;
  gap: 8px;
  border-bottom: 1px solid ${colors.gray100};
  box-sizing: border-box;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  font-family: "Domine";
  font-style: normal;
  font-weight: 400;
  font-size: ${(props) => (props.expanded ? "36px" : "24px")};
  line-height: 32px;
  color: ${colors.blue40};
  @media (max-width: 1366px) {
    font-size: 24px;
  }
  @media (max-width: 1024px) {
    font-size: 20px;
  }
`;

export const IconsContainer = styled.div`
  display: flex;
  position: absolute;
  gap: 8px;
  box-sizing: border-box;
`;
