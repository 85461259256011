/* eslint-disable no-unused-vars */
import React from "react";
import { Wrapper } from "./styled";
import Header from "./components/Header";
import { useSelector } from "react-redux";
import { get, isEmpty } from "lodash";
import { selectPaperworkMetadata } from "store/PaperWork/selectors";
import Content from "./components/Content";

const StageInfo = ({ stage, index }) => {
  const { jsonSchemaData, otherInputsData, stageTitle } = stage;
  const pProg = useSelector(selectPaperworkMetadata);
  const stageKey = get(pProg, `paperwork.stages[${index}].form.otherInputs[0].key`, 0);
  const schemaIsIncomplete = Object.values(jsonSchemaData).every((e) => !e);

  if ((schemaIsIncomplete || isEmpty(jsonSchemaData)) && isEmpty(otherInputsData[stageKey])) return null;

  return (
    <Wrapper>
      <Header title={stageTitle} />
      <Content stage={stage} index={index} />
    </Wrapper>
  );
};

export default StageInfo;
