import React from "react";
import Header from "./components/Header";
import Section from "./components/Section";
import { Wrapper } from "./style";

const BlockInfo = ({ block, index, filtersConstants }) => {
  const { title, sections } = block;

  const getSection = (section) => {
    return <Section section={section} />;
  };

  return (
    <Wrapper isLastChild={filtersConstants.length === index + 1}>
      <Header title={title} />
      {sections.map(getSection)}
    </Wrapper>
  );
};

export default BlockInfo;
