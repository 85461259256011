import styled from "styled-components";
import Img from "../../Img";

export const Wrapper = styled.div`
  display: flex;
  width: 22px;
  height: 22px;
  cursor: pointer;
`;

export const Image = styled(Img)`
  display: flex;
  width: 100%;
  height: 100%;
`;
