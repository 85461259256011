import React from "react";
import LeftBlock from "./components/LeftBlock";
import RightBlock from "./components/RightBlock";

const E1 = () => {
  return (
    <>
      <LeftBlock />
      <RightBlock />
    </>
  );
};

export default E1;
