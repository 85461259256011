import React from "react";
import { useSelector } from "react-redux";
import { selectUserCuit, selectUserName } from "store/Login/selectors";
import Input from "./components/Input";
import TextFieldPhone from "./components/TextFieldPhone";
import { Wrapper } from "./styled";

const Fields = ({ data, setData }) => {
  const name = useSelector(selectUserName);
  const cuit = useSelector(selectUserCuit);

  return (
    <Wrapper>
      <Input label={"Nombre y apellido"} setNewUser={setData} newUser={data} value={name} name={"name"} disabled={true} />
      <Input label={"CUIT/CUIL"} setNewUser={setData} newUser={data} value={cuit} name={"cuit"} disabled={true} />
      <Input label={"Email"} setNewUser={setData} newUser={data} value={data["email"]} name={"email"} />
      <TextFieldPhone
        label={"Teléfono"}
        setNewUser={setData}
        newUser={data}
        valueAreaCode={data["phoneAreaCode"]}
        valuePhone={data["phone"]}
        areaCodeField={"phoneAreaCode"}
        phoneField={"phone"}
      />
    </Wrapper>
  );
};

export default Fields;
