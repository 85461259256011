import styled from "styled-components";
import colors from "styles/colors";
import XX from "images/ilustrations/common-login.svg";
import TabletImage from "images/ilustrations/common-login-tablet.svg";
import MobileImage from "images/ilustrations/common-login-mobile.svg";
import SmallImage from "images/ilustrations/common-login-small.svg";
import { fadeIn } from "styles/effects";

export const Wrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: left;
  width: 100%;
  height: 100%;
  background: ${colors.gray100};
  gap: 500px;
  overflow-y: auto;
  box-sizing: border-box;
  animation: 0.9s ${fadeIn} forwards;
  @media (max-width: 830px) or (orientation: portraite) {
    flex-direction: column;
    overflow: hidden;
  }
`;

export const Image = styled.div`
  width: 53%;
  height: auto;
  margin-left: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(${XX});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  overflow: auto;
  @media (min-width: 1366px) {
    min-height: 1024px;
  }
  @media (max-width: 905px) {
    width: 407px;
    height: 680px;
    background-image: url(${TabletImage});
  }
  @media (max-width: 830px) or (orientation: portraite) {
    width: auto;
    height: 42%;
    margin-bottom: auto;
    background-position: top;
    background-image: url(${MobileImage});
  }
  @media (max-width: 580px) {
    height: 37%;
    background-image: url(${SmallImage});
  }
`;
