import React from "react";
import { Wrapper, TextArea } from "./style";
import { useSelector } from "react-redux";
import { selectMendRequestReason, selectMendRequestArea } from "store/PaperWork/selectors";
import { SubsanationText } from "../../../EditMendModal/components/SubsanationOptions/style";

const Content = ({ expanded }) => {
  const mendRequestReason = useSelector(selectMendRequestReason);
  const mendRequestArea = useSelector(selectMendRequestArea);

  return (
    <Wrapper expanded={expanded}>
      <SubsanationText>
        Para: {mendRequestArea}
      </SubsanationText>
      <TextArea value={mendRequestReason} />
    </Wrapper>
  );
};

export default Content;
