export const MODAL_MESSAGE = "messageModal";
export const MODAL_SHOW_OBSERVATION = "showObservationModal";
export const MODAL_EDIT_OBSERVATION = "editObservationModal";
export const MODAL_SHOW_REJECTION = "showRejectionModal";
export const MODAL_EDIT_REJECTION = "editRejectionModal";
export const MODAL_FILTERS = "filtersModal";
export const MODAL_SHARE_FILE = "shareFileModal";
export const MODAL_NOMENCLARURE = "nomenclatureModal";
export const MODAL_SENT_PAPERWORK = "sentPaperworkModal";
export const LOCKED_PAPERWORK_MODAL = "lockedPaperworkModal";
export const MODAL_SEE_MORE = "seeMoreModal";
export const MODAL_REJECTION_REQUEST = "rejectionRequestModal";
export const MODAL_EDIT_MEND = "editMendModal";
export const MODAL_SHOW_MEND = "showMendModal";
export const MODAL_MEND = "mendModal";
export const MODAL_ADD_USER = "addUser";
export const MODAL_EDIT_USER = "editModal";
export const MODAL_EXPAND_IMAGE = "expandImageModal";
export const MODAL_MULTIPLE_DEPARTURES = "multipleDeparturesModal";
export const MULTIPLE_PLOTS_MODAL = "multiplePlotsModal";
export const MODAL_WARN_MULTIPLE_PLOTS = "warnMultiplePlotsModal";
