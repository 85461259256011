import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: top;
  width: 100%;
  min-height: 4px;
  border-radius: 4px;
  background: ${colors.orange99};
  margin-bottom: 28px;
`;

export const Progress = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => props.width};
  height: 100%;
  border-radius: 4px;
  box-sizing: border-box;
  background: ${colors.orange50};
  transition: width 1s;
`;
