import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  box-sizing: border-box;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  width: 327px;
  height: 96px;
  overflow: visible;
  gap: 24px;
  font-size: 60px;
  box-sizing: border-box;
  @media (max-width: 580px) {
    flex-direction: column;
    width: 100%;
    height: auto;
    gap: 8px;
    font-size: 12px;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  width: 100%;
  height: 100%;
  gap: 16px;
  font-size: 60px;
  @media (max-width: 580px) {
    align-items: center;
  }
`;

export const Text = styled.div`
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.gray50};
`;

export const Error = styled.div`
  display: flex;
  min-height: 20px;
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.red50};
  @media (max-width: 1366px) {
    font-size: 12px;
  }
`;
