import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: left;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid ${colors.gray99};
`;

export const Content = styled.div`
  display: grid;
  padding: 20px 0px;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  gap: 2px;
  box-sizing: border-box;
  @media (max-width: 980px) {
    grid-template-columns: 1fr;
  }
`;
