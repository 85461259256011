import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 36px;
  box-sizing: border-box;
  @media (max-width: 905px) or (max-height: 700px) {
    padding-top: 20px;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 32px;
  gap: 8px;
  box-sizing: border-box;
  @media (max-width: 905px) {
    gap: 20px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  height: 56px;
  @media (max-width: 905px) {
    height: 48px;
  }
`;
