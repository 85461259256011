import React from "react";
import { Wrapper, Label, Input, Switch } from "./style";

const Button = ({ onClick, checked, disabled }) => {
  return (
    <Wrapper disabled={disabled}>
      <Label>
        <Input checked={checked} type="checkbox" onClick={onClick} readOnly />
        <Switch checked={checked} />
      </Label>
    </Wrapper>
  );
};

export default Button;
