export const wsConnect = (host) => ({ type: "WS_CONNECT", host });
export const wsConnected = (host) => ({ type: "WS_CONNECTED", host });
export const wsDisconnect = (host) => ({ type: "WS_DISCONNECT", host });
export const wsDisconnected = (host) => ({ type: "WS_DISCONNECTED", host });
export const wsEmit = (event, data) => ({ type: "WS_EMIT", event, data });

export const joinPaperworkDetailRoom = (paperworkProgressId) => {
  return async(dispatch) => {
    dispatch(wsEmit("join_paperwork_detail", { paperworkProgressId } ));
  };
};

export const leavePaperworkDetailRoom = (paperworkProgressId) => {
  return async(dispatch) => {
    dispatch(wsEmit("leave_paperwork_detail", { paperworkProgressId } ));
  };
};