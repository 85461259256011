/* eslint-disable react/no-children-prop */
import React from "react";
import { useSelector } from "react-redux";
import { selectPaperworkData } from "store/PaperWork/selectors";
import Wrapper from "./styled";
import { Title, Content, Column, Text } from "../styled";
import { get } from "lodash";

const RealStateTax = ({ selectedPlot }) => {
  const data = useSelector(selectPaperworkData);
  const realStateTax = get(data, `[${selectedPlot}].domainData.realStateTax[0]`, {});

  const name = get(realStateTax, "name", "-");
  const CUIT = get(realStateTax, "CUIT", "-");
  const address = get(realStateTax, "address", "-");
  const department = get(realStateTax, "department", "-");
  const floor = get(realStateTax, "floor", "-");
  const location = get(realStateTax, "location", "-");

  return (
    <Wrapper>
      <Title children="Contribuyente impuesto inmobiliario" />
      <Content>
        <Column>
          <Text children={`Nombre y apellido: ${name}`} />
          <Text children={`Identificación: ${CUIT}`} />
          <Text children={`Dirección: ${address}`} />
          <Text children={`Departamento: ${department}`} />
          <Text children={`Piso: ${floor}`} />
          <Text children={`Localidad: ${location}`} />
        </Column>
      </Content>
    </Wrapper>
  );
};

export default RealStateTax;
