import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`;

export const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  border: 1px solid;
  border-radius: 4px;
  border-color: ${({ disabled }) => (disabled ? colors.red80 : colors.green80)};
  color: ${({ disabled }) => (disabled ? colors.red50 : colors.green50)};
  background: ${({ disabled }) => (disabled ? colors.red95 : colors.green100)};
`;
