import styled from "styled-components";
import { ReactComponent as IconOutlined } from "images/icons/blue/filter_outlined.svg";
import { ReactComponent as IconFilled } from "images/icons/blue/filter_filled.svg";

export const Wrapper = styled.div`
  display: flex;
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

export const OutlinedIcon = styled(IconOutlined)``;

export const FilledIcon = styled(IconFilled)``;