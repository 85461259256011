/* eslint-disable react/jsx-key */
import React from "react";
import { useDispatch } from "react-redux";
import { getPaperworkDetail, getDocuments } from "store/PaperWork/actions/requests";
import { TD, TR } from "../../../../../../styled";

const Content = ({ data, prepareRow, cellActions }) => {
  const dispatch = useDispatch();

  const seeDetail = (id) => {
    if (cellActions) return;
    dispatch(getPaperworkDetail(id));
    dispatch(getDocuments(id));
  };

  return data.map((row) => {
    prepareRow(row);
    return (
      <TR
        {...row.getRowProps()}
        isExpanded={row.isExpanded && row.original.articleDescription !== ""}
        onClick={() => {
          seeDetail(row.original.paperworkProgressId);
        }}
        cellActions={cellActions}
      >
        {row.cells.map((cell) => {
          return (
            <TD {...cell.getCellProps()} isExpanded={row.isExpanded && row.original.articleDescription !== ""}>
              {cell.render("Cell")}
            </TD>
          );
        })}
      </TR>
    );
  });
};

export default Content;
