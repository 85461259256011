import React, { useEffect, useState } from "react";
import MainLayout from "components/commons/MainLayout";
import { Wrapper } from "./styled";
import Header from "./components/Header";
import { getNewPaperworkList } from "store/PaperWork/actions/requests";
import { useDispatch } from "react-redux";
import Sections from "./components/Sections";
import { resetGeneralStore } from "store/General/actions";
import { resetPaperworkStore, setPaperworkList } from "store/PaperWork/actions/actions";
import colors from "styles/colors";
import { resetFilters } from "store/Filters/actions";
import { NEW_KEY } from "./components/Sections/components/Section/functions";

const Home = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [id, setId] = useState(NEW_KEY);

  useEffect(() => {
    dispatch(getNewPaperworkList());
    dispatch(resetPaperworkStore());
  }, []);

  useEffect(
    () => () => {
      dispatch(resetGeneralStore());
      dispatch(setPaperworkList([]));
      dispatch(resetFilters());
    },
    []
  );

  return (
    <MainLayout header={<Header setSearch={setSearch} search={search} id={id} setId={setId} />} background={colors.gray100}>
      <Wrapper>
        <Sections search={search} id={id} setId={setId} />
      </Wrapper>
    </MainLayout>
  );
};

export default Home;
