import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Item = styled.div`
  display: flex;
  height: auto;
  align-items: center;
  justify-content: center;
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 38px;
  line-height: 34px;
  box-sizing: border-box;
  transition: all 0.4s;
  border-bottom: 2px solid ${colors.gray60};
  color: ${colors.black};
  @media (max-width: 580px) {
    font-size: 36px;
  }
  @media (max-width: 500px) {
    font-size: 36px;
  }
  @media (max-width: 480px) {
    font-size: 32px;
  }
  @media (max-width: 430px) {
    font-size: 28px;
  }
  @media (max-width: 386px) {
    font-size: 24px;
    line-height: 22px;
  }
`;
