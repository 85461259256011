/* eslint-disable react/no-children-prop */
import React from "react";
import Button from "./components/Button";
import { Wrapper, Label, InfoContainer, ButtonContainer, Description } from "./style";

const PrimaryCheckBox = ({ label, description, onClick, value, disabled }) => {
  return (
    <Wrapper>
      <InfoContainer>
        <Label children={label} />
        <Description label={label} children={description} />
      </InfoContainer>
      <ButtonContainer>
        <Button onClick={onClick} label={label} checked={value} disabled={disabled} />
      </ButtonContainer>
    </Wrapper>
  );
};

export default PrimaryCheckBox;
