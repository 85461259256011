import React, { useState } from "react";
import { Wrapper, TextArea, ButtonContainer, Footer } from "./style";
import { MODAL_MEND } from "store/Modals/constants";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "store/Modals/actions";
import LabelOnlyButton from "components/commons/Buttons/LabelOnlyButton";
import colors from "styles/colors";
import { mendPaperwork } from "store/PaperWork/actions/requests";
import { selectPaperworkMetadata } from "store/PaperWork/selectors";
import { PLACEHOLDER, SEND } from "./constants";
import { isNull, isEmpty } from "utils/functions";
import get from "lodash/get";

const Content = ({ expanded }) => {
  const dispatch = useDispatch();
  const metadata = useSelector(selectPaperworkMetadata);
  const id = get(metadata, "id", null);
  const currentMendingRequest = get(metadata, "currentMendingRequest", []);
  const [text, setText] = useState(null);
  const data = { reason: text, mendingRequestId: currentMendingRequest.mendingId };

  const handleObservation = (e) => {
    setText(e.target.value);
  };

  const mend = () => {
    dispatch(closeModal(MODAL_MEND));
    dispatch(mendPaperwork(id, data));
  };

  return (
    <Wrapper expanded={expanded}>
      <TextArea onChange={(e) => handleObservation(e)} placeholder={PLACEHOLDER} />
      <Footer>
        <ButtonContainer>
          <LabelOnlyButton
            background={colors.blue40}
            color={colors.white}
            label={SEND}
            onClick={mend}
            disabled={isNull(text) || isEmpty(text)}
          />
        </ButtonContainer>
      </Footer>
    </Wrapper>
  );
};

export default Content;
