import React, { useEffect } from "react";
import { Wrapper } from "./styled";
import Form from "./components/Form";
import { useDispatch, useSelector } from "react-redux";
import { selectProfileDefaultRoute, selectTokenExpires, selectUser, selectUserId } from "store/Login/selectors";
import { BYPASS_LOGIN, PATH_FISCAL_KEY_LOGIN, PATH_VALIDATION } from "components/pages/App/constants";
import { push } from "connected-react-router";
import { get } from "lodash";
import { resetError } from "store/Login/actions/actions";

const SignUp = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const id = useSelector(selectUserId);
  const uid = get(user, "uid", "");
  const profileId = get(user, "profileId", null);
  const internalUser = uid && profileId; //It is registered but has not yet been assigned a profile.
  const defaultRoute = useSelector(selectProfileDefaultRoute);
  const tokenExpires = useSelector(selectTokenExpires);
  const isTokenExpired = () => tokenExpires < Date.now() / 1000;

  useEffect(() => {
    if (id && (BYPASS_LOGIN || internalUser)) dispatch(push(defaultRoute));
    else if (!id && BYPASS_LOGIN) dispatch(push(PATH_FISCAL_KEY_LOGIN));
    else if (uid && !internalUser) dispatch(push(PATH_VALIDATION));
    else if (id && !isTokenExpired()) dispatch(push(defaultRoute));
  }, [id]);

  useEffect(() => {
    return () => dispatch(resetError());
  }, []);

  return (
    <Wrapper>
      <Form />
    </Wrapper>
  );
};

export default SignUp;
