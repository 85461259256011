import React, { useState } from "react";
import Observations from "components/pages/FormPreview/components/Observations";
import Header from "./components/Header";
import { Content, Wrapper } from "./styled";
import RecoveredData from "./components/RecoveredData";
import Owners from "./components/RecoveredData/components/Owners";
import TabSelector from "./components/TabSelector";
import StageInfo from "./components/StageInfo";
import { useSelector } from "react-redux";
import { selectPaperworkStageProgress } from "store/PaperWork/selectors";
import Dates from "../../../D1/components/LeftBlock/components/RecoveredData/components/Dates";

const LeftBlock = () => {
  const [selectedPlot, setSelectedPlot] = useState(0);
  const stageProgress = useSelector(selectPaperworkStageProgress);

  const getStageInfo = (item, index) => <StageInfo key={item.stageId} stage={item} index={index} />;

  return (
    <Wrapper>
      <Header />
      <TabSelector selectedPlot={selectedPlot} setSelectedPlot={setSelectedPlot} />
      <Content>
        {stageProgress.filter((stage) => stage.stageTitle !== "Ingreso de datos").map(getStageInfo)}
        <RecoveredData selectedPlot={selectedPlot} />
        <Observations />
        <Owners />
        <Dates />
      </Content>
    </Wrapper>
  );
};

export default LeftBlock;
