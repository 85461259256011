/* eslint-disable react/no-children-prop */
import React from "react";
import { Wrapper, Text } from "./styled";
import Tooltip from "components/commons/Tooltip";
import { DownloadIcon } from "../../styled";

const ApiCard = ({ url }) => {
  const name = url.substring(url.lastIndexOf("/") + 1).replaceAll("%", " ");

  return (
    <Wrapper>
      <Tooltip title={name}>
        <Text href={url} target="_blank" children={name} />
      </Tooltip>
      <DownloadIcon />
    </Wrapper>
  );
};

export default ApiCard;
