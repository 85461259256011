import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
  width: 100%;
  gap: 16px;
  font-size: 60px;
  box-sizing: border-box;
  margin-top: auto;
  @media (max-width: 980px) {
    padding: 0px 20px;
  }
`;

export const ButtonLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 103px;
  height: 40px;
  box-sizing: border-box;
`;

export const ButtonRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 172px;
  height: 40px;
  box-sizing: border-box;
`;
