/* eslint-disable no-unused-vars */
import React from "react";
import colors from "styles/colors";
import { ApprovedIcon, ObservedIcon, RejectIcon, Wrapper, Container } from "./styled";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "store/Modals/actions";
import { selectPaperworkMetadata, selectUserCanEditPaperworkProgress } from "store/PaperWork/selectors";
import { requestApprovePaperwork } from "store/PaperWork/actions/requests";
import { MODAL_EDIT_OBSERVATION, MODAL_REJECTION_REQUEST } from "store/Modals/constants";
import { PAPERWORK_STATE } from "store/PaperWork/constants";
import LabelIconButton from "components/pages/PaperworkDetail/components/LabelIconButton";
import { USER_PROFILE_ID } from "store/Login/initialState";
import { selectIsAllowed } from "store/Login/selectors";
import Tooltip from "components/commons/Tooltip";
import get from "lodash/get";

const Operator = () => {
  const dispatch = useDispatch();
  const allowedRoles = [USER_PROFILE_ID.OPERATOR, USER_PROFILE_ID.TERRITORIAL_INFO_OPERATOR];
  const allowed = useSelector((state) => selectIsAllowed(state, allowedRoles));
  const metadata = useSelector(selectPaperworkMetadata);
  const id = get(metadata, "id", null);
  const state = get(metadata, "state", null);
  const isPending = state === PAPERWORK_STATE.PENDING;
  const canEdit = useSelector(selectUserCanEditPaperworkProgress);

  const requestApprove = () => dispatch(requestApprovePaperwork(id));
  const requestReject = () => dispatch(openModal(MODAL_REJECTION_REQUEST));
  const sendToReview = () => dispatch(openModal(MODAL_EDIT_OBSERVATION));

  if (!allowed) return null;
  return (
    <Tooltip title={"Esperando respuestas\ndel area correspondiente"} hidden={isPending}>
      <Wrapper>
        <Container>
          <LabelIconButton
            type="secondary"
            disabled={!isPending || !canEdit}
            color={colors.blue50}
            background={colors.white}
            label={"Observar"}
            border={`1px solid ${colors.blue70}`}
            icon={<ObservedIcon disabled={!isPending || !canEdit} />}
            onClick={sendToReview}
            width={"216px"}
            height={"40px"}
          />
          <LabelIconButton
            type="secondary"
            disabled={!isPending || !canEdit}
            color={colors.blue50}
            background={colors.white}
            label={"Solicitar rechazo"}
            border={`1px solid ${colors.blue70}`}
            icon={<RejectIcon disabled={!isPending || !canEdit} />}
            onClick={requestReject}
            width={"216px"}
            height={"40px"}
          />
          <LabelIconButton
            type="primary"
            disabled={!isPending || !canEdit}
            color={colors.white}
            background={colors.blue50}
            label={"Validar"}
            icon={<ApprovedIcon disabled={!isPending || !canEdit} />}
            onClick={requestApprove}
            width={"216px"}
            height={"40px"}
          />
        </Container>
      </Wrapper>
    </Tooltip>
  );
};

export default Operator;
