import styled from "styled-components";
import colors from "styles/colors";
import shadows from "styles/shadows";

export const Wrapper = styled.div`
  display: flex;
  justify-content: left;
  width: ${(props) => (props.toogle ? "80px" : "288px")};
  height: 100%;
  background: ${colors.gray100};
  overflow: visible;
  position: relative;
  box-sizing: border-box;
  position: absolute;
  z-index: 4;
  transition: width 0.3s;
  border: 1px solid ${colors.gray95};
  box-shadow: ${({ toogle }) => (toogle ? "none" : shadows.shadow3)};
`;
