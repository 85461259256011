/* eslint-disable no-unused-vars */
import fetch from "../connector";
import * as paths from "components/pages/App/constants";

export const getNewPaperworksList = (token) =>
  fetch(paths.PATH_BASE_URL, paths.getPathNewPaperWList(token), {
    method: "GET",
  });

export const getPaperworkByType = (paperwType, token) =>
  fetch(paths.PATH_BASE_URL, paths.getPathPaperWType(paperwType, token), {
    method: "GET",
  });

export const getPaperworkDetail = (pProgId, token) =>
  fetch(paths.PATH_BASE_URL, paths.getPathPaperworkDetail(pProgId, token), {
    method: "GET",
  });

export const unlockPaperwork = (pProgId, token) =>
  fetch(paths.PATH_BASE_URL, paths.getPathUnlockPaperwork(pProgId, token), {
    method: "POST",
  });

export const getPaperworkHistory = (pageSize, pageNumber, data, token) =>
  fetch(paths.PATH_BASE_URL, paths.getPathPaperworkHistory(pageSize, pageNumber, token), {
    method: "POST",
    data: data,
  });

export const getSubsanations = (pageSize, pageNumber, data, token) =>
  fetch(paths.PATH_BASE_URL, paths.getPathSubsanations(pageSize, pageNumber, token), {
    method: "POST",
    data: data,
  });

export const saveStage = (paperwType, stageId, pProgId, data, token) =>
  fetch(paths.PATH_BASE_URL, paths.getPathSaveStage(paperwType, stageId, pProgId, token), {
    method: "POST",
    data: data,
  });

export const emptyStage = (paperwType, stageId, pProgId, token) =>
  fetch(paths.PATH_BASE_URL, paths.getPathEmptyStage(paperwType, stageId, pProgId, token), {
    method: "POST",
  });

export const sendPaperworkToReview = (paperwType, pProgId, token) =>
  fetch(paths.PATH_BASE_URL, paths.getPathSendToReview(paperwType, pProgId, token), {
    method: "POST",
  });

export const observePaperwork = (pProgId, data, token) =>
  fetch(paths.PATH_BASE_URL, paths.getPathObservePaperwork(pProgId, token), {
    method: "POST",
    data: data,
  });

export const approvePaperwork = (pProgId, token) =>
  fetch(paths.PATH_BASE_URL, paths.getPathApprovePaperwork(pProgId, token), {
    method: "POST",
  });

export const mendPaperwork = (pProgId, data, token) =>
  fetch(paths.PATH_BASE_URL, paths.getPathMendPaperwork(pProgId, token), {
    method: "POST",
    data: data,
  });

export const requestApprovePaperwork = (pProgId, token) =>
  fetch(paths.PATH_BASE_URL, paths.getPathRequestApprovePaperwork(pProgId, token), {
    method: "POST",
  });

export const requestMendingPaperwork = (pProgId, data, token) =>
  fetch(paths.PATH_BASE_URL, paths.getPathRequestMendingPaperwork(pProgId, token), {
    method: "POST",
    data: data,
  });

export const rejectPaperwork = (pProgId, data, token) =>
  fetch(paths.PATH_BASE_URL, paths.getPathRejectPaperwork(pProgId, token), {
    method: "POST",
    data: data,
  });

export const requestRejectPaperwork = (pProgId, data, token) =>
  fetch(paths.PATH_BASE_URL, paths.getPathRequestRejectPaperwork(pProgId, token), {
    method: "POST",
    data: data,
  });

export const downloadReport = (pProgId, token, signed) =>
  fetch(paths.PATH_BASE_URL, paths.getPathDownloadReport(pProgId, token, signed), {
    method: "GET",
    responseType: "arraybuffer",
  });

export const shareReport = (pProgId, token) =>
  fetch(paths.PATH_BASE_URL, paths.getPathShareReport(pProgId, token), {
    method: "POST",
  });

export const getPaperworksStatistics = (dateFrom, dateTo, token) =>
  fetch(paths.PATH_BASE_URL, paths.getPathPaperworksStatistics(dateFrom, dateTo, token), {
    method: "GET",
  });

export const getPaperworksStatisticsByState = (state, dateFrom, dateTo, token) =>
  fetch(paths.PATH_BASE_URL, paths.getPathPaperworksStatisticsByState(state, dateFrom, dateTo, token), {
    method: "GET",
  });

export const getPaperworksStatisticsIncome = (dateFrom, dateTo, token) =>
  fetch(paths.PATH_BASE_URL, paths.getPathPaperworksStatisticsIncome(dateFrom, dateTo, token), {
    method: "GET",
  });

export const getPaperworksStatisticsApproved = (dateFrom, dateTo, token) =>
  fetch(paths.PATH_BASE_URL, paths.getPathPaperworksStatisticsApproved(dateFrom, dateTo, token), {
    method: "GET",
  });

export const getDocuments = (pProgId, token) =>
  fetch(paths.PATH_BASE_URL, paths.getPathDocuments(pProgId, token), {
    method: "GET",
  });

export const downloadDocument = (url, token) =>
  fetch(null, paths.getPathDownloadDocument(url, token), {
    method: "GET",
    responseType: "arraybuffer",
  });

export const updateUserInfo = (data, token) =>
  fetch(paths.PATH_BASE_URL, paths.getPathUpdateUserInfo(token), {
    method: "PUT",
    data: data,
  });

export const updateUserPicture = (data, token) =>
  fetch(paths.PATH_BASE_URL, paths.getPathUpdateUserPicture(token), {
    method: "PUT",
    data: data,
  });

export const getUserPicture = (token) =>
  fetch(paths.PATH_BASE_URL, paths.getPathUpdateUserPicture(token), {
    method: "GET",
    responseType: "arraybuffer",
  });

export const getInternalSignIn = (idToken) =>
  fetch(paths.PATH_BASE_URL, paths.getInternalSignIn(idToken), {
    method: "GET",
  });

export const getSubsanationAreas = (token) =>
  fetch(paths.PATH_BASE_URL, paths.getSubsanationAreas(token), {
    method: "GET",
  });
