import React from "react";
import { Wrapper, Text } from "./styled";
import Tooltip from "components/commons/Tooltip";
import ImageIcon from "./components/ImageIcon";

const CommonCard = ({ field, value, image }) => {
  const text = field ? `${field}: ${value}` : value;

  return (
    <Wrapper>
      <ImageIcon show={image} />
      <Tooltip title={value}>
        <Text>{text}</Text>
      </Tooltip>
    </Wrapper>
  );
};

export default CommonCard;
