import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  width: 100%;
  height: 100%;
  font-size: 60px;
  color: blue;
  box-sizing: border-box;
  @media (max-width: 980px) {
    height: 90%;
    padding: 0px 16px 0px 16px;
    overflow-y: overlay;
  }
`;

export const Stages = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  width: 100%;
  margin-top: 24px;
  box-sizing: border-box;
`;

export const OptionContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: auto;
`;
