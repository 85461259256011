import React from "react";
import { useSelector } from "react-redux";
import { selectPaperworkMetadata } from "store/PaperWork/selectors";
import Header from "./component/Header";
import { Content, Wrapper } from "./styled";
import { get, isEmpty, entries, values } from "lodash";
import Item from "./component/Item";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const StageInfo = ({ stage, index }) => {
  const { jsonSchemaData, otherInputsData, formFieldsSchema, stageTitle } = stage;
  const pProg = useSelector(selectPaperworkMetadata);
  const stageKey = get(pProg, `paperwork.stages[${index}].form.otherInputs[0].key`, 0);
  const oIItems = entries(otherInputsData).map((e) => e.pop());
  const jSItems = entries(jsonSchemaData);
  const schemaIsIncomplete = values(jsonSchemaData).every((e) => !e);
  const excludedKeys = ["paperworkProgressId"];
  const { pathname } = useLocation();
  const currentSection = pathname.split("/")[1];
  const hidden = currentSection === "tramites";
  const notInfo = (schemaIsIncomplete || isEmpty(jsonSchemaData)) && isEmpty(otherInputsData[stageKey]);
  const getCard = (data, i) => <Item key={`${data[0]}_${i}`} data={data} fields={formFieldsSchema} index={index} />;

  if (hidden || notInfo || excludedKeys.includes(stageKey)) return null;

  return (
    <Wrapper>
      <Header title={stageTitle} />
      <Content>
        {jSItems.map(getCard)}
        {oIItems.map((e) => entries(e).map(getCard))}
      </Content>
    </Wrapper>
  );
};

export default StageInfo;
