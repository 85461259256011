/* eslint-disable quotes */
import React from "react";
import { Wrapper } from "./styled";
import Header from "./components/Header";
import Content from "./components/Content";
import { useSelector } from "react-redux";
import { selectPaperworkStructure } from "store/PaperWork/selectors";
import { get, isEmpty } from "lodash";

const StageInfo = ({ stage, index }) => {
  const paperwork = useSelector(selectPaperworkStructure);
  const stageStructure = get(paperwork, `stages[${index}]`, {});
  const stageKey = get(stageStructure, `form.otherInputs[0].key`, "");
  const jsonSchemaData = get(stage, `jsonSchemaData`, {});
  const otherInputsData = get(stage, `otherInputsData[${stageKey}]`, {});
  const schemaIsIncomplete = Object.values(jsonSchemaData).every((e) => !e);
  const excludedKeys = ["paperworkProgressId"];
  const notInfo = (schemaIsIncomplete || isEmpty(jsonSchemaData)) && isEmpty(otherInputsData);
  const { stageTitle } = stage;

  if (notInfo || excludedKeys.includes(stageKey)) return null;

  return (
    <Wrapper>
      <Header title={stageTitle} index={index} />
      <Content stage={stage} index={index} />
    </Wrapper>
  );
};

export default StageInfo;
