import React from "react";
import Section from "./components/Section";
import { Wrapper } from "./styled";

const Sections = ({ search, id}) => {

  return (
    <Wrapper>
      <Section id={id} search={search} />
    </Wrapper>
  );
};

export default Sections;
