/* eslint-disable react/no-children-prop */
import React from "react";
import { Wrapper, Text, Icon, ArrowUp, ArrowDown } from "./styled";

const DropDown = ({ value, height, isOpen, background, withBorder, textColor }) => {
  const getValue = (value) => value || "Seleccione una opción";
  const getIcon = () => (isOpen ? <ArrowUp /> : <ArrowDown />);

  return (
    <Wrapper height={height} background={background} withBorder={withBorder} >
      <Text children={getValue(value)} textColor={textColor} />
      <Icon left={false} children={getIcon()} />
    </Wrapper>
  );
};

export default DropDown;
