import styled from "styled-components";
import colors from "styles/colors";
import { ReactComponent as ArrowIcon } from "images/icons/gray/arrow-small.svg";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
  height: ${(props) => props.height};
  margin-bottom: 2px;
  padding-left: 12px;
  padding-right: ${(props) => (props.left ? "0px" : "40px")};
  font-weight: 500;
  font-size: 1.3rem;
  box-sizing: border-box;
  border: ${(props) => (props.withBorder ? `1px solid ${colors.gray90}` : "0px")};
  border-radius: 4px;
  color: ${colors.gray10};
  background: ${(props) => props.background};
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.gray10};
`;

export const Text = styled.div`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${(props) => (props.textColor ? props.textColor : null)};
`;

export const Icon = styled.div`
  position: absolute;
  left: ${(props) => (props.left ? "5px" : "none")};
  right: ${(props) => (props.left ? "none" : "10px")};
  top: 50%;
  transform: translateY(-50%);
`;

export const ArrowDown = styled(ArrowIcon)``;

export const ArrowUp = styled(ArrowIcon)`
  transform: rotate(180deg);
`;
