import React, { useState } from "react";
import Observations from "components/pages/FormPreview/components/Observations";
import Rejection from "components/pages/FormPreview/components/Rejection";
import MendingRequest from "components/pages/FormPreview/components/MendingRequest";
import Header from "./components/Header";
import { Content, Wrapper } from "./styled";
import RecoveredData from "./components/RecoveredData";
import Owners from "./components/RecoveredData/components/Owners";
import TabSelector from "./components/TabSelector";
import Dates from "./components/RecoveredData/components/Dates";
import { useSelector } from "react-redux";
import { selectPaperworkStageProgress } from "store/PaperWork/selectors";
import StageInfo from "./components/StageInfo";

const LeftBlock = () => {
  const [selectedPlot, setSelectedPlot] = useState(0);
  const stageProgress = useSelector(selectPaperworkStageProgress);

  const getStageInfo = (item, index) => <StageInfo key={item.stageId} stage={item} index={index} />;

  return (
    <Wrapper>
      <Header />
      <TabSelector selectedPlot={selectedPlot} setSelectedPlot={setSelectedPlot}/>
      <Content>
        {stageProgress.filter((stage) => stage.stageTitle !== "Ingreso de datos").map(getStageInfo)}
        <RecoveredData selectedPlot={selectedPlot} />
        <Observations />
        <Owners />
        <Dates/>
        <Rejection />
        <MendingRequest />
      </Content>
    </Wrapper>
  );
};

export default LeftBlock;
