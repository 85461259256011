/* eslint-disable no-unused-vars */
import React from "react";
import Icon from "images/icons/gray/copy.svg";
import { Wrapper, Image } from "./styled";

const CopyIcon = ({ onClick }) => {
  return (
    <Wrapper onClick={onClick}>
      <Image src={Icon} alt="icon" />
    </Wrapper>
  );
};

export default CopyIcon;
