import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  overflow: visible;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  width: 100%;
  box-sizing: border-box;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: top;
  width: 100%;
  box-sizing: border-box;
  flex: 1;
  @media (max-width: 980px) {
    overflow: overlay;
    padding: 0px 16px
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  margin-top: auto;
  flex: 0 0 128px;
  @media (max-width: 980px) {
    flex: 0 0 68px;
  }
`;
