import Observations from "components/pages/FormPreview/components/Observations";
import Rejection from "components/pages/FormPreview/components/Rejection";
import React from "react";
import { useSelector } from "react-redux";
import { selectPaperworkStageProgress } from "store/PaperWork/selectors";
import Header from "./components/Header";
import StageInfo from "./components/StageInfo";
import ImprovementValue from "./components/ImprovementValue";
import { Content, Wrapper } from "./styled";

const LeftBlock = () => {
  const stageProgress = useSelector(selectPaperworkStageProgress);

  const getStageInfo = (item, index) => <StageInfo key={item.stageId} stage={item} index={index} />;

  return (
    <Wrapper>
      <Header />
      <Content>
        {stageProgress.map(getStageInfo)}
        <ImprovementValue />
        <Observations />
        <Rejection />
      </Content>
    </Wrapper>
  );
};

export default LeftBlock;
