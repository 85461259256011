/* eslint-disable no-unused-vars */
import axios from "axios";
import { get } from "lodash";
import store from "utils/store";
import { logout } from "store/Login/actions/actions";

const path = (api, url) => {
  if (!api) return url;
  return `${api}${url}`;
};

/*Headers*/

export const HEADER_PAYMENT_REQUEST = {
  Authorization: "Bearer " + "8c4c5c64-8793-47b7-b959-c235784b6498",
  ContentType: "multipart/form-data",
};

async function fetch(api, url, config) {
  const { headers, method = "GET", ...rest } = config;
  const options = {
    method,
    headers: {
      ...headers,
    },
    ...rest,
  };

  try {
    return await axios(path(api, url), options);
  } catch (e) {
    const status = get(e, "response.status");
    if (status === 401) return store.dispatch(logout());
    throw e;
  }
}

export default fetch;
