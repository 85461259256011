import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: left;
  width: 100%;
  box-sizing: border-box;
  padding-bottom: 20px;
`;

export default Wrapper;