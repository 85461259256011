import * as filters from "./constants";

export const initialState = {
  [filters.FILTER_TYPES]: [],
  [filters.FILTER_DATE_FROM]: "",
  [filters.FILTER_DATE_TO]: "",
  [filters.FILTER_STATUS]: [],
  [filters.FILTER_IDENTIFICATION]: "",
  [filters.FILTER_DEPARTMENT]: "",
  [filters.FILTER_DEPARTURE]: "",
  [filters.FILTER_NOMENCLATURE]: "",
  [filters.FILTER_PAPERWORK_PROGRESS_ID]: "",
};
