import { USER_PROFILE_ID } from "store/Login/initialState";
import { PAPERWORK_STATE, PAPERWORK_TYPE } from "store/PaperWork/constants";

export const FILTER_TYPES = "paperworksTypes";
export const FILTER_DATE_FROM = "dateFrom";
export const FILTER_DATE_TO = "dateTo";
export const FILTER_STATUS = "paperworksStates";
export const FILTER_IDENTIFICATION = "cuit";
export const FILTER_DEPARTMENT = "department";
export const FILTER_DEPARTURE = "departure";
export const FILTER_NOMENCLATURE = "nomenclature";
export const FILTER_PAPERWORK_PROGRESS_ID = "paperworkProgressId";
export const MONTHS_TO_FILTER = 6;
export const FORMAT_DATE = "DD/MM/yyyy";

export const FILTER_TYPE_BY_ROLE = {
  [USER_PROFILE_ID.CONTRIBUTOR]: [],
  [USER_PROFILE_ID.ADMINISTRATOR]: [],
  [USER_PROFILE_ID.OPERATOR]: [],
  [USER_PROFILE_ID.CERTIFIER]: [],
  [USER_PROFILE_ID.SUBSANATOR]: [],
  [USER_PROFILE_ID.VALUATION_OPERATOR]: [PAPERWORK_TYPE.E1],
  [USER_PROFILE_ID.TERRITORIAL_INFO_OPERATOR]: [PAPERWORK_TYPE.D1, PAPERWORK_TYPE.D2, PAPERWORK_TYPE.D5],
};

export const FILTER_STATE_BY_ROLE = {
  [USER_PROFILE_ID.CONTRIBUTOR]: [],
  [USER_PROFILE_ID.ADMINISTRATOR]: [],
  [USER_PROFILE_ID.OPERATOR]: [],
  [USER_PROFILE_ID.CERTIFIER]: [],
  [USER_PROFILE_ID.SUBSANATOR]: [],
  [USER_PROFILE_ID.VALUATION_OPERATOR]: [PAPERWORK_STATE.PENDING, PAPERWORK_STATE.OBSERVED, PAPERWORK_STATE.APPROVED, PAPERWORK_STATE.REJECTED],
  [USER_PROFILE_ID.TERRITORIAL_INFO_OPERATOR]: [],
};
