import React, { useState } from "react";
import DropDown from "./components/DropDown";
import Options from "./components/Options";
import { Wrapper } from "./styled";

const CommonComboBox = ({ value, height, optionHeight, onChange, disabled, options, accessor = null, background = null, withBorder = true, textColor = null }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggling = () => setIsOpen(!isOpen);

  return (
    <Wrapper disabled={disabled} onClick={toggling}>
      <DropDown height={height} value={value} isOpen={isOpen} background={background} withBorder={withBorder} textColor={textColor} />
      <Options isOpen={isOpen} onChange={onChange} options={options} height={optionHeight} accessor={accessor} />
    </Wrapper>
  );
};

export default CommonComboBox;
