import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100%;
  max-height: 112px;
  padding: 16px;
  color: blue;
  border-radius: 0px 8px 8px 0px;
  box-sizing: border-box;
  background: ${(props) => props.background};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ChartContainer = styled.div`
  flex: 1;
  overflow: hidden;
`;

export const Title = styled.div`
  display: flex;
  flex: 0 0 15px;
  justify-content: center;
  align-items: center;
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  color: ${(props) => props.color};
  text-align: center;
  user-select: none;
`;
