import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 170px;
  max-height: 275px;
  font-family: "OpenSansRegular";
  font-size: 50px;
  font-style: normal;
  font-weight: 300;
`;
