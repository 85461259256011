import { emailPattern, onlyNumbers } from "utils/functions";
import { isMatchWith, isEmpty } from "lodash";

const emailIsValid = (value) => {
  if (value) return emailPattern(value);
  return true;
};

export const areaCodeIsValid = (value) => {
  if (value && (value?.length > 4 || value?.length < 2 || !onlyNumbers(value))) return false;
  return true;
};

export const phoneIsValid = (value) => {
  if (value && (value?.length < 6 || value?.length > 8 || !onlyNumbers(value))) return false;
  return true;
};

const completePhoneIsValid = (areaCode, phone) => {
  if ((!areaCode && phone) || (!phone && areaCode)) return false;
  return areaCodeIsValid(areaCode) && phoneIsValid(phone);
};

const customizer = (a, b) => {
  if (isEmpty(a) && isEmpty(b)) return true;
};

export const isValid = (updateData, currentData) => {
  if (isMatchWith(currentData, updateData, customizer)) return false;
  else return emailIsValid(updateData.email) && completePhoneIsValid(updateData.phoneAreaCode, updateData.phone);
};
