import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  color: blue;
  border-radius: 8px;
  box-sizing: border-box;
  background: ${colors.white};
`;

export const Number = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Domine";
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 44px;
  color: ${(props) => props.color};
`;

export const Detail = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'OpenSansRegular';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  box-sizing: border-box;
  color: ${(props) => props.color};
  text-align: center;
  max-width: 78px;
`;
