/* eslint-disable no-unused-vars */
/* eslint-disable react/no-children-prop */
import React, { useState } from "react";
import { Wrapper, Label } from "./styled";
import CommonInput from "components/commons/Inputs/CommonInput";
import colors from "styles/colors";
import useResize from "hooks/useResize";

const NameInput = ({ val, disabled, placeholder, name, updateUser, setUpdateUser }) => {
  const { screenWidth, screenHeight } = useResize();
  const height = screenWidth <= 905 ? "40px" : "48px";

  const [error, setError] = useState(false);
  const errorMessage = error ? "Nombre inválido" : null;

  const onChange = (e) => {
    if (e.target.value) setError(false);
    else setError(true);
    setUpdateUser({ ...updateUser, name: e.target.value });
  };

  return (
    <Wrapper>
      <Label children="Nombre completo" />
      <CommonInput
        name="nameInput"
        type="text"
        errorMessage={errorMessage}
        error={error}
        height={height}
        onChange={onChange}
        value={val}
        disabled={disabled}
        placeholder={placeholder}
        color={colors.gray10}
        border={`1px solid ${colors.gray50}`}
        borderRadius={"4px"}
      />
    </Wrapper>
  );
};

export default NameInput;
