import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: top;
  justify-content: right;
  width: 100%;
  padding: 48px 0px 13px 0px;
  font-size: 10px;
  box-sizing: border-box;
`;

export const Description = styled.div`
  margin-top: 5px;
  margin-right: auto;
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  font-size: ${(props) => props.size};
  color: ${colors.gray20};
  flex: 1;
  user-select: none;
  @media (max-width: 500px) {
    font-size: 16px;
  }
  @media (max-width: 310px) {
    font-size: 12px;
  }
`;
