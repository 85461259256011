/* eslint-disable no-unused-vars */
/* eslint-disable react/no-children-prop */
import React from "react";
import { Wrapper, Label } from "./styled";
import colors from "styles/colors";
import useResize from "hooks/useResize";
import CommonComboBox from "components/commons/ComboBox/CommonComboBox";
import { USER_PROFILE, USER_PROFILE_ID } from "store/Login/initialState";
import { useSelector } from "react-redux";
import { selectUserCuit } from "store/Login/selectors";

const Select = ({ user, error, label, errorMessage, onClick, onChange, val, disabled, placeholder, name }) => {
  const { screenWidth, screenHeight } = useResize();
  const cuit = useSelector(selectUserCuit);
  const height = screenWidth <= 905 ? "40px" : "48px";

  const profiles = [
    { name: USER_PROFILE[USER_PROFILE_ID.ADMINISTRATOR], id: USER_PROFILE_ID.ADMINISTRATOR },
    { name: USER_PROFILE[USER_PROFILE_ID.OPERATOR], id: USER_PROFILE_ID.OPERATOR },
    { name: USER_PROFILE[USER_PROFILE_ID.CERTIFIER], id: USER_PROFILE_ID.CERTIFIER },
    { name: USER_PROFILE[USER_PROFILE_ID.SUBSANATOR], id: USER_PROFILE_ID.SUBSANATOR },
    { name: USER_PROFILE[USER_PROFILE_ID.VALUATION_OPERATOR], id: USER_PROFILE_ID.VALUATION_OPERATOR },
    { name: USER_PROFILE[USER_PROFILE_ID.TERRITORIAL_INFO_OPERATOR], id: USER_PROFILE_ID.TERRITORIAL_INFO_OPERATOR },
  ];

  const contributorProfile = [{ name: USER_PROFILE[USER_PROFILE_ID.CONTRIBUTOR], id: USER_PROFILE_ID.CONTRIBUTOR }];

  return (
    <Wrapper>
      <Label children={label} />
      <CommonComboBox
        name={name}
        options={!user.cuit ? profiles : contributorProfile}
        errorMessage={errorMessage}
        error={error}
        height={height}
        onClick={(event) => onClick(event)}
        onChange={onChange}
        value={val}
        disabled={disabled}
        placeholder={placeholder}
        color={colors.gray10}
        border={`1px solid ${colors.gray50}`}
        borderRadius={"4px"}
        accessor={"name"}
      />
    </Wrapper>
  );
};

export default Select;
