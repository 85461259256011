import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: ${colors.gray100};
  box-sizing: border-box;
  transition: all 0.4s;
  overflow-y: auto;
`;
