/* eslint-disable react/no-children-prop */
import React from "react";
import { Icon, Wrapper } from "./styled";

const ImageIcon = ({ show }) => {
  if (!show) return null;

  return <Wrapper children={<Icon />} />;
};

export default ImageIcon;
