import React from "react";
import LabelOnlyButton from "components/commons/Buttons/LabelOnlyButton";
import colors from "styles/colors";
import { ButtonContainer, LeftButton, RightButton, Wrapper } from "./style";

const Buttons = ({ apply, goBack }) => {

  return (
    <Wrapper>
      <ButtonContainer>
        <LeftButton>
          <LabelOnlyButton label={"Volver"} color={colors.blue50} background={colors.white} onClick={goBack} />
        </LeftButton>
        <RightButton>
          <LabelOnlyButton label={"Aplicar"} color={colors.white} background={colors.blue50} onClick={apply} />
        </RightButton>
      </ButtonContainer>
    </Wrapper>
  );
};

export default Buttons;
