/* eslint-disable no-unused-vars */
/* eslint-disable react/no-children-prop */
import React from "react";
import Options from "./components/Options";
import { Wrapper } from "./styled";
import BottomElements from "./components/BottomElements";
import Content from "./components/Content";
import colors from "styles/colors";

const BackOfficeHeader = ({ color=colors.blue0, title, description, fontSize="36px", showBack, element, id, setId, selection, route, filters }) => {
  return (
    <Wrapper>
      {/* <Options /> */}
      <Content element={element} showBack={showBack} title={title} description={description} route={route} fontSize={fontSize} color={color} />
      <BottomElements id={id} setId={setId} sections={selection} filters={filters} />
    </Wrapper>
  );
};

export default BackOfficeHeader;
