import React, { useState } from "react";
import colors from "styles/colors";
import { Wrapper } from "./styled";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "store/Modals/actions";
import { MODAL_SHARE_FILE } from "store/Modals/constants";
import { downloadReport, printReport } from "store/PaperWork/actions/requests";
import { selectPaperworkMetadata } from "store/PaperWork/selectors";
import LabelIconButton from "components/pages/PaperworkDetail/components/LabelIconButton";
import { PrinterIcon, ShareIcon, DownloadIcon } from "components/pages/PaperworkDetail/components/Detail/components/types/styled";
import SignedPdf from "./components/SignedPdf";

const ShareActions = () => {
  const dispatch = useDispatch();
  const { id } = useSelector(selectPaperworkMetadata);
  const [signedPdf, setSignedPdf] = useState(false);
  const download = () => dispatch(downloadReport(id, Number(signedPdf)));
  const print = () => dispatch(printReport(id));
  /* TODO: enable all options when the pdf is implemented for D1 procedures */

  return (
    <>
      <SignedPdf signed={signedPdf} onChange={() => setSignedPdf(!signedPdf)}/>
      <Wrapper>
        <LabelIconButton
          type="secondary"
          color={colors.blue50}
          background={colors.white}
          label={"Imprimir"}
          border={`1px solid ${colors.blue70}`}
          icon={<PrinterIcon />}
          onClick={print}
        />
        <LabelIconButton
          type="secondary"
          color={colors.blue50}
          background={colors.white}
          label={"Compartir"}
          border={`1px solid ${colors.blue70}`}
          icon={<ShareIcon />}
          onClick={() => dispatch(openModal(MODAL_SHARE_FILE))}
        />
        <LabelIconButton
          type="primary"
          color={colors.white}
          background={colors.blue50}
          label={"Descargar"}
          icon={<DownloadIcon />}
          onClick={download}
        />
      </Wrapper>
    </>
  );
};

export default ShareActions;
