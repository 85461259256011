/* eslint-disable react/no-children-prop */
import React from "react";
import { Wrapper, Title } from "../styled";
import { useSelector } from "react-redux";
import { selectPaperworkData, selectPaperworkProgressId, selectPaperworkStageProgress } from "store/PaperWork/selectors";
import { get } from "lodash";
import Image from "./components/Image";
import { selectToken } from "store/Login/selectors";
import * as paths from "components/pages/App/constants";

const CadastralBoundary = ({ selectedPlot }) => {
  const token = useSelector(selectToken);
  const pwId = useSelector(selectPaperworkProgressId);
  const data = useSelector(selectPaperworkData);
  const resource = get(data, `[${selectedPlot}].planData.cadastralBoundary`, "");
  const stageProgress = useSelector(selectPaperworkStageProgress);
  const unconstitutedLand = get(stageProgress, "[0].otherInputsData.certificateReason.unconstitutedLand", "");
  const fileName = get(stageProgress, "[0].otherInputsData.certificateReason.file[0]", "");

  const storage = get(stageProgress, "[0].otherInputsData.certificateReason.storage", {});
  const key = get(storage, `${fileName}`, "");
  const storageName = get(key, "storageName", "");

  const croquis = `${paths.PATH_BASE_URL}/v1/paperwork-progress/${pwId}/files/${paths.EXEMPTS_BUCKET_TEST}/${storageName}?token=${token}`;
  const finalResource = unconstitutedLand ? croquis : resource;

  return (
    <Wrapper>
      <Title children="Linderos catastral" />
      <Image resource={finalResource} />
    </Wrapper>
  );
};

export default CadastralBoundary;
