import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  align-items: top;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 80px;
  padding: 28px;
  box-sizing: border-box;
  border-bottom: 1px solid ${colors.gray100};
  @media (max-width: 1366px) {
    padding: 28px 10px;
  }
`;

export const Title = styled.div`
  font-family: "Domine";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: ${colors.blue40};
  @media (max-width: 1366px) {
    font-size: 18px;
    margin-bottom: 10px;
  }
`;

export const IconContainer = styled.div`
  height: 100%;
  position: absolute;
  right: 28px;
`;
