import React from "react";
import { useLocation } from "react-router-dom";
import BlockInfo from "./component/BlockInfo";
import { Wrapper } from "./style";
import { FILTER_TYPES } from "store/Filters/constants";

const Content = ({ filtersConstants }) => {
  const location = useLocation();
  const filters = filtersConstants.map((item) => {
    if (location.pathname === "/certificados" && item.title === "Detalles de trámite") {
      return {
        ...item,
        sections: item.sections.filter((section) => section.key !== FILTER_TYPES),
      };
    }
    return item;
  });

  const getBlockInfo = (block, index) => {
    return <BlockInfo block={block} index={index} filtersConstants={filtersConstants} />;
  };

  return (
    <Wrapper>
      {filters.map(getBlockInfo)}
    </Wrapper>
  );
};

export default Content;
