import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPaperworksStatisticsByState } from "store/PaperWork/actions/requests";
import { selectpaperworksCountStatistics } from "store/PaperWork/selectors";
import colors from "styles/colors";
import SmallCard from "./components/SmallCard";
import { getReformatData } from "./functions";
import { Wrapper } from "./styled";
import { PAPERWORK_STATE } from "store/PaperWork/constants";
import { FORMAT_DATE, MONTHS_TO_FILTER } from "store/Filters/constants";
import moment from "moment";

const TopPanel = () => {
  const dispatch = useDispatch();
  const today = moment();
  const dateTo = today.subtract(1, "months").format(FORMAT_DATE);
  const dateFrom = today.subtract(MONTHS_TO_FILTER - 1, "months").format(FORMAT_DATE);
  const { reviewHistory, observedHistory, paperworksIncome } = useSelector(selectpaperworksCountStatistics);

  useEffect(() => {
    dispatch(getPaperworksStatisticsByState(dateFrom, dateTo, "observedHistory", PAPERWORK_STATE.OBSERVED));
    dispatch(getPaperworksStatisticsByState(dateFrom, dateTo, "reviewHistory", PAPERWORK_STATE.PENDING));
  }, []);

  return (
    <Wrapper>
      <SmallCard
        background={colors.lightBlue100}
        color={colors.lightBlue50}
        data={getReformatData(reviewHistory)}
        detail={"Pendientes de aprobar"}
        inCome={paperworksIncome.review}
      />
      <SmallCard
        background={colors.lightBlue100}
        color={colors.lightBlue50}
        data={getReformatData(observedHistory)}
        detail={"Observados"}
        inCome={paperworksIncome.observed}
      />
    </Wrapper>
  );
};

export default TopPanel;
