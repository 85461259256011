import styled from "styled-components";
import { ReactComponent as File } from "images/icons/blue/file-upload.svg";

export const Wrapper = styled.div`
  display: flex;
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  cursor: pointer;
`;

export const Icon = styled(File)`
  & > path {
    fill: ${(props) => props.color};
  }
  display: flex;
  width: 100%;
  height: 100%;
`;
