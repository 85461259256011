/* eslint-disable react/no-children-prop */
import React, { useEffect, useState } from "react";
import { push } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Icon from "./components/Icon";
import { Wrapper, TitleSection } from "./styled";
import { selectPathEnabled } from "store/Login/selectors";

const GenericItem = ({ toogle, elem }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const currentSection = pathname.split("/")[1];
  const [selected, setSelected] = useState(false);
  const isEnabled = useSelector((state) => selectPathEnabled(state, elem.path));

  const onClick = () => dispatch(push(elem.path));

  useEffect(() => {
    if (elem.section === currentSection) setSelected(true);
    else setSelected(false);
  }, [pathname]);

  if (!isEnabled) return null;

  return (
    <Wrapper toogle={toogle} selected={selected} onClick={onClick}>
      <Icon elem={elem} toogle={toogle} selected={selected} />
      <TitleSection toogle={toogle} selected={selected} children={elem.name} />
    </Wrapper>
  );
};

export default GenericItem;
