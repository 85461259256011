import React from "react";
import { Wrapper, Text } from "./style";
import colors from "styles/colors";
import CopyIcon from "components/commons/Icons/CopyIcon";
import { useSelector } from "react-redux";
import { selectReportLink } from "store/PaperWork/selectors";
import IconAndLabelInput from "components/commons/Inputs/IconAndLabelInput";

const Link = () => {
  const link = useSelector(selectReportLink);
  const copyLink = () => navigator.clipboard.writeText(link);

  return (
    <Wrapper>
      <Text>Link del archivo</Text>
      <IconAndLabelInput
        showBorder
        value={link}
        disabled={true}
        left={false}
        background={colors.white}
        icon={<CopyIcon onClick={copyLink} />}
        height={"34px"}
      />
    </Wrapper>
  );
};

export default Link;
