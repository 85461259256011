/* eslint-disable react/no-children-prop */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Icon from "./components/Icon";
import { Wrapper, TitleSection } from "./styled";
import { internalSignOut, logout } from "store/Login/actions/actions";
import { selectUserUid } from "store/Login/selectors";

const Logout = ({ toogle }) => {
  const dispatch = useDispatch();
  const uid = useSelector(selectUserUid);
  const onClick = () => (uid ? dispatch(internalSignOut()) : dispatch(logout()));

  return (
    <Wrapper toogle={toogle} onClick={onClick}>
      <Icon />
      <TitleSection toogle={toogle} children={"Cerrar sesión"} />
    </Wrapper>
  );
};

export default Logout;
