import React from "react";
import CustomForm from "../CustomForm";
import MultipleCardSelector from "../MultipleCardSelector";
import ListOfCertificates from "../ListOfCertificates";

const Item = ({ item, search }) => {
  const { id } = item;
  switch (id) {
    case 1:
      return <MultipleCardSelector item={item} />;
    case 2:
      return <CustomForm item={item} />;
    case 3:
      return <ListOfCertificates item={item} search={search} />;
    default:
      return null;
  }
};

export default Item;
