import styled from "styled-components";
import { fadeIn } from "styles/effects";
import colors from "styles/colors";

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  overflow: hidden;
  animation: 0.9s ${fadeIn} forwards;
  background-color: ${colors.gray99};
  cursor: pointer;
  ${({ selected }) =>
    selected &&
    `
    & > * {
     & path {
      fill: ${colors.orange50};
     }
    }
    border-bottom: 4px solid ${colors.orange50};
  `}
`;
