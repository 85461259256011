import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 28px;
  align-items: center;
  justify-content: top;
  width: 100%;
  box-sizing: border-box;
  background: transparent;
  overflow: auto;
  @media (max-width: 980px) {
    min-height: 92%;
    padding-top: 0px;
    overflow: overlay;
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: top;
  justify-content: center;
  font-size: 60px;
  color: blue;
`;
