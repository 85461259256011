import React from "react";
import { Wrapper, ArrowBack } from "./styled";

const ArrowExpandIcon = ({ onClick }) => {
  return (
    <Wrapper onClick={onClick}>
      <ArrowBack />
    </Wrapper>
  );
};

export default ArrowExpandIcon;
