import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-height: 112px;
  flex: 1;
  overflow: hidden;
  gap: 20px;
  font-size: 60px;
  color: blue;
  flex-wrap: wrap;
  @media (max-width: 550px) {
    max-height: 244px;
  }
`;
