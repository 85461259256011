import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0px 29px;
  gap: 10px;
  box-sizing: border-box;
  & > * {
    &:nth-child(1) {
      & > * {
        &:nth-child(3) {
          justify-content: left;
          & > * {
            & > * {
              &:nth-child(1) {
                width: 5px;
                margin-left: 17px;
                @media (max-width: 386px) {
                  margin-left: 22px;
                }
              }
              &:nth-child(2) {
                @media (max-width: 386px) {
                  margin-left: 10px;
                }
              }
            }
          }
        }
      }
    }
    &:nth-child(2) {
      & > * {
        &:nth-child(3) {
          margin-left: 42px;
          @media (max-width: 430px) {
            margin-left: 46px;
          }
          @media (max-width: 393px) {
            margin-left: 40px;
          }
          @media (max-width: 386px) {
            margin-left: 42px;
          }
          & > * {
            & > * {
              &:nth-child(1) {
                width: 28px;
                @media (max-width: 430px) {
                  margin-left: 2px;
                }
                @media (max-width: 386px) {
                  width: 23px;
                }
              }
              &:nth-child(2) {
                @media (max-width: 386px) {
                  margin-left: 2px;
                }
              }
            }
          }
        }
      }
    }
    &:nth-child(3) {
      & > * {
        &:nth-child(3) {
          margin-left: 64px;
          @media (max-width: 430px) {
            margin-left: 56px;
          }
          @media (max-width: 393px) {
            margin-left: 58px;
          }
          @media (max-width: 386px) {
            margin-left: 58px;
          }
          & > * {
            & > * {
              &:nth-child(1) {
                width: 52px;
                @media (max-width: 386px) {
                  width: 36px;
                }
              }
              &:nth-child(2) {
                margin-left: 30px;
                @media (max-width: 386px) {
                  margin-left: 18px;
                }
              }
            }
          }
        }
      }
    }
    &:nth-child(4) {
      & > * {
        &:nth-child(3) {
          margin-left: 86px;
          @media (max-width: 430px) {
            margin-left: 73px;
          }
          @media (max-width: 393px) {
            margin-left: 78px;
          }
          @media (max-width: 386px) {
            margin-left: 73px;
          }
          & > * {
            & > * {
              &:nth-child(1) {
                width: 44px;
                @media (max-width: 386px) {
                  width: 29px;
                }
              }
              &:nth-child(2) {
                margin-left: 23px;
                @media (max-width: 386px) {
                  margin-left: 12px;
                }
              }
            }
          }
        }
      }
    }
    &:nth-child(5) {
      & > * {
        &:nth-child(3) {
          margin-left: 86px;
          @media (max-width: 430px) {
            margin-left: 72px;
          }
          @media (max-width: 393px) {
            margin-left: 78px;
          }
          @media (max-width: 386px) {
            margin-left: 73px;
          }
          & > * {
            & > * {
              &:nth-child(1) {
                width: 31px;
                @media (max-width: 386px) {
                  width: 22px;
                }
              }
              &:nth-child(2) {
                margin-left: 5px;
                @media (max-width: 386px) {
                  margin-left: 0px;
                }
              }
            }
          }
        }
      }
    }
    &:nth-child(6) {
      & > * {
        &:nth-child(3) {
          margin-left: 85px;
          @media (max-width: 430px) {
            margin-left: 74px;
          }
          @media (max-width: 393px) {
            margin-left: 78px;
          }
          @media (max-width: 386px) {
            margin-left: 52px;
          }
          & > * {
            & > * {
              &:nth-child(1) {
                width: 20px;
                @media (max-width: 386px) {
                  width: 8px;
                  margin-left: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 420px) {
    gap: 7px;
  }
`;
