import styled from "styled-components";
import colors from "styles/colors";
import { ReactComponent as Prov } from "images/logos/MARCA COMPLETA-03.svg";
import { ReactComponent as DGC } from "images/logos/logo-dgc.svg";
import { ReactComponent as DGCMobile } from "images/logos/catastro.svg";
import { ReactComponent as ProvMobile } from "images/logos/Group.svg";
import { ReactComponent as IlusStep1 } from "images/ilustrations/step_1.svg";
import { ReactComponent as IlusStep2 } from "images/ilustrations/step_2.svg";
import { ReactComponent as IlusUnverifiedAccount } from "images/ilustrations/unverified_account.svg";
import { ReactComponent as IlusNoProfile } from "images/ilustrations/no_profile.svg";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  width: 100%;
  height: 100%;
  padding-bottom: 44px;
  box-sizing: border-box;
  background: ${colors.gray100};
  overflow-y: overlay;
`;

export const Logos = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 95px;
  padding: 32px 200px 0px 200px;
  box-sizing: border-box;
  @media (max-width: 905px) or (max-height: 890px) {
    margin-bottom: 10px;
    padding: 0px 20px;
  }
  @media (max-width: 667px) {
    margin-bottom: 20px;
    padding: 16px 20px 0px 20px;
  }
`;

export const StepContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 24px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  width: 424px;
  margin-top: 75px;
  padding: 24px 24px 28px 24px;
  gap: 35px;
  box-sizing: border-box;
  border: 1px solid ${colors.gray90};
  border-radius: 16px;
  background: ${colors.white};
  @media (max-width: 500px) or (max-height: 890px) {
    border: none;
    padding: 20px;
  }
  @media (max-width: 500px) {
    width: 90%;
  }
`;

export const Title = styled.div`
  font-family: "Domine";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${colors.blue50};
  @media (max-width: 680px) {
    font-size: 24px;
  }
`;

export const Description = styled.div`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1px;
  color: ${colors.gray20};
  @media (max-width: 680px) {
    font-size: 16px;
  }
`;

export const IconProv = styled(Prov)``;

export const IconDGC = styled(DGC)``;

export const Step1 = styled(IlusStep1)`
  @media (max-width: 720px) {
    width: 90%;
  }
`;

export const Step2 = styled(IlusStep2)`
  @media (max-width: 720px) {
    width: 90%;
  }
`;

export const UnverifiedAccount = styled(IlusUnverifiedAccount)``;

export const NoProfile = styled(IlusNoProfile)``;

export const IconDGCMobile = styled(DGCMobile)``;

export const IconProvMobile = styled(ProvMobile)``;
