import React from "react";
import { PROFILE, SECTIONS } from "./functions";
import Wrapper from "./styled";
import GenericItem from "../GenericItem";
import Logout from "./components/Logout";

const Options = () => {
  const getIcons = (elem) => <GenericItem elem={elem} />;

  return (
    <Wrapper>
      {SECTIONS.map(getIcons)}
      <GenericItem elem={PROFILE} />
      <Logout />
    </Wrapper>
  );
};

export default Options;
