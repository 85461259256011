import styled from "styled-components";
import { ReactComponent as Printer } from "images/icons/blue/printer.svg";
import { ReactComponent as Share } from "images/icons/blue/share.svg";
import { ReactComponent as Download } from "images/icons/white/download.svg";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 248px;
  width: 100%;
  height: 100%;
  min-height: 848px;
  gap: 16px;
  box-sizing: border-box;
  @media (max-width: 980px) {
    grid-template-columns: 1fr;
    min-height: 100%;
    padding: 20px;
    overflow: scroll;
  }
`;

export const PrinterIcon = styled(Printer)`
  & > path {
    fill: ${({ disabled }) => (disabled ? colors.gray80 : "auto")};
  }
`;

export const ShareIcon = styled(Share)`
  & > path {
    fill: ${({ disabled }) => (disabled ? colors.gray80 : "auto")};
  }
`;

export const DownloadIcon = styled(Download)`
  & > path {
    fill: ${({ disabled }) => (disabled ? colors.gray80 : "auto")};
  }
`;
