import React from "react";
import AdvancedFilters from "./components/AdvancedFilters";
import Item from "./components/Item";
import { Wrapper, ItemsContainer } from "./styled";
import { DASHBOARD_FILTERS } from "components/commons/Modals/FiltersModal/constants";
import { useSelector } from "react-redux";
import { selectUserProfileId } from "store/Login/selectors";
import { getFiltersConstants } from "store/Filters/functions";

const Filters = ({ items, getPaperworkHistory }) => {
  const profileId = useSelector(selectUserProfileId);
  const getItem = (i) => <Item key={i.id} text={i.text} states={i.states} getPaperworkHistory={getPaperworkHistory} />;

  return (
    <Wrapper>
      <ItemsContainer>{items.map(getItem)}</ItemsContainer>
      {/* <Search /> */}
      <AdvancedFilters filtersConstants={getFiltersConstants(DASHBOARD_FILTERS, profileId)} getPaperworkHistory={getPaperworkHistory} />
    </Wrapper>
  );
};

export default Filters;
