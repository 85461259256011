import React from "react";
import InputFile from "./components/Input";
import FileList from "./components/FileList";
import { Wrapper } from "./styled";

const FileUpload = ({ inputRef, disabled, filenameList, onChange, removeFile, accept, sizeLimit }) => {
  return (
    <Wrapper>
      <InputFile inputRef={inputRef} disabled={disabled} onChange={onChange} accept={accept} files={filenameList} sizeLimit={sizeLimit} />
      <FileList files={filenameList} removeFile={removeFile} />
    </Wrapper>
  );
};

export default FileUpload;
