import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: top;
  position: relative;
  width: 100%;
  gap: 8px;
  box-sizing: border-box;
`;

export const Title = styled.div`
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.gray10};
  @media (max-width: 1366px) {
    font-size: 24px;
    margin-bottom: 10px;
  }
`;