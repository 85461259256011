/* eslint-disable react/no-children-prop */
import React from "react";
import { push } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux";
import { setStageIndex } from "store/PaperWork/actions/actions";
import { selectPaperworkType } from "store/PaperWork/selectors";
import { Wrapper, Title, Modify } from "./styled";

const Header = ({ title, index }) => {
  const dispatch = useDispatch();
  const paperworkType = useSelector(selectPaperworkType);

  const goToStage = () => {
    dispatch(setStageIndex(index));
    dispatch(push(`/tramites/${paperworkType}`));
  };

  return (
    <Wrapper>
      <Title>{title}</Title>
      <Modify onClick={goToStage} children="Modificar" />
    </Wrapper>
  );
};

export default Header;
