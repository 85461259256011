/* eslint-disable react/no-children-prop */
import React from "react";
import { Wrapper } from "./styled";

const IconButton = ({ background, onClick, disabled, hidden, border, icon }) => {
  if (hidden) return null;

  return <Wrapper background={background} onClick={onClick} disabled={disabled} border={border} children={icon} />;
};

export default IconButton;
