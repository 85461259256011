import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 32px;
  box-sizing: border-box;
  border-bottom: 1px solid ${colors.gray95};
`;

export const Text = styled.div`
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  box-sizing: border-box;
  color: ${colors.gray50};
`;
