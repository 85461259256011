import React from "react";
import { useSelector } from "react-redux";
import { selectPaperworkMetadata } from "store/PaperWork/selectors";
import { Text, Wrapper, State, CreatorData } from "./styled";
import get from "lodash/get";
import { PAPERWORK_STATE, PAPERWORK_STATE_NAME } from "store/PaperWork/constants";

const Header = () => {
  const metadata = useSelector(selectPaperworkMetadata);
  const id = get(metadata, "id", "");
  const state = get(metadata, "state", "");
  const finishDate = get(metadata, "finishDate", "-");
  const finishAuthor = get(metadata, "finishAuthor.name", "-");
  const stateName = get(PAPERWORK_STATE_NAME, state, "-");
  const isFinished = state === PAPERWORK_STATE.APPROVED || state === PAPERWORK_STATE.REJECTED;

  return (
    <Wrapper>
      <Text>{`Trámite #${id}. Estado:`}</Text>
      <State state={state}>{stateName} </State>
      { isFinished && <CreatorData>{`el ${finishDate} por ${finishAuthor}`}</CreatorData> }
    </Wrapper>
  );
};

export default Header;
