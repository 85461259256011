import styled from "styled-components";
import colors from "styles/colors";
import { ReactComponent as Observed } from "images/icons/gray/eye.svg";
import { ReactComponent as Pending } from "images/icons/gray/clock.svg";
import { ReactComponent as Finished } from "images/icons/gray/check-circle.svg";
import { ReactComponent as Rejected } from "images/icons/gray/close-circle.svg";
import { PAPERWORK_STATE } from "store/PaperWork/constants";

export const Item = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  width: 100%;
  height: 80px;
  padding: 18px 14px;
  gap: 8px;
  box-sizing: border-box;
  cursor: pointer;
  border: 1px solid ${colors.gray95};
  ${({ onClick }) => !onClick && "pointer-events: none"};
  background: ${colors.white};
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 1px solid ${colors.gray95};
  border-radius: 100px;
  background: ${({ state }) => {
    if (state === PAPERWORK_STATE.OBSERVED) return colors.blue99;
    if (state === PAPERWORK_STATE.REJECTED) return colors.red100;
    if (state === PAPERWORK_STATE.APPROVED) return colors.green100;
    return colors.blue99;
  }};
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.div`
  margin-right: auto;
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.gray50};
  margin-bottom: 4px;
`;

export const State = styled.div`
  font-family: "OpenSansRegular";
  margin-right: auto;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
  color: ${colors.blue50};
  @media (max-width: 680px) {
    font-size: 12px;
  }
`;

export const ObservedIcon = styled(Observed)`
  & > path {
    fill: ${colors.blue80};
  }
`;

export const PendingIcon = styled(Pending)`
  & > path {
    fill: ${colors.blue80};
  }
`;

export const FinishedIcon = styled(Finished)`
  & > path {
    fill: ${colors.green90};
  }
`;
export const Rejectedcon = styled(Rejected)`
  & > path {
    fill: ${colors.red90};
  }
`;
