import React from "react";
import { PAPERWORK_STATE } from "store/PaperWork/constants";
import { useSelector } from "react-redux";
import { selectPaperworkMetadata } from "store/PaperWork/selectors";
import get from "lodash/get";
import Share from "./components/Share";
import Commons from "./components/Commons";

const Actions = () => {
  const paperworkProgress = useSelector(selectPaperworkMetadata);
  const pwState = get(paperworkProgress, "state", null);
  const isFinished = pwState === PAPERWORK_STATE.APPROVED || pwState === PAPERWORK_STATE.REJECTED;

  if (isFinished) return <Share />;
  return <Commons />;
};

export default Actions;
