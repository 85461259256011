import { PAPERWORK_STATE } from "store/PaperWork/constants";
import styled from "styled-components";
import colors from "styles/colors";
import get from "lodash/get";

const THEME = {
  [PAPERWORK_STATE.STARTED]: {
    color: colors.gray50,
    borderColor: colors.gray80,
    background: colors.gray100,
    },
  [PAPERWORK_STATE.PENDING]: {
    color: colors.gray50,
    borderColor: colors.gray80,
    background: colors.gray100,
  },
  [PAPERWORK_STATE.OBSERVED]: {
    color: colors.orange50,
    borderColor: colors.orange90,
    background: colors.orange100,
  },
  [PAPERWORK_STATE.APPROVED]: {
    color: colors.green50,
    borderColor: colors.green80,
    background: colors.green100,
  },
  [PAPERWORK_STATE.REJECTED]: {
    color: colors.red50,
    borderColor: colors.red80,
    background: colors.red95,
  },
};

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 16px;
  justify-content: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 8px;
  width: 80px;
  height: 24px;
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: ${({theme}) => get(THEME, `${theme}.color`, THEME[PAPERWORK_STATE.PENDING].color)};
  border-radius: 43px;
  border: 1px solid ${({theme}) => get(THEME, `${theme}.borderColor`, THEME[PAPERWORK_STATE.PENDING].borderColor)};
  border-radius: 4px;
  background: ${({theme}) => get(THEME, `${theme}.background`, THEME[PAPERWORK_STATE.PENDING].background)};
`;
