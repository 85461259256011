import styled from "styled-components";
import colors from "styles/colors";
import { ReactComponent as Icon } from "images/icons/gray/arrow.svg";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
  min-height: 88px;
  padding: 22px 16px;
  gap: 16px;
  border: 1px solid ${colors.gray95};
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;
  background: ${colors.white};
  ${({ selected }) => selected && `background: ${colors.blue99}; border: 1px solid ${colors.blue50}`};
  ${({ disabled }) => disabled && `background: ${colors.white}; border: 3px solid ${colors.gray95}; pointer-events: none`};
  @media (max-width: 580px) {
    min-height: 68px;
    padding: 14px 16px;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
`;

export const Title = styled.div`
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: ${colors.gray0};
  ${({ selected }) => selected && `color: ${colors.blue50};`};
  ${({ disabled }) => disabled && `color: ${colors.gray0};`};
  @media (max-width: 580px) {
    font-size: 16px;
  }
`;

export const Description = styled.div`
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.gray50};
  ${({ selected }) => selected && `color: ${colors.blue50};`};
  ${({ disabled }) => disabled && `color: ${colors.gray50};`};
  @media (max-width: 580px) {
    font-size: 12px;
  }
`;

export const Arrow = styled(Icon)`
  display: ${(props) => (props.disabled ? "none" : "flex")};
  min-width: 24px;
  min-height: 24px;
  margin-left: auto;
  & > path {
    fill: ${colors.gray50};
  }
`;
