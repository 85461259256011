/* eslint-disable no-unused-vars */
import { DESK, MOBILE, SIZE_LIMIT_DESK, SIZE_LIMIT_TABLET, TABLET } from "components/commons/MainLayout/constants";
import React, { useEffect, useState } from "react";

const useResize = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const checkSize = () => {
    if (screenWidth <= SIZE_LIMIT_DESK && screenWidth > SIZE_LIMIT_TABLET) return TABLET;
    if (screenWidth <= SIZE_LIMIT_TABLET) return MOBILE;
    return DESK;
  };

  useEffect(() => {
    const changeWidth = () => setScreenWidth(window.innerWidth);

    window.addEventListener("resize", changeWidth);

    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, []);

  return { screenWidth, checkSize };
};

export default useResize;
