/* eslint-disable react/no-children-prop */
import React from "react";
import { useDispatch } from "react-redux";
import { downloadDocument } from "store/PaperWork/actions/requests";
import { Text, Wrapper } from "./styled";
import Tooltip from "components/commons/Tooltip";
import { DownloadIcon } from "../../styled";

const StorageCard = ({ url }) => {
  const dispatch = useDispatch();
  const name = url.substring(url.lastIndexOf("/") + 1).replaceAll("%", " ");
  const downLoadFile = () => dispatch(downloadDocument(url, name));

  return (
    <Wrapper onClick={downLoadFile}>
      <Tooltip title={name}>
        <Text children={name} />
      </Tooltip>
      <DownloadIcon />
    </Wrapper>
  );
};

export default StorageCard;
