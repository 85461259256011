import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: left;
  width: 100%;
  gap: 16px;
  box-sizing: border-box;
`;

export const Title = styled.div`
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: ${colors.blue60};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  & > * {
    :last-child {
      border: none;
    }
  }
`;
