/* eslint-disable react/no-children-prop */
import React, { useState } from "react";
import { Wrapper, Label } from "./styled";
import CommonInput from "components/commons/Inputs/CommonInput";
import colors from "styles/colors";
import useResize from "hooks/useResize";
import { emailPattern } from "utils/functions";

const EmailInput = ({ val, setNewUser, newUser, disabled, placeholder }) => {
  const { screenWidth } = useResize();
  const height = screenWidth <= 905 ? "40px" : "48px";

  const [error, setError] = useState(false);
  const errorMessage = error ? "Email inválido" : null;

  const onChange = (e) => {
    if (emailPattern(e.target.value) || !e.target.value) setError(false);
    else setError(true);
    setNewUser({ ...newUser, [e.target.name]: e.target.value });
  };

  return (
    <Wrapper>
      <Label children="Usuario" />
      <CommonInput
        name="email"
        errorMessage={errorMessage}
        error={error}
        height={height}
        onChange={onChange}
        value={val}
        disabled={disabled}
        placeholder={placeholder}
        color={colors.gray10}
        border={`1px solid ${colors.gray50}`}
        borderRadius={"4px"}
      />
    </Wrapper>
  );
};

export default EmailInput;
