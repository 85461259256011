import BackOfficeHeader from "components/commons/BackofficeHeader";
import React from "react";

const Header = () => {
  return (
    <BackOfficeHeader
      title="Administración"
      description="En esta sección tendrás la posibilidad de realizar varias acciones sobre los usuarios. Podrás visualizar los cambios a través de las variantes en los botones."
    />
  );
};

export default Header;
