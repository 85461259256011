/* eslint-disable no-unused-vars */
import { createTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core";
import colors from "styles/colors";

export const muiTheme = createTheme({
  props: {
    MuiTextField: {
      autoFocus: true,
      variant: "outlined",
      inputProps: {
        autoComplete: "off",
      },
    },
  },
  overrides: {
    MuiTextField: {
      root: {
        "& > *": {
          display: "flex",
          alignItems: "center",
          justifyContent: "right",
          paddingBottom: "0px",
          minWidth: "100px",
          minHeight: "62px",
          marginBottom: "15px",
        },
        "& label.Mui-focused": {
          color: `${colors.blue40}`,
        },
        // focused color for input with variant='standard'
        "& .MuiInput-underline:after": {
          borderBottomColor: `${colors.blue40}`,
        },
        // focused color for input with variant='filled'
        "& .MuiFilledInput-underline:after": {
          borderBottomColor: `${colors.blue40}`,
        },
        // focused color for input with variant='outlined'
        "& .MuiOutlinedInput-root": {
          "&.Mui-focused fieldset": {
            borderColor: `${colors.blue40}`,
          },
        },
      },
    },
    MuiInputLabel: {
      root: {
        display: "flex",
        marginTop: "-5px",
        "@media (max-height: 700px)": {
          fontSize: "12px",
          marginTop: "-10px",
        },
      },
    },
    MuiMenuItem: {
      root: {
        "@media (max-height: 700px)": {
          fontSize: "12px",
        },
      },
    },
  },
});

export const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    display: "flex",
    height: "100%",
    paddingTop: "10px",
  }),
}));
