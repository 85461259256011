
import { Wrapper } from "../styled";
import styled from "styled-components";
// import colors from "styles/colors";

export const DatesWrapper = styled(Wrapper)`
    gap: 16px;
    flex-wrap: wrap;
    flex-direction: row;
`;

export const Item = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;