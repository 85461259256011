import React from "react";
import { useSelector } from "react-redux";
import { selectPaperworkMetadata } from "store/PaperWork/selectors";
import Header from "./component/Header";
import { Content, Wrapper } from "./styled";
import { get, isEmpty, entries, values } from "lodash";
import Item from "./component/Item";

const StageInfo = ({ stage, index }) => {
  const { jsonSchemaData, otherInputsData, formFieldsSchema, stageTitle } = stage;
  const metadata = useSelector(selectPaperworkMetadata);
  const stageKey = get(metadata, `paperwork.stages[${index}].form.otherInputs[0].key`, 0);
  const oIItems = entries(otherInputsData).map((e) => e.pop());
  const jSItems = entries(jsonSchemaData);
  const schemaIsIncomplete = values(jsonSchemaData).every((e) => !e);

  const getCard = (data, i) => <Item key={`${data[0]}_${i}`} data={data} fields={formFieldsSchema} index={index} />;

  if ((schemaIsIncomplete || isEmpty(jsonSchemaData)) && isEmpty(otherInputsData[stageKey])) return null;

  return (
    <Wrapper>
      <Header title={stageTitle} />
      <Content>
        {jSItems.map(getCard)}
        {oIItems.map((e) => entries(e).map(getCard))}
      </Content>
    </Wrapper>
  );
};

export default StageInfo;
