import React from "react";
import { useSelector } from "react-redux";
import { Wrapper } from "./styled";
import { get } from "lodash";
import { selectPaperworkMetadata } from "store/PaperWork/selectors";
import { checkDependecies } from "../../functions";
import Option from "./components/Option";

const RadioOptions = ({ item, stageKey }) => {
  const { dependencies, options } = item;
  const paperwork = useSelector(selectPaperworkMetadata);
  const stage = get(paperwork, "stageProgress", {});
  const values = checkDependecies(stage, dependencies, null);

  const getOptions = (option) => <Option stageKey={stageKey} item={item} name={option} />;

  if (values.hidden) return null;

  return <Wrapper disabled={values.disabled}>{options.map(getOptions)}</Wrapper>;
};

export default RadioOptions;
