const token = localStorage.getItem("token") || "";
const refreshToken = localStorage.getItem("refreshToken") || "";
const validSince = localStorage.getItem("validSince") || 0;
const user = JSON.parse(localStorage.getItem("user")) || null;
const tokenExpires = parseInt(localStorage.getItem("tokenExpires") || 0);
import * as p from "components/pages/App/constants";

export const USER_PROFILE_ID = {
  CONTRIBUTOR: 1,
  ADMINISTRATOR: 2,
  OPERATOR: 3,
  CERTIFIER: 4,
  SUBSANATOR: 5,
  VALUATION_OPERATOR: 6,
  TERRITORIAL_INFO_OPERATOR: 7,
};

export const USER_PROFILE = {
  [USER_PROFILE_ID.CONTRIBUTOR]: "Contribuyente",
  [USER_PROFILE_ID.ADMINISTRATOR]: "Administrador",
  [USER_PROFILE_ID.OPERATOR]: "Operador",
  [USER_PROFILE_ID.CERTIFIER]: "Certificador",
  [USER_PROFILE_ID.SUBSANATOR]: "Subsanador",
  [USER_PROFILE_ID.VALUATION_OPERATOR]: "Operador valuaciones",
  [USER_PROFILE_ID.TERRITORIAL_INFO_OPERATOR]: "Operador información territorial",
};

export const initialState = {
  user: user,
  token: token,
  tokenExpires: tokenExpires,
  validSince: validSince,
  refreshToken: refreshToken,
  error: "",
  permissions: {
    [USER_PROFILE_ID.CONTRIBUTOR]: {
      defaultRoute: p.PATH_HOME,
      [p.PATH_PAPERWORK]: true,
      [p.PATH_HOME]: true,
      [p.PATH_PAPERWORK_GENERATED]: true,
      [p.PATH_FORM_PREVIEW]: true,
      [p.PATH_DASHBOARD]: false,
      [p.PATH_CERTIFICATE_REQUESTS]: false,
      [p.PATH_CERTIFICATE_DETAIL]: false,
      [p.PATH_PAPERWORK_DETAIL]: true,
      [p.PATH_PROFILE]: true,
      [p.PATH_CONTRIBUTOR_DETAIL]: true,
      [p.PATH_SUBSANATION_REQUESTS]: false,
    },
    [USER_PROFILE_ID.ADMINISTRATOR]: {
      defaultRoute: p.PATH_DASHBOARD,
      [p.PATH_PAPERWORK]: true,
      [p.PATH_HOME]: true,
      [p.PATH_PAPERWORK_GENERATED]: true,
      [p.PATH_FORM_PREVIEW]: true,
      [p.PATH_DASHBOARD]: true,
      [p.PATH_CERTIFICATE_REQUESTS]: true,
      [p.PATH_CERTIFICATE_DETAIL]: true,
      [p.PATH_PAPERWORK_DETAIL]: true,
      [p.PATH_PROFILE]: true,
      [p.PATH_CONTRIBUTOR_DETAIL]: true,
      [p.PATH_SUBSANATION_REQUESTS]: true,
      [p.PATH_BACK_OFFICE]: true,
    },
    [USER_PROFILE_ID.OPERATOR]: {
      defaultRoute: p.PATH_DASHBOARD,
      [p.PATH_PAPERWORK]: false,
      [p.PATH_HOME]: false,
      [p.PATH_PAPERWORK_GENERATED]: true,
      [p.PATH_FORM_PREVIEW]: true,
      [p.PATH_DASHBOARD]: true,
      [p.PATH_CERTIFICATE_REQUESTS]: false,
      [p.PATH_CERTIFICATE_DETAIL]: false,
      [p.PATH_PAPERWORK_DETAIL]: true,
      [p.PATH_PROFILE]: true,
      [p.PATH_SUBSANATION_REQUESTS]: false,
    },
    [USER_PROFILE_ID.CERTIFIER]: {
      defaultRoute: p.PATH_CERTIFICATE_REQUESTS,
      [p.PATH_PAPERWORK]: false,
      [p.PATH_HOME]: false,
      [p.PATH_PAPERWORK_GENERATED]: true,
      [p.PATH_FORM_PREVIEW]: true,
      [p.PATH_DASHBOARD]: true,
      [p.PATH_CERTIFICATE_REQUESTS]: true,
      [p.PATH_CERTIFICATE_DETAIL]: true,
      [p.PATH_PAPERWORK_DETAIL]: true,
      [p.PATH_PROFILE]: true,
      [p.PATH_SUBSANATION_REQUESTS]: false,
    },
    [USER_PROFILE_ID.SUBSANATOR]: {
      defaultRoute: p.PATH_SUBSANATION_REQUESTS,
      [p.PATH_PAPERWORK]: false,
      [p.PATH_HOME]: false,
      [p.PATH_PAPERWORK_GENERATED]: true,
      [p.PATH_FORM_PREVIEW]: true,
      [p.PATH_DASHBOARD]: false,
      [p.PATH_CERTIFICATE_REQUESTS]: false,
      [p.PATH_CERTIFICATE_DETAIL]: false,
      [p.PATH_PAPERWORK_DETAIL]: true,
      [p.PATH_PROFILE]: true,
      [p.PATH_SUBSANATION_REQUESTS]: true,
    },
    [USER_PROFILE_ID.VALUATION_OPERATOR]: {
      defaultRoute: p.PATH_DASHBOARD,
      [p.PATH_PAPERWORK]: false,
      [p.PATH_HOME]: false,
      [p.PATH_PAPERWORK_GENERATED]: true,
      [p.PATH_FORM_PREVIEW]: true,
      [p.PATH_DASHBOARD]: true,
      [p.PATH_CERTIFICATE_REQUESTS]: false,
      [p.PATH_CERTIFICATE_DETAIL]: false,
      [p.PATH_PAPERWORK_DETAIL]: true,
      [p.PATH_PROFILE]: true,
      [p.PATH_SUBSANATION_REQUESTS]: false,
    },
    [USER_PROFILE_ID.TERRITORIAL_INFO_OPERATOR]: {
      defaultRoute: p.PATH_DASHBOARD,
      [p.PATH_PAPERWORK]: false,
      [p.PATH_HOME]: false,
      [p.PATH_PAPERWORK_GENERATED]: true,
      [p.PATH_FORM_PREVIEW]: true,
      [p.PATH_DASHBOARD]: true,
      [p.PATH_CERTIFICATE_REQUESTS]: false,
      [p.PATH_CERTIFICATE_DETAIL]: false,
      [p.PATH_PAPERWORK_DETAIL]: true,
      [p.PATH_PROFILE]: true,
      [p.PATH_SUBSANATION_REQUESTS]: false,
    },
  },
};
