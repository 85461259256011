/* eslint-disable no-unused-vars */
import API from "services/api";
import { loadingStart, loadingStop } from "store/General/actions";
import { selectToken, selectUser } from "store/Login/selectors";
import { setUsers } from "./actions";
import { handlingUsers, handlingPendingUsers, handlingUpdateUser, handlingDeleteUser } from "./handlings";
import { failed } from "store/PaperWork/actions/functions";
import { selectUsers } from "../selectors";

export const getUsers = () => {
  return async (dispatch, getState) => {
    dispatch(loadingStart());
    try {
      const token = selectToken(getState());
      const response = await API.backOffice.getUsers(token);
      dispatch(setUsers(response.data.result));
      dispatch(loadingStop());
    } catch (e) {
      dispatch(failed(e));
    }
  };
};

export const getPendingUsers = () => {
  return async (dispatch, getState) => {
    dispatch(loadingStart());
    try {
      const token = selectToken(getState());
      const response = await API.backOffice.getPendingUsers(token);
      dispatch(setUsers(response.data.result));
      dispatch(loadingStop());
    } catch (e) {
      dispatch(failed(e));
    }
  };
};

export const enabledUser = (uid, currentState) => {
  return async (dispatch, getState) => {
    dispatch(loadingStart());
    try {
      const token = selectToken(getState());
      const response = await API.backOffice.getEnabledUser(uid, currentState, token);
      dispatch(handlingUsers(response));
    } catch (e) {
      dispatch(failed(e));
    }
  };
};

export const deleteUser = (uid) => {
  return async (dispatch, getState) => {
    dispatch(loadingStart());
    try {
      const token = selectToken(getState());
      const currentUser = selectUser(getState());
      const users = selectUsers(getState());
      const response = await API.backOffice.getDeleteUser(uid, token);
      dispatch(handlingDeleteUser(response, currentUser, users));
    } catch (e) {
      dispatch(failed(e));
    }
  };
};

export const declineUser = (uid) => {
  return async (dispatch, getState) => {
    dispatch(loadingStart());
    try {
      const token = selectToken(getState());
      const response = await API.backOffice.getDeleteUser(uid, token);
      dispatch(handlingPendingUsers(response));
    } catch (e) {
      dispatch(failed(e));
    }
  };
};

export const verificationEmail = (uid) => {
  return async (dispatch, getState) => {
    dispatch(loadingStart());
    try {
      const token = selectToken(getState());
      const response = await API.backOffice.getVerificationEmail(uid, token);
      dispatch(handlingUsers(response));
    } catch (e) {
      dispatch(failed(e));
    }
  };
};

export const addUser = (user) => {
  return async (dispatch, getState) => {
    dispatch(loadingStart());
    try {
      const token = selectToken(getState());
      const response = await API.backOffice.getAddUser(user, token);
      dispatch(handlingUsers(response));
    } catch (e) {
      dispatch(failed(e));
    }
  };
};

export const acceptUser = (uid, user) => {
  return async (dispatch, getState) => {
    dispatch(loadingStart());
    try {
      const token = selectToken(getState());
      const response = await API.backOffice.getupdateUser(uid, user, token);
      dispatch(handlingPendingUsers(response));
    } catch (e) {
      dispatch(failed(e));
    }
  };
};

export const updateUser = (uid, user) => {
  return async (dispatch, getState) => {
    dispatch(loadingStart());
    try {
      const token = selectToken(getState());
      const currentUser = selectUser(getState());
      const response = await API.backOffice.getupdateUser(uid, user, token);
      dispatch(handlingUpdateUser(response, currentUser, user));
    } catch (e) {
      dispatch(failed(e));
    }
  };
};
