import React from "react";
import { Date, Title, Wrapper } from "./styled";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { setPaperworkData, setStageData } from "store/PaperWork/actions/actions";
import { push } from "connected-react-router";
import { selectPaperworkStageProgress, selectPaperworkStructure, selectStageIndex } from "store/PaperWork/selectors";
import { get, cloneDeep } from "lodash";
import { OTHER_INPUTS } from "store/PaperWork/constants";

const Data = ({ paperwork, pProgId, departure, date }) => {
  const dispatch = useDispatch();
  const index = useSelector(selectStageIndex);
  const stageProgress = useSelector(selectPaperworkStageProgress);
  const structure = useSelector(selectPaperworkStructure);
  const otherInputsData = get(stageProgress, `[${index}].otherInputsData`, {});
  const stageKey = get(structure, `stages[${index}].form.otherInputs[0].key`, {});
  const detail = get(paperwork, "data", []);

  const addCertificates = (key, id) => {
    const dataCopy = cloneDeep(otherInputsData);
    if (!dataCopy[key]) dataCopy[key] = [];
    if (!dataCopy[key].includes(id)) dataCopy[key].push(id);
    dispatch(setStageData(OTHER_INPUTS, dataCopy));
  };

  const seeDetail = () => {
    // dispatch(getDocuments(pProgId));
    addCertificates(stageKey, pProgId);
    dispatch(setPaperworkData(detail));
    dispatch(push(`/tramites/detalle/${pProgId}`));
  };

  return (
    <Wrapper onClick={seeDetail}>
      <Title>{`Partida Nro. ${departure}`}</Title>
      <Date>{moment(date).format("DD/MM/YYYY")}</Date>
    </Wrapper>
  );
};

export default Data;
