import React from "react";
import { Wrapper, Text } from "./styled";

const LargeButton = ({ onClick, disabled, label, hidden, isLoading=false }) => {
  
  if (hidden) return null;
  return (
    <Wrapper onClick={onClick} disabled={disabled} isLoading={isLoading}>
      <Text>{label}</Text>
    </Wrapper>
  );
};

export default LargeButton;
