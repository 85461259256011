import styled from "styled-components";

export const Wrapper = styled.div`
  @media (max-width: 500px) {
    display: flex;
  }
`;

export const Text = styled.div`
  margin-top: 5px;
  margin-right: auto;
  font-family: "Domine";
  font-style: normal;
  font-weight: 400;
  font-size: ${(props) => props.fontSize};
  color: ${(props) => props.color};
  flex: 1;
  user-select: none;
  @media (max-width: 538px) {
    font-size: 24px;
  }
  @media (max-width: 980px) {
    margin-top: -1px;
    margin-left: 5px;
  }
`;
