import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  border: 1px solid ${colors.gray99};
  @media (max-width: 1180px) {
    gap: 2px;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  margin-bottom: auto;
  padding-right: 20px;
  flex-direction: column;
`;

export const ButtonContainer = styled.div`
  margin-left: auto;
  margin-bottom: auto;
`;

export const Label = styled.div`
  margin-bottom: 16px;
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: ${colors.gray0};
  ${({ children }) => !children && "display: none"};
  @media (max-width: 580px) {
    font-size: 16px;
  }
`;

export const Description = styled.div`
  display: flex;
  align-items: center;
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.gray20};
  ${({ label }) => !label && `font-size: 18px; color: ${colors.gray0}; line-height: 24px;`};
  @media (max-width: 580px) {
    font-size: 12px;
  }
`;
