import React, { useState } from "react";
import { useSelector } from "react-redux";
import CommonComboBox from "components/commons/ComboBox/CommonComboBox";
import { SubsanationArea, SubsanationText } from "./style";
import { selectSubsanationAreas } from "store/PaperWork/selectors";
import colors from "styles/colors";

const SubsanationOptions = ({ setArea }) => {
  const [areaName, setAreaName] = useState(null);
  const subsanationAreas = useSelector(selectSubsanationAreas);

  const onChange = (value) => {
    setArea(value.id);
    setAreaName(value.name);
  };

  return (
    <SubsanationArea>
      <SubsanationText>Para:</SubsanationText>
      <CommonComboBox
        height={"32px"}
        options={subsanationAreas}
        value={areaName}
        optionHeight={"32px"}
        onChange={onChange}
        accessor={"name"}
        background={colors.gray100}
        withBorder={false}
        textColor={colors.gray50}
      />
    </SubsanationArea>
  );
};

export default SubsanationOptions;
