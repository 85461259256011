/* eslint-disable react/no-children-prop */
import React from "react";
import { Wrapper, Title, Column, Text, Content } from "../styled";
import { useSelector } from "react-redux";
import { selectPaperworkData } from "store/PaperWork/selectors";
import { get } from "lodash";

const PlanData = ({ selectedPlot }) => {
  const data = useSelector(selectPaperworkData);
  const planData = get(data, `[${selectedPlot}].planData`, {});
  const surface = get(data, `[${selectedPlot}].planData.surface`, {});

  const type = get(planData, "type", "-");
  const number = get(planData, "number", "-");
  const TF = get(planData, "volumeFolio", "-");

  const accordingToPlan = get(surface, "accordingToPlan", "-");
  const accordingToTitle = get(surface, "accordingToTitle", "-");

  return (
    <Wrapper>
      <Content>
        <Column>
          <Title children="Datos de plano" />
          <Text children={`Tipo: ${type}`} />
          <Text children={`Número: ${number}`} />
          <Text children={`T/F: ${TF}`} />
        </Column>
        <Column>
          <Title children="Superficie" />
          <Text children={`Según plano: ${accordingToPlan}`} />
          <Text children={`Según título: ${accordingToTitle}`} />
        </Column>
      </Content>
    </Wrapper>
  );
};

export default PlanData;
