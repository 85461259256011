import React from "react";
import colors from "styles/colors";
import { ApprovedIcon, ObservedIcon, RejectIcon, Container } from "./styled";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "store/Modals/actions";
import { selectPaperworkMetadata, selectUserCanEditPaperworkProgress } from "store/PaperWork/selectors";
import { approvePaperwork } from "store/PaperWork/actions/requests";
import { MODAL_EDIT_OBSERVATION, MODAL_EDIT_REJECTION, MODAL_MEND } from "store/Modals/constants";
import { PAPERWORK_STATE } from "store/PaperWork/constants";
import LabelIconButton from "components/pages/PaperworkDetail/components/LabelIconButton";
import { USER_PROFILE_ID } from "store/Login/initialState";
import { selectIsAllowed } from "store/Login/selectors";
import Tooltip from "components/commons/Tooltip";
import get from "lodash/get";

const Administrator = () => {
  const dispatch = useDispatch();
  const allowedRoles = [USER_PROFILE_ID.ADMINISTRATOR];
  const allowed = useSelector((state) => selectIsAllowed(state, allowedRoles));
  const metadata = useSelector(selectPaperworkMetadata);
  const id = get(metadata, "id", null);
  const state = get(metadata, "state", null);
  const isPending = state === PAPERWORK_STATE.PENDING || state === PAPERWORK_STATE.REJECT_REQUESTED || state === PAPERWORK_STATE.APPROVE_REQUESTED;
  const isWaitingForMend = state === PAPERWORK_STATE.MENDING_REQUESTED;
  const canEdit = useSelector(selectUserCanEditPaperworkProgress);

  const approve = () => dispatch(approvePaperwork(id));
  const reject = () => dispatch(openModal(MODAL_EDIT_REJECTION));
  const sendToReview = () => dispatch(openModal(MODAL_EDIT_OBSERVATION));
  const mend = () => dispatch(openModal(MODAL_MEND));

  const buttonDisabled = (!isPending && !isWaitingForMend) || !canEdit;
  const hidden = isPending || isWaitingForMend;

  if (!allowed) return null;
  return (
    <Tooltip title={"Esperando respuesta\ndel área correspondiente"} hidden={hidden}>
      <Container>
        {!isWaitingForMend && (
          <>
            <LabelIconButton
              type={"secondary"}
              disabled={buttonDisabled}
              color={colors.blue50}
              background={colors.white}
              label={"Observar"}
              border={`1px solid ${colors.blue70}`}
              icon={<ObservedIcon disabled={buttonDisabled} />}
              onClick={sendToReview}
              width={"216px"}
              height={"40px"}
            />
            <LabelIconButton
              type={"secondary"}
              disabled={buttonDisabled}
              color={colors.blue50}
              background={colors.white}
              label={"Rechazar"}
              border={`1px solid ${colors.blue70}`}
              icon={<RejectIcon disabled={buttonDisabled} />}
              onClick={reject}
              width={"216px"}
              height={"40px"}
            />
            <LabelIconButton
              type={"primary"}
              disabled={buttonDisabled}
              color={colors.white}
              background={colors.blue50}
              label={"Aprobar"}
              icon={<ApprovedIcon disabled={buttonDisabled} />}
              onClick={approve}
              width={"216px"}
              height={"40px"}
            />
          </>
        )}
        {isWaitingForMend && (
          <LabelIconButton
            type={"primary"}
            disabled={!isWaitingForMend || !canEdit}
            color={colors.white}
            background={colors.blue50}
            label={"Subsanar"}
            icon={<ApprovedIcon disabled={!isWaitingForMend || !canEdit} />}
            onClick={mend}
            width={"216px"}
            height={"40px"}
          />
        )}
      </Container>
    </Tooltip>
  );
};

export default Administrator;
