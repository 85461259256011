import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
  max-height: 42px;
  padding-top: 8px;
  border-top: 1px solid ${colors.gray95};
  @media (max-width: 720px) {
    padding-bottom: 0px;
  }
  @media (max-width: 680px) {
    gap: 16px;
  }
`;

export const TabContainer = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  gap: 8px;
`;
