/* eslint-disable react/jsx-key */
import React from "react";
import { TBody } from "../../../../styled";
import Content from "./components/Content";

const Body = ({ getTableBodyProps, prepareRow, data, cellActions }) => {
  return (
    <TBody {...getTableBodyProps()}>
      <Content data={data} prepareRow={prepareRow} cellActions={cellActions} />
    </TBody>
  );
};

export default Body;
