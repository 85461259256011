import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 232px;
  gap: 8px;
  box-sizing: border-box;
  border: 1px dashed ${colors.gray400};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  background: ${colors.gray100};
  cursor: pointer;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 2px;
  cursor: pointer;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  gap: 8px;
  cursor: pointer;
`;

export const InputText = styled.label`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${colors.lightBlue50};
  cursor: pointer;
`;

export const DescriptionText = styled.label`
  user-select: none;
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${colors.gray40};
  @media (max-width: 1366px) {
    font-size: 10px;
  }
  cursor: pointer;
`;

export const ErrorText = styled.label`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 20px;
  color: ${colors.red50};
  margin-top: 10px;
  @media (max-width: 1366px) {
    font-size: 10px;
  }
  cursor: pointer;
`;

export const Input = styled.input`
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: ${(props) => props.height};
  padding: 14px 8px;
  outline: none;
  border-radius: 4px;
  border: 1px solid ${colors.gray70};
  box-sizing: border-box;
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.color};
  background: ${(props) => props.background};
  @media (max-width: 580px) {
    font-size: 11px;
  }
`;
