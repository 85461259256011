import React from "react";
import { Tooltip as MuiTooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import colors from "styles/colors";

const StyledTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: colors.gray40,
    borderRadius: "4px",
    fontFamily: "OpenSansRegular",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "10px",
    lineHeight: "16px",
    color: colors.white,
    boxShadow: theme.shadows[1],
    whiteSpace: "pre-wrap"
  },
  arrow: {
    color: colors.gray40,
  }
}))(MuiTooltip);

const Tooltip = ({title, children, placement="right", hidden=false}) => {
  if (hidden) return children;
  return (
    <StyledTooltip title={title} placement={placement} arrow>
      {children}
    </StyledTooltip>
  );
};

export default Tooltip;
