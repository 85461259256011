import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: top;
  position: relative;
  width: 100%;
  gap: 8px;
  box-sizing: border-box;
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: ${(props) => props.columns};
  position: relative;
  width: 100%;
  max-height: ${(props) => (props.toogle ? "auto" : "100px")};
  gap: 18px;
  color: ${colors.gray50};
  overflow: hidden;
  transition: max-height 0.25s ease-in;
  @media (max-width: 1180px) {
    gap: 16px;
  }
  @media (max-width: 1250px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`;

export const Text = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: auto;
  bottom: 0;
  cursor: pointer;
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${colors.lightBlue50};
  @media (max-width: 1366px) {
    font-size: 24px;
    margin-bottom: 10px;
  }
`;
