/*** Combine all reducers in this file and export the combined reducers ***/

import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import history from "utils/history";
import paperWorkReducer from "store/PaperWork/reducer";
import modalsReducer from "store/Modals/reducer";
import generalReducer from "store/General/reducer";
import FiltersReducer from "store/Filters/reducer";
import loginReducer from "store/Login/reducer";
import { websocketReducer } from "store/websocket/reducer";
import backOfficeReducer from "store/BackOffice/reducer";

/*** Merges the main reducer with the router state and dynamically injected reducers ***/

export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    router: connectRouter(history),
    general: generalReducer,
    paperwork: paperWorkReducer,
    modals: modalsReducer,
    filters: FiltersReducer,
    login: loginReducer,
    webSocket: websocketReducer,
    backOffice: backOfficeReducer,
    ...injectedReducers,
  });

  return rootReducer;
}
