import React from "react";
import Field from "./components/Field";
import Number from "./components/Number";
import { Wrapper } from "./style";
import Conector from "./components/Conector";

const Item = ({ numberData, fieldData, left }) => {
  return (
    <Wrapper>
      <Number data={numberData} />
      <Conector />
      <Field data={fieldData} left={left} />
    </Wrapper>
  );
};

export default Item;
