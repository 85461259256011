import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: top;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 10px;
  box-sizing: border-box;
  @media (max-width: 1080px) {
    width: 35%;
  }
  @media (max-width: 680px) {
    margin-right: auto;
    padding-top: 0px;
    width: 75%;
  }
`;
