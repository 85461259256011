import React from "react";
import { Data, Text, Wrapper } from "./styled";

const Item = ({ className, text, data }) => {
  return (
    <Wrapper className={className}>
      <Text>{text}</Text>
      <Data>{data}</Data>
    </Wrapper>
  );
};

export default Item;
