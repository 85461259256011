import React from "react";
import { PropTypes } from "prop-types";
import {
  Wrapper, Summary, Prev, Next, WrapperButton
} from "./styled";

const Button = ({
  icon, tooltip, onClick, disabled
}) => <WrapperButton type="button" title={ tooltip } onClick={ onClick } disabled={ disabled }>{ icon }</WrapperButton>;

function Pagination ({
  pageNumber, limit, total, onPrev, onNext
}) {
  const offset = pageNumber + 1;
  const isFirstPage = () => (offset === 1);
  const isLastPage = () => (limit * offset) >= total;
  const from = () => (total === 0 ? 0 : ((offset - 1) * limit) + 1);
  const to = () => (isLastPage() ? total : limit * offset);

  return (
    <Wrapper>
      <Summary>{ `${from()}-${to()} de ${total}` }</Summary>
      <Button disabled={ isFirstPage() } icon={ <Prev /> } tooltip='Próxima' onClick={ onPrev } />
      <Button disabled={ isLastPage() } icon={ <Next /> } tooltip='Previa' onClick={ onNext } />
    </Wrapper>
  );
}

Pagination.propTypes = {
  offset: PropTypes.number,
  limit: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  onPrev: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired
};

Pagination.defaultProps = {
  offset: 1,
};

export default Pagination;
