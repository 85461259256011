import React from "react";
import { Wrapper } from "./styled";
import { VictoryPie, VictoryLabel } from "victory";
import colors from "styles/colors";

const PieChart = ({ leftColor, rightColor, data, detail }) => {
  return (
    <Wrapper viewBox={"0 0 100 100"}>
      <VictoryPie
        data={data}
        standalone={false}
        width={100}
        height={100}
        padding={{ top: 0, bottom: 0, right: 0, left: 0 }}
        innerRadius={47}
        labelRadius={100}
        colorScale={[leftColor, rightColor]}
        animate={{ easing: "exp" }}
        padAngle={3}
      />
      <circle cx="50" cy="50" r="19" fill="white" filter="drop-shadow(0px 2px 1px rgba(190, 196, 210, 0.2))" />
      <VictoryLabel textAnchor="middle" verticalAnchor="middle" x={50} y={50} style={{ fontFamily: "OpenSansRegular", fontSize: 16, fill: colors.gray50 }} text={detail} />
    </Wrapper>
  );
};

export default PieChart;
