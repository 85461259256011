/* eslint-disable no-unused-vars */
import { createTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core";
import colors from "styles/colors";

export const muiTheme = createTheme({
  props: {
    MuiTextField: {
      autoFocus: true,
      variant: "outlined",
      inputProps: {
        autoComplete: "off",
      },
    },
  },
  overrides: {
    MuiTextField: {
      root: {
        "& > *": {
          display: "flex",
          width: "100%",
          height: "52px",
          autoComplete: "off",
          overflow: "hidden",
        },
        "& label.Mui-focused": {
          color: colors.orange50,
        },
        // focused color for input with variant="standard"
        "& .MuiInput-underline:after": {
          borderBottomColor: colors.orange50,
        },
        // focused color for input with variant="filled"
        "& .MuiFilledInput-underline:after": {
          borderBottomColor: colors.orange50,
        },
        // focused color for input with variant="outlined"
        "& .MuiOutlinedInput-root": {
          background: "white",
          "&.Mui-focused fieldset": {
            borderColor: colors.orange50,
          },
        },
      },
    },
    MuiInputLabel: {
      root: {
        display: "flex",
        fontFamily: "Domine",
        fontSize: "12px",
      },
    },
    MuiInputBase: {
      root: {
        fontFamily: "Domine",
        fontSize: "14px",
      },
    },
    MuiMenuItem: {
      root: {
        "@media (max-height: 700px)": {
          fontSize: "12px",
        },
      },
    },
  },
});

export const useStyles = makeStyles((theme) => ({
  root: () => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "top",
    width: "100%",
    height: "100%",
    boxSizing: "border-box",
  }),
}));
