import Img from "components/commons/Img";
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  width: 100%;
  margin-bottom: auto;
  margin-right: auto;
  gap: 24px;
  gap: ${(props) => (props.toogle ? "24px" : "31px")};
  box-sizing: border-box;
  transition: all 0.5s;
`;

export const Logo = styled(Img)`
  display: flex;
  align-items: top;
  justify-content: center;
  width: ${(props) => (props.toogle ? "32px" : "256px")};
  height: ${(props) => (props.toogle ? "36px" : "56px")};
  margin-left: ${(props) => (props.toogle ? "22px" : "16px")};
`;
