import { config } from "initFirebase";

/* eslint-disable no-undef */
export const PATH_FISCAL_KEY_LOGIN = "/login";
export const PATH_FISCAL_KEY_FIRST_LOGIN = "/login/datos";
export const PATH_FISCAL_KEY_LOGIN_VALIDATION = "/login/aprobacion";
export const PATH_SIGN_IN = "/login-catastro";
export const PATH_SIGN_UP = "/registrarse";
export const PATH_VALIDATION = "/validacion";
export const PATH_RESET_PASSWORD = "/restablecer-contraseña";
export const PATH_PROFILE = "/perfil";
export const PATH_HOME = "/tramites";
export const PATH_PAPERWORK = "/tramites/:type";
export const PATH_FORM_PREVIEW = "/tramites/previsualizacion/:id";
export const PATH_PAPERWORK_GENERATED = "/tramites/reporte/:id";
export const PATH_CONTRIBUTOR_DETAIL = "/tramites/detalle/:id";
export const PATH_DASHBOARD = "/administracion";
export const PATH_PAPERWORK_DETAIL = "/administracion/detalle/:id";
export const PATH_CERTIFICATE_REQUESTS = "/certificados";
export const PATH_CERTIFICATE_DETAIL = "/certificados/detalle/:id";
export const PATH_SUBSANATION_REQUESTS = "/subsanaciones";
export const PATH_BACK_OFFICE = "/usuarios";

/* PATHS apis */
export const BYPASS_LOGIN = process.env.REACT_APP_BYPASS_LOGIN === "true";
export const PATH_BASE_URL = process.env.REACT_APP_BASE_URL;
export const WEBSOCKET_BASE_URL = process.env.REACT_APP_WEBSOCKET_BASE_URL;
export const KEYCLOAK_BASE_URL = process.env.REACT_APP_AUTENTICAR_BASE_URL;
export const KEYCLOAK_SECRET = process.env.REACT_APP_AUTENTICAR_SECRET;
export const PATH_API_PAPERWORk = "/v1/paperworks/";
export const EXEMPTS_BUCKET_TEST = "exempts-bucket-test";

export const getPathNewPaperWList = (token) => {
  return `/v1/paperworks?token=${token}`;
};

export const getPathPaperWType = (paperwType, token) => {
  return `/v1/paperworks/${paperwType}?token=${token}`;
};

export const getPathPaperworkDetail = (pProgId, token) => {
  return `/v1/paperwork/${pProgId}/detail?token=${token}`;
};

export const getPathUnlockPaperwork = (pProgId, token) => {
  return `/v1/paperwork-progress/${pProgId}/unlock?token=${token}`;
};

export const getPathPaperworkHistory = (pageSize, pageNumber, token) => {
  return `/v1/paperworks-history?pageSize=${pageSize}&pageNumber=${pageNumber}&token=${token}`;
};

export const getPathSubsanations = (pageSize, pageNumber, token) => {
  return `/v1/subsanations?pageSize=${pageSize}&pageNumber=${pageNumber}&token=${token}`;
};

export const getPathSaveStage = (paperwType, stageId, pProgId, token) => {
  return `/v1/paperworks/${paperwType}/stages/${stageId}?paperworkProgressId=${pProgId}&token=${token}`;
};

export const getPathEmptyStage = (paperwType, stageId, pProgId, token) => {
  return `/v1/empty-stage/${paperwType}/stages/${stageId}?paperworkProgressId=${pProgId}&token=${token}`;
};

export const getPathSendToReview = (paperwType, pProgId, token) => {
  return `/v1/paperworks/${paperwType}?paperworkProgressId=${pProgId}&token=${token}`;
};

export const getPathObservePaperwork = (pProgId, token) => {
  return `/v1/paperwork-progress/${pProgId}/observe?token=${token}`;
};

export const getPathApprovePaperwork = (pProgId, token) => {
  return `/v1/paperwork-progress/${pProgId}/approve?token=${token}`;
};

export const getPathMendPaperwork = (pProgId, token) => {
  return `/v1/paperwork-progress/${pProgId}/mend?token=${token}`;
};

export const getPathRequestApprovePaperwork = (pProgId, token) => {
  return `/v1/paperwork-progress/${pProgId}/requestApprove?token=${token}`;
};

export const getPathRequestMendingPaperwork = (pProgId, token) => {
  return `/v1/paperwork-progress/${pProgId}/requestMending?token=${token}`;
};

export const getPathRejectPaperwork = (pProgId, token) => {
  return `/v1/paperwork-progress/${pProgId}/reject?token=${token}`;
};

export const getPathRequestRejectPaperwork = (pProgId, token) => {
  return `/v1/paperwork-progress/${pProgId}/requestReject?token=${token}`;
};

export const getPathDownloadReport = (pProgId, token, signed) => {
  return `/v1/paperwork-progress/${pProgId}/report?token=${token}&sign=${signed}`;
};

export const getPathShareReport = (pProgId, token) => {
  return `/v1/paperwork-progress/${pProgId}/share?token=${token}`;
};

export const getPathPaperworksStatistics = (dateFrom, dateTo, token) => {
  return `/v1/paperwork-statistics?dateFrom=${dateFrom}&dateTo=${dateTo}&token=${token}`;
};

export const getPathPaperworksStatisticsByState = (state, dateFrom, dateTo, token) => {
  return `/v1/paperwork-statistics/${state}?dateFrom=${dateFrom}&dateTo=${dateTo}&token=${token}`;
};

export const getPathPaperworksStatisticsIncome = (dateFrom, dateTo, token) => {
  return `/v1/paperwork-statistics/income?dateFrom=${dateFrom}&dateTo=${dateTo}&token=${token}`;
};

export const getPathPaperworksStatisticsApproved = (dateFrom, dateTo, token) => {
  return `/v1/paperwork-statistics/approved?dateFrom=${dateFrom}&dateTo=${dateTo}&token=${token}`;
};

export const getPathDocuments = (pProgId, token) => {
  return `/v1/paperwork-progress/${pProgId}/files?token=${token}`;
};

export const getPathDownloadDocument = (url, token) => {
  return `${url}?token=${token}`;
};

export const getPathUpdateUserInfo = (token) => {
  return `/v1/user/profile?token=${token}`;
};

export const getPathUpdateUserPicture = (token) => {
  return `/v1/user/profile/picture?token=${token}`;
};

export const getPathUserPicture = (token) => {
  return `${PATH_BASE_URL}/v1/user/profile/picture?token=${token}`;
};

export const getInternalSignIn = (idToken) => {
  return `/v1/internal-login?idToken=${idToken}`;
};

export const getSubsanationAreas = (token) => {
  return `/v1/subsanations/areas?token=${token}`;
};

export const getEnabledUser = (uid, currentState, token) => {
  return `/v1/backoffice/users/${uid}/enabled?enabled=${currentState}&token=${token}`;
};

export const getUsers = (token) => {
  return `/v1/backoffice/users?token=${token}`;
};

export const getDeleteUser = (uid, token) => {
  return `/v1/backoffice/users/${uid}?token=${token}`;
};

export const getVerificationEmail = (uid, token) => {
  return `/v1/backoffice/users/${uid}/verificationEmail?token=${token}`;
};

export const getAddUser = (token) => {
  return `/v1/backoffice/users?token=${token}`;
};

export const getUpdateUser = (uid, token) => {
  return `/v1/backoffice/users/${uid}?token=${token}`;
};

export const getPendingUsers = (token) => {
  return `/v1/backoffice/users/pending?token=${token}`;
};

export const getResetPassword = (email) => {
  return `/v1/backoffice/users/${email}/resetPassword`;
};

export const getIdToken = () => {
  return `https://securetoken.googleapis.com/v1/token?key=${config.apiKey}`;
};
