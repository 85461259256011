/* eslint-disable no-irregular-whitespace */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formIsComplete, getValueRef } from "store/PaperWork/actions/functions";
import { selectPaperworkStructure, selectPaperworkMetadata, selectStageIndex, selectPaperworkStageProgress } from "store/PaperWork/selectors";
import Container from "./components/Container";
import { Wrapper } from "./styled";
import { get, isEmpty } from "lodash";
import { setStageStructure } from "store/PaperWork/actions/actions";
import { OTHER_INPUTS_STRUCTURE } from "store/PaperWork/constants";
import { updateData } from "./functions";

const CustomForm = ({ item }) => {
  const dispatch = useDispatch();
  const { required, key, formFields } = item;
  const index = useSelector(selectStageIndex);
  const paperwork = useSelector(selectPaperworkStructure);
  const stage = useSelector(selectPaperworkStructure);
  const metadata = useSelector(selectPaperworkMetadata);
  const stageP = useSelector(selectPaperworkStageProgress);
  const data = get(metadata, `stageProgress[${index}].otherInputsData[${key}]`, null);
  const dependencies = get(paperwork, `stages.[${index}].form.dependencies`, []);
  const dependency = dependencies.find((e) => e.posibilities.every((p) => p.value === getValueRef(p, stageP)));
  const dependencyRequired = get(dependency, "required", required);
  const dependencyFields = get(dependency, "formFields", formFields);
  const otherInputs = get(paperwork, `stages[${index}].form.otherInputs`, []);
  const updateOtherInputs = updateData(otherInputs, dependencyRequired, dependencyFields);

  useEffect(() => dispatch(formIsComplete(data, dependencyRequired)), [stage, metadata, index]);

  useEffect(() => {
    //If there is a dependency, the values ​​indicated by it are set
    if (!isEmpty(dependency)) dispatch(setStageStructure(index, OTHER_INPUTS_STRUCTURE, updateOtherInputs));
  }, [dependency]);

  return (
    <Wrapper>
      <Container item={item} />
    </Wrapper>
  );
};

export default CustomForm;
