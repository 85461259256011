import React from "react";
import { VictoryBar } from "victory";

const BarChart = ({ color, data }) => {
  return (
    <VictoryBar
      domainPadding={{ x: 0 }}
      data={data}
      barWidth={14}
      style={{ data: { fill: color }}}
      animate={{
        onExit: {
          duration: 200,
          before: () => ({
            _y: 0,
            fill: "orange",
            label: "BYE",
          }),
        },
      }}
    />
  );
};

export default BarChart;
