/* eslint-disable react/no-children-prop */
import React from "react";
import { Icon, Wrapper, Img } from "./styled";

const Image = ({ resource }) => {
  if (!resource) return <Wrapper children={<Icon />} />;
  return <Img src={resource} />;
};

export default Image;
