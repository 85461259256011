import React from "react";
import Structure from "./components/Structure";
import { Wrapper } from "./styled";

const Table = ({ columns, children, cellActions, data }) => {
  return (
    <Wrapper>
      <Structure columns={columns} data={data} cellActions={cellActions}>
        {children}
      </Structure>
    </Wrapper>
  );
};

export default Table;
