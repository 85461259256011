/* eslint-disable no-unused-vars */
import React from "react";
import { Wrapper, Icon } from "./styled";
import colors from "styles/colors";

const FileUploadIcon = ({ onClick, size = "24px" , color = colors.lightBlue50}) => {
  return (
    <Wrapper onClick={onClick} size={size} >
      <Icon color={color}/>
    </Wrapper>
  );
};

export default FileUploadIcon;
