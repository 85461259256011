import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  @media (max-width: 360px) {
    height: 28px;
  }
`;

export const Line = styled.div`
  display: flex;
  align-items: center;
  width: 1px;
  height: 100%;
  background: ${colors.gray60};
  transition: all 0.4s;
  box-sizing: border-box;
`;
