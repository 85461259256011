import React, { useEffect, useMemo } from "react";
import { getColumns } from "./resources";
import { TableContainer } from "components/pages/BackOffice/styled";
import { useDispatch, useSelector } from "react-redux";
import { getUsers } from "store/BackOffice/actions/requests";
import { selectUsers } from "store/BackOffice/selectors";
import Tools from "../../../Tools";
import Table from "components/commons/Table";

const Users = () => {
  const dispatch = useDispatch();
  const data = useSelector(selectUsers);
  const columns = useMemo(() => getColumns(), []);

  useEffect(() => dispatch(getUsers()), []);

  return (
    <TableContainer>
      <Table columns={columns} data={data} cellActions={true}>
        <Tools />
      </Table>
    </TableContainer>
  );
};

export default Users;
