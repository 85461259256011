import React from "react";
import { Approve, Wrapper } from "./styled";
import { MODAL_EDIT_USER } from "store/Modals/constants";
import { useDispatch } from "react-redux";
import { openModal, setParams } from "store/Modals/actions";

const ApproveCell = ({ row }) => {
  const dispatch = useDispatch();

  const onClick = (event) => {
    dispatch(setParams(row.original));
    dispatch(openModal(MODAL_EDIT_USER));
    event.stopPropagation();
  };

  return (
    <Wrapper>
      <Approve onClick={onClick} />
    </Wrapper>
  );
};

export default ApproveCell;
