import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 8px;
  cursor: pointer;
  transition: all 0.4s;
  background: ${(props) => props.background};
  &:hover {
    opacity: 0.8;
  }
  ${({ disabled }) =>
    disabled &&
    `
    pointer-events: none;
    opacity: 0.4;
  `}
  box-sizing: border-box;
  border: ${(props) => props.border};
  border-radius: 4px;
`;
