import styled from "styled-components";
import colors from "styles/colors";
import { fadeIn } from "styles/effects";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  min-height: 75px;
  padding-inline: 22px;
  gap: 4px;
  overflow: hidden;
  animation: 0.9s ${fadeIn} forwards;
  background-color: ${colors.gray99};
`;

export default Wrapper;
