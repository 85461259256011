import React from "react";
import { Line, Title, Wrapper } from "./styled";

const Header = ({ title }) => {
  return (
    <Wrapper>
      <Title>{title}</Title>
      <Line />
    </Wrapper>
  );
};

export default Header;
