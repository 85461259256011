import React from "react";
import { Wrapper } from "./style";
import Media from "./components/Media";
import Link from "./components/Link";

const Content = () => {
  return (
    <Wrapper>
      <Media />
      <Link />
    </Wrapper>
  );
};

export default Content;
