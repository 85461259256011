/* eslint-disable react/react-in-jsx-scope */
import { PATH_DASHBOARD, PATH_HOME, PATH_CERTIFICATE_REQUESTS, PATH_SUBSANATION_REQUESTS, PATH_BACK_OFFICE } from "components/pages/App/constants";
import { PaperworkIcon, DashboardIcon, UserIcon } from "./Bar/styled";

export const SECTIONS = [
  { name: "Trámites", Component: <PaperworkIcon key={"paperworks"} />, section: "tramites", path: PATH_HOME },
  { name: "Administración", Component: <DashboardIcon key={"dashboard_paperwork"} />, section: "administracion", path: PATH_DASHBOARD },
  { name: "Certificados", Component: <DashboardIcon key={"certificates"} />, section: "certificados", path: PATH_CERTIFICATE_REQUESTS },
  { name: "Subsanaciones", Component: <DashboardIcon key={"subsanations"} />, section: "subsanaciones", path: PATH_SUBSANATION_REQUESTS },
  { name: "Usuarios", Component: <UserIcon key={"usuarios"} />, section: "usuarios", path: PATH_BACK_OFFICE },
];

export const OPTIONS = [];
