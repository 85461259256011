/* eslint-disable quotes */
import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`;

export const Input = styled.input`
  min-height: ${(props) => props.height};
  width: 100%;
  border: ${(props) => (props.border ? props.border : `1px solid ${colors.gray90}`)};
  outline: none;
  padding: 8px;
  box-sizing: border-box;
  transition: 0.3s;
  padding-left: ${(props) => (props.left ? "50px" : "16px")};
  padding-right: ${(props) => (props.left ? "0px" : "40px")};
  border-radius: ${(props) => (props.borderRadius ? props.borderRadius : "4px")};
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-overflow: ellipsis;
  background: ${(props) => props.background};
  color: ${colors.gray10};
  ${({ error }) => error && `border: 1px solid ${colors.red50};`}

  &:focus {
    border-color: ${(props) => (props.error ? colors.red50 : colors.lightBlue50)};
  }
  :focus + .left-icon {
    svg {
      fill: dodgerBlue;
    }
  }
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${colors.gray90};
  }
`;

export const Icon = styled.div`
  position: absolute;
  left: ${(props) => (props.left ? "5px" : "none")};
  right: ${(props) => (props.left ? "none" : "10px")};
  top: 50%;
  transform: translateY(-50%);
  svg {
    fill: black;
    transition: 0.3s;
  }
`;

export const Label = styled.div`
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  color: ${colors.red50};
  @media (max-width: 1366px) {
    font-size: 12px;
  }
`;
