import React from "react";
import { Wrapper, LeftButton, RigthButton } from "./styled";
import LabelOnlyButton from "components/commons/Buttons/LabelOnlyButton";
import colors from "styles/colors";

const OptionButtons = ({ leftAction, rightAction, leftText, rightText, leftDisb, rigthDisb }) => {
  return (
    <Wrapper>
      <LeftButton>
        <LabelOnlyButton
          hidden={leftDisb}
          onClick={() => leftAction()}
          label={leftText}
          color={colors.blue50}
          background={colors.white}
          fontSize={"16px"}
          disabled={leftDisb}
          border={`1px solid ${colors.blue70}`}
        />
      </LeftButton>
      <RigthButton>
        <LabelOnlyButton
          onClick={() => rightAction()}
          label={rightText}
          color={colors.white}
          background={colors.blue50}
          fontSize={"16px"}
          disabled={rigthDisb}
        />
      </RigthButton>
    </Wrapper>
  );
};

export default OptionButtons;
