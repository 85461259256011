import Img from "components/commons/Img";
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Image = styled(Img)`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: ${({ size }) => size};
  max-width: ${({ size }) => size};
  min-height: ${({ size }) => size};
  max-height: ${({ size }) => size};
  border-radius: 100px;
  overflow: hidden;
`;
