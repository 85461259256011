import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
  padding: 4px 8px;
  gap: 4px;
  box-sizing: border-box;
  border-radius: 4px;
  background: ${colors.gray100};
`;

export const Text = styled.a`
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.gray40};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
  @media (max-width: 1366px) {
    font-size: 10px;
  }
`;
