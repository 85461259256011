import React from "react";
import { useDispatch } from "react-redux";
import { getPaperworkByType } from "store/PaperWork/actions/requests";
import { Wrapper, Text, Info, Draft, Arrow } from "./styled";

const Card = ({ isDraft, text, type }) => {
  const dispatch = useDispatch();
  const getPaperwork = () => dispatch(getPaperworkByType(type));

  return (
    <Wrapper onClick={getPaperwork}>
      <Info>
        <Text>{text}</Text>
        {isDraft && <Draft> Borrador </Draft>}
      </Info>
      <Arrow />
    </Wrapper>
  );
};

export default Card;
