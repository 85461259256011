import React, { useState } from "react";
import CheckBox from "../CheckBox";
import Button from "./components/Button";
import { Content, Wrapper } from "./style";

const MultipleSelector = ({ section, seeMore }) => {
  const { elements, columns } = section;
  const [toogle, setToogle] = useState(false);

  const getComponent = (element) => <CheckBox name={element.name} section={section} elmKey={element.key} />;

  return (
    <Wrapper>
      <Content columns={columns} toogle={toogle}>
        {elements.map(getComponent)}
      </Content>
      <Button isActive={toogle} show={seeMore} onClick={() => setToogle(!toogle)} />
    </Wrapper>
  );
};

export default MultipleSelector;
