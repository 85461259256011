import React from "react";
import Content from "./components/Content";
import { Wrapper } from "./styled";
import Header from "./components/Header";

const UserInfo = () => {
  return (
    <Wrapper>
      <Header title={"Datos del solicitante"} />
      <Content />
    </Wrapper>
  );
};

export default UserInfo;
