/* eslint-disable no-unused-vars */
/* eslint-disable react/no-children-prop */
import React, { useState } from "react";
import { ButtonContainer, Error, ForgotPass, Icon, InputContainer, Line, Wrapper } from "./styled";
import LabelOnlyButton from "components/commons/Buttons/LabelOnlyButton";
import colors from "styles/colors";
import LabelIconButton from "components/pages/PaperworkDetail/components/LabelIconButton";
import { useDispatch, useSelector } from "react-redux";
import { firebaseSignIn, firebaseSignInWithGoogle } from "store/Login/actions/actions";
import { emailPattern } from "utils/functions";
import EmailInput from "./components/EmailInput";
import PasswordInput from "./components/PaswordInput";
import { push } from "connected-react-router";
import { PATH_RESET_PASSWORD } from "components/pages/App/constants";
import { selectLoginError } from "store/Login/selectors";
import useKeyPress from "hooks/useKeyPress";

const Elements = () => {
  const dispatch = useDispatch();
  const [user, setUser] = useState({ email: "", password: "" });
  const errorMessage = useSelector(selectLoginError);
  const goToResetPassword = () => dispatch(push(PATH_RESET_PASSWORD));
  const signIn = () => dispatch(firebaseSignIn(user.email, user.password));
  const signInWithGoogle = () => dispatch(firebaseSignInWithGoogle(user.email, user.password));

  useKeyPress(() => {
    if (/^.{6,}$/.test(user.password) && emailPattern(user.email)) signIn();
  }, ["Enter"]);

  return (
    <Wrapper>
      <InputContainer>
        <EmailInput newUser={user} setNewUser={setUser} />
        <PasswordInput newUser={user} setNewUser={setUser} />
      </InputContainer>
      <ForgotPass children="Olvidé mi contraseña" onClick={goToResetPassword} />
      <ButtonContainer>
        <LabelOnlyButton
          disabled={!/^.{6,}$/.test(user.password) || !emailPattern(user.email)}
          label="Iniciar sesión"
          background={colors.blue50}
          color={colors.white}
          onClick={signIn}
        />
      </ButtonContainer>
      <Line children="O ingresá con" />
      <ButtonContainer>
        <LabelIconButton
          label="Iniciar sesión con Google"
          background={colors.white}
          color={colors.blue50}
          border={`1px solid ${colors.blue50}`}
          icon={<Icon />}
          onClick={signInWithGoogle}
        />
      </ButtonContainer>
      <Error>{errorMessage}</Error>
    </Wrapper>
  );
};

export default Elements;
