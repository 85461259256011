import React from "react";
import { Wrapper, Title, IconContainer } from "./style";
import CloseIcon from "components/commons/Icons/CloseIcon";
import { GRAY } from "styles/constants";

const Header = ({ goBack }) => {

  return (
    <Wrapper>
      <Title>Filtros avanzados</Title>
      <IconContainer>
        <CloseIcon color={GRAY} onClick={goBack} />
      </IconContainer>
    </Wrapper>
  );
};

export default Header;
