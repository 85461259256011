export const initialState = {
  stageIndex: 0,
  stageCompleted: false,
  formErrors: [],
  pageSize: 50,
  totalCount: 0,
  actualPage: 0,
  list: [],
  newPaperworksList: [],
  apiDocuments: {},
  storageDocuments: [],
  reportShareLink: "",
  currentRejectionReason: "",
  currentMendingRequestReason: "",
  currentMendingRequestArea: "",
  subsanationAreas: [],
  image: null,
  modifyMode: false,
  detail: {
    data: {},
    metadata: {},
    structure: {},
  },
  charts: {
    paperworksCount: {},
    paperworksIncome: {},
    approvedPaperworks: {},
    observedHistory: [],
    reviewHistory: [],
  },
  seeMorePaperwork: {},
};
