import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: left;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid ${colors.gray99};
`;

export const Header = styled.div`
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: ${colors.blue60};
`;

export const Text = styled.div`
  padding: 20px 0px;
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
  gap: 8px;
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: ${colors.gray10};
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;