import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
  width: 100%;
  min-height: 80px;
  padding: 24px 24px;
  box-sizing: border-box;
  overflow: hidden;
  border-bottom: 1px solid ${colors.gray99};
  @media (max-width: 980px) {
    width: 100%;
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-family: "Domine";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  color: ${colors.blue50};
  text-align: center;
  @media (max-width: 980px) {
    font-size: 18px;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  box-sizing: border-box;
`;
