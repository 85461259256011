import React, { useState } from "react";
import { Wrapper, Container } from "./style";
import Header from "../components/Header";
import Content from "./components/Content";
import { MODAL_SHOW_MEND } from "store/Modals/constants";

const ShowMendModal = () => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Wrapper>
      <Container expanded={expanded}>
        <Header expanded={expanded} setExpanded={setExpanded} modal={MODAL_SHOW_MEND} title={"Subsanar"} />
        <Content expanded={expanded} />
      </Container>
    </Wrapper>
  );
};

export default ShowMendModal;
