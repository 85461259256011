import React from "react";
import { Container, Wrapper, Logos, IconProv, IconDGC, Image, Step, Content, StepContainer } from "./styled";
import Header from "./components/Header";
import Elements from "./components/Elements";

const Form = () => {
  return (
    <Wrapper>
      <Logos>
        <IconProv />
        <IconDGC />
      </Logos>
      <Content>
        <StepContainer>
          <Step />
        </StepContainer>
        <Container>
          <Header />
          <Elements />
        </Container>
      </Content>
      <Image />
    </Wrapper>
  );
};

export default Form;
