/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addMultipleOption } from "store/PaperWork/actions/functions";
import { selectPaperworkStructure, selectPaperworkMetadata, selectStageIndex } from "store/PaperWork/selectors";
import { Wrapper, Name, Image, ImgContainer, ExpanderContainer} from "./styled";
import { get } from "lodash";
import imgNotFound from "images/icons/gray/imgNotFound.svg";
import { openModal, setParams } from "store/Modals/actions";
import { MODAL_EXPAND_IMAGE } from "store/Modals/constants";
import ArrowExpandIcon from "components/commons/Icons/ArrowExpandIcon";

const Card = ({ option }) => {
  const dispatch = useDispatch();
  const { id, image, name } = option;
  const index = useSelector(selectStageIndex);
  const paperwork = useSelector(selectPaperworkStructure);
  const stageKey = get(paperwork, `stages[${index}].form.otherInputs[0].key`, {});
  const paperworkProg = useSelector(selectPaperworkMetadata);
  const key = get(paperworkProg, `stageProgress[${index}].otherInputsData[${stageKey}]`, []);
  const [imageLoaded, setImageLoaded] = useState(true);

  const select = () => dispatch(addMultipleOption(stageKey, id));

  const check = () => {
    if (!key) return false;
    return key.some((s) => s === id);
  };

  const onImgError = ({ currentTarget }) => {
    currentTarget.onerror = null; // prevents looping
    currentTarget.src=imgNotFound;
    setImageLoaded(false);
  };

  const expandImage = (e) => {
    e.stopPropagation();
    dispatch(openModal(MODAL_EXPAND_IMAGE));
    const params = {
      "image": imageLoaded? image : imgNotFound,
      "titleImage": name,
    };
    dispatch(setParams(params));
  };

  return (
    <Wrapper active={check()} onClick={select}>
      <ImgContainer >
        <Image src={image} alt="image" onError={onImgError} />
        <ExpanderContainer onClick={expandImage} show={ imageLoaded }>
          <ArrowExpandIcon />
        </ExpanderContainer>
      </ImgContainer>
      <Name>
        {name}
      </Name>
    </Wrapper>
  );
};

export default Card;
