import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: top;
  justify-content: right;
  flex: 0 0 110px;
  margin-left: auto;
  box-sizing: border-box;
  cursor: pointer;
  height: 100%;
  @media (max-width: 500px) {
    flex: 0 0 auto;
    height: auto;
    align-items: center;
  }
`;