import React, { useState } from "react";
import { Wrapper, TextArea, ButtonContainer, Footer } from "./style";
import { MODAL_EDIT_OBSERVATION } from "store/Modals/constants";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "store/Modals/actions";
import LabelOnlyButton from "components/commons/Buttons/LabelOnlyButton";
import colors from "styles/colors";
import { observePaperwork } from "store/PaperWork/actions/requests";
import { selectPaperworkMetadata } from "store/PaperWork/selectors";
import { PLACEHOLDER, SEND } from "./constants";

const Content = ({ expanded }) => {
  const dispatch = useDispatch();
  const { id } = useSelector(selectPaperworkMetadata);
  const [text, setText] = useState(null);
  const observation = { observation: text };

  const handleObservation = (e) => {
    setText(e.target.value);
  };

  const review = () => {
    dispatch(closeModal(MODAL_EDIT_OBSERVATION));
    dispatch(observePaperwork(id, observation));
  };

  return (
    <Wrapper expanded={expanded}>
      <TextArea onChange={(e) => handleObservation(e)} placeholder={PLACEHOLDER} />
      <Footer>
        <ButtonContainer>
          <LabelOnlyButton 
          background={colors.blue40} 
          color={colors.white} 
          disabled={!text}
          label={SEND} 
          onClick={review} 
        />
        </ButtonContainer>
      </Footer>
    </Wrapper>
  );
};

export default Content;
