import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
  gap: 16px;
  box-sizing: border-box;
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: ${(props) => props.columns};
  width: 100%;
  gap: 16px;
  width: 100%;
  color: ${colors.gray50};
  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`;

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Title = styled.div`
  text-transform: capitalize;
  font-family: "OpenSansRegular";
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: ${colors.gray70};
`;


export const Input = styled.input`
  display: flex;
  align-items: top;
  justify-content: left;
  width: 100%;
  height: 48px;
  padding: 16px;
  outline: none;
  border: 1px solid ${colors.gray70};
  border-radius: 4px;
  box-sizing: border-box;
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.gray40};

  &::-webkit-inner-spin-button {
    display: none;
  }

  /* &::-webkit-calendar-picker-indicator {
    opacity: 0;
    cursor: pointer;
  } */

  &::before {
    content: attr(data-placeholder);
    width: 100%;
  }
  &:focus::before,
  &:valid::before {
    display: none;
  }
`;
