/* eslint-disable no-constant-condition */
import React from "react";
import { Wrapper, Input, Icon, Label } from "./styled";

const CommonInput = ({
  name,
  border,
  borderRadius,
  type,
  value,
  key,
  height,
  background,
  onFocus,
  onChange,
  onClick,
  onBlur,
  disabled,
  color,
  placeholder,
  icon,
  left,
  error,
  errorMessage,
  autoComplete,
}) => {
  return (
    <Wrapper>
      <Input
        name={name}
        error={error}
        border={border}
        value={value}
        key={key}
        type={type}
        height={height}
        background={background}
        onChange={onChange}
        onClick={onClick}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={disabled}
        placeholder={placeholder}
        color={color}
        borderRadius={borderRadius}
        autoComplete={autoComplete}
      />
      <Icon left={left}>{icon}</Icon>
      <Label>{errorMessage}</Label>
    </Wrapper>
  );
};

export default CommonInput;
