import React from "react";
import { Wrapper } from "./styled";
import { useDispatch } from "react-redux";
import { declineUser, deleteUser } from "store/BackOffice/actions/requests";

const DeleteCell = ({ row, icon }) => {
  const dispatch = useDispatch();
  const onClick = () => (row.original.profileId ? dispatch(deleteUser(row.original.id)) : dispatch(declineUser(row.original.id)));

  return <Wrapper onClick={onClick}>{icon}</Wrapper>;
};

export default DeleteCell;
