import React from "react";
import { Wrapper } from "./style";
import Header from "./components/Header";
import Content from "./components/Content";

const ExpandImageModal = () => {
  return (
    <Wrapper>
      <Header />
      <Content />
    </Wrapper>
  );
};

export default ExpandImageModal;
