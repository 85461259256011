/* eslint-disable no-unused-vars */
/* eslint-disable react/no-children-prop */
import React, { useState } from "react";
import { ButtonContainer, Error, InputContainer, Wrapper } from "./styled";
import LabelOnlyButton from "components/commons/Buttons/LabelOnlyButton";
import colors from "styles/colors";
import { useDispatch, useSelector } from "react-redux";
import { updateUserInfo } from "store/Login/actions/actions";
import NameInput from "./components/NameInput";
import EmailInput from "./components/EmailInput";
import { emailPattern, onlyNumbers } from "utils/functions";
import { selectLoginError } from "store/Login/selectors";
import CUITInput from "./components/CUITInput";
import { selectUserName, selectUserCuit } from "store/Login/selectors";
import TextFieldPhone from "./components/TextFieldPhone";
import { areaCodeIsValid, phoneIsValid } from "components/pages/Profile/components/Form/components/Buttons/functions";

const Elements = () => {
  const dispatch = useDispatch();
  const [user, setUser] = useState({
    email: "",
    phone: "",
    phoneAreaCode: "",
  });
  const message = useSelector(selectLoginError);
  const userName = useSelector(selectUserName);
  const userCuit = useSelector(selectUserCuit);
  const signUp = () => dispatch(updateUserInfo(user));

  const disabled =
    !emailPattern(user?.email) ||
    !phoneIsValid(user?.phone) ||
    user?.phone.length > 8 ||
    !user?.phone ||
    !areaCodeIsValid(user?.phoneAreaCode) ||
    !user?.phoneAreaCode ||
    user?.phoneAreaCode.length > 4;

  return (
    <Wrapper>
      <InputContainer>
        <NameInput setUser={setUser} user={user} disabled={true} val={userName} />
        <CUITInput setUser={setUser} user={user} disabled={true} val={userCuit} />
        <EmailInput setUser={setUser} user={user} />
        <TextFieldPhone
          label={"Teléfono"}
          setNewUser={setUser}
          newUser={user}
          valueAreaCode={user["phoneAreaCode"]}
          valuePhone={user["phone"]}
          areaCodeField={"phoneAreaCode"}
          phoneField={"phone"}
        />
      </InputContainer>
      <ButtonContainer>
        <LabelOnlyButton disabled={disabled} label="Enviar solicitud" background={colors.blue50} color={colors.white} onClick={signUp} />
      </ButtonContainer>
      <Error>{message}</Error>
    </Wrapper>
  );
};

export default Elements;
