import React from "react";
import NewPaperwork from "./components/NewPaperwork";
import PaperworkInProgress from "./components/PaperworkInProgress";
import FinishedPaperwork from "./components/FinishedPaperwork";
import { NEW_KEY, IN_PROGRESS_KEY, FINISHED_KEY } from "./functions";
import { Wrapper } from "./styled";

const Section = ({ id, search }) => {
  return (
    <Wrapper>
      {id === NEW_KEY && <NewPaperwork search={search} />}
      {id === IN_PROGRESS_KEY && <PaperworkInProgress search={search} />}
      {id === FINISHED_KEY && <FinishedPaperwork search={search} />}
    </Wrapper>
  );
};

export default Section;
