import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  margin-right: auto;
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: ${colors.gray20};
  @media (max-width: 580px) {
    font-size: 16px;
  }
`;
