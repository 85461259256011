import React from "react";
import { TabWrapper, Tab, RightArrow, LeftArrow } from "./styled";
import { useSelector } from "react-redux";
import { selectPaperworkData } from "store/PaperWork/selectors";
import get from "lodash/get";

const TabSelector = ({selectedPlot, setSelectedPlot}) => {
  const paperworkData = useSelector(selectPaperworkData);
  const hasMultiplePlots = paperworkData.length > 1;
  const firstPlot = selectedPlot > 0;
  const lastPlot = selectedPlot < paperworkData.length - 1;

  if (!hasMultiplePlots) return null;
  return (
    <TabWrapper>
      <LeftArrow $show={firstPlot} onClick={() => setSelectedPlot(selectedPlot - 1)} />
      {
        paperworkData.map((element, index) => 
          <Tab isSelected={selectedPlot === index} key={index} onClick={() => setSelectedPlot(index)}>
            {get(element, "nomenclature.plot", "-")}
          </Tab>)
      }
      <RightArrow $show={lastPlot} onClick={() => setSelectedPlot(selectedPlot + 1)} />
    </TabWrapper>
  );
};

export default TabSelector;
