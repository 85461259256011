import React from "react";
import Tooltip from "components/commons/Tooltip";
import { Wrapper, Container } from "./styled";

const Icon = ({ elem, toogle, selected }) => {
  return (
    <Wrapper>
      <Tooltip title={elem.name} >
        <Container toogle={toogle} selected={selected}>
          {elem.Component}
        </Container>
      </Tooltip>
    </Wrapper>
  );
};

export default Icon;
