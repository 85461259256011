/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setForm } from "store/PaperWork/actions/functions";
import { selectPaperworkMetadata, selectStageIndex } from "store/PaperWork/selectors";
import { checkDependecies, getInitialValue, getValues } from "../../functions";
import Icon from "./components/Icon";
import { Wrapper, InfoContainer, Title, Description, Arrow } from "./styled";
import { get } from "lodash";

const SelectionCard = ({ item, stageKey }) => {
  const dispatch = useDispatch();
  const { title, description, iconId, dependencies, key, value, disabled } = item;
  const index = useSelector(selectStageIndex);
  const paperwork = useSelector(selectPaperworkMetadata);
  const stage = get(paperwork, "stageProgress", {});
  const valueRef = get(stage, `[${index}].otherInputsData[${stageKey}][${key}]`, null);
  const values = checkDependecies(stage, dependencies, false);
  const inDisuse = values.hidden || values.disabled;
  const [selected, setSelected] = useState(getInitialValue(stage, index, stageKey, key) === value);

  const onClick = () => {
    setSelected(!selected);
    if (valueRef !== value) dispatch(setForm(stageKey, key, value, inDisuse));
    else dispatch(setForm(stageKey, key, null, inDisuse));
  };

  useEffect(() => setSelected(getInitialValue(stage, index, stageKey, key) === value), [index]);

  useEffect(() => {
    if (valueRef !== value) setSelected(false);
  }, [stage]);

  if (values.hidden) return null;

  return (
    <Wrapper selected={getValues(selected, "value", dependencies, stage, values)} onClick={onClick} disabled={disabled}>
      <Icon id={iconId} selected={getValues(selected, "value", dependencies, stage, values)} disabled={disabled} />
      <InfoContainer>
        <Title selected={getValues(selected, "value", dependencies, stage, values)} disabled={disabled}>
          {title}
        </Title>
        <Description selected={getValues(selected, "value", dependencies, stage, values)} disabled={disabled}>
          {description}
        </Description>
      </InfoContainer>
      <Arrow selected={getValues(selected, "value", dependencies, stage, values)} disabled={disabled} />
    </Wrapper>
  );
};

export default SelectionCard;
