import { PAPERWORK_STATE } from "store/PaperWork/constants";
export const ADMINISTRATION_ITEMS = [
  { 
    id: "pending",
    text: "Pendientes",
    states: [
      PAPERWORK_STATE.PENDING,
      PAPERWORK_STATE.APPROVE_REQUESTED,
      PAPERWORK_STATE.REJECT_REQUESTED,
      PAPERWORK_STATE.MENDING_REQUESTED
    ],
  },
  { 
    id: "observed",
    text: "Observados",
    states: [PAPERWORK_STATE.OBSERVED],
  },
  { 
    id: "approved",
    text: "Aprobados",
    states: [PAPERWORK_STATE.APPROVED],
  },
  { 
    id: "rejected",
    text: "Rechazados",
    states: [PAPERWORK_STATE.REJECTED],
  },
];

export const CERTIFICATE_ITEMS = [
  { 
    id: "pending",
    text: "Pendientes",
    states: [
      PAPERWORK_STATE.APPROVE_REQUESTED,
      PAPERWORK_STATE.REJECT_REQUESTED,
    ],
  },
  { 
    id: "observed",
    text: "Observados",
    states: [PAPERWORK_STATE.OBSERVED],
  },
  { 
    id: "approved",
    text: "Aprobados",
    states: [PAPERWORK_STATE.APPROVED],
  },
  { 
    id: "rejected",
    text: "Rechazados",
    states: [PAPERWORK_STATE.REJECTED],
  },
];
