import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: left;
  width: 100%;
  gap: 20px;
  box-sizing: border-box;
`;

export const Row = styled.div`
  display: flex;
  gap: 16px;
  border-bottom: 1px solid ${colors.gray99};
`;
