import React from "react";
import { Wrapper, Container, Title, Description, Image } from "./style";
import Footer from "./components/Footer";

const ConfirmModal = ({ action, title, description, icon, toggle, open }) => {
  if (!open) return null;

  return (
    <Wrapper onClick={toggle}>
      <Container>
        <Image src={icon} alt="img" />
        <Title>{title}</Title>
        <Description>{description}</Description>
        <Footer action={action} toggle={toggle} />
      </Container>
    </Wrapper>
  );
};

export default ConfirmModal;
