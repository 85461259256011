/* eslint-disable react/no-children-prop */
import React from "react";
import { Title, Text } from "../styled";
import { useSelector } from "react-redux";
import { selectPaperworkMetadata } from "store/PaperWork/selectors";
import get from "lodash/get";
import { DatesWrapper, Item } from "./styled";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const Dates = () => {
  const metadata = useSelector(selectPaperworkMetadata);
  const startDate = get(metadata, "startDate", null);
  const finishDate = get(metadata, "finishDate", null);
  const { pathname } = useLocation();
  const currentSection = pathname.split("/")[1];
  const hidden = currentSection === "tramites";

  if (hidden) return null;

  if (!finishDate)
    return (
      <DatesWrapper>
        <Item>
          <Title children="Fecha de Ingreso" />
          <Text children={`${startDate}`} />
        </Item>
      </DatesWrapper>
    );

  return (
    <DatesWrapper>
      <Item>
        <Title children="Fecha de Ingreso" />
        <Text children={`${startDate}`} />
      </Item>
      <Item>
        <Title children="Fecha de Finalización" />
        <Text children={`${finishDate}`} />
      </Item>
    </DatesWrapper>
  );
};

export default Dates;
