import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2 1 0;
  align-items: center;
  justify-content: top;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  padding: 100px;
  @media (max-width: 980px) {
    width: 100%;
    height: unset;
    padding: 16px;
  }
`;

export const Title = styled.div`
  text-align: center;
  margin-bottom: 8px;
  font-family: "Domine";
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 44px;
  color: ${colors.orange50};
  @media (max-width: 980px) {
    max-width: 350px;
    line-height: 32px;
    font-weight: 700;
    font-size: 24px;
    text-align: center;
    letter-spacing: 0.18px;
    margin-bottom: 28px;
  }
`;

export const Subtitle = styled.div`
  font-family: "Domine";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: ${colors.gray40};
  max-width: 491px;
  margin-bottom: 40px;
  @media (max-width: 980px) {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.18px;
  }
`;
