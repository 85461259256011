export default {
  black: "#242424",
  white: "#FFFFFF",
  gray0: "#282F3E",
  gray10: "#30394B",
  gray20: "#404C64",
  gray30: "#505F7C",
  gray40: "#5F7195",
  gray400: "#A3ADC1",
  gray50: "#7687A7",
  gray60: "#8F9CB7",
  gray70: "#A8B2C7",
  gray80: "#C1C8D7",
  gray90: "#DADEE7",
  gray95: "#E6E9EF",
  gray99: "#F3F4F7",
  gray100: "#F9F9FB",
  blue0: "#121E40",
  blue10: "#162650",
  blue20: "#1B2D5F",
  blue30: "#1F356F",
  blue40: "#243D7F",
  blue50: "#2D4B9F",
  blue60: "#4065C9",
  blue70: "#607ED2",
  blue80: "#90A5E0",
  blue90: "#BFCCED",
  blue95: "#DFE5F6",
  blue99: "#EFF2FB",
  blue100: "#F7F8FD",
  orange0: "#904314",
  orange10: "#A24C16",
  orange20: "#B45418",
  orange30: "#C55C1B",
  orange40: "#D7651D",
  orange50: "#E26E26",
  orange60: "#E47B3A",
  orange70: "#E7874B",
  orange80: "#E9935D",
  orange90: "#EEAB81",
  orange95: "#F3C3A5",
  orange99: "#F8DBC9",
  orange100: "#FAE7DB",
  yellow50: "#FFD21F",
  yellow60: "#FFDA47",
  yellow70: "#FFE270",
  yellow80: "#FFEB99",
  yellow90: "#FFF3C2",
  yellow95: "#FFFBEB",
  yellow100: "#FFFDF5",
  lightBlue50: "#007AFF",
  lightBlue60: "#3396FF",
  lightBlue70: "#5CABFF",
  lightBlue80: "#99CAFF",
  lightBlue90: "#C2DFFF",
  lightBlue95: "#EBF4FF",
  lightBlue100: "#F5F9FF",
  red50: "#FF2A1F",
  red60: "#FF5147",
  red70: "#FF7770",
  red80: "#FF9E99",
  red90: "#FFC5C2",
  red95: "#FFECEB",
  red100: "#FFF5F5",
  green50: "#007D00",
  green60: "#009D05",
  green70: "#00AF1B",
  green80: "#00D336",
  green90: "#67E37C",
  green100: "#C3F3C8",
  greenA1: "#09BD8A",
  greenA2: "#0AC75C",
};
