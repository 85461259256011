import Img from "components/commons/Img";
import styled from "styled-components";
import colors from "styles/colors";
import { ReactComponent as NextIcon } from "images/icons/white/arrowRight.svg";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-left: ${({ toogle }) => (toogle ? "0px" : "24px")};
  padding-right: ${({ toogle }) => (toogle ? "0px" : "16px")};
  box-sizing: border-box;
`;

export const Image = styled(Img)`
  display: ${({ isLoading }) => (isLoading ? "none" : "flex")};
  min-width: ${({ toogle }) => (toogle ? "32px" : "40px")};
  min-height: ${({ toogle }) => (toogle ? "32px" : "40px")};
  max-width: ${({ toogle }) => (toogle ? "32px" : "40px")};
  max-height: ${({ toogle }) => (toogle ? "32px" : "40px")};
  border-radius: 100px;
  overflow: hidden;
  margin-top: auto;
  box-sizing: border-box;
  object-fit: cover;
  ${({ selected, toogle }) =>
    selected &&
    toogle &&
    `
    border: 1px solid ${colors.blue50};
    padding: 2px;
    min-width: 38px;
    min-height: 38px;
  `}
`;

export const Info = styled.div`
  display: ${({ toogle }) => (toogle ? "none" : "flex")};
  flex-direction: column;
  align-items: left;
  justify-content: center;
  width: 100%;
  padding-left: 6px;
  box-sizing: border-box;
`;

export const Name = styled.div`
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${colors.gray10};
`;

export const Mail = styled.div`
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${colors.gray50};
`;

export const Arrow = styled(NextIcon)`
  display: ${({ $toogle }) => ($toogle ? "none" : "flex")};
  & > path {
    fill: ${colors.gray40};
  }
`;
