import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import { Wrapper, InputText, Input, StyledIcon } from "./styled";
import { updateUserPicture } from "store/Login/actions/actions";

const Button = ({ setError }) => {
  const dispatch = useDispatch();
  const allowedExtensions = ["jpg", "jpeg", "png"];
  const sizeLimit = 3145728;
  const inputRef = useRef(null);

  const handleChange = (event) => {
    const file = event.target.files[0];
    if (file.size > sizeLimit) return setError("La imagen seleccionada supera el peso permitido");
    if (!allowedExtensions.includes(file.type.split("/")[1])) return setError("El formato de la imagen seleccionada es inválido");
    setError("");
    const formData = new FormData();
    formData.append("file", file);
    dispatch(updateUserPicture(formData));
  };

  const handleClick = () => {
    inputRef.current.click();
  };

  return (
    <Wrapper onClick={handleClick}>
      <StyledIcon />
      <InputText>
        Cargar foto
        <Input type="file" onChange={handleChange} ref={inputRef} />
      </InputText>
    </Wrapper>
  );
};

export default Button;
