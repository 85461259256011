import colors from "./colors";

export const borders = {
  selected1: `1px solid ${colors.blue40}`,
};

export const activeBorder = (props) => {
  if (props.active) {
    return borders.selected1;
  }
  return null;
};
