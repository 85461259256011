import React from "react";
import { Wrapper } from "./styled";
import Content from "./components/Content";

const Detail = () => {
  return (
    <Wrapper>
      <Content />
    </Wrapper>
  );
};

export default Detail;
