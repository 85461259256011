import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

export const Label = styled.label`
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${colors.gray0};
  ${({ disabled }) =>
    disabled &&
    `
      color: ${colors.orange50} !important; 
      cursor: not-allowed;
   `}
`;

export const Input = styled.input`
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  width: 1.5em;
  height: 1.5em;
  border: 2px solid;
  border-color: ${({ checked }) => (checked ? colors.lightBlue50 : colors.gray50)};
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  ::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 75%;
    height: 75%;
  }
  :checked {
    ::after {
      background: ${colors.lightBlue50};
    }
  }
`;
