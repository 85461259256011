import React from "react";
import { Section, Text, Wrapper, Icon, Container } from "./styled";

const TabSelector = ({ setId, id, sections }) => {
  const getSection = (sect) => {
    return (
      <Section key={sect.name} active={sect.name === id} onClick={() => setId(sect.name)}>
        <Icon active={sect.name === id}>{sect.icon}</Icon>
        <Text active={sect.name === id}>{sect.name}</Text>
      </Section>
    );
  };

  if (!sections) return null;
  return (
    <Wrapper>
      <Container>{sections.map(getSection)}</Container>
    </Wrapper>
  );
};

export default TabSelector;
