import styled from "styled-components";
import colors from "styles/colors";
export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    height: 48px;
    width: 100%;
    margin-left: 200px;
    margin-top: 50px;
    margin-bottom: 100px;
    @media (max-width: 600px) {
        margin-left: 60px;
        margin-top: 30px;
        margin-bottom: 75px;
    }
    @media (max-width: 400px) {
        margin-left: 40px;
    }
`;

export const Title = styled.div`
    display: flex;
    width: 100%;
    text-align: center;
    margin-left: 15px;
    color: ${colors.white};
    font-weight: 500;
    font-size: 18px;
    @media (max-width: 600px) {
        font-size: 14px;
        margin-left: 25px;
    }
`;