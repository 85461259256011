/* eslint-disable react/react-in-jsx-scope */
import { PAPERWORK_STATE, PAPERWORK_STATE_NAME } from "store/PaperWork/constants";
import { Clock, More, PaperWork } from "../Selector/styled";

export const NEW_KEY = "Nuevos";
export const IN_PROGRESS_KEY = "En curso";
export const FINISHED_KEY = "Finalizados";

export const SECTIONS = [
  { name: NEW_KEY, key: null, icon: <More /> },
  { name: IN_PROGRESS_KEY, key: PAPERWORK_STATE.PENDING, icon: <Clock /> },
  { name: FINISHED_KEY, key: PAPERWORK_STATE.APPROVED, icon: <PaperWork /> },
];

/* complete with the ids of paperworks as we implement them */
export const CATEGORY_HORIZONTAL_PROP = [];
export const CATEGORY_CARTOGRAPIC_DOC = [];
export const CATEGORY_DDJJ = ["E1"];

export const filterByValue = (list, value) => {
  const lowerCase = value.toLowerCase();
  if (!value) return list;
  return list.filter((p) => p.id.toLowerCase().includes(lowerCase) || p.name.toLowerCase().includes(lowerCase));
};

export const filterByState = (list, value) => {
  const lowerCase = value.toLowerCase();
  if (!value) return list;
  return list.filter((p) => PAPERWORK_STATE_NAME[p.state].toLocaleLowerCase().includes(lowerCase) || String(p.paperworkProgressId).includes(value));
};

export const filterByDeparture = (list, value) => {
  if (!value) return list;
  return list.filter((p) => p?.departure?.includes(value));
};
