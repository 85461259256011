/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { Wrapper, Container } from "./style";
import Header from "./components/Header";
import Content from "./components/Content";
import { useDispatch, useSelector } from "react-redux";
import { selectPaperworkMetadata } from "store/PaperWork/selectors";
import { shareReport } from "store/PaperWork/actions/requests";
import { setReportLink } from "store/PaperWork/actions/actions";
import { get } from "lodash";

const ShareFileModal = () => {
  const dispatch = useDispatch();
  const pProgress = useSelector(selectPaperworkMetadata);
  const id = get(pProgress, "id", null);

  useEffect(() => {
    dispatch(shareReport(id));
    return () => {
      dispatch(setReportLink(""));
    };
  }, []);

  return (
    <Wrapper>
      <Container>
        <Header />
        <Content />
      </Container>
    </Wrapper>
  );
};

export default ShareFileModal;
