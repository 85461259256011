import React from "react";
import { Wrapper } from "./styled";
import CommonCard from "../Cards/CommonCard";

const Content = ({ tributaryPlots }) => {

  const getCard = (plot, i) => <CommonCard key={`${plot[0]}_${i}`} plot={plot} />;

  return (
    <Wrapper>
      {tributaryPlots.map(getCard)}
    </Wrapper>
  );
};

export default Content;
