import React from "react";
import { Wrapper, ButtonContainer, LeftButton, RightButton } from "./style";
import LabelOnlyButton from "components/commons/Buttons/LabelOnlyButton";
import colors from "styles/colors";

const Footer = ({ action, toggle }) => {
  return (
    <Wrapper>
      <ButtonContainer>
        <LeftButton>
          <LabelOnlyButton label="Cancelar" color={colors.blue50} background={colors.white} onClick={toggle} />
        </LeftButton>
        <RightButton>
          <LabelOnlyButton label="Confirmar" color={colors.white} background={colors.blue50} onClick={action} />
        </RightButton>
      </ButtonContainer>
    </Wrapper>
  );
};

export default Footer;
