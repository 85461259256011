import Ilust1 from "images/ilustrations/ilustration_1.svg";
import Ilust2 from "images/ilustrations/ilustration_2.svg";
import Ilust3 from "images/ilustrations/ilustration_3.svg";
import Ilust4 from "images/ilustrations/ilustration_4.svg";
import Ilust5 from "images/ilustrations/ilustration_5.svg";
import DefaultIlust from "images/ilustrations/ilustration_7.svg";
import Ilust6 from "images/ilustrations/ilustration_6.svg";
import Ilust8 from "images/ilustrations/ilustration_8.svg";
import Ilust9 from "images/ilustrations/ilustration_9.svg";
import Ilust10 from "images/ilustrations/ilustration_10.svg";
import Ilust11 from "images/ilustrations/ilustration_11.svg";
import Ilust12 from "images/ilustrations/ilustration_12.svg";
import Ilust13 from "images/ilustrations/ilustration_13.svg";

export const DEFAULT_TITLE = "Algo salió mal...";
export const DEFAULT_DESCRIPTION =
  "Te pedimos disculpas por las molestias ocasionadas y te recomendamos refrescar la página para solucionar este problema.";
export const DEFAULT_IMAGE = DefaultIlust;
export const CONTROLS_FAILED_ERROR_CODE = 2;
export const NO_D1_FOR_REVALIDATE_CODE = 81;
export const MESSAGE_DESCRIPTIONS = {
  6: {
    name: "INSERTED_OBSERVATION_OK",
    title: "¡Observación enviada!",
    description: "Tu observación fue enviada con éxito al propietario del trámite.",
    image: Ilust2,
  },
  7: {
    name: "APPROVE_PAPERWORK_OK",
    title: "¡Trámite aprobado!",
    description: "El trámite ha sido aprobado. A la brevedad será notificada la persona usuaria.",
    image: Ilust5,
  },
  14: {
    name: "PAPERWORK_ACCEPTED",
    title: "¡Trámite validado!",
    description: "El trámite ha sido validado correctamente y enviado para su aprobación.",
    image: Ilust4,
  },
  21: {
    name: "REJECT_PAPERWORK_OK",
    title: "¡Trámite rechazado!",
    description: "El trámite ha sido rechazado. A la brevedad será notificada la persona usuaria.",
    image: Ilust3,
  },
  22: {
    name: "CONTROL_NOMENCLATURE_NOT_FOUND",
    title: "Nomenclatura no encontrada",
    description: "La nomenclatura solicitada no fue encontrada. Le recomendamos verificar la información ingresada.",
    image: Ilust8,
  },
  23: {
    name: "CONTROL_DEPARTURE_NOT_FOUND",
    title: "Partida no encontrada",
    description: "La partida solicitada no fue encontrada. Le recomendamos verificar la información ingresada.",
    image: Ilust8,
  },
  24: {
    name: "CONTROL_COULD_NOT_VERIFY_NOMENCLATURE",
    title: "Algo salió mal...",
    description: "La nomenclatura ingresada no pudo ser verificada. Le recomendamos reintentar en unos minutos.",
    image: Ilust8,
  },
  25: {
    name: "CONTROL_COULD_NOT_VERIFY_DEPARTURE",
    title: "Algo salió mal...",
    description: "La partida ingresada no pudo ser verificada, te recomendamos reintentar en unos minutos.",
    image: Ilust8,
  },
  29: {
    name: "CONTROL_DEPARTURE_UNICITY",
    title: "Partida con trámite en curso",
    description: "La partida ingresada ya forma parte de un trámite en curso.",
    image: Ilust1,
  },
  30: {
    name: "CONTROL_WRONG_IMMOVABLE_OWNER",
    title: "Titular erróneo",
    description: "Se detectó que los datos ingresados no coinciden con los del titular del inmueble.",
    image: Ilust6,
  },
  31: {
    name: "CONTROL_COULD_NOT_VERIFY_OWNER",
    title: "Algo salió mal...",
    description: "No se pudo verificar el titular ingresado. Le recomendamos reintentar en unos minutos.",
    image: Ilust6,
  },
  35: {
    name: "CONTROL_NON_MATCHING_NOMENCLATURE",
    title: "Algo salió mal...",
    description: "Alguno de los datos de nomenclatura ingresados no coinciden con el número de partida.",
    image: Ilust1,
  },
  36: {
    name: "CONTROL_MULTIPLE_DEPARTURES",
    title: "Múltiples partidas",
    description: "La parcela ingresada contiene múltiples partidas. Deberás seleccionar N° de partida - UF - Tipo de partida correspondiente.",
    image: null,
  },
  37: {
    name: "REQUEST_APPROVE_PAPERWORK_OK",
    title: "Trámite validado",
    description: "El trámite ha sido validado correctamente y enviado para su aprobación.",
    image: Ilust4,
  },
  38: {
    name: "REQUEST_REJECT_PAPERWORK_OK",
    title: "Solicitud de rechazo",
    description: "Se ha solicitado el rechazo del trámite. A la brevedad un certificador estará trabajando en dicho trámite.",
    image: Ilust3,
  },
  39: {
    name: "REQUEST_MENDING_PAPERWORK_OK",
    title: "Solicitud de subsanación",
    description: "Se ha solicitado la subsanación del trámite. A la brevedad el área asignada estará trabajando en dicho trámite.",
    image: Ilust2,
  },
  44: {
    name: "MEND_PAPERWORK_OK",
    title: "¡Trámite subsanado!",
    description: "El trámite ha sido subsanado y devuelto a la bandeja correspondiente.",
    image: Ilust2,
  },
  52: {
    name: "DELETE_USER_OK",
    title: "¡Usuario eliminado!",
    description: "El usuario seleccionado ha sido eliminado exitosamente.",
    image: Ilust12,
  },
  53: {
    name: "EDIT_USER_OK",
    title: "Edición de usuario",
    description: "Los datos del usuario seleccionado han sido modificados exitosamente.",
    image: Ilust9,
  },
  54: {
    name: "USER_CREATION_OK",
    title: "Nuevo usuario",
    description: "El nuevo usuario ha sido creado exitosamente.",
    image: Ilust11,
  },
  55: {
    name: "RESET_PASSWORD_OK",
    title: "Solicitud enviada",
    description: "La solicitud para el cambio de contraseña se ha enviado exitosamente.",
    image: Ilust13,
  },
  56: {
    name: "ENABLED_USER_OK",
    title: "Cambio de estado",
    description: "El cambio de estado se ha realizado exitosamente.",
    image: Ilust10,
  },
  58: {
    name: "RESEND_VERIFICATION_EMAIL_OK",
    title: "¡Mail enviado!",
    description: "El mail de verificación ha sido enviado exitosamente.",
    image: Ilust2,
  },
  74: {
    name: "MODIFICATION_OFPERSONAL_DATA_OK",
    title: "Edición de usuario",
    description: "Tus datos de usuario han sido modificados exitosamente. Por favor, volvé a iniciar sesión.",
    image: Ilust9,
  },
  75: {
    name: "CANNOT_REMOVE_ADMIN",
    title: "Eliminación de usuario",
    description: "No es posible eliminar el usuario seleccionado. Debe establecer otro administrador primero.",
    image: DefaultIlust,
  },
  76: {
    name: "CANNOT_EDIT_ADMIN",
    title: "Edición de usuario",
    description: "No es posible modificar el perfil del usuario seleccionado. Debe establecer otro administrador primero.",
    image: DefaultIlust,
  },
  77: {
    name: "RESEND_VERIFICATION_EMAIL_FAILED",
    title: "Algo salió mal...",
    description: "El mail de verificación no ha podido ser enviado. Intente nuevamente en unos minutos.",
    image: DefaultIlust,
  },
  78: {
    name: "RESET_PASSWORD_FAILED",
    title: "Algo salió mal...",
    description: "La solicitud para el cambio de contraseña no ha podido ser enviada. Intente nuevamente en unos minutos.",
    image: DefaultIlust,
  },
  81: {
    name: "NO_D1_FOR_REVALIDATE",
    title: "Sin certificados",
    description: "No existen certificados para revalidar.",
    image: DefaultIlust,
  },
  86: {
    name: "EMAIL_ALREADY_IN_USE",
    title: "Email en uso.",
    description: "El email que se ingresó ya se encuentra en uso.",
    image: DefaultIlust,
  },
};
