import React from "react";
import { Wrapper } from "./styled";
import colors from "styles/colors";
import LabelOnlyButton from "components/commons/Buttons/LabelOnlyButton";
import { useDispatch } from "react-redux";
import { closeModal } from "store/Modals/actions";
import { emailPattern } from "utils/functions";

const Buttons = ({ modal, confirm, user, edit }) => {
  const dispatch = useDispatch();
  const close = () => dispatch(closeModal(modal));

  const accept = () => {
    confirm(user);
    dispatch(closeModal(modal));
  };

  return (
    <Wrapper>
      <LabelOnlyButton label="Cancelar" background={colors.white} color={colors.blue50} border={`1px solid ${colors.blue70}`} onClick={close} />
      <LabelOnlyButton
        label="Aceptar"
        background={colors.blue50}
        color={colors.white}
        onClick={accept}
        disabled={(!edit && (!user.password || !/^.{6,}$/.test(user.password))) || !emailPattern(user.email) || !user.name || !user.profileId}
      />
    </Wrapper>
  );
};

export default Buttons;
