import IconGray from "images/icons/gray/close.svg";
import IconBlue from "images/icons/blue/close.svg";
import { GRAY, BLUE } from "styles/constants";

export const getIcon = (color) => {
  switch (color) {
    case GRAY:
      return IconGray;
    case BLUE:
      return IconBlue;
    default:
      return IconBlue;
  }
};
