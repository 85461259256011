import React from "react";
import colors from "styles/colors";
import { Container } from "./styled";
import LabelIconButton from "components/pages/PaperworkDetail/components/LabelIconButton";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { PAPERWORK_TYPE } from "store/PaperWork/constants";
import { addMultipleOption } from "store/PaperWork/actions/functions";
import { saveStage } from "store/PaperWork/actions/requests";
import { selectPaperworkData, selectPaperworkStageProgress, selectPaperworkStructure, selectStageIndex } from "store/PaperWork/selectors";
import { get } from "lodash";
import { generatePayload } from "components/pages/Paperwork/components/Buttons/functions";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const Contributor = () => {
  const dispatch = useDispatch();
  const structure = useSelector(selectPaperworkStructure);
  const index = useSelector(selectStageIndex);
  const data = useSelector(selectPaperworkData);
  const id = get(data, "[0].paperworkProgressId", "");
  const stageProgress = useSelector(selectPaperworkStageProgress);
  const jsonSchemaData = get(stageProgress, `[${index}].jsonSchemaData`, {});
  const otherInputsData = get(stageProgress, `[${index}].otherInputsData`, {});
  const stageKey = get(structure, `stages[${index}].form.otherInputs[0].key`, {});
  const stageId = get(stageProgress, `[${index}].stageId`, null);
  const files = get(stageProgress, `[${index}].attachedFiles`, []);
  const stageData = generatePayload(jsonSchemaData, otherInputsData, files);
  const { pathname } = useLocation();
  const currentSection = pathname.split("/")[1];
  const hidden = currentSection === "administracion";

  const back = () => {
    dispatch(addMultipleOption(stageKey, id));
    dispatch(push(`/tramites/${PAPERWORK_TYPE.D2}`));
  };

  const next = () => {
    dispatch(saveStage(stageId, stageData));
    dispatch(push(`/tramites/${PAPERWORK_TYPE.D2}`));
  };

  if (hidden) return null;

  return (
    <Container>
      <LabelIconButton
        type={"secondary"}
        color={colors.blue50}
        background={colors.white}
        label={"Volver"}
        border={`1px solid ${colors.blue70}`}
        onClick={back}
        width={"216px"}
        height={"40px"}
      />
      <LabelIconButton
        type={"primary"}
        color={colors.white}
        background={colors.blue50}
        label={"Siguiente"}
        onClick={next}
        width={"216px"}
        height={"40px"}
      />
    </Container>
  );
};

export default Contributor;
