/* eslint-disable no-unused-vars */
export const errorHandling = (errors, schema) => {
  return errors.map((error) => {
    if (undefined !== schema?.properties) {
      if (undefined === schema?.properties[error?.property.split(".")[1]]?.messages) {
        return false;
      }
      error.message = schema?.properties[error?.property.split(".")[1]]?.messages[error?.name];
      if (!error.message) {
        return false;
      }
      return error;
    }
    return false;
  });
};

export const normalize = (value) => {
  return value.split(".")[1];
};

export const updateFieldObject = (object, field, value) => {
  const objCopy = { ...object };
  if (value === "") {
    delete objCopy[field];
    return objCopy;
  }
  Object.assign(objCopy, {
    [field]: value,
  });
  return objCopy;
};

export const getDefVal = (max) => {
  return "".padEnd(max, "0");
};

export const zerosPadding = (value, max) => {
  if (typeof value !== "string") {
    return value?.toString().padStart(max, "0");
  }
  return value?.padStart(max, "0");
};

export const getZeros = (schema, field, preValue, extension) => {
  if (schema.properties[field]?.zeroPadding) {
    return zerosPadding(preValue, extension);
  }
  return preValue;
};

export const onFocusField = (field, jsonSchema, formData, setFormData) => {
  if (undefined === formData) {
    return null;
  }
  const filNor = normalize(field);
  const value = formData[filNor];
  const maxlengthInZeros = new Array(jsonSchema.properties[field]?.maxLength);
  for (let i = 0; i < maxlengthInZeros.length; i++) {
    maxlengthInZeros[i] = "0";
  }
  const maxlengthInZerosValue = "".concat(...maxlengthInZeros);
  if (value === maxlengthInZerosValue) {
    setFormData(updateFieldObject(formData, filNor, ""));
  } else {
    return;
  }
};
