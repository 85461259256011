import styled from "styled-components";
import { ReactComponent as Icon } from "images/icons/gray/Delete_outline.svg";
import { ReactComponent as DeclineIcon } from "images/icons/gray/decline.svg";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  cursor: pointer;
`;

export const Delete = styled(Icon)``;

export const Decline = styled(DeclineIcon)``;
