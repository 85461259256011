import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
  height: 240px;
  gap: 16px;
  font-size: 60px;
  color: blue;
  @media (max-width: 1034px) {
    flex-wrap: wrap;
    height: auto;
  }
`;
