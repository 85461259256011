import styled from "styled-components";
import colors from "styles/colors";
import Item from "./components/Item";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  max-height: 240px;
  width: 50%;
  padding: 24px 40px;
  font-size: 60px;
  color: blue;
  box-sizing: border-box;
  border: 1px solid ${colors.gray100};
  border-radius: 17px;
  background: ${colors.white};
  border: 1px solid ${colors.gray99};
  border-radius: 4px;
  overflow: hidden;
  @media (max-width: 1034px) {
    flex: 0 0 100%;
  }
`;

export const Title = styled.div`
  display: flex;
  flex: 0 0 24px;
  margin-right: auto;
  margin-bottom: 16px;
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: ${colors.gray50};
  @media (max-width: 420px) {
    font-size: 16px;
  }
`;

export const ItemsContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 16px;
`;

export const ApprovedItem = styled(Item)`
  background-color: ${colors.green100.concat("33")}; // 33 is the alpha channel
  & > * {
    color: ${colors.green60};
  }
`;

export const InProgressItem = styled(Item)`
  background-color: ${colors.lightBlue100};
  & > * {
    color: ${colors.lightBlue50};
  }
`;
