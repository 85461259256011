import React from "react";
import { Wrapper, ButtonContainer, LeftButton, RightButton } from "./style";
import { useDispatch, useSelector } from "react-redux";
import LabelOnlyButton from "components/commons/Buttons/LabelOnlyButton";
import colors from "styles/colors";
import { selectSeeMorePaperwork } from "store/PaperWork/selectors";
import { getPaperworkDetail, getDocuments } from "store/PaperWork/actions/requests";
import { closeModal } from "store/Modals/actions";
import { MODAL_SEE_MORE } from "store/Modals/constants";

const Footer = () => {
    const dispatch = useDispatch();
    const seeMorePaperwork = useSelector(selectSeeMorePaperwork);
    
    const seeDetail = (id) => {
        dispatch(getPaperworkDetail(id));
        dispatch(getDocuments(id));
        dispatch(closeModal(MODAL_SEE_MORE));
    };

    const toggle = () => {
        dispatch(closeModal(MODAL_SEE_MORE));
    };

    return (
        <Wrapper>
            <ButtonContainer>
                <LeftButton>
                    <LabelOnlyButton 
                        label={"Volver"} 
                        color={colors.blue50} 
                        background={colors.white} 
                        onClick={toggle} 
                    />
                </LeftButton>
                <RightButton>
                    <LabelOnlyButton 
                    label={"Ver detalle"} 
                    color={colors.white} 
                    background={colors.blue50} 
                    onClick={() => seeDetail(seeMorePaperwork.paperworkProgressId)} 
                />
                </RightButton>
            </ButtonContainer>
        </Wrapper>
    );
};

export default Footer;