import SearchIcon from "components/commons/Icons/SearchIcon";
import IconAndLabelInput from "components/commons/Inputs/IconAndLabelInput";
import React from "react";
import colors from "styles/colors";
import { Wrapper } from "./styled";

const Search = ({ value, onChange }) => {
  return (
    <Wrapper>
      <IconAndLabelInput
        onChange={(e) => onChange(e.target.value)}
        value={value}
        icon={<SearchIcon />}
        left={true}
        label={"Buscar trámite"}
        background={colors.gray99}
      />
    </Wrapper>
  );
};

export default Search;
