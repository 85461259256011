import React from "react";
import { useStyles } from "./muiStyled";
import { GridWrapper } from "./styled";
import Grid from "@material-ui/core/Grid";

const WrapTemplate = ({ DescriptionField, description, TitleField, title, properties, required, uiSchema, idSchema }) => {
  const classes = useStyles();

  return (
    <GridWrapper>
      {(uiSchema["ui:title"] || title) && <TitleField id={`${idSchema.$id}-title`} title={title} required={required} />}
      {description && <DescriptionField id={`${idSchema.$id}-description`} description={description} />}
      <Grid container={true} spacing={2} className={classes.root}>
        {properties.map((element, index) => (
          <Grid item={true} xs={6} key={index} style={{ marginBottom: "10px", marginRight: "auto" }}>
            {element.content}
          </Grid>
        ))}
      </Grid>
    </GridWrapper>
  );
};

export default WrapTemplate;
