/* eslint-disable react/react-in-jsx-scope */
import {
  MODAL_EDIT_OBSERVATION,
  MODAL_EDIT_REJECTION,
  MODAL_SHARE_FILE,
  MODAL_SHOW_OBSERVATION,
  MODAL_SHOW_REJECTION,
  MODAL_MESSAGE,
  LOCKED_PAPERWORK_MODAL,
  MODAL_NOMENCLARURE,
  MODAL_SENT_PAPERWORK,
  MODAL_SEE_MORE,
  MODAL_REJECTION_REQUEST,
  MODAL_EXPAND_IMAGE,
  MODAL_EDIT_MEND,
  MODAL_SHOW_MEND,
  MODAL_MEND,
  MODAL_MULTIPLE_DEPARTURES,
  MULTIPLE_PLOTS_MODAL,
  MODAL_EDIT_USER,
  MODAL_ADD_USER,
  MODAL_WARN_MULTIPLE_PLOTS,
} from "store/Modals/constants";
import MessageModal from "components/commons/Modals/MessageModal";
import EditObservationModal from "../Modals/ObservationModal/EditObservationModal";
import ShowObservationModal from "../Modals/ObservationModal/ShowObservationModal";
import EditRejectionModal from "../Modals/RejectionModal/EditRejectionModal";
import ShowRejectionModal from "../Modals/RejectionModal/ShowRejectionModal";
import ShareFileModal from "../Modals/ShareFileModal";
import LockedPaperworkModal from "../Modals/LockedPaperworkModal";
import NomenclatureModal from "../Modals/NomenclatureModal";
import SentPaperworkModal from "../Modals/SentPaperworkModal";
import SeeMoreModal from "../Modals/SeeMoreModal";
import RejectionRequestModal from "../Modals/RejectionModal/RejectionRequestModal";
import EditMendModal from "../Modals/MendModal/EditMendModal";
import ExpandImageModal from "../Modals/ExpandImageModal";
import ShowMendModal from "../Modals/MendModal/ShowMendModal";
import MendModal from "../Modals/MendModal/MendModal";
import MultipleDeparturesModal from "../Modals/MultipleDeparturesModal";
import MultiplePlotsModal from "../Modals/MultiplePlotsModal";
import WarnMultiplePlotsModal from "../Modals/WarnMultiplePlotsModal";
import EditModal from "../Modals/UserModal/EditUser";
import AddUser from "../Modals/UserModal/AddUser";

export const MODALS = [
  { component: <MessageModal />, id: MODAL_MESSAGE },
  { component: <EditObservationModal />, id: MODAL_EDIT_OBSERVATION },
  { component: <ShowObservationModal />, id: MODAL_SHOW_OBSERVATION },
  { component: <RejectionRequestModal />, id: MODAL_REJECTION_REQUEST },
  { component: <EditRejectionModal />, id: MODAL_EDIT_REJECTION },
  { component: <ShowRejectionModal />, id: MODAL_SHOW_REJECTION },
  { component: <EditMendModal />, id: MODAL_EDIT_MEND },
  { component: <ShowMendModal />, id: MODAL_SHOW_MEND },
  { component: <MendModal />, id: MODAL_MEND },
  { component: <ShareFileModal />, id: MODAL_SHARE_FILE },
  { component: <LockedPaperworkModal />, id: LOCKED_PAPERWORK_MODAL },
  { component: <NomenclatureModal />, id: MODAL_NOMENCLARURE },
  { component: <SentPaperworkModal />, id: MODAL_SENT_PAPERWORK },
  { component: <SeeMoreModal />, id: MODAL_SEE_MORE },
  { component: <MultipleDeparturesModal />, id: MODAL_MULTIPLE_DEPARTURES },
  { component: <AddUser />, id: MODAL_ADD_USER },
  { component: <MultiplePlotsModal />, id: MULTIPLE_PLOTS_MODAL },
  { component: <WarnMultiplePlotsModal />, id: MODAL_WARN_MULTIPLE_PLOTS },
  { component: <EditModal />, id: MODAL_EDIT_USER },
  { component: <ExpandImageModal />, id: MODAL_EXPAND_IMAGE },
];
