import React, { useEffect } from "react";
import { Wrapper, Container, ItemContainer } from "./style";
import { useDispatch, useSelector } from "react-redux";
import Header from "./components/Header";
import Item from "./components/Item";
import { selectSeeMorePaperwork } from "store/PaperWork/selectors";
import get from "lodash/get";
import { MODAL_PAPERWORK_MAP } from "./constants";
import Footer from "./components/Footer";
import { setSeeMorePaperwork } from "store/PaperWork/actions/actions";

const SeeMoreModal = () => {
  const dispatch = useDispatch();
  const seeMorePaperwork = useSelector(selectSeeMorePaperwork);
  const title = get(MODAL_PAPERWORK_MAP, `${seeMorePaperwork.paperworkId}.title`);
  const structure = get(MODAL_PAPERWORK_MAP, `${seeMorePaperwork.paperworkId}.structure`);

  useEffect(() => () => {
    dispatch(setSeeMorePaperwork({}));
  }, []);

  return (
    <Wrapper>
      <Container>
        <Header title={title}/>
        <ItemContainer>
          { structure.map((i) => <Item key={i.id} label={i.label} id={i.id} value={get(seeMorePaperwork, i.id)} />) }
        </ItemContainer>
        <Footer/>
      </Container>
    </Wrapper>
  );
};

export default SeeMoreModal;
