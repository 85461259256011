import React from "react";
import Item from "./components/Item";
import { Wrapper } from "./style";

const Nomenclature = () => {
  return (
    <Wrapper>
      <Item numberData={"01"} fieldData={"Depto."} left={true} />
      <Item numberData={"02"} fieldData={"Municipio"} />
      <Item numberData={"013"} fieldData={"Sección"} />
      <Item numberData={"0124"} fieldData={"Chacra"} />
      <Item numberData={"0124"} fieldData={"Manzana"} />
      <Item numberData={"0124"} fieldData={"Parcela"} />
    </Wrapper>
  );
};

export default Nomenclature;
