import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  height: 100%;
  color: blue;
  border-radius: 8px;
  box-sizing: border-box;
  background: ${colors.white};
  border: 1px solid ${colors.gray99};
  border-radius: 4px;
  @media (max-width: 550px) {
    flex: 0 0 100%;
    max-height: 112px;
  }
`;
