import styled from "styled-components";

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    overflow: auto;
`;

export const ContainerOptions = styled.div`
    width: 100%;
    overflow: scroll;
`;