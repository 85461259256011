import React from "react";
import { isEmpty } from "utils/functions";
import Card from "./components/Card";
import { Wrapper } from "./styled";

const FileList = ({ files, removeFile }) => {
  const getFiles = (file, index) => <Card name={file} removeFile={removeFile} key={`${file}_${index}`} />;

  if (isEmpty(files)) return null;

  return <Wrapper>{files.map(getFiles)}</Wrapper>;
};

export default FileList;
