import React from "react";
import BottomPanel from "./components/BottomPanel";
import TopPanel from "./components/TopPanel";
import { Wrapper } from "./styled";

const Charts = () => {
  return (
    <Wrapper>
      <TopPanel />
      <BottomPanel />
    </Wrapper>
  );
};

export default Charts;
