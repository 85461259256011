/* eslint-disable react/no-children-prop */
import React from "react";
import { Wrapper, Item } from "./style";

const Number = ({ data }) => {
  return (
    <Wrapper>
      <Item children={data} />
    </Wrapper>
  );
};

export default Number;
