/* eslint-disable react/no-children-prop */
import React from "react";
import { useSelector } from "react-redux";
import { selectPaperworkData } from "store/PaperWork/selectors";
import Wrapper from "../RealStateTax/styled";
import { Title, Text } from "../styled";
import List from "./styled";
import { get } from "lodash";
import { isEmpty } from "utils/functions";

const IssuedCertificates = ({ selectedPlot }) => {
  const data = useSelector(selectPaperworkData);
  const issuedCertificates = get(data, `[${selectedPlot}].domainData.issuedCertificates`, []);

  return (
    <Wrapper>
      <Title children="Certificados emitidos" />
      {isEmpty(issuedCertificates) && <Text children="No existen certificados" />}
      <List>
        {issuedCertificates.map((certificate, index) => (
          <div key={index}>
            <Text children={`Nombre y apellido: ${get(certificate, "name", "-")}`} />
            <Text children={`Número: ${get(certificate, "number", "-")}`} />
            <Text children={`Fecha: ${get(certificate, "date", "-")}`} />
          </div>
        ))}
      </List>
    </Wrapper>
  );
};

export default IssuedCertificates;
