/* eslint-disable react/no-children-prop */
import React from "react";
import {
  Container,
  Wrapper,
  Logos,
  IconProv,
  IconDGC,
  Title,
  Description,
  Step2,
  NoProfile,
  IconProvMobile,
  IconDGCMobile,
  StepContainer,
} from "./styled";
import useResize from "hooks/useResize";

const Form = () => {
  const { screenWidth } = useResize();
  const title = "Tu perfil aun no ha sido validado";
  const description = "Un administrador verificará tu solicitud y validará tu ingreso. Ante cualquier inconveniente te recomendamos contactar a un administrador.";
  const step = <Step2 />;
  const image = <NoProfile />; 
  const iconProv = screenWidth <= 375 ? <IconProvMobile /> : <IconProv />;
  const iconDGC = screenWidth <= 375 ? <IconDGCMobile /> : <IconDGC />;

  return (
    <Wrapper>
      <Logos>
        {iconProv}
        {iconDGC}
      </Logos>
      <StepContainer>{step}</StepContainer>
      <Container>
        <Title children={title} />
        {image}
        <Description children={description} />
      </Container>
    </Wrapper>
  );
};

export default Form;
