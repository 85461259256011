import { OTHER_INPUTS } from "store/PaperWork/constants";
import { onlyLetters, onlyNumbers, validCuit } from "utils/functions";

export const getInitialValue = (stage, index, stageKey, key) => {
  if (!stage[index][OTHER_INPUTS][stageKey]) return "";
  return stage[index][OTHER_INPUTS][stageKey][key];
};

export const isInvalid = (value, min, max, type) => {
  if (type === "text" && !onlyLetters(value)) return true;
  if (type === "integer" && !onlyNumbers(value)) return true;
  if (type === "cuit" && !validCuit(value)) return true;
  if (value?.length > max || value?.length < min) return true;
  if (!value) return true;
  return false;
};
