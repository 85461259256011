import React from "react";
import { Container, Wrapper, Logos, IconProv, IconDGC, NoAccount, Text, Highlighted, Content } from "./styled";
import Header from "./components/Header";
import Elements from "./components/Elements";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { PATH_SIGN_UP } from "components/pages/App/constants";

const Form = () => {
  const dispatch = useDispatch();
  const signIn = () => dispatch(push(PATH_SIGN_UP));

  return (
    <Wrapper>
      <Logos>
        <IconProv />
        <IconDGC />
      </Logos>
      <Content>
        <Container>
          <Header />
          <Elements />
        </Container>
        <NoAccount>
          <Text>¿No tenés cuenta?</Text>
          <Highlighted onClick={signIn}>Registrate</Highlighted>
        </NoAccount>
      </Content>
    </Wrapper>
  );
};

export default Form;
