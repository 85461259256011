import React from "react";
import LabelOnlyButton from "components/commons/Buttons/LabelOnlyButton";
import { useDispatch, useSelector } from "react-redux";
import { updateUserInfo } from "store/Login/actions/actions";
import colors from "styles/colors";
import { Wrapper, ButtonLeft, ButtonRight } from "./styled";
import { isValid } from "./functions";
import { selectProfileDefaultRoute, selectUser } from "store/Login/selectors";
import { push } from "connected-react-router";

const Buttons = ({ data }) => {
  const dispatch = useDispatch();
  const defaultRoute = useSelector(selectProfileDefaultRoute);
  const user = useSelector(selectUser);
  const cancel = () => dispatch(push(defaultRoute));
  const submit = () => dispatch(updateUserInfo(data));

  return (
    <Wrapper>
      <ButtonLeft>
        <LabelOnlyButton label={"Cancelar"} background={colors.white} color={colors.blue50} onClick={cancel} border={"1px solid #2D4B9F"} />
      </ButtonLeft>
      <ButtonRight>
        <LabelOnlyButton label={"Guardar cambios"} background={colors.blue50} color={colors.white} onClick={submit} disabled={!isValid(data, user)} />
      </ButtonRight>
    </Wrapper>
  );
};

export default Buttons;
