import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  transition: all 0.4s;
  z-index: 1;
`;

export const DropDownList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: top;
  width: 100%;
  max-height: 200px;
  background: ${colors.white};
  border: 1px solid ${colors.gray90};
  border-radius: 4px;
  box-sizing: border-box;
  color: ${colors.lightBlue70};
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  overflow: overlay;
`;

export const ListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  min-height: ${({ height }) => height};
  padding-left: 12px;
  list-style: none;
  border-bottom: 1px solid ${colors.gray90};
  color: ${colors.gray10};
  transition: all 0.6s;
  &:hover {
    background: ${colors.gray100};
  }
`;
