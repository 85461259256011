/* eslint-disable react/no-children-prop */
import React from "react";
import { Wrapper, Header, Text, Container } from "./styled";
import { selectPaperworkData } from "store/PaperWork/selectors";
import { useSelector } from "react-redux";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

const ImprovementValue = () => {
    const data = useSelector(selectPaperworkData);
    const e1Value = get(data, "e1Value", "-");
    const improvementRatio = get(data, "improvementRatio", "-");
    
    if(isEmpty(data)) return null;
    return (
        <Wrapper>
            <Header children="Valor de Mejora" />
            <Container>
                <Text children={`Mejora: ${e1Value}`} />
                <Text children={`Ratio de Mejora: ${improvementRatio}`} />
            </Container>
        </Wrapper>
    );
};

export default ImprovementValue;