import { PAPERWORK_STATE } from "store/PaperWork/constants";
import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  align-items: top;
  justify-content: left;
  width: 100%;
  height: 73px;
  padding: 22px 0px 22px 40px;
  box-sizing: border-box;
  border-bottom: 1px solid ${colors.gray95};
`;

export const Text = styled.span`
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.gray20};
`;

export const CreatorData = styled(Text)`
  margin-left: 3px;
`;

export const State = styled.span`
  padding-left: 5px;
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${({ state }) => {
    if (state === PAPERWORK_STATE.APPROVED) return `${colors.green50}`;
    if (state === PAPERWORK_STATE.REJECTED) return `${colors.red60}`;
    if (state === PAPERWORK_STATE.OBSERVED) return `${colors.orange50}`;
    return `${colors.lightBlue50}`;
    }};
`;
