import React from "react";
import Icon from "images/icons/white/surface.svg";
import { Wrapper, Image } from "./styled";

const SurfaceIcon = ({ onClick }) => {
  return (
    <Wrapper onClick={onClick}>
      <Image src={Icon} alt="icon" />
    </Wrapper>
  );
};

export default SurfaceIcon;
