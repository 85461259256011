import styled from "styled-components";
import colors from "styles/colors";
import { spinner } from "styles/effects";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  border: ${(props) => props.border};
  cursor: pointer;
  transition: all 0.4s;
  background: ${(props) => props.background};
  ${({ isLoading }) => isLoading && spinner({ diameter: "12px", foreground: `${colors.gray99}` })};
  ${({ isLoading }) => isLoading && "pointer-events: none;"};
  &:hover {
    opacity: 0.8;
  }
  ${({ disabled }) =>
    disabled &&
    `
    pointer-events: none;
    opacity: 0.4;
  `}
`;

export const Text = styled.div`
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 600;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "14px")};
  line-height: 24px;
  color: ${(props) => props.color};
`;
