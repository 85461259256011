/* eslint-disable react/no-children-prop */
import React from "react";
import { Wrapper, Text, CloseIcon } from "./styled";

const Card = ({ name, removeFile }) => {
  return (
    <Wrapper>
      <Text children={name} />
      <CloseIcon onClick={() => removeFile(name)} />
    </Wrapper>
  );
};
export default Card;
