import React from "react";
import { Wrapper, Container } from "./styled";
import { LogoutIcon } from "../../../../../../styled";
import Tooltip from "components/commons/Tooltip";

const Icon = () => {
  return (
    <Wrapper>
      <Tooltip title={"Cerrar sesión"}>
        <Container>
          <LogoutIcon />
        </Container>
      </Tooltip>
    </Wrapper>
  );
};

export default Icon;
