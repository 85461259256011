/* eslint-disable react/no-children-prop */
import React from "react";
import { useSelector } from "react-redux";
import { selectPaperworkData } from "store/PaperWork/selectors";
import Wrapper from "../RealStateTax/styled";
import { Column, Content, Text, Title } from "../styled";
import { get } from "lodash";

const Location = ({selectedPlot}) => {
  const data = useSelector(selectPaperworkData);
  const location = get(data, `[${selectedPlot}].domainData.location`, "-");

  return (
    <Wrapper>
      <Title children="Ubicación" />
      <Content>
        <Column>
          <Text children={location} />
        </Column>
      </Content>
    </Wrapper>
  );
};

export default Location;
