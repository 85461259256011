/* eslint-disable no-unused-vars */
import styled, { keyframes } from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
  gap: 8px;
  box-sizing: border-box;
  @media (max-width: 1180px) {
    gap: 2px;
  }
`;

export const Name = styled.div`
  display: flex;
  align-items: center;
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.gray20};
  @media (max-width: 1180px) {
    font-size: 11px;
  }
`;

export const Check = styled.input`
  display: flex;
  width: 20px;
  height: 20px;
  transition: all 1s;
  margin-bottom: 2px;
`;
