import React from "react";
import { Wrapper } from "./styled";
import Data from "./components/Data";
import Checkbox from "./components/Checkbox";
import { get } from "lodash";
import { useSelector } from "react-redux";
import { selectPaperworkStageProgress, selectPaperworkStructure, selectStageIndex } from "store/PaperWork/selectors";
import { isEmpty } from "lodash";

const Card = ({ paperwork, pProgId, departure, date }) => {
  const structure = useSelector(selectPaperworkStructure);
  const index = useSelector(selectStageIndex);
  const stageKey = get(structure, `stages[${index}].form.otherInputs[0].key`, "");
  const stageProgress = useSelector(selectPaperworkStageProgress);
  const otherInputsData = get(stageProgress, `[${index}].otherInputsData`, {});
  const selection = get(otherInputsData, `[${stageKey}]`, []);
  const isChecked = selection.includes(pProgId);
  const disabled = !isEmpty(selection) && !isChecked;

  return (
    <Wrapper checked={isChecked} disabled={disabled}>
      <Data paperwork={paperwork} pProgId={pProgId} departure={departure} date={date} />
      <Checkbox checked={isChecked} stageKey={stageKey} id={pProgId} />
    </Wrapper>
  );
};

export default Card;
