/* eslint-disable react/no-children-prop */
import React from "react";
import isEmpty from "lodash/isEmpty";
import { Content, Title, Wrapper } from "./styled";
import { useSelector } from "react-redux";
import { selectPaperworkMetadata } from "store/PaperWork/selectors";
import Observation from "./Observation";
import get from "lodash/get";
import { PAPERWORK_STATE } from "store/PaperWork/constants";

const Observations = () => {
  const paperworkProgress = useSelector(selectPaperworkMetadata);
  const observations = get(paperworkProgress, "observations", []);
  const sortedObservations = [...observations].sort((a, b) => b.inserted_date - a.inserted_date);

  const metadata = useSelector(selectPaperworkMetadata);
  const state = get(metadata, "state", null);
  const isRejected = state === PAPERWORK_STATE.REJECTED;

  if(isEmpty(observations)) return null;
  if(isRejected) return null;
  return (
    <Wrapper>
      <Title>Observaciones</Title>
      <Content>{sortedObservations.map((obs) => <Observation key={obs.id} observation={obs} />)}</Content>
    </Wrapper>
  );
};

export default Observations;
