import React from "react";
import Buttons from "./Buttons";
import { Wrapper, Title, Subtitle } from "./styled";

const Actions = () => {
  return (
    <Wrapper>
      <Title>
        ¡Bienvenido a la app de DGC!
      </Title>
      <Subtitle>
        En esta app podrás realizar tus trámites de Catastro de la Provincia de Misiones.
      </Subtitle>
      <Buttons />
    </Wrapper>
  );
};

export default Actions;
