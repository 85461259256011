import styled from "styled-components";
import { ReactComponent as Icon } from "images/icons/gray/edit.svg";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  cursor: pointer;
`;

export const Edit = styled(Icon)``;
