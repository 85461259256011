import React from "react";
import Indicator from "./components/Indicator";
import { Wrapper, Text} from "./styled";

const Counter = ({ data, color, label, hide }) => {
  return (
    <Wrapper>
      <Indicator data={data} color={color} duration={1} hide={hide}/>
      <Text color={color} hide={hide}>{label}</Text>
    </Wrapper>
  );
};

export default Counter;
