import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: start;
  box-sizing: border-box;
  padding-top: 20px;
  @media (max-width: 980px) {
    padding: 0px 16px;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
  }
`;

export const Stages = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 30px;
  padding-bottom: 30px;
`;
