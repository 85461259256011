import get from "lodash/get";
import { wsConnected, wsDisconnected } from "store/websocket/actions";
import { setDocuments, setPaperwDetail } from "store/PaperWork/actions/actions";

const dispatchWSAction = (store, event, payload) => {
  switch (event) {
    case "connect":
      store.dispatch(wsConnected());
      break;

    case "disconnect":
      store.dispatch(wsDisconnected());
      break;
      
    case "paperwork_detail_update":
      store.dispatch(setPaperwDetail({metadata: get(payload, "metadata", {})}));
      store.dispatch(setDocuments(get(payload, "apiDocuments", []), get(payload, "storageDocuments", [])));
      break;

    default:
      break;
  }
};

export default dispatchWSAction;
