/* eslint-disable react/no-children-prop */
import React from "react";
import {
  Container,
  Wrapper,
  Logos,
  IconProv,
  IconDGC,
  Step1,
  Title,
  UnverifiedAccount,
  Description,
  Step2,
  NoProfile,
  IconProvMobile,
  IconDGCMobile,
  StepContainer,
} from "./styled";
import { useSelector } from "react-redux";
import { selectUser } from "store/Login/selectors";
import { get } from "lodash";
import useResize from "hooks/useResize";

const Form = () => {
  const user = useSelector(selectUser);
  const { screenWidth } = useResize();
  const profileId = get(user, "profileId", null);
  const verified = get(user, "verified", false);
  const title = !profileId && verified ? "Solo un paso más..." : "Tu mail aun no ha sido validado";
  const description =
    !profileId && verified
      ? "Un administrador verificará tu solicitud y validará tu ingreso. Ante cualquier inconveniente te recomendamos contactar a un administrador."
      : "Por favor revisa tu casilla de mail para poder registrarte. Si aún no lo recibiste, es recomendable que contactes al administrador.";
  const step = !profileId && verified ? <Step2 /> : <Step1 />;
  const image = !profileId && verified ? <NoProfile /> : <UnverifiedAccount />;
  const iconProv = screenWidth <= 375 ? <IconProvMobile /> : <IconProv />;
  const iconDGC = screenWidth <= 375 ? <IconDGCMobile /> : <IconDGC />;

  return (
    <Wrapper>
      <Logos>
        {iconProv}
        {iconDGC}
      </Logos>
      <StepContainer>{step}</StepContainer>
      <Container>
        <Title children={title} />
        {image}
        <Description children={description} />
      </Container>
    </Wrapper>
  );
};

export default Form;
