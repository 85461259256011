import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  width: 100%;
  gap: 4px;
  font-size: 60px;
  @media (max-width: 580px) {
    align-items: center;
  }
`;

export const Name = styled.div`
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: ${colors.black};
  @media (max-width: 580px) {
    font-size: 22px;
  }
`;

export const Email = styled.div`
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.gray50};
  @media (max-width: 580px) {
    font-size: 12px;
  }
`;
