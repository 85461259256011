import styled from "styled-components";
import colors from "styles/colors";
import { ReactComponent as Arrow } from "images/icons/gray/arrow.svg";

export const TabWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 20px;
  flex: 0 0 36px;
  align-items: center;
  box-sizing: border-box;
  padding: 8px 40px;
  user-select: none;
  background-color: ${colors.blue100};
`;

export const Tab = styled.div`
  display: flex;
  font-family: 'OpenSansRegular';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  height: 100%;
  width: 46px;
  cursor: pointer;
  color: ${colors.gray20};
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  ${({ isSelected }) => isSelected && `
    font-weight: 600;
    color: ${colors.orange50};
    border-bottom: 2px solid ${colors.orange50};
  `};
`;

export const RightArrow = styled(Arrow)`
  ${({ $show }) => !$show && "visibility: hidden"};
  cursor: pointer;
  width: 16px;
  height: 16px;
  & path {
  fill: ${colors.gray20};
  }
`;

export const LeftArrow = styled(RightArrow)`
  transform: rotate(180deg);
`;