/* eslint-disable react/no-children-prop */
import React from "react";
import { Wrapper, Title, Description } from "./styled";

const Header = () => {
  return (
    <Wrapper>
      <Title children="Iniciar sesión" />
      <Description children="Ingresá tus datos para poder iniciar sesión y comenzar a operar." />
    </Wrapper>
  );
};

export default Header;
