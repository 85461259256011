import produce from "immer";
import { initialState } from "./initialState";
import { LOADING_FAILED, LOADING_START, LOADING_STOP, RESET_GENERAL_STORE } from "./types";

const generalReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case LOADING_START:
        draft.loading += 1;
        draft.failed = false;
        break;
      case LOADING_FAILED:
        draft.failed = true;
        draft.loading = Math.max(0, draft.loading - 1);
        break;
      case LOADING_STOP:
        draft.loading = Math.max(0, draft.loading - 1);
        break;
      case RESET_GENERAL_STORE:
        draft.loading = 0;
        draft.failed = false;
        break;
    }
  });

export default generalReducer;
