import React from "react";
import { Wrapper, TextArea } from "./style";
import { useSelector } from "react-redux";
import { selectPaperworkMetadata } from "store/PaperWork/selectors";
import get from "lodash/get";
import last from "lodash/last";

const Content = ({ expanded }) => {
  const metadata = useSelector(selectPaperworkMetadata);
  const observations = get(metadata, "observations", []);
  const lastObservation = get(last(observations), "data", "");

  return (
    <Wrapper expanded={expanded}>
      <TextArea value={lastObservation} />
    </Wrapper>
  );
};

export default Content;
