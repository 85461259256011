import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectUserId, selectProfileDefaultRoute, selectTokenExpires, selectUser } from "store/Login/selectors";
import { push } from "connected-react-router";
import Actions from "./Actions";
import { Wrapper, Sidebar, IllustrationSidebar } from "./styled";
import { BYPASS_LOGIN, PATH_FISCAL_KEY_FIRST_LOGIN, PATH_FISCAL_KEY_LOGIN_VALIDATION } from "../../App/constants";
import { get } from "lodash";

const FiscalKeyLogin = () => {
  const dispatch = useDispatch();
  const id = useSelector(selectUserId);
  const user = useSelector(selectUser);
  const profileId = get(user, "profileId", null);
  const defaultRoute = useSelector(selectProfileDefaultRoute);
  const tokenExpires = useSelector(selectTokenExpires);
  const isTokenExpired = () => tokenExpires < Date.now() / 1000;
  const email = get(user, "email", "");
  const phone = get(user, "phone", "");
  const formCompleted = phone && email;
  const uid = get(user, "firebaseDataId", "");
  const internalUser = uid && profileId; //It is registered but has not yet been assigned a profile.

  useEffect(() => {
    if (id && !formCompleted && !internalUser) dispatch(push(PATH_FISCAL_KEY_FIRST_LOGIN));
    else if (id && !profileId && formCompleted) dispatch(push(PATH_FISCAL_KEY_LOGIN_VALIDATION));
    else if (id && BYPASS_LOGIN) dispatch(push(defaultRoute));
    else if (id && !isTokenExpired()) dispatch(push(defaultRoute));
  }, [user]);

  return (
    <Wrapper>
      <Sidebar>
        <IllustrationSidebar />
      </Sidebar>
      <Actions />
    </Wrapper>
  );
};

export default FiscalKeyLogin;
