import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 48px;
  box-sizing: border-box;
  overflow-y: visible;
  @media (max-width: 980px) {
    padding: 16px;
  }
`;

export const ContainerWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  width: 100%;
  column-gap: 16px;
  row-gap: 16px;
  @media (max-width: 1440px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 680px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 350px) {
    grid-template-columns: 1fr;
  }
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

export const Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 72px;
  height: 72px;
  border-radius: 100%;
  opacity: 0.8;
  background: ${colors.blue40};
`;

export const Text = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  font-family: "Donime";
  font-size: 12px;
  border-radius: 100%;
  color: ${colors.black};
`;
