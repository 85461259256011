/* eslint-disable no-unused-vars */
import React from "react";
import colors from "styles/colors";
import { ApprovedIcon, ObservedIcon, RejectIcon, Wrapper, Container } from "./styled";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "store/Modals/actions";
import { selectPaperworkMetadata, selectUserCanEditPaperworkProgress } from "store/PaperWork/selectors";
import { approvePaperwork } from "store/PaperWork/actions/requests";
import { MODAL_EDIT_OBSERVATION, MODAL_EDIT_REJECTION } from "store/Modals/constants";
import { PAPERWORK_STATE } from "store/PaperWork/constants";
import { selectIsAllowed } from "store/Login/selectors";
import { USER_PROFILE_ID } from "store/Login/initialState";
import LabelIconButton from "components/pages/PaperworkDetail/components/LabelIconButton";
import Tooltip from "components/commons/Tooltip";
import get from "lodash/get";

const Commons = () => {
  const dispatch = useDispatch();
  const allowedRoles = [USER_PROFILE_ID.ADMINISTRATOR, USER_PROFILE_ID.OPERATOR, USER_PROFILE_ID.VALUATION_OPERATOR];
  const allowed = useSelector((state) => selectIsAllowed(state, allowedRoles));
  const metadata = useSelector(selectPaperworkMetadata);
  const id = get(metadata, "id", null);
  const state = get(metadata, "state", null);
  const isObserved = state === PAPERWORK_STATE.OBSERVED;
  const canEdit = useSelector(selectUserCanEditPaperworkProgress);

  const approve = () => dispatch(approvePaperwork(id));
  const reject = () => dispatch(openModal(MODAL_EDIT_REJECTION));
  const sendToReview = () => dispatch(openModal(MODAL_EDIT_OBSERVATION));

  if (!allowed) return null;
  return (
    <Tooltip title={"Esperando respuestas\ndel area correspondiente"} hidden={!isObserved}>
      <Wrapper>
        <Container>
          <LabelIconButton
            type={"secondary"}
            disabled={isObserved || !canEdit}
            color={colors.blue50}
            background={colors.white}
            label={"Observar"}
            border={`1px solid ${colors.blue70}`}
            icon={<ObservedIcon disabled={isObserved || !canEdit} />}
            onClick={sendToReview}
            width={"216px"}
            height={"40px"}
          />
          <LabelIconButton
            type={"secondary"}
            disabled={isObserved || !canEdit}
            color={colors.blue50}
            background={colors.white}
            label={"Rechazar"}
            border={`1px solid ${colors.blue70}`}
            icon={<RejectIcon disabled={isObserved || !canEdit} />}
            onClick={reject}
            width={"216px"}
            height={"40px"}
          />
          <LabelIconButton
            type={"primary"}
            disabled={isObserved || !canEdit}
            color={colors.white}
            background={colors.blue50}
            label={"Aprobar"}
            icon={<ApprovedIcon disabled={isObserved || !canEdit} />}
            onClick={approve}
            width={"216px"}
            height={"40px"}
          />
        </Container>
      </Wrapper>
    </Tooltip>
  );
};

export default Commons;
