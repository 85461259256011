/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-children-prop */
import React from "react";
import { Wrapper, Button, Delete, Pause, Play } from "./styled";
import { MODAL_SEE_MORE } from "store/Modals/constants";
import { useDispatch } from "react-redux";
import { openModal } from "store/Modals/actions";
import { setSeeMorePaperwork } from "store/PaperWork/actions/actions";
import { enabledUser } from "store/BackOffice/actions/requests";

const ActiveCell = ({ row }) => {
  const dispatch = useDispatch();
  const currentStage = row.original.disabled ? 1 : 0;

  const onClick = (event) => {
    dispatch(enabledUser(row.original.id, currentStage));
    event.stopPropagation();
  };

  const getIcon = () => {
    if (row.original.disabled) return <Play />;
    return <Pause />;
  };

  return <Wrapper onClick={onClick}>{getIcon()}</Wrapper>;
};

export default ActiveCell;
