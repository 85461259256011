/* eslint-disable no-unused-vars */
import React from "react";
import { Wrapper, Container, Title, Description, Buttons, AcceptButtonContainer, Icon } from "./style";
import { useDispatch, useSelector } from "react-redux";
import { selectParams } from "store/Modals/selectors";
import { get } from "lodash";
import LabelOnlyButton from "components/commons/Buttons/LabelOnlyButton";
import colors from "styles/colors";
import { closeModal } from "store/Modals/actions";
import { MODAL_WARN_MULTIPLE_PLOTS } from "store/Modals/constants";

const WarnMultiplePlotsModal = () => {
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(closeModal(MODAL_WARN_MULTIPLE_PLOTS));
  };

  const params = useSelector(selectParams);
  const plots = get(params, "plots", []);
  const plotList = plots.join(", ");

  return (
    <Wrapper>
      <Container>
        <Icon />
        <Title>
          Múltiples parcelas
        </Title>
        <Description>
          Este trámite contiene múltiples parcelas: {plotList}. Se deberá pagar una tasa por cada una.
        </Description>
        <Buttons>
          <AcceptButtonContainer>
            <LabelOnlyButton 
              border={` 1px solid ${colors.blue50}`} 
              background={colors.blue50} 
              color={colors.white} 
              label="Aceptar"
              onClick={onClose}
            />
          </AcceptButtonContainer>
        </Buttons>
      </Container>
    </Wrapper>
  );
};

export default WarnMultiplePlotsModal;
