import { initialState } from "store/Filters/initialState";
import * as filters from "store/Filters/constants";
import { PAPERWORK_STATE } from "store/PaperWork/constants";
import { IN_PROGRESS_KEY, FINISHED_KEY } from "../../../Section/functions";

export const FILTERS_HOME_DEFAULT = {
    [IN_PROGRESS_KEY]:{ 
        ...initialState,
        [filters.FILTER_STATUS]: [
            PAPERWORK_STATE.OBSERVED,
            PAPERWORK_STATE.PENDING,
            PAPERWORK_STATE.APPROVE_REQUESTED,
            PAPERWORK_STATE.REJECT_REQUESTED,
            PAPERWORK_STATE.MENDING_REQUESTED,
        ],
        [filters.FILTER_PAPERWORK_PROGRESS_ID]: ""
    },
    [FINISHED_KEY]:{ 
        ...initialState,
        [filters.FILTER_STATUS]: [PAPERWORK_STATE.APPROVED, PAPERWORK_STATE.REJECTED],
        [filters.FILTER_PAPERWORK_PROGRESS_ID]: ""
    }
};
