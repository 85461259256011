import React from "react";
import { Wrapper, Label, Value, State } from "./style";

const Item = ({ id, label, value }) => {
  return (
    <Wrapper>
      <Label>{label}</Label>
      {id !== "stateName" && <Value>{value || "-"}</Value>}
      {id === "stateName" && <State state={value}>{value}</State>}
    </Wrapper>
  );
};

export default Item;
