import React from "react";
import { Wrapper, Title, IconContainer } from "./style";
import { MODAL_NOMENCLARURE } from "store/Modals/constants";
import { useDispatch } from "react-redux";
import { closeModal } from "store/Modals/actions";
import CloseIcon from "components/commons/Icons/CloseIcon";

const Header = () => {
  const dispatch = useDispatch();

  const toggle = () => {
    dispatch(closeModal(MODAL_NOMENCLARURE));
  };

  return (
    <Wrapper>
      <Title>¿Dónde encuentro estos datos?</Title>
      <IconContainer>
        <CloseIcon onClick={toggle} />
      </IconContainer>
    </Wrapper>
  );
};

export default Header;
