import React, { useState } from "react";
import Button from "./components/Button";
import Info from "./components/Info";
import UserImage from "./components/UserImage";
import { Wrapper, Container, InfoContainer, Text, Error } from "./styled";

const UploadFile = () => {
  const [error, setError] = useState("");

  return (
    <Wrapper>
      <Container>
        <UserImage />
        <InfoContainer>
          <Info />
          <Button setError={setError} />
        </InfoContainer>
      </Container>
      <Text>La imagen no debe superar los 3MB</Text>
      <Error>{error}</Error>
    </Wrapper>
  );
};

export default UploadFile;
