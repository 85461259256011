import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { selectPathEnabled, selectProfileDefaultRoute, selectTokenExpires, selectUser, selectUserId } from "store/Login/selectors";
import { BYPASS_LOGIN, PATH_FISCAL_KEY_LOGIN, PATH_VALIDATION } from "components/pages/App/constants";
import { get } from "lodash";

const PrivateRoute = ({ component: Component, children, path, ...rest }) => {
  const id = useSelector(selectUserId);
  const pathEnabled = useSelector((state) => selectPathEnabled(state, path));
  const defaultRoute = useSelector(selectProfileDefaultRoute);
  const tokenExpires = useSelector(selectTokenExpires);
  const isTokenExpired = () => tokenExpires < Date.now() / 1000;
  const user = useSelector(selectUser);
  const uid = get(user, "uid", false);
  const profile = get(user, "profileId", null);
  const verified = get(user, "verified", false);

  const render = (props) => {
    if (uid && (!profile || !verified)) return <Redirect to={{ pathname: PATH_VALIDATION }} />;
    if (!id || (isTokenExpired() && !BYPASS_LOGIN && !uid)) return <Redirect to={{ pathname: PATH_FISCAL_KEY_LOGIN }} />;
    if (pathEnabled) return <Component {...props}>{children}</Component>;
    return <Redirect to={{ pathname: defaultRoute }} />;
  };

  return <Route {...rest} render={render} />;
};

export default PrivateRoute;
