import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100%;
  max-height: 112px;
  font-size: 60px;
  color: blue;
  border-radius: 8px;
  box-sizing: border-box;
  cursor: ${({ onClick }) => (onClick ? "pointer" : "default")};
  background: ${colors.white};
  border: 1px solid ${colors.gray99};
  border-radius: 4px;
  padding: 8px;
  overflow: hidden;
  position: relative;
  @media (max-width: 550px) {
    flex: 0 0 100%;
    max-height: 112px;
  }
`;

export const ChartContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 178px;
  font-size: 60px;
  color: blue;
  border-radius: 8px;
  box-sizing: border-box;
  background: ${colors.white};
  background: red;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  margin-right: auto;
  width: 100%;
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  color: ${colors.gray60};
  box-sizing: border-box;
  flex: 0 0 16px;
`;
