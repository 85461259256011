/* eslint-disable quotes */
import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 2px;
  ${({ disabled }) => disabled && `pointer-events: none;`}
`;

export const Label = styled.div`
  margin-right: auto;
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.gray70};
  @media (max-width: 1366px) {
  }
`;

export const Input = styled.textarea`
  display: flex;
  padding: 12px 16px;
  width: 100%;
  height: 168px;
  box-sizing: 4px;
  background: ${colors.gray100};
  outline: none;
  border: 1px solid ${colors.gray100};
  resize: none;
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.gray50};
  box-sizing: border-box;
  &:focus {
    outline: none;
    border-color: ${colors.lightBlue50};
  }
  ::placeholder {
    color: ${colors.gray90};
  }
  @media (max-width: 1366px) {
    padding: 10px 10px;
    font-size: 10px;
  }
`;
