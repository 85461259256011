import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
  min-height: 88px;
  padding: 16px;
  gap: 8px;
  box-sizing: border-box;
  border: 1px solid ${colors.gray95};
  background: ${colors.white};
  border-radius: 4px;
`;

export const Text = styled.div`
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.gray20};
  flex: 1 1 0;
  margin-right: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
`;
