import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  max-width: 256px;
  height: ${({ toogle }) => (toogle ? "24px" : "40px")};
  margin-left: ${({ toogle }) => (toogle ? "0px" : "7px")};
  padding-left: ${({ toogle }) => (toogle ? "0px" : "16px")};
  border-radius: 4px;
  box-sizing: border-box;
  &:hover {
    background: ${({ toogle }) => (toogle ? "none" : colors.gray99)};
    transition: all 0.5s;
  }
  ${({ selected, toogle }) =>
    selected &&
    !toogle &&
    `
    background: ${colors.lightBlue95};
    transition: all 0.5s;
  `}
`;

export const Icon = styled.div`
  display: ${(props) => (props.toogle ? "none" : "flex")};
  align-items: center;
  justify-content: left;
  height: ${({ toogle }) => (toogle ? "24px" : "40px")};
  transition: all 0.5s;
`;

export const TitleSection = styled.div`
  display: ${(props) => (props.toogle ? "none" : "flex")};
  align-items: center;
  justify-content: left;
  height: ${({ toogle }) => (toogle ? "24px" : "40px")};
  padding-left: 5px;
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.gray20};
  transition: all 0.5s;
  white-space: nowrap;
  box-sizing: border-box;
  ${({ selected }) =>
    selected &&
    `
    color: ${colors.blue50};
    font-weight: 700;
  `}
`;
