import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 100%;
  min-height: 88px;
  gap: 5px;
  @media (max-width: 580px) {
    min-height: 68px;
  }
`;
