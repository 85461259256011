import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  min-height: 40px;
  border-radius: 100px;
  background: ${({ selected, disabled }) => (selected && !disabled ? `${colors.blue70}` : `${colors.gray90}`)};
`;
