import React from "react";
import { useSelector } from "react-redux";
import { Wrapper } from "./styled";
import AdvancedFilters from "components/commons/Table/components/Filters/components/AdvancedFilters";
import { getUserPaperworks } from "store/PaperWork/actions/requests";
import { HOME_FILTERS } from "components/commons/Modals/FiltersModal/constants";
import { selectFilters } from "store/Filters/selectors";
import { FILTERS_HOME_DEFAULT } from "./constants";
import { getCustomFilters } from "store/Filters/functions";

const Filters = ({ show, id }) => {
  const storeFilters = useSelector(selectFilters);
  const filters = getCustomFilters(storeFilters, FILTERS_HOME_DEFAULT[id]);

  if (!show) return null;
  return (
    <Wrapper>
      <AdvancedFilters filtersConstants={HOME_FILTERS} getPaperworkHistory={() => getUserPaperworks(filters)} />
    </Wrapper>
  );
};

export default Filters;
