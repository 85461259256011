import React from "react";
import { useSelector } from "react-redux";
import { selectUserEmail, selectUserName } from "store/Login/selectors";
import { Email, Name, Wrapper } from "./styled";

const Info = () => {
  const name = useSelector(selectUserName);
  const email = useSelector(selectUserEmail);

  return (
    <Wrapper>
      <Name>{name}</Name>
      <Email>{email}</Email>
    </Wrapper>
  );
};

export default Info;
