import React from "react";
import { Text, Wrapper } from "./styled";

const Header = ({ title }) => {
  return (
    <Wrapper>
      <Text>{title}</Text>
    </Wrapper>
  );
};

export default Header;
