/* eslint-disable no-unused-vars */
import React from "react";
import { useSelector } from "react-redux";
import { selectPaperworkMetadata } from "store/PaperWork/selectors";
import { Wrapper, Progress } from "./styled";

const ProgressBar = () => {
  const { stageProgress } = useSelector(selectPaperworkMetadata);
  const stagesLength = stageProgress?.length;
  const completes = [];

  stageProgress?.map((e) => {
    if (e.alreadyFilled) completes.push(e);
  });

  const getProgress = (com) => {
    return (com / stagesLength) * 100;
  };

  return (
    <Wrapper>
      <Progress width={`${getProgress(completes.length)}${"%"}`} />
    </Wrapper>
  );
};

export default ProgressBar;
