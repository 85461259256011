import fetch from "../connector";
import * as paths from "components/pages/App/constants";

export const getUserData = (token) => fetch(`${paths.KEYCLOAK_BASE_URL}/auth/realms/stuc-afip/protocol/openid-connect`, "/userinfo", { headers: { Authorization: `Bearer ${token}` } });

export const getUser = (token) => fetch(paths.PATH_BASE_URL, `/v1/user?token=${token}`, {});

export const getResetPassword = (email) =>
  fetch(paths.PATH_BASE_URL, paths.getResetPassword(email), {
    method: "POST",
  });

export const getIdToken = (refreshToken) =>
  fetch(null, paths.getIdToken(), {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    params: { grant_type: "refresh_token", refresh_token: refreshToken },
  });

export const updateUserInfo = (data, token) =>
  fetch(paths.PATH_BASE_URL, paths.getPathUpdateUserInfo(token), {
    method: "PUT",
    data: data,
  });

export const updateUserPicture = (data, token) =>
  fetch(paths.PATH_BASE_URL, paths.getPathUpdateUserPicture(token), {
    method: "PUT",
    data: data,
  });

export const getUserPicture = (token) =>
  fetch(paths.PATH_BASE_URL, paths.getPathUpdateUserPicture(token), {
    method: "GET",
    responseType: "arraybuffer",
  });
