import BackOfficeHeader from "components/commons/BackofficeHeader";
import React from "react";
import { useSelector } from "react-redux";
import { PAPERWORK_STATE, PAPERWORK_TYPE } from "store/PaperWork/constants";
import get from "lodash/get";
import { PATH_DASHBOARD } from "components/pages/App/constants";
import { selectPaperworkMetadata } from "store/PaperWork/selectors";

const Header = () => {
  const metadata = useSelector(selectPaperworkMetadata);
  const pwState = get(metadata, "state", null);
  const pwType = get(metadata, "paperworkId", null);

  const finishedTitle = {
    [PAPERWORK_TYPE.E1]: "Informe final",
    [PAPERWORK_TYPE.D1]: "Certificado catastral",
    [PAPERWORK_TYPE.D2]: "Revalidación de Certificado catastral",
    [PAPERWORK_TYPE.D5]: "Informe catastral",
  };

  const title = pwState === PAPERWORK_STATE.APPROVED || pwState === PAPERWORK_STATE.REJECTED ? finishedTitle[pwType] : "Detalle del trámite";

  return <BackOfficeHeader title={title} fontSize={"28px"} showBack={"Volver a bandeja de trámites"} route={PATH_DASHBOARD} />;
};

export default Header;
