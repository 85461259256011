/* eslint-disable react/no-children-prop */
import React from "react";
import { Wrapper, Container, Line, Item } from "./style";

const Field = ({ data, left }) => {
  return (
    <Wrapper>
      <Container>
        <Line left={left} />
        <Item children={data} />
      </Container>
    </Wrapper>
  );
};

export default Field;
