import React, { useState } from "react";
import { ButtonContainer, InputContainer, Wrapper } from "./styled";
import LabelOnlyButton from "components/commons/Buttons/LabelOnlyButton";
import colors from "styles/colors";
import { useDispatch } from "react-redux";
import EmailInput from "./components/EmailInput";
import { emailPattern } from "utils/functions";
import { resetPassword } from "store/Login/actions/actions";

const Content = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const send = () => dispatch(resetPassword(email));

  return (
    <Wrapper>
      <InputContainer>
        <EmailInput setEmail={setEmail} />
      </InputContainer>
      <ButtonContainer>
        <LabelOnlyButton disabled={!emailPattern(email)} label="Enviar solicitud" background={colors.blue50} color={colors.white} onClick={send} />
      </ButtonContainer>
    </Wrapper>
  );
};

export default Content;
