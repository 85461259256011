import React from "react";
import IconButton from "components/commons/Buttons/IconButton";
import FilterIcon from "components/commons/Icons/FilterIcon";
import LabelIconButton from "components/pages/PaperworkDetail/components/LabelIconButton";
import { SIZE_LIMIT_TABLET } from "components/commons/MainLayout/constants";
import useResize from "hooks/useResize";
import colors from "styles/colors";
import Wrapper from "./styled";

const Button = ({ border, background, isActive }) => {
  const { screenWidth } = useResize();
  if (screenWidth <= SIZE_LIMIT_TABLET)
    return (
      <Wrapper>
        <IconButton icon={<FilterIcon filled={isActive} />} />
      </Wrapper>
    );
  return <LabelIconButton border={border} color={colors.blue50} background={background} label={"Filtros"} icon={<FilterIcon filled={isActive} />} />;
};

export default Button;
