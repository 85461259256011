import React, { useEffect, useState } from "react";
import { Wrapper, Image } from "./styled";
import Icon from "images/icons/general/user.svg";
import { useSelector } from "react-redux";
import { selectToken, selectUserPicture } from "store/Login/selectors";
import { getPathUserPicture } from "components/pages/App/constants";
import ImageLoading from "components/commons/Spinners/ImageLoading";

const UserImage = () => {
  const token = useSelector(selectToken);
  const userPicture = useSelector(selectUserPicture);
  const [isLoading, setIsLoading] = useState(true);
  const [source, setSource] = useState(null);
  const imgSource = userPicture ? source : Icon;

  const imageOnLoad = () => setIsLoading(false);

  useEffect(() => setIsLoading(true), [userPicture]);

  useEffect(() => setSource(""), [userPicture]);

  useEffect(() => setSource(getPathUserPicture(token)), [source]);

  return (
    <Wrapper>
      <ImageLoading show={isLoading && userPicture} size={"96px"} />
      <Image src={imgSource} alt="image" onLoad={imageOnLoad} isLoading={isLoading} />
    </Wrapper>
  );
};

export default UserImage;
