import React from "react";
import Options from "./components/Options";
import { Wrapper } from "./styled";

const Menu = () => {
  return (
    <Wrapper>
      <Options />
    </Wrapper>
  );
};

export default Menu;
