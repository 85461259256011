import React from "react";
import { useSelector } from "react-redux";
import { selectPaperworkStructure, selectStageIndex } from "store/PaperWork/selectors";
import { Wrapper } from "./styled";
import { get } from "lodash";
import Item from "./components/Item";
import { isEmpty } from "utils/functions";

const SpecialItems = ({ search }) => {
  const index = useSelector(selectStageIndex);
  const paperwork = useSelector(selectPaperworkStructure);
  const stage = get(paperwork, `stages[${index}]`, {});
  const otherInputs = get(stage, "form.otherInputs", []);

  if (isEmpty(otherInputs)) return null;

  return (
    <Wrapper>
      {otherInputs.map((item) => (
        <Item key={item.index} item={item} search={search} />
      ))}
    </Wrapper>
  );
};

export default SpecialItems;
