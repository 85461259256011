import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
  box-sizing: border-box;
  @media (max-width: 720px) {
    flex-direction: column;
    margin-top: 0px;
  }
  @media (max-width: 720px) {
    flex-direction: column;
    margin-bottom: 10px;
  }
`;
