import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: top;
  width: 100%;
  height: 800px;
  box-sizing: border-box;
  padding-bottom: 10px;
  overflow: hidden;
  border-radius: 17px;
  border: 1px solid ${colors.gray95};
  background: ${colors.white};
  @media (max-width: 980px) {
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 26px;
  padding: 31px 40px;
  box-sizing: border-box;
  overflow-y: overlay;
`;