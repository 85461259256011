import MainLayout from "components/commons/MainLayout";
import { push } from "connected-react-router";
import get from "lodash/get";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { openModal, setParams } from "store/Modals/actions";
import { MODAL_WARN_MULTIPLE_PLOTS } from "store/Modals/constants";
import { setModifyMode } from "store/PaperWork/actions/actions";
import { getPaperworkProgress } from "store/PaperWork/actions/requests";
import { PAPERWORK_NOT_SUSCEPTIBLE_TO_MULTIPLE_PLOTS, PAPERWORK_STATE } from "store/PaperWork/constants";
import { selectPaperworkMetadata, selectPaperworkData, selectPaperworkStageProgress } from "store/PaperWork/selectors";
import colors from "styles/colors";
import { PATH_HOME } from "../App/constants";
import { isEmpty } from "lodash";
import { everyAlreadyFilled } from "../Paperwork/components/Buttons/functions";
import ProgressBar from "../Paperwork/components/ProgressBar";
import Buttons from "./components/Buttons";
import Header from "./components/Header";
import Observations from "./components/Observations";
import StageInfo from "./components/StageInfo";
import TributaryPlots from "./components/TributaryPlots";
import { Stages, Wrapper } from "./styled";

const FormPreview = () => {
  const dispatch = useDispatch();
  const metadata = useSelector(selectPaperworkMetadata);
  const data = useSelector(selectPaperworkData);
  const state = get(metadata, "state", "");
  const type = get(metadata, "paperworkId", "");
  const omitMultiplePlots = PAPERWORK_NOT_SUSCEPTIBLE_TO_MULTIPLE_PLOTS.includes(type);
  const tributaryPlots = !omitMultiplePlots && data.length > 1 ? data.map((plot) => plot.nomenclature.plot) : [];
  const sortedTributaryPlots = [...tributaryPlots].sort();
  const stageProgress = useSelector(selectPaperworkStageProgress);
  const { id } = useParams();
  const isObserved = state == 3;

  const getStageInfo = (item, index) => <StageInfo key={item.stageId} stage={item} index={index} />;

  useEffect(() => {
    if (isEmpty(metadata)) dispatch(getPaperworkProgress(id));
  }, [metadata]);

  useEffect(() => {
    if (everyAlreadyFilled(stageProgress)) dispatch(setModifyMode());
  }, [stageProgress]);

  useEffect(() => {
    if (state && state !== PAPERWORK_STATE.STARTED && state !== PAPERWORK_STATE.OBSERVED) dispatch(push(PATH_HOME));
  }, [state]);

  useEffect(() => {
    if (sortedTributaryPlots.length > 0 && !isObserved) {
      dispatch(setParams({ plots: sortedTributaryPlots }));
      dispatch(openModal(MODAL_WARN_MULTIPLE_PLOTS));
    }
  }, [JSON.stringify(sortedTributaryPlots)]);
  // le paso string porque si un array igual reemplaza al que ya esta pero
  // tiene distinta posicion de memoria, hace que se renderice devuelta cuando no deberia

  return (
    <MainLayout header={<Header />} background={colors.gray100}>
      <Wrapper>
        <ProgressBar />
        <Stages>{stageProgress.map(getStageInfo)}</Stages>
        <TributaryPlots tributaryPlots={sortedTributaryPlots} />
        <Observations />
        <Buttons />
      </Wrapper>
    </MainLayout>
  );
};

export default FormPreview;
