import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  width: 100%;
  gap: 5px;
  box-sizing: border-box;
`;

export const Title = styled.div`
  ${({content}) => (!content && "display: none")};
  margin-right: auto;
  font-family: "OpenSansRegular";
  font-size: 18px;
  color: ${colors.gray0};
  font-weight: 500;
`;

export const Description = styled.div`
  ${({content}) => (!content && "display: none")};
  margin-right: auto;
  margin-bottom: 2px;
  font-family: "OpenSansRegular";
  font-size: 14px;
  color: ${colors.gray20};
  font-weight: 400;
`;