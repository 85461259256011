import React from "react";
import { Title, Wrapper } from "./styled";
import { GraphWrapper, PieWrapper } from "../LeftCard/styled";
import Counter from "../LeftCard/components/Counter";
import PieChart from "./components/PieChart";

const RightCard = ({ leftChartColor, rightChartColor, data, observedCount, detail }) => {
  return (
    <Wrapper>
      <Title>Ingresos del mes</Title>
      <GraphWrapper>
        <Counter data={data.observed} color={rightChartColor} label={"No observados"} hide />
        <PieWrapper>
          <PieChart leftColor={leftChartColor} rightColor={rightChartColor} data={data} detail={detail} />
        </PieWrapper>
        <Counter data={observedCount} color={rightChartColor} label={"Observados"} />
      </GraphWrapper>
    </Wrapper>
  );
};

export default RightCard;
