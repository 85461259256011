import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: auto;
  padding: 24px 0px;
  gap: 24px;
  box-sizing: border-box;
`;

export const OptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-content: ${({ toogle }) => (toogle ? "center" : "left")};
  width: 100%;
  margin-top: auto;
  box-sizing: border-box;
`;

export const Items = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${({ toogle }) => (toogle ? "center" : "left")};
  justify-content: top;
  width: 100%;
  height: 100%;
  gap: ${({ toogle }) => (toogle ? "13.5px" : "12px")};
  margin-left: ${(props) => (props.toogle ? "0px" : "8px")};
`;
