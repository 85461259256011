import BackOfficeHeader from "components/commons/BackofficeHeader";
import React from "react";

const Header = () => {
  const DESCRIPTION = "En esta sección podras configurar los datos de tu cuenta";

  return <BackOfficeHeader title={"Mi cuenta"} description={DESCRIPTION} />;
};

export default Header;
