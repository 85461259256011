import styled from "styled-components";


export const Wrapper = styled.div`
    width: 100%;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
`;

export const ButtonWrapper = styled.div`
    height: 48px;
    width: 88px;
    left: 285px;
    top: 24px;
    border-radius: 4px;
    padding: 0px 16px 0px 16px;
`;