/* eslint-disable quotes */
import React from "react";
import { Wrapper, Title } from "./styled";
import Content from "./components/Content";
import isEmpty from "lodash/isEmpty";

const TributaryPlots = ({ tributaryPlots }) => {

  if (isEmpty(tributaryPlots)) return null;
  return (
    <Wrapper>
      <Title>Partida Tributaria</Title>
      <Content tributaryPlots={tributaryPlots} />
    </Wrapper>
  );
};

export default TributaryPlots;
