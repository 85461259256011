import React from "react";
import Filters from "components/commons/Table/components/Filters";
import { CERTIFICATE_ITEMS } from "components/commons/Table/components/Filters/constants";
import { getPaperworkHistoryCustom } from "store/PaperWork/actions/requests";
import { FILTER_CERTIFICATES_TABLE } from "../constants";

const CertificateFilters = () => {

  return <Filters items={CERTIFICATE_ITEMS} getPaperworkHistory={() => getPaperworkHistoryCustom(FILTER_CERTIFICATES_TABLE)} />;
};

export default CertificateFilters;
