import styled from "styled-components";
import colors from "styles/colors";
import { ReactComponent as ArrowIcon } from "images/icons/gray/arrow.svg";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 112px;
  padding: 16px 16px 16px 16px;
  gap: 34px;
  box-sizing: border-box;
  border: 1px solid ${colors.gray95};
  border-radius: 4px;
  cursor: pointer;
  background: ${colors.white};
  @media (max-width: 980px) {
    min-height: 72px;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 77px;
  gap: 6px;
  background: ${colors.white};
  overflow: hidden;
`;

export const Text = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  height: 44px;
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.18px;
  color: ${colors.gray30};
  background: ${colors.white};
  @media (max-width: 980px) {
    font-size: 16px;
  }
`;

export const Draft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  gap: 10px;
  position: relative;
  width: 72px;
  height: 24px;
  color: ${colors.gray50};
  background: ${colors.gray100};
  border-radius: 4px;
  margin-right: auto;
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
`;

export const Arrow = styled(ArrowIcon)``;
