import RadioButton from "components/commons/Buttons/RadioButton";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import { selectPaperworkMetadata, selectStageIndex } from "store/PaperWork/selectors";
import { setForm } from "store/PaperWork/actions/functions";
import { checkDependecies, getValues } from "../../../../functions";

const Option = ({ item, stageKey, name }) => {
  const dispatch = useDispatch();
  const { dependencies, key } = item;
  const paperwork = useSelector(selectPaperworkMetadata);
  const stage = get(paperwork, "stageProgress", {});
  const index = useSelector(selectStageIndex);
  const values = checkDependecies(stage, dependencies, name);
  const inDisuse = values.hidden || values.disabled;
  const currentValue = get(stage, `[${index}].otherInputsData[${stageKey}][${key}]`);

  const onClick = (e) => {
    if (e.target.value === currentValue) dispatch(setForm(stageKey, key, null, values.hidden));
    else dispatch(setForm(stageKey, key, getValues(name, "value", dependencies, stage, values), values.hidden));
  };

  useEffect(() => {
    if (!values.value) dispatch(setForm(stageKey, key, getValues(name, "value", dependencies, stage, values), inDisuse));
  }, [values]);

  return <RadioButton onClick={onClick} label={name} checked={currentValue === name} value={name} name={"radio-button"} />;
};

export default Option;
