import * as types from "./types";

export function loadingStart() {
  return {
    type: types.LOADING_START,
  };
}

export function loadingStop() {
  return {
    type: types.LOADING_STOP,
  };
}

export function loadingFailed() {
  return {
    type: types.LOADING_FAILED,
  };
}

export function resetGeneralStore() {
  return {
    type: types.RESET_GENERAL_STORE,
  };
}
