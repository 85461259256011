export const SCHEMA_DATA = "jsonSchemaData";
export const OTHER_INPUTS = "otherInputsData";
export const ATTACHED_FILES = "attachedFiles";
export const OTHER_INPUTS_STRUCTURE = "otherInputs";
export const JSON_SCHEMA_STRUCTURE = "jsonSchema";
export const ALREADY_FILLED = "alreadyFilled";
export const STAGE_TITLE = "stageTitle";
export const STAGE_DESCRIPTION = "stageDescription";
export const STAGE_ID = "stageId";
export const FORM_FIELDS = "formFields";
export const PAPERWORK_STATE = {
  STARTED: 1,
  PENDING: 2,
  OBSERVED: 3,
  APPROVED: 4,
  APPROVE_REQUESTED: 5,
  REJECTED: 6,
  REJECT_REQUESTED: 7,
  MENDING_REQUESTED: 8,
};

export const PENDING_STATES = [
  PAPERWORK_STATE.STARTED,
  PAPERWORK_STATE.PENDING,
  PAPERWORK_STATE.APPROVE_REQUESTED,
  PAPERWORK_STATE.REJECT_REQUESTED,
  PAPERWORK_STATE.MENDING_REQUESTED,
];

export const PAPERWORK_STATE_NAME = {
  [PAPERWORK_STATE.STARTED]: "Borrador",
  [PAPERWORK_STATE.PENDING]: "Pendiente",
  [PAPERWORK_STATE.OBSERVED]: "Observado",
  [PAPERWORK_STATE.APPROVED]: "Aprobado",
  [PAPERWORK_STATE.APPROVE_REQUESTED]: "Pendiente: Validado",
  [PAPERWORK_STATE.REJECTED]: "Rechazado",
  [PAPERWORK_STATE.REJECT_REQUESTED]: "Pendiente: Rechazo solicitado",
  [PAPERWORK_STATE.MENDING_REQUESTED]: "Pendiente: Enviado a subsanar",
};

export const PAPERWORK_TYPE = {
  E1: "E1",
  D1: "D1",
  D2: "D2",
  D5: "D5",
};

export const PAPERWORK_WITHOUT_PREVIEW = [PAPERWORK_TYPE.D2];

export const PAPERWORK_NAME = {
  E1: "Mejora de inmuebles",
  D1: "Certificado catastral",
  D2: "Revalidación de CC",
  D5: "Informe catastral",
};

export const PAPERWORK_NOT_SUSCEPTIBLE_TO_MULTIPLE_PLOTS = [PAPERWORK_TYPE.E1];
