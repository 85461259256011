import React, { useEffect, useState } from "react";
import { Wrapper, Container } from "./style";
import Content from "./components/Content";
import Header from "../components/Header";
import { MODAL_EDIT_MEND } from "store/Modals/constants";
import { useDispatch, useSelector } from "react-redux";
import { selectSubsanationAreas } from "store/PaperWork/selectors";
import isEmpty from "lodash/isEmpty";
import { getSubsanationAreas } from "store/PaperWork/actions/requests";

const EditMendModal = () => {
  const [expanded, setExpanded] = useState(false);
  const dispatch = useDispatch();
  const subsanationAreas = useSelector(selectSubsanationAreas);

  useEffect(() => {
    if (isEmpty(subsanationAreas)) dispatch(getSubsanationAreas());
  }, []);

  return (
    <Wrapper>
      <Container expanded={expanded}>
        <Header expanded={expanded} setExpanded={setExpanded} modal={MODAL_EDIT_MEND} />
        <Content expanded={expanded} />
      </Container>
    </Wrapper>
  );
};

export default EditMendModal;
