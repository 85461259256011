import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Bar from "./Bar";
import { Wrapper } from "./styled";

const Menu = () => {
  const location = useLocation();
  const [toogle, setToogle] = useState(true);
  const open = () => setToogle(!toogle);

  useEffect(() => setToogle(true), [location]);

  return (
    <Wrapper onClick={open} toogle={toogle}>
      <Bar toogle={toogle} />
    </Wrapper>
  );
};

export default Menu;
