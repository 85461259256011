/* eslint-disable react/no-children-prop */
import React from "react";
import { Wrapper, Title, Description } from "./styled";

const Header = () => {
  return (
    <Wrapper>
      <Title children="Restablecer contraseña" />
      <Description children="Ingresá tu mail para restablecer tu contraseña" />
    </Wrapper>
  );
};

export default Header;
