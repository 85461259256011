import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPaperworksStatistics } from "store/PaperWork/actions/requests";
import { selectpaperworksCountStatistics } from "store/PaperWork/selectors";
import { Wrapper, Title, ItemsContainer, ApprovedItem, InProgressItem } from "./styled";
import moment from "moment";
import { FORMAT_DATE } from "store/Filters/constants";

const NumericalIndicators = () => {
  const dispatch = useDispatch();
  const today = moment();
  const dateTo = today.format(FORMAT_DATE);
  const dateFrom = today.subtract(1, "months").format(FORMAT_DATE);
  const {
    paperworksCount: { approved, inCourse },
  } = useSelector(selectpaperworksCountStatistics);

  useEffect(() => {
    dispatch(getPaperworksStatistics(dateFrom, dateTo, "paperworksCount"));
  }, []);

  return (
    <Wrapper>
      <Title>Estadísticas trámites del último mes</Title>
      <ItemsContainer>
        <ApprovedItem text={"Aprobados"} data={approved} />
        <InProgressItem text={"En curso"} data={inCourse} />
      </ItemsContainer>
    </Wrapper>
  );
};

export default NumericalIndicators;
