import React, { useState } from "react";
import { Wrapper, TextArea, ButtonContainer, Footer } from "./style";
import { MODAL_REJECTION_REQUEST } from "store/Modals/constants";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "store/Modals/actions";
import LabelOnlyButton from "components/commons/Buttons/LabelOnlyButton";
import colors from "styles/colors";
import { requestRejectPaperwork } from "store/PaperWork/actions/requests";
import { selectPaperworkMetadata } from "store/PaperWork/selectors";
import { PLACEHOLDER, SEND } from "./constants";
import { isNull, isEmpty } from "utils/functions";

const Content = ({ expanded }) => {
  const dispatch = useDispatch();
  const { id } = useSelector(selectPaperworkMetadata);
  const [text, setText] = useState(null);
  const rejection = { reason: text };

  const handleObservation = (e) => {
    setText(e.target.value);
  };

  const reject = () => {
    dispatch(closeModal(MODAL_REJECTION_REQUEST));
    dispatch(requestRejectPaperwork(id, rejection));
  };

  return (
    <Wrapper expanded={expanded}>
      <TextArea onChange={(e) => handleObservation(e)} placeholder={PLACEHOLDER} />
      <Footer>
        <ButtonContainer>
          <LabelOnlyButton background={colors.blue40} color={colors.white} label={SEND} onClick={reject} disabled={isNull(text) || isEmpty(text)} />
        </ButtonContainer>
      </Footer>
    </Wrapper>
  );
};

export default Content;
