/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Content, TableInstance } from "../../styled";
import { useTable, usePagination, useExpanded, useRowSelect, useFlexLayout } from "react-table";
import Header from "./components/Head";
import Body from "./components/Body";
import Footer from "./components/Footer";
import Wrapper from "./styled";

const Structure = ({ columns, data, children, cellActions }) => {
  const [editableRowIndex, setEditableRowIndex] = useState(null);
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } = useTable(
    {
      columns,
      data,
      editableRowIndex,
      setEditableRowIndex,
      initialState: {
        pageSize: 1000,
      },
      autoResetExpanded: false,
      autoResetSelectedRows: false,
    },
    useExpanded,
    usePagination,
    useRowSelect,
    useFlexLayout,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          disableResizing: false,
        },
        ...columns,
      ]);
    }
  );

  return (
    <Wrapper>
      {children}
      <TableInstance {...getTableProps()}>
        <Content>
          <Header data={headerGroups} />
          <Body getTableBodyProps={getTableBodyProps} data={page} prepareRow={prepareRow} cellActions={cellActions} />
        </Content>
        <Footer />
      </TableInstance>
    </Wrapper>
  );
};

export default Structure;
