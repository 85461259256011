import styled from "styled-components";
import colors from "styles/colors";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-spacing: 0;
  box-sizing: border-box;
  background: transparent;
  overflow-y: hidden;
  overflow-x: hidden;
  border: 1px solid ${colors.gray99};
  border-radius: 8px 8px 0px 0px;
  padding: 16px 22px 0px 22px;
  @media (max-width: 600px) {
    padding-inline: 0px;
  }
`;

export default Wrapper;
