/* eslint-disable react/no-children-prop */
import SurfaceIcon from "components/commons/Icons/SurfaceIcon";
import UserIcon from "components/commons/Icons/UserIcon";
import UsersIcon from "components/commons/Icons/UsersIcon";
import React from "react";
import { Wrapper } from "./styled";

const Icon = ({ id, selected, disabled }) => {
  const getIcon = () => {
    if (id === 1) return <UserIcon />;
    if (id === 2) return <UsersIcon />;
    if (id === 3) return <SurfaceIcon />;
    return null;
  };
  return (
    <Wrapper selected={selected} disabled={disabled}>
      {getIcon()}
    </Wrapper>
  );
};

export default Icon;
