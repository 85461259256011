import BackOfficeHeader from "components/commons/BackofficeHeader";
import { PATH_HOME } from "components/pages/App/constants";
import React from "react";

const Header = () => {
  return (
    <BackOfficeHeader
      title={"Previsualización de formulario"}
      description={"Los siguientes ítems son los indicados en tu formulario. En caso de no estar conforme podrás modificarlo."}
      showBack={"Volver al menu de tramites"}
      route={PATH_HOME}
    />
  );
};

export default Header;
