import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFilters } from "store/Filters/actions";
import { selectFilterByKey } from "store/Filters/selectors";
import { cloneDeep } from "lodash";
import { Wrapper, Check, Name } from "./style";
import { check, checkValue } from "./functions";

const CheckBox = ({ name, section, elmKey }) => {
  const dispatch = useDispatch();
  const { key } = section;
  const value = useSelector((state) => selectFilterByKey(state, key));
  let copyValue = cloneDeep(value);
  const handleChange = () => dispatch(setFilters(key, checkValue(copyValue, elmKey)));

  return (
    <Wrapper>
      <Check
        type="radio"
        onClick={handleChange}
        name={name}
        value={value}
        checked={check(value, elmKey)}
      />
      <Name>{name}</Name>
    </Wrapper>
  );
};

export default CheckBox;
