import React from "react";
import { Wrapper, Container } from "../styled";
import Header from "../components/Header";
import Content from "../components/Content";
import { MODAL_ADD_USER } from "store/Modals/constants";
import { useDispatch } from "react-redux";
import { addUser } from "store/BackOffice/actions/requests";
import { closeModal } from "store/Modals/actions";

const UserModal = () => {
  const dispatch = useDispatch();
  const confirm = (user) => dispatch(addUser(user));
  const close = () => dispatch(closeModal(MODAL_ADD_USER));

  return (
    <Wrapper onClick={(e) => close(e)}>
      <Container onClick={(e) => e.stopPropagation()}>
        <Header title="Crear nuevo usuario" description="Ingresá los datos de la persona usuaria para dar el alta en el sistema." />
        <Content modal={MODAL_ADD_USER} confirm={confirm} />
      </Container>
    </Wrapper>
  );
};

export default UserModal;
