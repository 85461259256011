import styled from "styled-components";
import colors from "styles/colors";
import { fadeIn } from "styles/effects";
import Cell from "./components/Structure/components/Cell";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  width: 100%;
  min-height: 600px;
  font-size: 60px;
  color: blue;
  box-sizing: border-box;
`;

export const TableInstance = styled.table`
  display: flex;
  flex-direction: column;
  flex: 0 0 600px;
  width: 100%;
  border: 1px solid ${colors.blue95};
  border-radius: 8px 8px 0px 0px;
  margin: 22px 0px;
`;

export const Content = styled.div`
  overflow-x: auto;
`;

export const THead = styled.thead`
  display: flex;
  align-items: center;
  justify-content: left;
  min-height: 40px;
  box-sizing: border-box;
  background: ${colors.blue99};
  border-radius: 8px 8px 0px 0px;
  border-bottom: 1px solid ${colors.blue95};
  overflow-y: scroll;
`;

export const TRHead = styled.tr`
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
  height: 100%;
  font-size: 10px;
  text-align: left;
  color: ${colors.gray80};
  box-sizing: border-box;
  background: transparent;
  & > * {
    &:nth-child(1) {
      display: none;
    }
    &:not(:last-child) {
      border-right: 1px solid ${colors.blue95};
    }
  }
`;

export const THHead = styled.th`
  height: 100%;
  height: 40px;
  position: sticky;
  white-space: nowrap;
  overflow: visible;
  text-overflow: ellipsis;
  background: ${colors.blue99};
`;

export const TBody = styled.tbody`
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 510px;
  color: ${colors.black};
  overflow-x: hidden;
  overflow-y: scroll;
  z-index: 1;
  box-sizing: border-box;
  background: ${colors.white};
  & > * {
    &:nth-child(even) {
      background: ${colors.blue100};
    }
  }
  width: max-content;
  min-width: 100%;
`;

export const TRBody = styled.tr`
  align-items: center;
  font-size: 14.5px;
  color: ${colors.black};
  &:hover {
    background: ${colors.gray99};
  }
`;

export const TDBody = styled.td`
  white-space: nowrap;
  color: ${colors.black};
  text-overflow: ellipsis;
`;

export const TR = styled.tr`
  height: 40px;
  max-height: 40px;
  color: ${colors.black};
  text-align: center;
  vertical-align: bottom;
  overflow: hidden;
  animation: 1s ${fadeIn} forwards;
  cursor: ${({ cellActions }) => (cellActions ? "auto" : "pointer")};
  &:hover {
    transition: all 0.4s;
    background: ${(props) => (props.isExpanded ? null : `${colors.gray99}`)};
  }
  & > * {
    &:nth-child(1) {
      display: none;
    }
    &:not(:last-child) {
      border-right: 1px solid ${colors.blue95};
    }
  }
`;

export const TD = styled.td`
  display: flex;
  flex: 1;
`;

export const TDSub = styled.td``;

export const Span = styled.span`
  height: 2px;
`;

export const Text = styled.div`
  margin-left: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${(props) => (props.isExpanded ? colors.orange50 : colors.gray0)};
  font-size: 18px;
  transition: 0.5s font-variation-settings ease-in;
  font-weight: ${(props) => (props.isExpanded || props.mark ? 700 : 400)};
  opacity: ${(props) => (props.isSelected || props.canExpand ? 1 : 0.5)};
  @media (max-width: 1400px) {
    font-size: 14px;
  }
`;

export const Column = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  gap: 4px;
  font-family: "OpenSansRegular";
  font-size: 12px;
  overflow-x: hidden;
  box-sizing: border-box;
  justify-content: center;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: ${colors.blue40};

  @media (max-width: 1366px) {
    font-size: 10px;
  }
`;

export const LeftJustifiedCell = styled(Cell)`
  justify-content: left;
`;
