import React from "react";
import { Wrapper, Image } from "./style";
import { useSelector } from "react-redux";
import { selectParams } from "store/Modals/selectors";

const Content = () => {
    const { image } = useSelector(selectParams);
    return <Wrapper>
        <Image src={image} alt="image"/>
    </Wrapper>;
};

export default Content;