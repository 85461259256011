import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: left;
  width: 100%;
  box-sizing: border-box;
  padding-bottom: 20px;
  border-bottom: 1px solid ${colors.gray99};
`;

export const Content = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  justify-content: top;
  align-items: left;
  width: 100%;
  box-sizing: border-box;
`;

export const Title = styled.div`
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: ${colors.blue60};
  margin-bottom: 8px;
`;

export const SubTitle = styled.div`
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${colors.blue30};
  margin-bottom: 4px;
`;

export const Text = styled.div`
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.black};
`;

export const Column = styled.div`
  flex: 1;
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const RowWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;