import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex: 0 0 56px;
  width: 100%;
  box-sizing: border-box;
  background: ${colors.blue100};
  border: 1px solid ${colors.gray99};
  border-radius: 8px;
  padding: 12px;
  height: 32px;
  gap: 50px;
  @media (max-width: 500px) {
    padding: 0px;
    gap: 0px;
  }
`;

export const ItemsContainer = styled.div`
  display: flex;
  align-items: top;
  justify-content: left;
  flex: 0 0 333px;
  gap: 8px;
  box-sizing: border-box;
  background: ${colors.gray99};
  border-radius: 4px;
  padding: 4px;
  height: 32px;
  @media (max-width: 600px) {
    flex: 0 0 auto;
    height: auto;
  }
`;
