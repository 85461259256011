import React from "react";
import { Wrapper, Text } from "../style";
import WhatsappIcon from "components/commons/Icons/SocialMedia/WhatsappIcon";
import colors from "styles/colors";
import { selectReportLink } from "store/PaperWork/selectors";
import { useSelector } from "react-redux";

const Whatsapp = () => {
  const link = useSelector(selectReportLink);
  const body = `Hola, te adjunto este archivo: ${link}`;
  const url = `https://api.whatsapp.com/send?text=${encodeURIComponent(body)}`;
  
  return (
    <Wrapper>
      <a href={url} target="_blank" rel="noreferrer">
        <WhatsappIcon />
      </a>
      <Text color={colors.black}>Whatsapp</Text>
    </Wrapper>
  );
};

export default Whatsapp;
