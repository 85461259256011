/* eslint-disable no-unused-vars */
import * as types from "../types";
import API from "services/api";
import get from "lodash/get";
import { BYPASS_LOGIN, PATH_SIGN_IN } from "components/pages/App/constants";
import { loadingStart, loadingStop } from "store/General/actions";
import { handlingGetIdToken, handlingLogin, handlingResetPassword } from "./handlings";
import { signInWithEmailAndPassword, signOut, createUserWithEmailAndPassword } from "firebase/auth";
import { auth, config, firebaseApp } from "initFirebase";
import { push } from "connected-react-router";
import { selectRefreshToken, selectToken } from "../selectors";
import { signInWithPopup, updateProfile, GoogleAuthProvider } from "firebase/auth";
import { FIREBASE_ERRORS } from "../constants";
import { failed } from "store/PaperWork/actions/functions";

export const getUserData = () => {
  return async (dispatch) => {
    try {
      const token = get(window, "keycloakSession.token", "");
      const tokenExpires = get(window, "keycloakSession.tokenParsed.exp", 0);
      const response = await API.login.getUser(token);
      if (response.status == 200) {
        localStorage.setItem("token", token);
        localStorage.setItem("user", JSON.stringify(response.data.result.user));
        localStorage.setItem("tokenExpires", tokenExpires);
        dispatch({ type: types.LOGIN_SUCCESS, token: token, tokenExpires, user: response.data.result.user });
      } else dispatch({ type: types.LOGIN_FAILED, error: response?.statusText });
    } catch (error) {
      dispatch({ type: types.LOGIN_FAILED, error });
    }
  };
};

export const logout = () => {
  return async (dispatch) => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("tokenExpires");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("validSince");
    dispatch({ type: types.LOGOUT_SUCCESS });
    if (!BYPASS_LOGIN) window.keycloakSession.logout();
  };
};

export const updateToken = () => {
  return async (dispatch) => {
    try {
      window.keycloakSession
        .updateToken(-1) //force update
        .success(() => {
          const token = get(window, "keycloakSession.token", "");
          const tokenExpires = get(window, "keycloakSession.tokenParsed.exp", 0);
          localStorage.setItem("token", token);
          localStorage.setItem("tokenExpires", tokenExpires);
          dispatch({ type: types.UPDATE_TOKEN_SUCCESS, token: token, tokenExpires });
        })
        .error(() => {
          localStorage.removeItem("token");
          localStorage.removeItem("tokenExpires");
          dispatch({ type: types.UPDATE_TOKEN_FAILED });
        });
    } catch {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("tokenExpires");
      dispatch({ type: types.UPDATE_TOKEN_FAILED });
    }
  };
};

export function updateUser(user) {
  return async (dispatch) => {
    dispatch({ type: types.UPDATE_USER, user });
  };
}

export const updateUserInfo = (data) => {
  return async (dispatch, getState) => {
    dispatch(loadingStart());
    try {
      const token = selectToken(getState());
      const response = await API.login.updateUserInfo(data, token);
      dispatch(loadingStop());
      localStorage.setItem("user", JSON.stringify(response.data.result));
      dispatch(updateUser(response.data.result));
    } catch (e) {
      dispatch(failed(e));
    }
  };
};

export const updateUserPicture = (data) => {
  return async (dispatch, getState) => {
    dispatch(loadingStart());
    try {
      const token = selectToken(getState());
      const response = await API.login.updateUserPicture(data, token);
      dispatch(loadingStop());
      localStorage.setItem("user", JSON.stringify(response.data.result));
      dispatch(updateUser(response.data.result));
    } catch (e) {
      dispatch(failed(e));
    }
  };
};

export function resetError() {
  return async (dispatch) => {
    dispatch({ type: types.RESET_ERROR });
  };
}

/* Requests related to firebase users */

export const internalSignIn = () => {
  return async (dispatch) => {
    try {
      const token = firebaseApp.auth().currentUser.auth.currentUser.accessToken;
      const refreshToken = firebaseApp.auth().currentUser.auth.currentUser.refreshToken;
      const tokenExpires = firebaseApp.auth().currentUser.auth.currentUser.stsTokenManager.expirationTime;
      const validSince = firebaseApp.auth().currentUser.auth.currentUser.metadata.lastLoginAt;
      const response = await API.paperW.getInternalSignIn(token);
      dispatch(handlingLogin(response, refreshToken, tokenExpires, validSince));
    } catch (e) {
      dispatch({ type: types.LOGIN_FAILED, e });
    }
  };
};

export const internalSignOut = () => {
  return async (dispatch) => {
    try {
      await signOut(auth);
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      localStorage.removeItem("tokenExpires");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("validSince");
      dispatch({ type: types.LOGOUT_SUCCESS });
      dispatch(push(PATH_SIGN_IN));
    } catch (e) {
      dispatch(failed(e));
    }
  };
};

export const resetPassword = (email) => {
  return async (dispatch) => {
    dispatch(loadingStart());
    try {
      const response = await API.login.getResetPassword(email);
      dispatch(handlingResetPassword(response));
    } catch (e) {
      dispatch(failed(e));
    }
  };
};

export const getIdToken = () => {
  return async (dispatch, getState) => {
    try {
      const refreshToken = selectRefreshToken(getState());
      const response = await API.login.getIdToken(refreshToken);
      dispatch(handlingGetIdToken(response));
    } catch (e) {
      dispatch({ type: types.LOGIN_FAILED, e });
    }
  };
};

export const firebaseSignIn = (email, password) => {
  return async (dispatch) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      dispatch(internalSignIn());
    } catch (e) {
      const errorCode = get(e, "code", "");
      dispatch({ type: types.LOGIN_FAILED, error: get(FIREBASE_ERRORS, `${errorCode}`, "") });
    }
  };
};

export const firebaseSignUp = (name, email, password) => {
  return async (dispatch) => {
    try {
      await createUserWithEmailAndPassword(auth, email, password);
      await updateProfile(auth.currentUser, { displayName: name });
      firebaseApp.auth().onAuthStateChanged(function (user) {
        !user.emailVerified && user.sendEmailVerification();
      });
      dispatch(internalSignIn());
    } catch (e) {
      const errorCode = get(e, "code", "");
      dispatch({ type: types.LOGIN_FAILED, error: get(FIREBASE_ERRORS, `${errorCode}`, "") });
    }
  };
};

export const firebaseSignInWithGoogle = () => {
  return async (dispatch) => {
    try {
      const provider = new GoogleAuthProvider();
      provider.setCustomParameters({ prompt: "select_account" });
      await signInWithPopup(auth, provider);
      dispatch(internalSignIn());
    } catch (e) {
      const errorCode = get(e, "code", "");
      dispatch({ type: types.LOGIN_FAILED, error: get(FIREBASE_ERRORS, `${errorCode}`, "") });
    }
  };
};
