import React from "react";
import { Wrapper } from "./style";
import colors from "styles/colors";
import { closeModal } from "store/Modals/actions";
import { useDispatch } from "react-redux";
import { MODAL_EXPAND_IMAGE } from "store/Modals/constants";
import LabelIconButton from "./LabelIconButton";
import ArrowLeftIcon from "components/commons/Icons/ArrowLeftIcon";
import useResize from "hooks/useResize";
import { SIZE_LIMIT_TABLET } from "components/commons/MainLayout/constants";
import IconButton from "components/commons/Buttons/IconButton";

const BackButton = () => {
    const dispatch = useDispatch();

    const goBack = () => dispatch(closeModal(MODAL_EXPAND_IMAGE));
    const { screenWidth } = useResize();
    if (screenWidth <= SIZE_LIMIT_TABLET) {
        return <Wrapper>
            <IconButton onClick={goBack} 
                color={colors.white} 
                border={`1px solid ${colors.white}`} 
                background={colors.gray10}
                icon={<ArrowLeftIcon />}
            />
        </Wrapper>;
    }    
    return <Wrapper>
        <LabelIconButton 
            onClick={goBack} 
            color={colors.white} 
            border={`1px solid ${colors.white}`}
            background={colors.gray10}
            label={"Volver"} 
            icon={<ArrowLeftIcon />}
        />
    </Wrapper>;
};

export default BackButton;