import React, { useEffect } from "react";
import MainLayout from "components/commons/MainLayout";
import { Stages, Wrapper } from "./styled";
import Header from "./components/Header";
import BackButton from "./components/BackButton";
import { useDispatch, useSelector } from "react-redux";
import { selectPaperworkMetadata, selectPaperworkData } from "store/PaperWork/selectors";
import StageInfo from "./components/StageInfo";
import { paperWorkIsComplete } from "utils/functions";
import { PATH_HOME } from "../App/constants";
import Observations from "../FormPreview/components/Observations";
import TributaryPlots from "../FormPreview/components/TributaryPlots";
import { push } from "connected-react-router";
import get from "lodash/get";
import colors from "styles/colors";
import isEmpty from "lodash/isEmpty";
import { getPaperworkProgress } from "store/PaperWork/actions/requests";
import { useParams } from "react-router-dom";

const PaperworkGenerated = () => {
  const dispatch = useDispatch();
  const metadata = useSelector(selectPaperworkMetadata);
  const data = useSelector(selectPaperworkData);
  const stageProgress = get(metadata, "stageProgress", []);
  const { id } = useParams();
  const isD1 = get(metadata, "paperworkId", "") === "D1";
  const tributaryPlots = (isD1 && data.length > 1) ? data.map((plot) => plot.nomenclature.plot) : [];
  const sortedTributaryPlots = [...tributaryPlots].sort();

  const getStageInfo = (item, index) => <StageInfo key={item.stageId} stage={item} index={index} />;

  useEffect(() => {
    if (isEmpty(metadata)) dispatch(getPaperworkProgress(id));
  }, [metadata]);

  if (!paperWorkIsComplete(stageProgress)) {
    dispatch(push(PATH_HOME));
    return null;
  }

  return (
    <MainLayout header={<Header />} background={colors.gray100}>
      <Wrapper>
        <Stages>{stageProgress.map(getStageInfo)}</Stages>
        <TributaryPlots tributaryPlots={sortedTributaryPlots} />
        <Observations />
        <BackButton />
      </Wrapper>
    </MainLayout>
  );
};

export default PaperworkGenerated;
