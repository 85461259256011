import moment from "moment";
import { setFilters } from "store/Filters/actions";
import { FILTER_STATUS, FILTER_DATE_FROM, FILTER_DATE_TO, FILTER_STATE_BY_ROLE } from "store/Filters/constants";
import { FORMAT_DATE } from "store/Filters/constants";
import { initialState } from "./initialState";
import { isEqual, omitBy, isEmpty, cloneDeep } from "lodash";
import { FILTER_TYPE_BY_ROLE } from "store/Filters/constants";

export const setFilterLastMonthByState = (states) => {
  return (dispatch) => {
    const today = moment();
    const dateTo = today.format(FORMAT_DATE);
    const dateFrom = today.subtract(1, "months").format(FORMAT_DATE);
    dispatch(setFilters(FILTER_STATUS, states));
    dispatch(setFilters(FILTER_DATE_FROM, dateFrom));
    dispatch(setFilters(FILTER_DATE_TO, dateTo));
  };
};

export const getCustomFilters = (storeFilters, basicFilters) => {
  // we want to ignore all the empty fields
  const cleanedStoreFilters = omitBy(storeFilters, (f) => !f || isEmpty(f));

  const customFilters = { ...basicFilters, ...cleanedStoreFilters };

  // we only want the custom filters if the storeFilters is setted
  const filters = isEqual(storeFilters, initialState) ? basicFilters : customFilters;
  return filters;
};

// Filter the types of procedures available by profile
export const getFiltersConstants = (filters, profileId) => {
  const itemsCopy = cloneDeep(filters);
  if (!isEmpty(FILTER_TYPE_BY_ROLE[profileId]))
    itemsCopy[0].sections[0].elements = itemsCopy[0].sections[0].elements.filter((e) => FILTER_TYPE_BY_ROLE[profileId].includes(e.key));
  if (!isEmpty(FILTER_STATE_BY_ROLE[profileId]))
    itemsCopy[0].sections[2].elements = itemsCopy[0].sections[2].elements.filter((e) => FILTER_STATE_BY_ROLE[profileId].includes(e.key));
  return itemsCopy;
};
