import styled from "styled-components";

export const Wrapper = styled.div`
  display: block;
  position: relative;
  width: 100%;
  cursor: pointer;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
`;
