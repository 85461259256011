import React from "react";
import { useSelector } from "react-redux";
import { selectPaperworkMetadata } from "store/PaperWork/selectors";
import { get } from "lodash";
import { PAPERWORK_TYPE } from "store/PaperWork/constants";
import D1 from "../types/D1";
import D2 from "../types/D2";
import D5 from "../types/D5";
import E1 from "../types/E1";

const Content = () => {
  const metadata = useSelector(selectPaperworkMetadata);
  const type = get(metadata, "paperworkId", "");

  if (type == PAPERWORK_TYPE.D1) return <D1 />;
  if (type == PAPERWORK_TYPE.D2) return <D2 />;
  if (type == PAPERWORK_TYPE.D5) return <D5 />;
  if (type == PAPERWORK_TYPE.E1) return <E1 />;
  return null;
};

export default Content;
