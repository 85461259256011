import styled from "styled-components";
import { ReactComponent as Printer } from "images/icons/blue/printer.svg";
import { ReactComponent as Share } from "images/icons/blue/share.svg";
import { ReactComponent as Download } from "images/icons/white/download.svg";
import colors from "styles/colors";

export const PrinterIcon = styled(Printer)`
  & > path {
    fill: ${({ disabled }) => (disabled ? colors.gray80 : "auto")};
  }
`;

export const ShareIcon = styled(Share)`
  & > path {
    fill: ${({ disabled }) => (disabled ? colors.gray80 : "auto")};
  }
`;

export const DownloadIcon = styled(Download)`
  & > path {
    fill: ${({ disabled }) => (disabled ? colors.gray80 : "auto")};
  }
`;
