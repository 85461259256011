import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  width: 100%;
  height: 100%;
  padding: 40px 24px;
  padding: ${(props) => (props.expanded ? "56px 40px" : "40px 24px")};
  box-sizing: border-box;
  @media (max-width: 1366px) {
    padding: 24px;
  }
`;

export const TextArea = styled.textarea`
  display: flex;
  padding: 24px 16px;
  width: 100%;
  height: 100%;
  background: ${colors.gray100};
  outline: none;
  border: none;
  resize: none;
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-top: 16px;
  margin-bottom: 10px;
  color: ${colors.gray50};
  box-sizing: border-box;
  @media (max-width: 1366px) {
    padding: 10px 10px;
    font-size: 10px;
  }
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${colors.gray50};
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: end;
  justify-content: right;
  width: 100%;
  height: 72px;
  padding-top: 36px;
  box-sizing: border-box;
  @media (max-width: 1366px) {
    height: 62px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 36px;
`;
