/* eslint-disable react/no-children-prop */
import React from "react";
import { Wrapper, Email } from "./styled";
import { useDispatch } from "react-redux";
import { verificationEmail } from "store/BackOffice/actions/requests";

const SendEmailCell = ({ row }) => {
  const dispatch = useDispatch();
  const onClick = () => {
    if (isDbUser) return;
    dispatch(verificationEmail(row.original.id));
  };
  const isDbUser = !row.original.firebaseDataId && row.original.id;

  return <Wrapper onClick={onClick} isDbUser={isDbUser} children={<Email />} />;
};

export default SendEmailCell;
