import React from "react";
import { Wrapper, Line } from "./style";

const Conector = () => {
  return (
    <Wrapper>
      <Line />
    </Wrapper>
  );
};

export default Conector;
