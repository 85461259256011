import React, { useState } from "react";
import CommonComboBox from "components/commons/ComboBox/CommonComboBox";
import { useDispatch, useSelector } from "react-redux";
import { setForm } from "store/PaperWork/actions/functions";
import { selectPaperworkMetadata, selectStageIndex } from "store/PaperWork/selectors";
import { Wrapper } from "./styled";
import { checkDependecies, getInitialValue, getValues } from "../../functions";
import Label from "../Input/components/Label";

const Select = ({ item, stageKey }) => {
  const dispatch = useDispatch();
  const { options, dependencies, key, label } = item;
  const index = useSelector(selectStageIndex);
  const stage = useSelector(selectPaperworkMetadata).stageProgress;
  const values = checkDependecies(stage, dependencies, null);
  const [val, setVal] = useState(getInitialValue(stage, index, stageKey, key, values.value));

  const onChange = (value) => {
    setVal(value);
    dispatch(setForm(stageKey, key, value));
  };

  if (values.hidden) return null;

  return (
    <Wrapper>
      <Label text={label} />
      <CommonComboBox
        height={"48px"}
        options={getValues(options, "options", dependencies, stage, values)}
        value={val}
        optionHeight={"40px"}
        onChange={onChange}
      />
    </Wrapper>
  );
};

export default Select;
