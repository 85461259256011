import React from "react";
import { Wrapper, Text } from "../style";
import EmailIcon from "components/commons/Icons/SocialMedia/EmailIcon";
import colors from "styles/colors";
import { TITLE } from "components/commons/Modals/ShareFileModal/components/Content/constants";
import { selectPaperworkProgressAuthor, selectReportLink } from "store/PaperWork/selectors";
import { useSelector } from "react-redux";
import get from "lodash/get";

const Email = () => {
  const link = useSelector(selectReportLink);
  const author = useSelector(selectPaperworkProgressAuthor);
  const body = `Hola, te adjunto este archivo: ${link}`;
  const email = get(author, "email", "");
  const url = `mailto:${encodeURIComponent(email)}?subject=${TITLE}&body=${encodeURIComponent(body)}`;

  return (
    <Wrapper>
      <a href={url} target="_blank" rel="noreferrer">
        <EmailIcon />
      </a>
      <Text color={colors.gray10}>Correo Electrónico</Text>
    </Wrapper>
  );
};

export default Email;
