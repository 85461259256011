/* eslint-disable no-unused-vars */
import React from "react";
import { ChartContainer, Container, Wrapper, Title } from "./styled";
import BarChart from "./BarChart";

const RightBlock = ({ background, color, data }) => {
  return (
    <Wrapper background={background}>
      <Container>
        <ChartContainer>
          <BarChart color={color} data={data} />
        </ChartContainer>
        <Title color={color}>Últimos 6 meses</Title>
      </Container>
    </Wrapper>
  );
};

export default RightBlock;
