/* eslint-disable no-unused-vars */
import React from "react";
import Tooltip from "components/commons/Tooltip";
import { PENDING_STATES, PAPERWORK_STATE_NAME } from "store/PaperWork/constants";
import { Wrapper, Content } from "./styled";
import get from "lodash/get";

const StatusCell = ({ value }) => {
  const isPending = PENDING_STATES.includes(value);
  const state = get(PAPERWORK_STATE_NAME, value, "-");
  return (
    <Wrapper>
      <Tooltip title={state}>
        <Content theme={value}>{isPending ? "Pendiente" : state }</Content>
      </Tooltip>
    </Wrapper>
  );
};

export default StatusCell;
