import React from "react";
import useResize from "hooks/useResize";
import { Wrapper, Text } from "./style";
import { getText } from "./functions";

const Description = () => {
  const { checkSize } = useResize();

  return (
    <Wrapper>
      <Text>{getText(checkSize())}</Text>
    </Wrapper>
  );
};

export default Description;
