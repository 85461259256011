import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
    display: flex;
    background: ${colors.gray10};
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    padding: 0 20px;
    box-sizing: border-box;
`;
