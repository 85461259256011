/* eslint-disable react/no-children-prop */
import React from "react";
import { Wrapper, DropDownList, ListItem } from "./styled";

const Options = ({ isOpen, onChange, options, height = "30px", accessor = null }) => {
  const getOptions = (i) => {
    let children;
    if (accessor) {
      children = i[accessor];
    } else {
      children = i;
    }

    return <ListItem height={height} key={i} children={children} onClick={() => onChange(i)} />;
  };

  if (!isOpen) return null;

  return (
    <Wrapper>
      <DropDownList>{options.map(getOptions)}</DropDownList>
    </Wrapper>
  );
};

export default Options;
