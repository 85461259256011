import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
  width: 100%;
  min-height: 80px;
  margin-bottom: auto;
  padding: 12px 12px;
  gap: 16px;
  font-size: 10px;
  box-sizing: border-box;
  border-bottom: 2px solid ${colors.gray99};
`;

export const Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 100px;
  background: ${colors.white};
`;

export const Notification = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 10px;
  height: 10px;
  margin-left: 40px;
  margin-bottom: 30px;
  border-radius: 100px;
  background: red;
`;
