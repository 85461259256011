import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  @media (max-width: 580px) {
    width: auto;
  }
  &:hover {
    & > * {
      & > * {
        color: ${colors.orange50};
        border-color: ${colors.orange50};
      }
      &:nth-child(2) {
        & > * {
          &:nth-child(1) {
            background: ${colors.orange50};
          }
        }
      }
      &:nth-child(3) {
        & > * {
          & > * {
            color: ${colors.orange50};
            border-color: ${colors.orange50};
            &:nth-child(2) {
              background: ${colors.orange100};
            }
          }
        }
      }
    }
  }
`;
