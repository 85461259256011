/* eslint-disable react/no-children-prop */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setForm } from "store/PaperWork/actions/functions";
import { selectPaperworkMetadata, selectStageIndex } from "store/PaperWork/selectors";
import { checkDependecies, getInitialValue } from "../../functions";
import { Wrapper, Input, Label } from "./styled";
import { get } from "lodash";

const TextArea = ({ item, stageKey }) => {
  const { label, placeholder, key, dependencies } = item;
  const dispatch = useDispatch();
  const paperwork = useSelector(selectPaperworkMetadata);
  const stage = get(paperwork, "stageProgress", {});
  const index = useSelector(selectStageIndex);
  const [val, setVal] = useState(getInitialValue(stage, index, stageKey, key, null));
  const values = checkDependecies(stage, dependencies, null);
  const inDisuse = values.hidden || values.disabled;

  const onChange = (e) => {
    setVal(e.target.value);
    dispatch(setForm(stageKey, key, e.target.value, false));
  };
  useEffect(() => {
    if (inDisuse) setVal("");
    dispatch(setForm(stageKey, key, val, inDisuse));
  }, [inDisuse]);

  if (values.hidden) return null;
  return (
    <Wrapper disabled={values.disabled}>
      <Label children={label} />
      <Input placeholder={placeholder} value={val} onChange={onChange} />
    </Wrapper>
  );
};

export default TextArea;
