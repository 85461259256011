/* eslint-disable no-unused-vars */
import React from "react";
import { Wrapper, Input, Icon } from "./styled";

const IconAndLabelInput = ({ showBorder, value, height, left, background, onChange, disabled, label, icon }) => {
  return (
    <Wrapper showBorder={showBorder}>
      <Input left={left} value={value} height={height} background={background} onChange={onChange} disabled={disabled} placeholder={label} />
      <Icon left={left}>{icon}</Icon>
    </Wrapper>
  );
};

export default IconAndLabelInput;
