import styled from "styled-components";
import Img from "components/commons/Img";

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  margin-bottom: 198px;
  @media (max-width: 600px) {
    margin-bottom: 153px;
  }
`;

export const Image = styled(Img)`
  display: flex;
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 8px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  pointer-events: none;
`;