import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  width: 65%;
  height: 536px;
  padding: 0px 32px 24px 32px;
  box-sizing: border-box;
  background: ${colors.white};
  overflow: hidden;
  border: 1px solid ${colors.gray90};
  @media (max-width: 980px) {
    padding: 0px;
  }
  @media (max-width: 500px) {
    width: 100%;
  }
`;
