import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

export const getReformatIncomeData = (incomeData) => {
  const initialIncomeData = [10, 90];
  if (isEmpty(incomeData)) return initialIncomeData;

  const totalCount = get(incomeData, "totalPaperworks", 0);
  const observedCount = get(incomeData, "observed", 0);

  return [observedCount, totalCount];
};

export const getReformatApprovedData = (approvedData) => {
  let initialApprovedData = [10, 90];
  if (isEmpty(approvedData)) return initialApprovedData;

  const approved = get(approvedData, "approved", 0);
  const approvedObserved = get(approvedData, "approvedObserved", 0);

  return [approved, approvedObserved];
};
