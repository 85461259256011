import React, { useState } from "react";
import MainLayout from "components/commons/MainLayout";
import colors from "styles/colors";
import { Wrapper } from "./styled";
import Header from "./components/Header";
import Form from "./components/Form";
import Buttons from "./components/Form/components/Buttons";
import { useSelector } from "react-redux";
import { selectUserEmail, selectUserPhone, selectUserPhoneAreaCode } from "store/Login/selectors";

const Profile = () => {
  const email = useSelector(selectUserEmail);
  const phone = useSelector(selectUserPhone);
  const phoneAreaCode = useSelector(selectUserPhoneAreaCode);

  const [data, setData] = useState({
    email: email,
    phone: phone,
    phoneAreaCode: phoneAreaCode,
  });

  return (
    <MainLayout background={colors.gray100} header={<Header />}>
      <Wrapper>
        <Form data={data} setData={setData} />
        <Buttons data={data} />
      </Wrapper>
    </MainLayout>
  );
};

export default Profile;
