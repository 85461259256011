/* eslint-disable react/no-children-prop */
import React from "react";
import { Wrapper } from "./styled";
import SectionData from "../SectionData";
import TopElements from "./components/TopElements";

const Content = ({ showBack, title, description, route, fontSize, color, element }) => {
  return (
    <Wrapper>
      <SectionData showBack={showBack} title={title} description={description} route={route} fontSize={fontSize} color={color} />
      <TopElements element={element} />
    </Wrapper>
  );
};

export default Content;
