import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 75px;
  position: fixed;
  bottom: 0;
  transition: all 0.9s;
  overflow: visible;
  box-sizing: border-box;
`;
