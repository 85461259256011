import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Wrapper } from "./styled";
import colors from "styles/colors";
import { selectFilters } from "store/Filters/selectors";
import { initialState } from "store/Filters/initialState";
import isEqual from "lodash/isEqual";
import Button from "./components/Button";
import FiltersModal from "components/commons/Modals/FiltersModal";

const AdvancedFilters = ({ filtersConstants, getPaperworkHistory }) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const toggle = () => setShowModal(!showModal);
  const filters = useSelector(selectFilters);
  const [isActive, setIsActive] = useState(!isEqual(filters, initialState));
  const border = isActive ? `2px solid ${colors.blue70}` : `1px solid ${colors.blue40}`;
  const background = isActive ? colors.blue99 : colors.white;

  const apply = () => {
    dispatch(getPaperworkHistory());
    setShowModal(false);
  };

  useEffect(() => setIsActive(!isEqual(filters, initialState)), [filters]);

  return (
    <Wrapper onClick={toggle}>
      <FiltersModal show={showModal} filtersConstants={filtersConstants} apply={apply} goBack={toggle} />
      <Button border={border} background={background} isActive={isActive} />
    </Wrapper>
  );
};

export default AdvancedFilters;
