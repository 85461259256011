import React from "react";
import Data from "./components/Data";
import { Title, Wrapper } from "./styled";

const LeftBlock = ({ background, color, detail, inCome }) => {
  return (
    <Wrapper>
      <Title>En curso</Title>
      <Data background={background} color={color} detail={detail} inCome={inCome} />
    </Wrapper>
  );
};

export default LeftBlock;
