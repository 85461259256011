/* eslint-disable react/no-children-prop */
import React from "react";
import { Wrapper, Title } from "../styled";
import { useSelector } from "react-redux";
import { selectPaperworkProgressId, selectPaperworkStageProgress } from "store/PaperWork/selectors";
import Image from "./components/Image";
import { get } from "lodash";
import { selectToken } from "store/Login/selectors";
import * as paths from "components/pages/App/constants";

const CadastralBoundary = () => {
  const token = useSelector(selectToken);
  const pwId = useSelector(selectPaperworkProgressId);
  const stageProgress = useSelector(selectPaperworkStageProgress);
  const unconstitutedLand = get(stageProgress, "[0].otherInputsData.InformCatastral.unconstitutedLand", "");
  const fileName = get(stageProgress, "[0].otherInputsData.InformCatastral.file[0]", "");

  const storage = get(stageProgress, "[0].otherInputsData.InformCatastral.storage", {});
  const key = get(storage, `${fileName}`, "");
  const storageName = get(key, "storageName", "");

  const croquis = `${paths.PATH_BASE_URL}/v1/paperwork-progress/${pwId}/files/${paths.EXEMPTS_BUCKET_TEST}/${storageName}?token=${token}`;

  if (!unconstitutedLand) {
    return null;
  }

  return (
    <Wrapper>
      <Title children="Linderos catastral" />
      <Image resource={croquis} />
    </Wrapper>
  );
};

export default CadastralBoundary;
