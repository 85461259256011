/* eslint-disable react/no-children-prop */
import React from "react";
import { Wrapper, Text } from "./styled";
import ShowBack from "../ShowBack";

const Title = ({ showBack, route, color, fontSize, text }) => {
  return (
    <Wrapper>
      <ShowBack showBack={showBack} route={route} />
      <Text color={color} fontSize={fontSize} children={text} />
    </Wrapper>
  );
};

export default Title;
