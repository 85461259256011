import { PATH_FISCAL_KEY_LOGIN } from "components/pages/App/constants";
import { get } from "lodash";

export const selectLoginFetching = (state) => get(state, "login.isFetching", false);
export const selectLoginError = (state) => get(state, "login.error", "");
export const selectToken = (state) => get(state, "login.token", "");
export const selectRefreshToken = (state) => get(state, "login.refreshToken", "");
export const selectTokenExpires = (state) => get(state, "login.tokenExpires", 0);
export const selectValidSince = (state) => get(state, "login.validSince", 0);
export const selectUser = (state) => get(state, "login.user", null);
export const selectUserId = (state) => get(state, "login.user.id", null);
export const selectUserUid = (state) => get(state, "login.user.uid", "");
export const selectUserName = (state) => get(state, "login.user.name", "");
export const selectUserEmail = (state) => get(state, "login.user.email", "");
export const selectUserCuit = (state) => get(state, "login.user.cuit", "");
export const selectUserPhone = (state) => get(state, "login.user.phone", "");
export const selectUserPhoneAreaCode = (state) => get(state, "login.user.phoneAreaCode", "");
export const selectUserProfileId = (state) => get(state, "login.user.profileId", null);
export const selectIsAllowed = (state, roles) => roles.includes(selectUserProfileId(state));
export const selectUserPicture = (state) => get(state, "login.user.profilePictureUrl", null);
export const selectPathEnabled = (state, path) => get(state, `login.permissions.${selectUserProfileId(state)}.${path}`, false);
export const selectProfileDefaultRoute = (state) => get(state, `login.permissions.${selectUserProfileId(state)}.defaultRoute`, PATH_FISCAL_KEY_LOGIN);
