import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectPaperworkListByState } from "store/PaperWork/selectors";
import Item from "../Item";
import { ContainerWrap, Wrapper } from "./styled";
import { PAPERWORK_STATE } from "store/PaperWork/constants";
import { getPaperworkProgress, getUserPaperworks } from "store/PaperWork/actions/requests";
import { IN_PROGRESS_KEY } from "../../functions";
import { FILTERS_HOME_DEFAULT } from "../../../Selector/components/Filters/constants";
import { filterByState } from "../../functions";
import { selectFilters } from "store/Filters/selectors";
import { getCustomFilters } from "store/Filters/functions";

const PaperworkInProgress = ({ search }) => {
  const dispatch = useDispatch();

  const IN_PROGRESS_STATES = [
    PAPERWORK_STATE.STARTED,
    PAPERWORK_STATE.PENDING,
    PAPERWORK_STATE.OBSERVED,
    PAPERWORK_STATE.APPROVE_REQUESTED,
    PAPERWORK_STATE.REJECT_REQUESTED,
    PAPERWORK_STATE.MENDING_REQUESTED,
  ];

  const paperworkList = useSelector((state) => selectPaperworkListByState(state, IN_PROGRESS_STATES));
  const filteredList = filterByState(paperworkList, search);

  const storeFilters = useSelector(selectFilters);

  useEffect(() => {
    const customFilters = getCustomFilters(storeFilters, FILTERS_HOME_DEFAULT[IN_PROGRESS_KEY]);
    dispatch(getUserPaperworks(customFilters));
  }, []);

  const Card = ({ item }) => {
    const isObserved = item.state === PAPERWORK_STATE.OBSERVED;
    const getDetail = (id) => dispatch(getPaperworkProgress(id));
    return (
      <Item
        state={item.state}
        title={item.paperworkProgressId}
        detail={item.paperworkName}
        onClick={isObserved ? () => getDetail(item.paperworkProgressId) : null}
      />
    );
  };

  return (
    <Wrapper>
      <ContainerWrap>
        {filteredList.map((item) => (
          <Card key={item.paperworkProgressId} item={item} />
        ))}
      </ContainerWrap>
    </Wrapper>
  );
};

export default PaperworkInProgress;
