import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
  padding-left: 0px;
  gap: 16px;
  box-sizing: border-box;
  border-bottom: 1px solid ${colors.gray99};
`;

export const UserInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3px;
`;

export const Author = styled.div`
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: ${colors.gray50};
  justify-content: center;
`;

export const Date = styled.div`
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: ${colors.gray50};
  justify-content: center;
`;

export const Separator = styled(Date)`
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
`;

export const IndentedBar = styled.div`
  margin-left: 10px;
  width: 2px;
  background-color: ${colors.gray80};
`;

export const Text = styled.div`
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  background-color: transparent;
  color: ${colors.gray10};
`;