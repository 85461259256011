export const FIREBASE_ERRORS = {
  "auth/wrong-password": "Contraseña incorrecta",
  "auth/user-not-found": "Usuario no encontrado",
  "auth/invalid-email": "Email inválido",
  "auth/email-already-in-use": "El mail ingresado ya se encuentra en uso",
  "auth/missing-password": "Contraseña inválida",
  "auth/weak-password": "La contraseña debe tener al menos 6 caracteres",
  "auth/too-many-requests": "Acceso deshabilitado temporalmente debido a muchos intentos de inicio de sesión fallidos.  Podes volver a intentarlo más tarde.",
  "auth/user-disabled": "Usuario deshabilitado",
};
