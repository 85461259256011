/* eslint-disable react/no-children-prop */
import React from "react";
import { Wrapper, Author, Date, Separator, Text, UserInfoWrapper, TextWrapper, IndentedBar} from "./styled";
import moment from "moment";

const Observation = ({observation}) => {
  const parseDate = (ts) => {
    const date = moment.unix(ts).format("DD/MM/YYYY");
    const time = moment.unix(ts).format("HH:mm");
    return `${date} a las ${time}`;
  };

  return (
    <Wrapper>
      <UserInfoWrapper>
        <Date children={`[${parseDate(observation.inserted_date)}]`} />
        <Separator children='-' />
        <Author children={observation.author.name} />
      </UserInfoWrapper>
      <TextWrapper>
        <IndentedBar />
        <Text children={observation.data} />
      </TextWrapper>
    </Wrapper>
  );
};

export default Observation;
