import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 100%;
  min-height: 90px;
  gap: 2px;
`;
