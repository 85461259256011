import styled from "styled-components";
import colors from "styles/colors";
import { ReactComponent as Question } from "images/icons/lightBlue/question.svg";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: auto;
  margin-bottom: 10px;
  gap: 4px;
  cursor: pointer;
`;

export const Text = styled.div`
  margin-right: auto;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${colors.lightBlue50};
`;

export const Icon = styled(Question)`
  & > path {
    fill: ${colors.lightBlue50};
  }
`;
