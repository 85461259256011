/* eslint-disable no-unused-vars */
import React from "react";
import Wrapper from "./styled";
import CountUp from "react-countup";

const Indicator = ({ data, color, hide}) => {
  return (
    <Wrapper color={color} hide={hide}>
      <CountUp end={data} duration={1}/>
    </Wrapper>
  );
};

export default Indicator;
