/* eslint-disable react/no-children-prop */
import React from "react";
import isEmpty from "lodash/isEmpty";
import { MendingAreaText, MendingReasonText, Title, Wrapper } from "./styled";
import { useSelector } from "react-redux";
import { selectPaperworkMetadata } from "store/PaperWork/selectors";
import get from "lodash/get";
import { PAPERWORK_STATE } from "store/PaperWork/constants";

const MendingRequest = () => {
  const paperworkProgress = useSelector(selectPaperworkMetadata);
  const currentMendingRequest = get(paperworkProgress, "currentMendingRequest", []);
  const state = get(paperworkProgress, "state", null);
  const isRejected = state === PAPERWORK_STATE.REJECTED;

  if(isEmpty(currentMendingRequest)) return null;
  if(isRejected) return null;
  return (
    <Wrapper>
      <Title>Solicitud de subsanación</Title>
      <MendingAreaText>
        Para: {currentMendingRequest?.mendingAreaName}
      </MendingAreaText>
      <MendingReasonText>
        Razón: {currentMendingRequest?.reason}
      </MendingReasonText>
    </Wrapper>
  );
};

export default MendingRequest;
