import Img from "components/commons/Img";
import styled from "styled-components";
import colors from "styles/colors";
import { fadeIn } from "styles/effects";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 5;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 30px 30px 30px 30px;
  overflow: hidden;
  animation: 1s ${fadeIn} forwards;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.75);
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  width: 100%;
  height: 100%;
  max-width: 424px;
  max-height: 540px;
  padding: 24px;
  box-sizing: border-box;
  background: ${colors.gray100};
  border-radius: 16px;
  overflow: hidden;
  @media (max-width: 480px) {
    width: 100%;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
  min-height: 32px;
  width: 100%;
  max-width: 87px;
  box-sizing: border-box;
  margin: 28px 0 4px auto;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  font-family: "Domine";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  color: ${colors.blue50};
  margin-bottom: 24px;
`;

export const Image = styled(Img)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  width: 100%;
  height: 100%;
  max-width: 310px;
  max-height: 260px;
  box-sizing: border-box;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 24px;
`;

export const Description = styled.div`
  display: flex;
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: ${colors.gray20};
  @media (max-width: 980px) {
    font-size: 14px;
  }
`;
