/* eslint-disable no-unused-vars */
/* eslint-disable react/no-children-prop */
import React from "react";
import { Wrapper, Edit } from "./styled";
import { MODAL_EDIT_USER } from "store/Modals/constants";
import { useDispatch } from "react-redux";
import { openModal, setParams } from "store/Modals/actions";

const EditCell = ({ row }) => {
  const dispatch = useDispatch();
  const onClick = () => {
    dispatch(setParams(row.original));
    dispatch(openModal(MODAL_EDIT_USER));
  };

  return <Wrapper onClick={onClick} children={<Edit />} />;
};

export default EditCell;
