import styled from "styled-components";
import { ReactComponent as Logo } from "images/ilustrations/login.svg";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  user-select: none;
  background: ${colors.gray100};
  @media (max-width: 980px) {
    flex-direction: column;
  }
`;

export const Sidebar = styled.div`
  display: flex;
  flex: 1 1 0;
  align-items: center;
  justify-content: center;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  @media (max-width: 980px) {
    width: 100%;
    height: unset;
    flex: unset;
    max-height: 500px;
  }
`;

export const IllustrationSidebar = styled(Logo)`
  height: 100%;
  width: auto;
`;
