import React from "react";
import { Wrapper, Row } from "./styled";
import NomenclatureData from "./components/NomenclatureData";
import PlanData from "./components/PlanData";
import RealStateDenomination from "./components/RealStateDenomination";
import CadastralBoundary from "./components/CadastralBoundary";
import Registration from "./components/Registration";
import IssuedCertificates from "./components/IssuedCertificates";
import Value from "./components/Value";
import RealStateTax from "./components/RealStateTax";
import Location from "./components/Location";
import DepartureObservations from "./components/DepartureObservations";
import PlotObservations from "./components/PlotObservations";
import FractionDenomination from "./components/FractionDenomination";

const RecoveredData = ({ selectedPlot }) => {
  return (
    <Wrapper>
      <NomenclatureData selectedPlot={selectedPlot} />
      <PlanData selectedPlot={selectedPlot} />
      <RealStateDenomination selectedPlot={selectedPlot} />
      <CadastralBoundary selectedPlot={selectedPlot} />
      <Value selectedPlot={selectedPlot} />
      <Row>
        <Registration selectedPlot={selectedPlot} />
        <RealStateTax selectedPlot={selectedPlot} />
      </Row>
      <Row>
        <IssuedCertificates selectedPlot={selectedPlot} />
        <Location selectedPlot={selectedPlot} />
      </Row>
      <PlotObservations selectedPlot={selectedPlot} />
      <DepartureObservations selectedPlot={selectedPlot} />
      <FractionDenomination />
    </Wrapper>
  );
};

export default RecoveredData;
