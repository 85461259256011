import React from "react";
import { useSelector } from "react-redux";
import { selectIsAllowed } from "store/Login/selectors";
import { USER_PROFILE_ID } from "store/Login/initialState";
import Rectification from "./components/Rectification";
import Operator from "./components/Actions/Operator";
import Subsanator from "./components/Actions/Subsanator";
import Administrator from "./components/Actions/Administrator";

const Commons = () => {
  const allowedRoles = [
    USER_PROFILE_ID.ADMINISTRATOR,
    USER_PROFILE_ID.OPERATOR,
    USER_PROFILE_ID.SUBSANATOR,
    USER_PROFILE_ID.TERRITORIAL_INFO_OPERATOR,
  ];
  const allowed = useSelector((state) => selectIsAllowed(state, allowedRoles));

  if (!allowed) return null;
  return (
    <>
      <Rectification />
      <Operator />
      <Subsanator />
      <Administrator />
    </>
  );
};

export default Commons;
