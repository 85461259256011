import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  min-height: 128px;
  margin-top: auto;
  padding: 0px;
  gap: 24px;
  box-sizing: border-box;
  position: relative;
  bottom: 0;
  @media (max-width: 980px) {
    min-height: 68px;
    position: absolute;
    justify-content: space-between;
    padding: 0px 16px;
    gap: 0px;
    background: ${colors.gray100};
  }
`;

export const LeftButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  width: 83px;
  height: 40px;
  border-radius: 4px;
`;

export const RigthButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  width: 107px;
  height: 40px;
`;
