/* eslint-disable react/no-children-prop */
import React from "react";
import useResize from "hooks/useResize";
import CommonLayout from "./components/CommonLayout";
import MobileLayout from "./components/MobileLayout";
import { DESK, MOBILE, TABLET } from "./constants";

const MainLayout = ({ children, header, background, paddingLeft, paddingRight }) => {
  const { checkSize } = useResize();

  switch (checkSize()) {
    case DESK:
      return <CommonLayout children={children} header={header} background={background} paddingLeft={paddingLeft} paddingRight={paddingRight}/>;
    case TABLET:
      return <MobileLayout children={children} header={header} />;
    case MOBILE:
      return <MobileLayout children={children} header={header} />;
  }
};

export default MainLayout;
