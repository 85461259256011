import produce from "immer";
import { MODAL_MESSAGE } from "./constants";
import { initialState } from "./initialState";
import { OPEN_MODAL, CLOSE_MODAL, SET_SUCCESS, SET_ERROR, CLEAR_MESSAGE, SET_PARAMS, SET_MESSAGE_CODE } from "./types";

const modalsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case OPEN_MODAL:
        draft[action.modalName] = true;
        break;
      case CLOSE_MODAL:
        draft[action.modalName] = false;
        draft.params = {};
        break;
      case SET_SUCCESS:
        draft[MODAL_MESSAGE] = true;
        draft.messageCode = action.messageCode;
        break;
      case SET_ERROR:
        draft[MODAL_MESSAGE] = true;
        draft.messageCode = action.messageCode;
        break;
      case CLEAR_MESSAGE:
        draft.messageCode = null;
        break;
      case SET_PARAMS:
        draft.params = action.params;
        break;
      case SET_MESSAGE_CODE:
        draft.messageCode = action.messageCode;
        break;
    }
  });

export default modalsReducer;
