import React, { useState } from "react";
import CommonInput from "components/commons/Inputs/CommonInput";
import colors from "styles/colors";
import Label from "./components/Label";
import { Wrapper, InputContainer, Number, AreaContainer, PhoneContainer } from "./styled";
import { areaCodeIsValid, phoneIsValid } from "components/pages/Profile/components/Form/components/Buttons/functions";

const TextFieldPhone = ({ setNewUser, newUser, label, disabled }) => {
  const [errorCode, setErrorCode] = useState(false);
  const errorCodeMessage = errorCode ? "Código inválido" : "";
  const [errorPhone, setErrorPhone] = useState(false);
  const errorPhoneMessage = errorPhone ? "Número inválido" : "";

  const codeOnChange = (e) => {
    if (areaCodeIsValid(e.target.value)) setErrorCode(false);
    else setErrorCode(true);
    setNewUser({
      ...newUser,
      ["phoneAreaCode"]: e.target.value,
    });
  };

  const phoneOnChange = (e) => {
    if (phoneIsValid(e.target.value)) setErrorPhone(false);
    else setErrorPhone(true);
    setNewUser({
      ...newUser,
      ["phone"]: e.target.value,
    });
  };

  return (
    <Wrapper>
      <AreaContainer>
        <Label text={label} />
        <InputContainer>
          <Number>0</Number>
          <CommonInput
            errorMessage={errorCodeMessage}
            error={errorCode}
            height={"48px"}
            onChange={codeOnChange}
            value={newUser["phoneAreaCode"]}
            disabled={disabled}
            color={colors.gray10}
            border={`1px solid ${colors.gray50}`}
            borderRadius={"4px"}
            placeholder={"376"}
          />
        </InputContainer>
      </AreaContainer>
      <PhoneContainer>
        <Label text={null} />
        <InputContainer>
          <Number>15</Number>
          <CommonInput
            errorMessage={errorPhoneMessage}
            error={errorPhone}
            height={"48px"}
            onChange={phoneOnChange}
            value={newUser["phone"]}
            disabled={disabled}
            color={colors.gray10}
            border={`1px solid ${colors.gray50}`}
            borderRadius={"4px"}
            placeholder={"15345670"}
          />
        </InputContainer>
      </PhoneContainer>
    </Wrapper>
  );
};

export default TextFieldPhone;
