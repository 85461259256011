import styled from "styled-components";

export const Wrapper = styled.div`
  display: grid;
  justify-items: center;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  width: 100%;
  height: 100%;
  gap: 24px 16px;
  @media (max-width: 1720px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  @media (max-width: 1439px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 980px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 728px) {
    grid-template-columns: 1fr;
    height: 100%;
  }
`;