import React from "react";
import { COMPONENTS } from "./constants";
import { Wrapper } from "./styled";

const Content = ({ form, stageKey }) => {
  const getElement = (e, index) => COMPONENTS[e.element](e, stageKey, index);

  return <Wrapper>{form.map(getElement)}</Wrapper>;
};

export default Content;
