/* eslint-disable react/no-children-prop */
import React from "react";
import isEmpty from "lodash/isEmpty";
import { Text, Title, Wrapper } from "./styled";
import { useSelector } from "react-redux";
import { selectPaperworkMetadata } from "store/PaperWork/selectors";
import get from "lodash/get";

const Rejection = () => {
  const paperworkProgress = useSelector(selectPaperworkMetadata);
  const rejection = get(paperworkProgress, "rejection", []);

  if(isEmpty(rejection)) return null;
  return (
    <Wrapper>
      <Title>Motivo del rechazo</Title>
      <Text>
        {rejection?.reason}
      </Text>
    </Wrapper>
  );
};

export default Rejection;
