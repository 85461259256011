import React from "react";
import { SECTIONS } from "../../../../../constants";
import GenericItem from "../../../GenericItem";
import { Wrapper, Icons } from "./styled";

const ItemsList = ({ toogle }) => {
  return (
    <Wrapper toogle={toogle}>
      <Icons toogle={toogle}>
        {SECTIONS.map((elem) => <GenericItem toogle={toogle} elem={elem} key={elem.section}/>)}
      </Icons>
    </Wrapper>
  );
};

export default ItemsList;
