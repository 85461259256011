import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: ${(props) => props.width ? props.width : "100%"};
  height: ${(props) => props.height ? props.height : "100%"};
  gap: 8px;
  border-radius: 4px;
  border: ${({ disabled, border }) => (disabled ? `1px solid ${colors.gray80}` : border)};
  cursor: pointer;
  transition: all 200ms;
  background: ${({ type, disabled, background }) => {
    if (type === "primary" && disabled) return `${colors.gray99}`;
    if (type === "secondary" && disabled) return `${colors.white}`;
    return background;
  }};
  &:hover {
    opacity: 0.8;
  }
  ${({ disabled }) => disabled && "pointer-events: none;"}
  box-sizing: border-box;
`;

export const Text = styled.div`
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.32px;
  color: ${({ disabled, color }) => (disabled ? colors.gray80 : color)};
  font-size: 14px;
`;
