import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  flex: 1;
  height: 100%;
  max-height: 112px;
  padding: 8px;
  color: blue;
  border-radius: 8px;
  box-sizing: border-box;
  cursor: ${({ onClick }) => (onClick ? "pointer" : "default")};
  background: ${colors.white};
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  margin-right: auto;
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  color: ${colors.gray60};
`;
