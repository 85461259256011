import MainLayout from "components/commons/MainLayout";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import colors from "styles/colors";
import Header from "./Header";
import { TableContainer, Wrapper } from "./styled";
import Table from "../../commons/Table";
import { getColumns } from "./resources.jsx";
import { resetFilters } from "store/Filters/actions";
import { getPaperworkHistoryCustom } from "store/PaperWork/actions/requests";
import CertificateFilters from "./Filters";
import { FILTER_CERTIFICATES_TABLE } from "./constants";
import { selectPaperworkList } from "store/PaperWork/selectors";
import { resetGeneralStore } from "store/General/actions";
import { setPaperworkList } from "store/PaperWork/actions/actions";

const CertificateRequests = () => {
  const dispatch = useDispatch();
  const data = useSelector(selectPaperworkList);

  useEffect(() => {
    dispatch(getPaperworkHistoryCustom(FILTER_CERTIFICATES_TABLE));
  }, []);

  useEffect(() => () => {
    dispatch(resetGeneralStore());
    dispatch(setPaperworkList([]));
    dispatch(resetFilters());
  }, []);

  const columns = useMemo(() => getColumns(), []);

  return (
    <MainLayout background={colors.gray100} header={<Header />} paddingLeft={30} paddingRight={110}>
      <Wrapper>
        <TableContainer>
          <Table columns={columns} data={data}>
            <CertificateFilters />
          </Table>
        </TableContainer>
      </Wrapper>
    </MainLayout>
  );
};

export default CertificateRequests;
