export const MODAL_PAPERWORK_MAP = {
  E1: {
    title: "Mejora de inmueble",
    structure: [
      {
        id: "paperworkProgressId",
        label: "Número de trámite",
      },
      {
        id: "cadastralNomenclature",
        label: "Nomenclatura Catastral",
      },
      {
        id: "department",
        label: "Departamento",
      },
      {
        id: "departure",
        label: "Partida",
      },
      {
        id: "finishDate",
        label: "Fecha de Finalización",
      },
      {
        id: "stateName",
        label: "Estado",
      },
      {
        id: "startDate",
        label: "Fecha de Inicio",
      },
      {
        id: "userName",
        label: "Contribuyente",
      },
      {
        id: "cuit",
        label: "CUIT/CUIL",
      },
      {
        id: "userDni",
        label: "DNI",
      },
    ],
  },
  D1: {
    title: "Certificado catastral",
    structure: [
      {
        id: "paperworkProgressId",
        label: "Número de trámite",
      },
      {
        id: "startDate",
        label: "Fecha de Inicio",
      },
      {
        id: "cuit",
        label: "CUIT/CUIL",
      },
      {
        id: "stateName",
        label: "Estado",
      },
      {
        id: "department",
        label: "Departamento",
      },
      // {
      //     id: "reason",
      //     label: "Motivo del Certificado",
      // },
      {
        id: "departure",
        label: "Partida",
      },
      // {
      //     id: "profession",
      //     label: "Profesión",
      // },
      {
        id: "cadastralNomenclature",
        label: "Nomenclatura Catastral",
      },
    ],
  },
  D5: {
    title: "Informe catastral",
    structure: [
      {
        id: "paperworkProgressId",
        label: "Número de trámite",
      },
      {
        id: "startDate",
        label: "Fecha de Inicio",
      },
      {
        id: "cuit",
        label: "CUIT/CUIL",
      },
      {
        id: "stateName",
        label: "Estado",
      },
      {
        id: "department",
        label: "Departamento",
      },
      // {
      //     id: "reason",
      //     label: "Motivo del Certificado",
      // },
      {
        id: "departure",
        label: "Partida",
      },
      // {
      //     id: "profession",
      //     label: "Profesión",
      // },
      {
        id: "cadastralNomenclature",
        label: "Nomenclatura Catastral",
      },
    ],
  },
  D2: {
    title: "Certificado catastral",
    structure: [
      {
        id: "paperworkProgressId",
        label: "Número de trámite",
      },
      {
        id: "startDate",
        label: "Fecha de Inicio",
      },
      {
        id: "cuit",
        label: "CUIT/CUIL",
      },
      {
        id: "stateName",
        label: "Estado",
      },
      {
        id: "department",
        label: "Departamento",
      },
      {
        id: "departure",
        label: "Partida",
      },
      {
        id: "cadastralNomenclature",
        label: "Nomenclatura Catastral",
      },
    ],
  },
};
