import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  padding-top: 28px;
  flex-direction: column;
  align-items: top;
  justify-content: top;
  box-sizing: border-box;
  @media (max-width: 720px) {
    padding: 20px 0px;
    height: 90%;
  }
`;
