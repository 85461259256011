import styled from "styled-components";
import colors from "styles/colors";

export const Line = styled.div`
  width: 100%;
  min-height: 1px;
  margin-top: 4px;
  margin-bottom: 4px;
  background: ${colors.gray80};
`;
