import React from "react";
import { useDispatch } from "react-redux";
import { logout } from "store/Login/actions/actions";
import { Item } from "../styled";
import LogoutIcon from "components/commons/Icons/LogoutIcon";
import { Wrapper } from "./styled";

const Logout = () => {
  const dispatch = useDispatch();
  const resetUser = () => dispatch(logout());

  return (
    <Wrapper>
      <Item onClick={resetUser} key={"logout"}>
        <LogoutIcon />
      </Item>
    </Wrapper>
  );
};

export default Logout;
