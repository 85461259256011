import React from "react";
import { GraphWrapper, PieWrapper, Title, Wrapper } from "./styled";
import Counter from "./components/Counter";
import PieChart from "./components/PieChart";

const LeftCard = ({ leftChartColor, rightChartColor, data, detail, approvedObserved, approved }) => {
  return (
    <Wrapper>
      <Title>Aprobados</Title>
      <GraphWrapper>
        <Counter data={approved} color={leftChartColor} label={"Sin observar"} />
        <PieWrapper>
          <PieChart leftColor={leftChartColor} rightColor={rightChartColor} data={data} detail={detail} />
        </PieWrapper>
        <Counter data={approvedObserved} color={rightChartColor} label={"Observados"} />
      </GraphWrapper>
    </Wrapper>
  );
};

export default LeftCard;
