import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 39px 48px 20px 48px;
  gap: 39px;
  box-sizing: border-box;
  overflow-y: scroll;
  @media (max-width: 580px) {
    padding: 39px 16px 20px 16px;
  }
`;

export const Title = styled.div`
  font-family: "Domine";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: ${colors.blue40};
  @media (max-width: 1366px) {
    font-size: 24px;
    margin-bottom: 10px;
  }
`;

export const IconContainer = styled.div`
  height: 100%;
  position: absolute;
  right: 28px;
`;
