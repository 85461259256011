import styled from "styled-components";

export const Wrapper = styled.div`
  display: ${(props) => (props.hidden ? "hidden" : "flex")};
  flex-direction: column;
  justify-content: center;
`;

export const Text = styled.div`
  font-family: "OpenSansRegular";
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  color: ${(props) => (props.hide ? "transparent" : props.color)};
`;
