import React from "react";
import LargeButton from "components/commons/Buttons/LargeButton";
import API from "services/api";
import { Wrapper } from "./styled";
import { useDispatch } from "react-redux";
import * as types from "store/Login/types";
import { BYPASS_LOGIN } from "components/pages/App/constants";

const Buttons = () => {
  const dispatch = useDispatch();

  const onSubmit = async () => {
    if (BYPASS_LOGIN) {
      try {
        const response = await API.login.getUser("");
        if (response.status == 200) {
          localStorage.setItem("user", JSON.stringify(response.data.result.user));
          dispatch({ type: types.LOGIN_SUCCESS, token: "", tokenExpires: "", user: response.data.result.user });
        } else dispatch({ type: types.LOGIN_FAILED, error: response?.statusText });
      } catch (error) {
        dispatch({ type: types.LOGIN_FAILED, error });
      }
    } else window.keycloakSession.init({ onLoad: "login-required", checkLoginIframe: false });
  };

  return (
    <Wrapper onClick={onSubmit}>
      <LargeButton label={"Iniciar con Clave Fiscal"} onClick={onSubmit} />
    </Wrapper>
  );
};

export default Buttons;
