import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: top;
  justify-content: left;
  width: 100%;
  max-height: 255px;
  box-sizing: border-box;
  border: 1px solid ${colors.gray95};
  border-radius: 17px;
  background: ${colors.white};
`;
