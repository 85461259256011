/* eslint-disable no-unused-vars */
import { push } from "connected-react-router";
import { loadingStop } from "store/General/actions";
import { selectProfileDefaultRoute } from "../selectors";
import * as types from "../types";
import { success } from "store/Modals/actions";
import { PATH_SIGN_IN } from "components/pages/App/constants";

export const handlingLogin = (response, refreshToken, tokenExpires, validSince) => {
  return (dispatch, getState) => {
    localStorage.setItem("user", JSON.stringify(response.data.result.user));
    localStorage.setItem("token", response.data.result.token);
    localStorage.setItem("refreshToken", refreshToken);
    localStorage.setItem("validSince", validSince);
    localStorage.setItem("tokenExpires", tokenExpires);
    const token = response.data.result.token;
    dispatch({
      type: types.LOGIN_SUCCESS,
      token: token,
      tokenExpires: tokenExpires,
      user: response.data.result.user,
      refreshToken: refreshToken,
      validSince: validSince,
    });
    const defaultRoute = selectProfileDefaultRoute(getState());
    dispatch(push(defaultRoute));
  };
};

export const handlingResetPassword = (response) => {
  return (dispatch) => {
    dispatch(success(response.data.code));
    dispatch(loadingStop());
    dispatch(push(PATH_SIGN_IN));
  };
};

export const handlingGetIdToken = (response) => {
  return (dispatch) => {
    const token = response.data.access_token;
    const refreshToken = response.data.refresh_token;
    localStorage.setItem("token", token);
    localStorage.setItem("refreshToken", refreshToken);
    const newExpiration = Date.now() + parseInt(response.data.expires_in, 10) * 1000; // milliseconds.
    localStorage.setItem("tokenExpires", newExpiration);
    dispatch({
      type: types.UPDATE_TOKEN_SUCCESS,
      token: token,
      tokenExpires: newExpiration,
      refreshToken: refreshToken,
    });
  };
};
