import React from "react";
import Cell from "components/commons/Table/components/Structure/components/Cell";
import { Column } from "components/commons/Table/styled";
import { USER_PROFILE } from "store/Login/initialState";
import ApproveCell from "components/commons/Table/components/Structure/components/ApproveCell";
import { Decline } from "components/commons/Table/components/Structure/components/DeleteCell/styled";
import DeleteCell from "components/commons/Table/components/Structure/components/DeleteCell";

const columns = [
  {
    Header: () => <Column>Nombre completo</Column>,
    width: 261,
    accessor: "name",
    Cell: ({ value, row }) => <Cell value={value} row={row} />,
  },
  {
    Header: () => <Column>Tipo de perfil</Column>,
    width: 261,
    accessor: "profileId",
    Cell: ({ value }) => <Cell value={USER_PROFILE[value]} />,
  },
  {
    Header: () => <Column>Mail</Column>,
    width: 261,
    accessor: "email",
    Cell: ({ value, row }) => <Cell value={value} row={row} />,
  },
  {
    Header: () => <Column>Rechazar</Column>,
    width: 107,
    id: "decline",
    Cell: ({ row }) => <DeleteCell row={row} icon={<Decline />} />,
  },
  {
    Header: () => <Column>Aprobar</Column>,
    width: 110,
    id: "approve",
    Cell: ({ row }) => <ApproveCell row={row} />,
  },
];

export const getColumns = () => {
  return columns;
};
