import { get } from "lodash";
import { selectUserId } from "store/Login/selectors";

export const selectPaperworkStructure = (state) => get(state, "paperwork.detail.structure", {});
export const selectPaperworkData = (state) => get(state, "paperwork.detail.data", {});
export const selectPaperworkMetadata = (state) => get(state, "paperwork.detail.metadata", {});
export const selectPaperworkStageProgress = (state) => get(state, "paperwork.detail.metadata.stageProgress", []);
export const selectPaperworkProgressAuthor = (state) => get(state, "paperwork.detail.metadata.user", {});
export const selectPaperworkProgressId = (state) => get(state, "paperwork.detail.metadata.id", null);
export const selectStageIndex = (state) => get(state, "paperwork.stageIndex", 0);
export const selectPaperworkType = (state) => get(state, "paperwork.detail.structure.id", null);
export const selectStageCompleted = (state) => get(state, "paperwork.stageCompleted", false);
export const selectFormErrors = (state) => get(state, "paperwork.formErrors", []);
export const selectPaperworkList = (state) => get(state, "paperwork.list", []);
export const selectPaperworkListByState = (state, states) => {
  const list = selectPaperworkList(state);
  return list.filter((item) => states.includes(item.state));
};
export const selectPaperworkPageSize = (state) => get(state, "paperwork.pageSize", 50);
export const selectPaperworkTotalCount = (state) => get(state, "paperwork.totalCount", 0);
export const selectPaperworkActualPage = (state) => get(state, "paperwork.actualPage", 1);
export const selectReportLink = (state) => get(state, "paperwork.reportShareLink", "");
export const selectpaperworksCountStatistics = (state) => get(state, "paperwork.charts", {});
export const selectNewPaperworksList = (state) => get(state, "paperwork.newPaperworksList", []);
export const selectApiDocuments = (state) => get(state, "paperwork.apiDocuments", {});
export const selectStorageDocuments = (state) => get(state, "paperwork.storageDocuments", []);
export const selectRejectionReason = (state) => get(state, "paperwork.currentRejectionReason", []);
export const selectMendRequestReason = (state) => get(state, "paperwork.currentMendingRequestReason", []);
export const selectMendRequestArea = (state) => get(state, "paperwork.currentMendingRequestArea", []);
export const selectSubsanationAreas = (state) => get(state, "paperwork.subsanationAreas", []);
export const selectUserCanEditPaperworkProgress = (state) => {
  const paperworkProgress = selectPaperworkMetadata(state);
  const editorId = get(paperworkProgress, "editor.id", null);
  const userId = selectUserId(state);
  return !editorId || editorId === userId;
};
export const selectModifyMode = (state) => get(state, "paperwork.modifyMode", false);
export const selectSeeMorePaperwork = (state) => get(state, "paperwork.seeMorePaperwork", {});
