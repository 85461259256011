import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: top;
  width: 100%;
  margin-bottom: 20px;
  gap: 46px;
  font-size: 60px;
  box-sizing: border-box;
  @media (max-width: 980px) {
    height: 100%;
    overflow-y: overlay;
    padding: 0px 20px;
  }
  @media (max-width: 580px) {
    padding: 20px 16px;
    gap: 0px;
    background: ${colors.white};
    border: 1px solid ${colors.gray95};
    border-radius: 4px;
  }
`;
