import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: top;
  position: relative;
  width: 100%;
  padding-bottom: ${(props) => (!props.isLastChild ? "40px" : "none")};
  gap: 32px;
  box-sizing: border-box;
  border-bottom: ${(props) => (!props.isLastChild ? `1px solid ${colors.gray90}` : "none")};
`;

export const Title = styled.div`
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
  color: ${colors.gray0};
  @media (max-width: 1366px) {
    font-size: 24px;
    margin-bottom: 10px;
  }
`;

export const Description = styled.div`
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.gray50};
  @media (max-width: 1366px) {
    font-size: 24px;
    margin-bottom: 10px;
  }
`;
