import produce from "immer";
import { initialState } from "./initialState";
import * as types from "./types";

const loginReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.LOGIN_START:
        draft.token = "";
        draft.tokenExpires = 0;
        draft.error = "";
        draft.user = null;
        draft.isFetching = true;
        break;
      case types.LOGIN_SUCCESS:
        draft.token = action.token;
        draft.tokenExpires = action.tokenExpires;
        draft.refreshToken = action.refreshToken;
        draft.validSince = action.validSince;
        draft.error = "";
        draft.user = action.user;
        draft.isFetching = false;
        break;
      case types.LOGIN_FAILED:
        draft.token = "";
        draft.tokenExpires = 0;
        draft.error = action.error;
        draft.user = null;
        draft.isFetching = false;
        break;
      case types.LOGOUT_SUCCESS:
        draft.token = "";
        draft.tokenExpires = 0;
        draft.refreshToken = "";
        draft.validSince = 0;
        draft.error = "";
        draft.user = null;
        draft.isFetching = false;
        break;
      case types.LOGOUT_FAILED:
        draft.token = "";
        draft.tokenExpires = 0;
        draft.error = action.error;
        draft.user = null;
        draft.isFetching = false;
        break;
      case types.UPDATE_TOKEN_SUCCESS:
        draft.token = action.token;
        draft.tokenExpires = action.tokenExpires;
        draft.refreshToken = action.refreshToken;
        break;
      case types.UPDATE_TOKEN_FAILED:
        draft.token = "";
        draft.tokenExpires = 0;
        draft.user = null;
        break;
      case types.UPDATE_USER:
        draft.user = action.user;
        break;
      case types.RESET_ERROR:
        draft.error = "";
        break;
    }
  });

export default loginReducer;
