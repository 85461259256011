import styled from "styled-components";
import colors from "styles/colors";
import { fadeIn } from "styles/effects";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 5;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 30px 30px 30px 30px;
  overflow: hidden;
  animation: 1s ${fadeIn} forwards;
  box-sizing: border-box;
  border-bottom: 1px solid ${colors.gray100};
  background: rgba(0, 0, 0, 0.75);
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  min-width: ${(props) => (props.expanded ? "60%" : "35.5%")};
  min-height: ${(props) => (props.expanded ? "80%" : "50%")};
  box-sizing: border-box;
  background: ${colors.white};
  border: ${colors.gray100};
  border-radius: 16px;
  overflow: hidden;
  transition: all 1s;
  @media (max-width: 980px) {
    width: 100%;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
  width: 100%;
  height: ${(props) => (props.expanded ? "96px" : "80px")};
  padding: 24px 24px;
  gap: 8px;
  border-bottom: 1px solid ${colors.gray100};
  box-sizing: border-box;
`;

export const IconsContainer = styled.div`
  display: flex;
  position: absolute;
  gap: 8px;
  box-sizing: border-box;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  width: 100%;
  height: 100%;
  padding: 40px 24px;
  padding: ${(props) => (props.expanded ? "56px 40px" : "40px 24px")};
  box-sizing: border-box;
  @media (max-width: 1366px) {
    padding: 24px;
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  font-family: "Domine";
  font-style: normal;
  font-weight: 400;
  font-size: ${(props) => (props.expanded ? "36px" : "24px")};
  line-height: 32px;
  color: ${colors.blue40};
  @media (max-width: 1366px) {
    font-size: 24px;
  }
  @media (max-width: 1024px) {
    font-size: 20px;
  }
`;

export const TextArea = styled.textarea`
  display: flex;
  padding: 24px 16px;
  width: 100%;
  height: 100%;
  background: ${colors.gray100};
  outline: none;
  border: none;
  resize: none;
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.gray50};
  box-sizing: border-box;
  @media (max-width: 1366px) {
    padding: 10px 10px;
    font-size: 10px;
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: end;
  justify-content: right;
  width: 100%;
  height: 72px;
  padding-top: 36px;
  box-sizing: border-box;
  @media (max-width: 1366px) {
    height: 62px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 36px;
`;
