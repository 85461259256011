import React from "react";
import { Container, Wrapper, Logos, IconProv, IconDGC, Image } from "./styled";
import Header from "./components/Header";
import Content from "./components/Content";

const Form = () => {
  return (
    <Wrapper>
      <Logos>
        <IconProv />
        <IconDGC />
      </Logos>
      <Container>
        <Header />
        <Content />
      </Container>
      <Image />
    </Wrapper>
  );
};

export default Form;
