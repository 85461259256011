import React from "react";
import { Wrapper, Text } from "../style";
import TelegramIcon from "components/commons/Icons/SocialMedia/TelegramIcon";
import colors from "styles/colors";
import { selectReportLink } from "store/PaperWork/selectors";
import { useSelector } from "react-redux";

const Telegram = () => {
  const link = useSelector(selectReportLink);
  const text = "Hola, te adjunto este archivo";
  const url = `https://t.me/share/url?url=${encodeURIComponent(link)}&text=${encodeURIComponent(text)}`;
  
  return (
    <Wrapper>
      <a href={url} target="_blank" rel="noreferrer">
        <TelegramIcon />
      </a>
      <Text color={colors.gray10}>Telegram</Text>
    </Wrapper>
  );
};

export default Telegram;
