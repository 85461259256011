import styled from "styled-components";
import { fadeIn } from "styles/effects";
import colors from "styles/colors";
import { ReactComponent as MoreIcon } from "images/icons/gray/more.svg";
import { ReactComponent as ClockIcon } from "images/icons/orange/paperwork.svg";
import { ReactComponent as PaperworkIcon } from "images/icons/gray/paperwork.svg";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 5px;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
  min-height: 42px;
  gap: 6px;
  overflow: hidden;
  animation: 0.9s ${fadeIn} forwards;
  box-sizing: border-box;
  @media (max-width: 980px) {
    gap: 0px;
    background: ${colors.gray100};
  }
  @media (max-width: 480px) {
    gap: 20px;
  }
`;

export const Section = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-inline: 16px;
  font-family: "OpenSansRegular";
  font-weight: 700;
  font-size: 12px;
  gap: 6px;
  color: ${(props) => (props.active ? colors.blue40 : colors.gray0)};
  cursor: pointer;
  border-bottom: ${(props) => (props.active ? `2px solid ${colors.orange50}` : "none")};
  box-sizing: border-box;
  @media (max-width: 980px) {
    padding-inline: 8px;
  }
  @media (max-width: 320px) {
    width: 33%;
  }
`;

export const Text = styled.div`
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: ${({ active }) => (active ? colors.orange50 : colors.gray50)};
  @media (max-width: 580px) {
    font-size: 12px;
  }
  @media (max-width: 344px) {
    font-size: 10px;
  }
  @media (max-width: 480px) {
    display: none;
  }
`;

export const Icon = styled.div`
  display: flex;
  & > svg {
    & > path {
      fill: ${(props) => (props.active ? colors.orange50 : colors.gray50)};
    }
    @media (max-width: 580px) {
      width: 16px;
      height: 16px;
    }
  }
`;

export const More = styled(MoreIcon)``;

export const Clock = styled(ClockIcon)``;

export const PaperWork = styled(PaperworkIcon)``;
