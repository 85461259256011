import React from "react";
import Fields from "./components/Fields";
import UploadFile from "./components/UploadFile";
import { Wrapper } from "./styled";

const Form = ({ data, setData }) => {
  return (
    <Wrapper>
      <UploadFile />
      <Fields data={data} setData={setData} />
    </Wrapper>
  );
};

export default Form;
