/* eslint-disable no-unused-vars */
/* eslint-disable react/no-children-prop */
import React, { useState } from "react";
import { ButtonContainer, Error, Icon, InputContainer, Line, Wrapper } from "./styled";
import LabelOnlyButton from "components/commons/Buttons/LabelOnlyButton";
import colors from "styles/colors";
import LabelIconButton from "components/pages/PaperworkDetail/components/LabelIconButton";
import { useDispatch, useSelector } from "react-redux";
import { firebaseSignInWithGoogle, firebaseSignUp } from "store/Login/actions/actions";
import NameInput from "./components/NameInput";
import EmailInput from "./components/EmailInput";
import PasswordInput from "./components/PasswordInput";
import { emailPattern, onlyLetters } from "utils/functions";
import { selectLoginError } from "store/Login/selectors";

const Elements = () => {
  const dispatch = useDispatch();
  const [user, setUser] = useState({
    email: "",
    password: "",
    name: "",
  });
  const message = useSelector(selectLoginError);
  const signInWithGoogle = () => dispatch(firebaseSignInWithGoogle());
  const signUp = () => dispatch(firebaseSignUp(user.name, user.email, user.password));

  return (
    <Wrapper>
      <InputContainer>
        <NameInput setUser={setUser} user={user} />
        <EmailInput setUser={setUser} user={user} />
        <PasswordInput setUser={setUser} user={user} />
      </InputContainer>
      <ButtonContainer>
        <LabelOnlyButton
          disabled={!emailPattern(user?.email) || !/^.{6,}$/.test(user?.password) || !user.name || !onlyLetters(user?.name)}
          label="Enviar solicitud"
          background={colors.blue50}
          color={colors.white}
          onClick={signUp}
        />
      </ButtonContainer>
      <Line children="O ingresá con" />
      <ButtonContainer>
        <LabelIconButton
          label="Iniciar sesión con Google"
          background={colors.white}
          color={colors.blue50}
          border={`1px solid ${colors.blue50}`}
          icon={<Icon />}
          onClick={signInWithGoogle}
        />
      </ButtonContainer>
      <Error>{message}</Error>
    </Wrapper>
  );
};

export default Elements;
