import React from "react";
import { useSelector } from "react-redux";
import { getFields, getValues } from "store/PaperWork/actions/functions";
import { Text } from "./styled";
import { get, isEmpty } from "lodash";
import { selectPaperworkMetadata } from "store/PaperWork/selectors";

const Item = ({ data, fields, index }) => {
  const pProg = useSelector(selectPaperworkMetadata);
  const otherInputs = get(pProg, `paperwork.stages[${index}].form.otherInputs`, []);
  const inputType = get(pProg, `paperwork.stages[${index}].form.otherInputs[0].id`, 0);
  const inputsFields = get(pProg, `paperwork.stages[${index}].form.otherInputs[0].formFields`, {});
  const field = getFields(inputType, data[0], fields, inputsFields);
  const value = getValues(inputType, otherInputs, data[1]);

  const getText = () => {
    if (Array.isArray(value) && isEmpty(value) && field) return `${field}: No hay datos`;
    if (Array.isArray(value) && isEmpty(value) && !field) return "No hay datos";
    if (field) return `${field}: ${value}`;
    return value;
  };

  if (data[0] === "storage") return null;
  if (data[0] === "fractionDenomination") return null;

  return (
    <>
      <Text>{getText()}</Text>
    </>
  );
};

export default Item;
