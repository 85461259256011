import React from "react";
// import { Wrapper, ButtonsContainer, SearchContainer, DownloadUsers, AddUser, Add, Download } from "./styled";
import { Wrapper, ButtonsContainer, SearchContainer, AddUser, Add } from "./styled";
import IconAndLabelInput from "components/commons/Inputs/IconAndLabelInput";
import SearchIcon from "components/commons/Icons/SearchIcon";
import colors from "styles/colors";
import LabelIconButton from "components/pages/PaperworkDetail/components/LabelIconButton";
import { useDispatch } from "react-redux";
import { openModal } from "store/Modals/actions";
import { MODAL_ADD_USER } from "store/Modals/constants";

const Tools = () => {
  const dispatch = useDispatch();
  const addUser = () => dispatch(openModal(MODAL_ADD_USER));

  return (
    <Wrapper>
      <SearchContainer>
        <IconAndLabelInput
          onChange={null}
          value={null}
          icon={<SearchIcon />}
          left={true}
          label={"Buscar usuario"}
          background={colors.white}
          disabled
        />
      </SearchContainer>
      <ButtonsContainer>
        {/* <DownloadUsers>
          <LabelIconButton color={colors.blue50} label="Descargar" border={`1px solid ${colors.blue70}`} icon={<Download disabled />} disabled />
        </DownloadUsers> */}
        <AddUser>
          <LabelIconButton background={colors.blue50} color={colors.white} label="Cargar usuario" icon={<Add />} onClick={addUser} />
        </AddUser>
      </ButtonsContainer>
    </Wrapper>
  );
};

export default Tools;
