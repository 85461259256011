import Img from "components/commons/Img";
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-radius: 100px;
`;

export const Image = styled(Img)`
  display: ${({ isLoading }) => (isLoading ? "none" : "flex")};
  width: 96px;
  height: 96px;
  object-fit: cover;
  border-radius: 100px;
  overflow: hidden;
  @media (max-width: 580px) {
    width: 125px;
    height: 125px;
  }
`;
