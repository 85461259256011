import React, { useState } from "react";
import MainLayout from "components/commons/MainLayout";
import colors from "styles/colors";
import Header from "./components/Header";
import { Wrapper } from "./styled";
import Selector, { USERS } from "./components/Selector";
import Sections from "./components/Sections";

const BackOffice = () => {
  const [id, setId] = useState(USERS);

  return (
    <MainLayout background={colors.gray100} header={<Header />} paddingLeft={30} paddingRight={110}>
      <Wrapper>
        <Selector setId={setId} id={id} />
        <Sections id={id} />
      </Wrapper>
    </MainLayout>
  );
};

export default BackOffice;
