import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: top;
  width: 100%;
  height: 88px;
  box-sizing: border-box;
`;

export const Label = styled.div`
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.gray50};
  @media (max-width: 905px) {
    font-size: 12px;
  }
`;
