import styled from "styled-components";
import colors from "styles/colors";
import { ReactComponent as Reject } from "images/icons/blue/reject.svg";

export const Wrapper = styled.div`
  display: flex;
  align-items: top;
  justify-content: center;
  position: sticky;
  box-sizing: border-box;
  background: ${(props) => props.active && `${colors.white}`};
  border-radius: 4px;
  padding: 4px 8px;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.4s;
`;

export const Text = styled.div`
  display: flex;
  align-items: center;
  font-family: "OpenSansRegular";
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  box-sizing: border-box;
  color: ${(props) => (props.active ? colors.orange50 : colors.gray20)};
  @media (max-width: 600px) {
    font-size: 8px;
  }
`;

export const RejectIcon = styled(Reject)`
  & > path {
    fill: ${({ color }) => (color === "orange" ? "orange" : `${colors.gray90}`)};
  }
`;
