import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex: 0 0 50%;
  flex-direction: column;
  align-items: left;
  justify-content: flex-start;
  overflow: hidden;
  margin-bottom: 16px;
`;

export const Label = styled.div`
  color: ${colors.gray50};
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
`;

export const Value = styled.div`
  color: ${colors.gray10};
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`;

export const State = styled(Value)`
  color: ${({ state }) => {
    if (state === "Aprobado") return `${colors.green50}`;
    if (state === "Rechazado") return `${colors.red50}`;
    if (state === "Observado") return `${colors.orange50}`;
    return `${colors.lightBlue50}`;
    }};
`;
