import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  gap: 8px;
  font-size: 60px;
  color: blue;
  border-radius: 17px;
  box-sizing: border-box;
  cursor: ${({ onClick }) => (onClick ? "pointer" : "default")};
  background: ${colors.gray100};
  overflow: hidden;
  padding: 16px;
`;

export const Text = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.gray40};
  @media (max-width: 420px) {
    font-size: 14px;
  }
`;

export const Data = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Domine";
  font-style: normal;
  font-weight: 400;
  font-size: 96px;
  line-height: 96px;
  color: ${colors.gray40};
  @media (max-width: 420px) {
    font-size: 76px;
  }
`;
