import React, { useEffect } from "react";
import MainLayout from "components/commons/MainLayout";
import { useDispatch, useSelector } from "react-redux";
import { selectUserCanEditPaperworkProgress, selectPaperworkProgressId } from "store/PaperWork/selectors";
import { useParams } from "react-router-dom";
import { getDocuments, getPaperworkDetail } from "store/PaperWork/actions/requests";
import colors from "styles/colors";
import Header from "../components/Header";
import { joinPaperworkDetailRoom, leavePaperworkDetailRoom } from "store/websocket/actions";
import useWebsocket from "hooks/useWebsocket";
import { closeModal, openModal } from "store/Modals/actions";
import { LOCKED_PAPERWORK_MODAL } from "store/Modals/constants";
import Detail from "../components/Detail";
import { resetPaperworkStore } from "store/PaperWork/actions/actions";

const OperatorDetail = () => {
  const dispatch = useDispatch();
  const canEdit = useSelector(selectUserCanEditPaperworkProgress);
  const pwId = useSelector(selectPaperworkProgressId);
  const { id } = useParams();

  const onConnect = () => dispatch(joinPaperworkDetailRoom(id));
  const onDisconnect = () => dispatch(leavePaperworkDetailRoom(id));

  useWebsocket(onConnect, onDisconnect);

  useEffect(() => {
    if (!canEdit) dispatch(openModal(LOCKED_PAPERWORK_MODAL));
    else dispatch(closeModal(LOCKED_PAPERWORK_MODAL));
  }, [canEdit]);

  useEffect(() => {
    if (!pwId) {
      dispatch(getPaperworkDetail(id));
      dispatch(getDocuments(id));
    }
  }, [dispatch]);
  
  useEffect(() => {
    return () => {
      dispatch(resetPaperworkStore());
    };
  }, []);

  return (
    <MainLayout background={colors.gray100} header={<Header />}>
      <Detail />
    </MainLayout>
  );
};

export default OperatorDetail;
