import React from "react";
import { PAPERWORK_STATE_NAME, PAPERWORK_STATE } from "store/PaperWork/constants";
import { Description, State, IconContainer, Item, Title, ObservedIcon, PendingIcon, FinishedIcon, Rejectedcon } from "./styled";
import get from "lodash/get";

const DeskItem = ({ state, title, onClick = null }) => {
  const getIcon = () => {
    if (state === PAPERWORK_STATE.OBSERVED) return <ObservedIcon />;
    if (state === PAPERWORK_STATE.APPROVED) return <FinishedIcon />;
    if (state === PAPERWORK_STATE.REJECTED) return <Rejectedcon />;
    return <PendingIcon />;
  };

  return (
    <Item onClick={onClick}>
      <IconContainer state={state}>{getIcon()}</IconContainer>
      <Description>
        <State>{get(PAPERWORK_STATE_NAME, state, "-")}</State>
        <Title>{`#${title}`}</Title>
      </Description>
    </Item>
  );
};

export default DeskItem;
