import styled from "styled-components";
import { ReactComponent as PauseIcon } from "images/icons/gray/Pause_outline.svg";
import { ReactComponent as PlayIcon } from "images/icons/gray/Play_outline.svg";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  cursor: pointer;
`;

export const Pause = styled(PauseIcon)``;
export const Play = styled(PlayIcon)``;
