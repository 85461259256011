import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  justify-content: left;
  width: 100%;
  min-height: 90px;
  gap: 2px;
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
`;

export const Number = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: ${colors.gray60};
  margin-bottom: auto;
`;

export const AreaContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 23%;
  gap: 2px;
  @media (max-width: 580px) {
    width: 33%;
  }
`;

export const PhoneContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 35%;
  gap: 2px;
  @media (max-width: 580px) {
    width: 45%;
  }
`;

export const Error = styled.div`
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: red;
`;
