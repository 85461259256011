import React from "react";
import DataPickers from "./component/DatePickers";
import Form from "./component/Form";
import MultipleSelector from "./component/MultipleSelector";
import Switch from "./component/Switch";
import { Content, Title, Wrapper } from "./style";

const Section = ({ section }) => {
  const { title, componentId, seeMore } = section;

  const getComponent = () => {
    switch (componentId) {
      case 1:
        return <MultipleSelector section={section} seeMore={seeMore} />;
      case 2:
        return <DataPickers section={section} />;
      case 3:
        return <Switch section={section} />;
      case 4:
        return <Form section={section} />;
      default:
        return null;
    }
  };

  return (
    <Wrapper>
      <Title>{title}</Title>
      <Content>{getComponent()}</Content>
    </Wrapper>
  );
};

export default Section;
