import styled from "styled-components";
import { fadeIn } from "styles/effects";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  box-sizing: border-box;
  background-color: ${colors.white};
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  overflow-y: overlay;
  box-sizing: border-box;
  padding-bottom: 75px;
  animation: 500ms ${fadeIn};
`;

export const Section = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
  background: ${colors.gray100};
  box-sizing: border-box;
  overflow: hidden;
`;
