const websocketInitialState = { connected: false };

export const websocketReducer = (state = { ...websocketInitialState }, action) => {
  switch (action.type) {
    case "WS_CONNECTED":
      return { ...state, connected: true };
    case "WS_DISCONNECTED":
      return { ...state, connected: false };
    default:
      return state;
  }
};