import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  row-gap: 24px;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 100%;
  gap: 16px;
  box-sizing: border-box;
  overflow: hidden;
  @media (max-width: 1440px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 580px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: top;
    width: 100%;
    overflow-y: overlay;
  }
`;

export const Text = styled.div`
  ${({ text }) => !text && "display: none;"};
  margin-right: auto;
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: ${colors.gray20};
  @media (max-width: 1366px) {
    font-size: 16px;
  }
`;
