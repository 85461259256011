import React from "react";
import { ContainerOptions, Wrapper } from "./style";
import { useSelector } from "react-redux";
import { selectParams } from "store/Modals/selectors";
import RadioButton from "./RadioButton";
import { get } from "lodash";

const RadioOptions = ({currentValue, onClick}) => {
    const params = useSelector(selectParams);
    const departureOptions = get(params, "departureOptions", []);

    const generateLabel = (departureOption) => {
        const departure = get(departureOption, "departure", "");
        const functionalUnity = get(departureOption, "functionalUnity", "");
        const departureType = get(departureOption, "departureType", "");
        return `${departure} - ${functionalUnity} - ${departureType}`;
    };

    return (
        <Wrapper>
            <ContainerOptions>
                { departureOptions.map((i, index) => <RadioButton key={index} onClick={onClick} label={generateLabel(i)} value={i.departure} checked={currentValue == i.departure} name={"radio-button"} />) }
            </ContainerOptions>
        </Wrapper>
    );
};

export default RadioOptions;