/* eslint-disable no-unused-vars */
import React from "react";
import colors from "styles/colors";
import { ApprovedIcon, Wrapper } from "./styled";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "store/Modals/actions";
import { selectPaperworkMetadata, selectUserCanEditPaperworkProgress } from "store/PaperWork/selectors";
import { MODAL_MEND } from "store/Modals/constants";
import { PAPERWORK_STATE } from "store/PaperWork/constants";
import LabelIconButton from "components/pages/PaperworkDetail/components/LabelIconButton";
import { USER_PROFILE_ID } from "store/Login/initialState";
import { selectIsAllowed } from "store/Login/selectors";

const Mending = () => {
  const dispatch = useDispatch();
  const allowedRoles = [USER_PROFILE_ID.SUBSANATOR];
  const allowed = useSelector((state) => selectIsAllowed(state, allowedRoles));
  const { state } = useSelector(selectPaperworkMetadata);
  const isStarted = state === PAPERWORK_STATE.STARTED;
  const isWaitingForMend = state === PAPERWORK_STATE.MENDING_REQUESTED;
  const canEdit = useSelector(selectUserCanEditPaperworkProgress);

  const mend = () => dispatch(openModal(MODAL_MEND));

  if (!allowed || isStarted) return null;
  return (
    <Wrapper>
      <LabelIconButton
        type={"primary"}
        disabled={!isWaitingForMend || !canEdit}
        color={colors.white}
        background={colors.blue50}
        label={"Subsanar"}
        icon={<ApprovedIcon disabled={!isWaitingForMend || !canEdit} />}
        onClick={mend}
        width={"216px"}
        height={"40px"}
      />
    </Wrapper>
  );
};

export default Mending;
