import React from "react";
import { Image, Wrapper } from "./styled";
import Spinner from "images/icons/general/spinner.gif";

const ImageLoading = ({ show, size }) => {
  if (!show) return null;

  return (
    <Wrapper>
      <Image src={Spinner} alt="image" size={size} />
    </Wrapper>
  );
};

export default ImageLoading;
