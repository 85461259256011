import styled from "styled-components";
import colors from "styles/colors";
import { ReactComponent as DefaultImage } from "images/icons/gray/image.svg";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 374px;
  box-sizing: border-box;
  background: ${colors.gray99};
  padding-bottom: 20px;
`;

export const Icon = styled(DefaultImage)`
  width: 174px;
  height: 174px;
`;

export const Img = styled.img`
  width: 100%;
  max-height: 350px;
  object-fit: contain;
  padding-bottom: 20px;
`;
