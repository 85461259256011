/* eslint-disable no-unused-vars */
import React from "react";
import Icon from "images/icons/white/arrowLeft.svg";
import { Wrapper, Image } from "./styled";

const ArrowLeftIcon = ({ onClick }) => {
  return (
    <Wrapper onClick={onClick}>
      <Image src={Icon} alt="icon" />
    </Wrapper>
  );
};

export default ArrowLeftIcon;
