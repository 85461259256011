import styled from "styled-components";
import { ReactComponent as ArrowBackIcon } from "images/icons/gray/arrow-back.svg";

export const Wrapper = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

export const ArrowBack = styled(ArrowBackIcon)`
  display: flex;
  width: 100%;
  height: 100%;
`;
