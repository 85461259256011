import styled from "styled-components";
import colors from "styles/colors";
import { ReactComponent as DownloadIcon } from "images/icons/gray/download.svg";
import { ReactComponent as AddIcon } from "images/icons/gray/account_plus_filled.svg";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 0 0 56px;
  width: 100%;
  box-sizing: border-box;
  background: ${colors.blue100};
  border: 1px solid ${colors.gray99};
  border-radius: 8px;
  padding: 12px;
  height: 32px;
  gap: 50px;
  @media (max-width: 500px) {
    gap: 0px;
  }
  @media (max-width: 720px) {
    flex-direction: column;
    height: auto;
    gap: 10px;
  }
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: top;
  justify-content: left;
  flex: 0 0 631px;
  max-height: 36px;
  box-sizing: border-box;
  @media (max-width: 600px) {
    flex: 0 0 auto;
    height: auto;
  }
  @media (max-width: 1250px) {
    flex: 0 0 300px;
  }
  @media (max-width: 720px) {
    width: 100%;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
  width: 100%;
  gap: 16px;
  margin-left: auto;
`;

export const DownloadUsers = styled.div`
  display: flex;
  flex: 0 0 131px;
  height: 40px;
  box-sizing: border-box;
  border-radius: 4px;
`;

export const AddUser = styled.div`
  display: flex;
  flex: 0 0 165px;
  height: 40px;
  box-sizing: border-box;
  border-radius: 4px;
`;

export const Add = styled(AddIcon)``;

export const Download = styled(DownloadIcon)`
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  & > path {
    fill: ${colors.blue50};
  }
`;
