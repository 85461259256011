import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  width: 100%;
  height: 100%;
  padding: 56px 32px 72px 32px;
  gap: 40px;
  box-sizing: border-box;
  @media (max-width: 1366px) {
    overflow: auto;
    padding: 56px 16px;
    gap: 20px;
  }
`;
