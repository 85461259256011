import React from "react";
import { Wrapper } from "./styled";
import { useSelector } from "react-redux";
import { selectApiDocuments, selectStorageDocuments } from "store/PaperWork/selectors";
import { get } from "lodash";
import StorageCard from "./components/StorageCard";
import ApiCard from "./components/Apicard";

const Content = () => {
  const apiDocs = useSelector(selectApiDocuments);
  const urls = get(apiDocs, "urls", []);
  const storageDocs = useSelector(selectStorageDocuments);

  return (
    <Wrapper>
      {urls.map((i) => (
        <ApiCard key={i} url={i} />
      ))}
      {storageDocs.map((i) => (
        <StorageCard key={i} url={i} />
      ))}
    </Wrapper>
  );
};

export default Content;
