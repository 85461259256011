import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFilters } from "store/Filters/actions";
import { selectFilterByKey } from "store/Filters/selectors";
import { Wrapper, Input, Content, ItemWrapper, Title } from "./style";
import moment from "moment";
import { FORMAT_DATE } from "store/Filters/constants";

const DataPickers = ({ section }) => {
  const dispatch = useDispatch();
  const { columns, elements } = section;
  const dateFrom = useSelector((state) => selectFilterByKey(state, elements[0].key));
  const dateTo = useSelector((state) => selectFilterByKey(state, elements[1].key));
  const values = [dateFrom, dateTo];

  const handleFromUpdate = (e) => {
    dispatch(setFilters(elements[0].key, moment(e.target.value).format(FORMAT_DATE)));
  };

  const handleToUpdate = (e) => {
    dispatch(setFilters(elements[1].key, moment(e.target.value).format(FORMAT_DATE)));
  };

  const handlers = [handleFromUpdate, handleToUpdate];

  const getItem = (element, index) => {
    return (
      <ItemWrapper>
        <Title>
          {element.name}
        </Title>
        <Input
          value={values[index].split("/").reverse().join("-")}
          onChange={(e) => handlers[index](e)}
          type="date"
          data-placeholder={"Ingrese una fecha"}
          aria-required="true"
          required
          />
        </ItemWrapper>
    );
  };

  return (
    <Wrapper>
      <Content columns={columns}>{elements.map(getItem)}</Content>
    </Wrapper>
  );
};

export default DataPickers;
