import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFilters } from "store/Filters/actions";
import { selectFilterByKey } from "store/Filters/selectors";
import { Wrapper, Label, Input, Switch } from "./style";

const Button = ({ section }) => {
  const { key } = section;
  const dispatch = useDispatch();
  const value = useSelector((state) => selectFilterByKey(state, key));

  const onClick = () => {
    dispatch(setFilters(key, !value));
  };

  return (
    <Wrapper>
      <Label>
        <Input checked={value} type="checkbox" onClick={onClick} />
        <Switch />
      </Label>
    </Wrapper>
  );
};

export default Button;
