/* eslint-disable react/react-in-jsx-scope */
import { PATH_BACK_OFFICE, PATH_CERTIFICATE_REQUESTS, PATH_DASHBOARD, PATH_HOME, PATH_PROFILE } from "components/pages/App/constants";
import { PaperworkIcon, DashboardIcon, UserIcon } from "components/commons/MainLayout/components/CommonLayout/components/Menu/Bar/styled";

export const SECTIONS = [
  { name: "Trámites", Component: <PaperworkIcon key="paperworks" />, section: "tramites", path: PATH_HOME },
  { name: "Administración", Component: <DashboardIcon key={"dadashboard_paperwork"} />, section: "administracion", path: PATH_DASHBOARD },
  { name: "Certificados", Component: <DashboardIcon key={"certificates"} />, section: "certificados", path: PATH_CERTIFICATE_REQUESTS },
  { name: "Usuarios", Component: <DashboardIcon key={"usuarios"} />, section: "usuarios", path: PATH_BACK_OFFICE },
];

export const PROFILE = { name: "Perfil", Component: <UserIcon key="paperworks" />, section: "perfil", path: PATH_PROFILE };
