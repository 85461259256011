/* eslint-disable no-unused-vars */
import React from "react";
import { Wrapper, Container, Lock, Title, Description, Buttons, AcceptButtonContainer, CancelButtonContainer } from "./style";
import { useDispatch } from "react-redux";
import LabelOnlyButton from "components/commons/Buttons/LabelOnlyButton";
import colors from "styles/colors";
import { closeModal } from "store/Modals/actions";
import { LOCKED_PAPERWORK_MODAL} from "store/Modals/constants";
import { push } from "connected-react-router";
import { PATH_DASHBOARD } from "components/pages/App/constants";

const LockedPaperworkModal = () => {
  const dispatch = useDispatch();

  const goBack = () => {
    dispatch(push(PATH_DASHBOARD));
  };

  const onClose = () => {
    dispatch(closeModal(LOCKED_PAPERWORK_MODAL));
  };


  return (
    <Wrapper>
      <Container>
        <Lock />
        <Title>
          Trámite bloqueado
        </Title>
        <Description>
          Este trámite está siendo editado por otro operador, por lo que solamente podrás visualizarlo.
        </Description>
        <Buttons>
          <CancelButtonContainer>
            <LabelOnlyButton 
              border={` 1px solid ${colors.blue50}`} 
              background={colors.white} 
              color={colors.blue50} 
              label="Salir"
              onClick={goBack}
            />
          </CancelButtonContainer>
          <AcceptButtonContainer>
            <LabelOnlyButton 
              border={` 1px solid ${colors.blue50}`} 
              background={colors.blue50} 
              color={colors.white} 
              label="Aceptar"
              onClick={onClose}
            />
          </AcceptButtonContainer>
        </Buttons>
      </Container>
    </Wrapper>
  );
};

export default LockedPaperworkModal;
