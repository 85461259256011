import styled from "styled-components";
import { ReactComponent as Image } from "images/icons/blue/image_outlined.svg";
import colors from "styles/colors";

export const StyledIcon = styled(Image)``;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 32px;
  margin-top: auto;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 32px;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid ${colors.blue70};
  cursor: pointer;
  transition: all 0.4s;
  background: transparent;
  &:hover {
    opacity: 0.8;
  }
  @media (max-width: 580px) {
    width: 144px;
  }
`;

export const InputText = styled.label`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-family: "OpenSansRegular";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.32px;
  color: ${colors.blue50};
  cursor: pointer;
`;

export const Input = styled.input`
  letter-spacing: 0.32px;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: ${(props) => props.height};
  padding: 14px 8px;
  outline: none;
  border-radius: 4px;
  border: 2x solid ${colors.blue50};
  box-sizing: border-box;
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.color};
  background: ${(props) => props.background};
  @media (max-width: 580px) {
    font-size: 11px;
  }
`;
