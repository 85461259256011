import styled from "styled-components";
import colors from "styles/colors";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 24px;
  box-sizing: border-box;
  border-radius: 0px 0px 16px 16px;
`;

export const LeftButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 77px;
  height: 32px;
  margin-left: auto;
  box-sizing: border-box;
  border: 1px solid ${colors.blue70};
  border-radius: 4px;
`;

export const RightButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 109px;
  height: 32px;
  margin-left: auto;
  box-sizing: border-box;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
  width: 198px;
  height: 40px;
  margin-left: auto;
  box-sizing: border-box;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.4px;
`;