import BackOfficeHeader from "components/commons/BackofficeHeader";
import React from "react";
import Search from "./component/Search";
import { NEW_KEY, SECTIONS } from "../Sections/components/Section/functions";
import Filters from "../Sections/components/Selector/components/Filters";

const Header = ({ search, setSearch, id, setId }) => {
  const DESCRIPTION = "Se detallan a continuación los trámites disponibles según su estado";

  return (
    <BackOfficeHeader
      title={"Trámites"}
      description={DESCRIPTION}
      element={<Search value={search} onChange={setSearch} />}
      id={id}
      setId={setId}
      selection={SECTIONS}
      filters={<Filters show={NEW_KEY != id} id={id} />}
    />
  );
};

export default Header;
