import MainLayout from "components/commons/MainLayout";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPaperworkHistoryFromFilters } from "store/PaperWork/actions/requests";
import colors from "styles/colors";
import DataPanel from "./DataPanel";
import Header from "./Header";
import { TableContainer, Wrapper } from "./styled";
import Table from "../../commons/Table";
import { getColumns } from "./resources";
import { resetFilters } from "store/Filters/actions";
import AdministrationFilters from "./Filters";
import { selectPaperworkList } from "store/PaperWork/selectors";
import { setPaperworkList } from "store/PaperWork/actions/actions";
import { resetGeneralStore } from "store/General/actions";

const Dashboard = () => {
  const dispatch = useDispatch();
  const data = useSelector(selectPaperworkList);

  useEffect(() => {
    dispatch(resetFilters());
    dispatch(getPaperworkHistoryFromFilters());
  }, []);

  useEffect(
    () => () => {
      dispatch(resetGeneralStore());
      dispatch(setPaperworkList([]));
      dispatch(resetFilters());
    },
    []
  );

  const columns = useMemo(() => getColumns(), []);

  return (
    <MainLayout background={colors.gray100} header={<Header />} paddingLeft={30} paddingRight={110}>
      <Wrapper>
        <DataPanel />
        <TableContainer>
          <Table columns={columns} data={data}>
            <AdministrationFilters />
          </Table>
        </TableContainer>
      </Wrapper>
    </MainLayout>
  );
};

export default Dashboard;
