import React from "react";
import { PATH_HOME } from "components/pages/App/constants";
import { BACK } from "components/commons/OptionButtons/constants";
import { useDispatch } from "react-redux";
import OptionButtons from "components/commons/OptionButtons";
import { push } from "connected-react-router";

const BackButton = () => {
  const dispatch = useDispatch();

  const setHomePage = () => {
    dispatch(push(PATH_HOME));
  };

  return (
    <OptionButtons
      leftDisb={true}
      leftText={BACK}
      rightText={BACK}
      rightAction={() => {
        setHomePage();
      }}
    />
  );
};

export default BackButton;
