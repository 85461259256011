import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  width: 50%;
  height: 100%;
  gap: 16px;
  font-size: 60px;
  color: blue;
  box-sizing: border-box;
  @media (max-width: 1034px) {
    flex: 0 0 100%;
  }
`;
