import React from "react";
import { Wrapper } from "./style";

const Button = ({ isActive, show, onClick }) => {
  if (!show) return null;

  return <Wrapper onClick={onClick}>{isActive ? "Ver menos" : "Ver más"}</Wrapper>;
};

export default Button;
