import React, { useState } from "react";
import { Wrapper, Container } from "./style";
import Content from "./components/Content";
import Header from "../components/Header";
import { MODAL_EDIT_OBSERVATION } from "store/Modals/constants";

const EditObservationModal = () => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Wrapper>
      <Container expanded={expanded}>
        <Header expanded={expanded} setExpanded={setExpanded} modal={MODAL_EDIT_OBSERVATION} />
        <Content expanded={expanded} />
      </Container>
    </Wrapper>
  );
};

export default EditObservationModal;
