/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Wrapper, Image, Info, Name, Mail, Arrow } from "./styled";
import UserIcon from "images/icons/general/user.svg";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { getPathUserPicture, PATH_PROFILE } from "components/pages/App/constants";
import { selectToken, selectUserEmail, selectUserName, selectUserPicture } from "store/Login/selectors";
import ImageLoading from "components/commons/Spinners/ImageLoading";
import { useLocation } from "react-router-dom";

const UserInfo = ({ toogle }) => {
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const userPicture = useSelector(selectUserPicture);
  const name = useSelector(selectUserName);
  const email = useSelector(selectUserEmail);
  const [isLoading, setIsLoading] = useState(true);
  const { pathname } = useLocation();
  const currentSection = pathname.split("/")[1];
  const [source, setSource] = useState(null);
  const imgSource = userPicture ? source : UserIcon;

  const onClick = () => dispatch(push(PATH_PROFILE));

  const imageOnLoad = () => setIsLoading(false);

  useEffect(() => setIsLoading(true), [userPicture]);

  useEffect(() => setSource(""), [userPicture]);

  useEffect(() => setSource(getPathUserPicture(token)), [source]);

  return (
    <Wrapper toogle={toogle} onClick={onClick}>
      <ImageLoading show={isLoading && userPicture} size={"32px"} />
      <Image src={imgSource} alt="image" toogle={toogle} onLoad={imageOnLoad} isLoading={isLoading} selected={currentSection == "perfil"} />
      <Info toogle={toogle}>
        <Name>{name}</Name>
        <Mail>{email}</Mail>
      </Info>
      <Arrow $toogle={toogle} />
    </Wrapper>
  );
};

export default UserInfo;
